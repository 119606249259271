import { Session } from '../types'

/**
 * Base permissions checks implementation
 */
export class GateKeeper<S extends Session = Session> {
  session: S

  constructor(session: S) {
    this.session = session
  }

  /**
   * Permissions check
   * @param action Action that the user is attempting to perform
   * @returns Whether the user can perform the given action
   */
  can(action: string): boolean {
    const allowedActions = this.session.currentUser?.allowedActions
    return Array.isArray(allowedActions) && Array.from(allowedActions).includes(action)
  }

  /**
   * Asynchronous version of `can`
   * @param action Action that the user is attempting to perform
   * @returns A promise resolving to whether the user can perform the given action
   */
  async canEnsureLoaded(action: string): Promise<boolean> {
    try {
      await this.session.loaded
      return this.can(action)
    } catch {
      return false
    }
  }

  /**
   * Checks if the session has loaded
   * @returns {Promise<boolean>} Boolean indicating if the session has finished loading
   */
  isSessionLoaded(): Promise<boolean> {
    return this.session.loaded
  }
}
