import { usePageState } from '@msaf/core-react'
import { useEffect } from 'react'

export const usePageHeading = (heading: string, tags?: string[]) => {
  const { setPageState } = usePageState()

  useEffect(() => {
    setPageState((prevState) => ({ ...prevState, heading, tags }))
  }, [setPageState, heading])
}
