import React from 'react'
import classNames from 'classnames'
import { MarkerIconProps } from '../index'

export interface CustomMarkerIconProps extends MarkerIconProps {
  iconFilePath: string
  isLarge?: boolean
}

export function CustomMarkerIcon({ iconFilePath, isLarge, markerStyle }: CustomMarkerIconProps) {
  const classes = classNames('c-marker-icon c-marker-icon--custom', {
    [`c-marker-icon--${markerStyle}`]: !!markerStyle,
    'c-marker-icon--large': isLarge,
  })

  return <img src={iconFilePath} className={classes} />
}
