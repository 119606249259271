import React from 'react'

export function DropdownMenu({ children }: React.PropsWithChildren<{}>) {
  return (
    <ul className='c-dropdown-menu'>
      {React.Children.map(children, (menuItems, index) => (
        /**
         * The list/menu is unlikely to change after the first render,
         * so `index` as key should fine in this case
         **/
        <li key={index} className='c-dropdown-menu__item'>
          {menuItems}
        </li>
      ))}
    </ul>
  )
}
