import React, { useEffect, useMemo } from 'react'
import { MenuActionProps, MenuLinkProps } from '../dropdown-menu/menu-item'
import { PageProvider, usePageState } from '../../../context/page-context'
import { AppHeaderMenu } from './app-header-menu'
import { AppHeaderTabs } from './app-header-tabs'
import { PageHeaderMenu, ActionsMenuItem } from './page-header-menu'
import { useTitleChange } from '../../../hooks/page-title'
import { ToolbarMenu } from './toolbar-menu'
import { DropdownHeader } from '../dropdown-header'
import { DropdownMenu } from '../dropdown-menu'
import { MenuItem } from '../dropdown-menu/menu-item'

interface AppHeaderProps {
  hideMenu: (isHidden: boolean) => void
  openMenu: () => void
  user?: {
    firstName: string
    lastName: string
    email: string
  }
  actionsMenuItems?: Array<ActionsMenuItem>
  userMenuLinks: Array<MenuActionProps | MenuLinkProps>
  isSearchMenu?: boolean
  isDownloadsMenu?: boolean
  isNotificationsMenu?: boolean
  unauthenticatedUserComponent: JSX.Element
}

function AppHeader({
  hideMenu,
  openMenu,
  user,
  userMenuLinks,
  actionsMenuItems,
  isSearchMenu = true,
  isDownloadsMenu = true,
  isNotificationsMenu = true,
  unauthenticatedUserComponent: UnauthenticatedUserComponent,
}: AppHeaderProps) {
  const { pageState } = usePageState()
  useTitleChange(pageState.heading ?? '')

  useEffect(() => {
    // Hide the nav panel/menu for full screen pages
    hideMenu(!!pageState.isFullScreen)
  }, [pageState.isFullScreen, hideMenu])

  // Hide app header for full screen pages
  if (pageState.isFullScreen) {
    return null
  }

  const userInitials = useMemo(() => `${user?.firstName[0]}${user?.lastName[0]}`, [user?.firstName, user?.lastName])

  return (
    <div className='l-page__header'>
      <header className='c-app-header'>
        <AppHeaderMenu backLink={pageState.backLink} openMenu={openMenu}>
          {/* This is where we can ensure that only user specific content ends up in the user menus */}
          <>
            {/* TODO: wire up toolbar menus and remove placeholder booleans above */}
            {isSearchMenu && (
              <ToolbarMenu icon='search' iconAriaLabel='Search'>
                <DropdownHeader headingText='Search' />
              </ToolbarMenu>
            )}

            {isDownloadsMenu && (
              <ToolbarMenu icon='folder' iconAriaLabel='Downloads' badgeCount={1}>
                <DropdownHeader headingText='Downloads' />
              </ToolbarMenu>
            )}

            {isNotificationsMenu && (
              <ToolbarMenu icon='bell' iconAriaLabel='Notifications' badgeCount={99}>
                <DropdownHeader headingText='Notifications' />
              </ToolbarMenu>
            )}

            {user ? (
              <ToolbarMenu userInitials={userInitials}>
                <DropdownHeader headingText={`${user.firstName} ${user.lastName}`} subheadingText={user.email} />
                {Array.isArray(userMenuLinks) && (
                  <DropdownMenu>
                    {userMenuLinks.map((menuLink) => (
                      <MenuItem key={menuLink.label} {...menuLink} />
                    ))}
                  </DropdownMenu>
                )}
              </ToolbarMenu>
            ) : (
              UnauthenticatedUserComponent
            )}
          </>
        </AppHeaderMenu>
        <PageHeaderMenu pageHeading={pageState.heading} tags={pageState.tags} actionsMenuItems={actionsMenuItems} />
        <AppHeaderTabs tabs={pageState.headerTabs} />
      </header>
    </div>
  )
}

export function AppShell(props: React.PropsWithChildren<AppHeaderProps>) {
  const { children, ...appHeaderProps } = props
  return (
    <PageProvider>
      <AppHeader {...appHeaderProps} />
      <div className='l-page__content'>{children}</div>
    </PageProvider>
  )
}
