import { motion } from 'framer-motion'
import React, { forwardRef } from 'react'
import { IconType } from '../../../../../types'
import { Icon } from '../../../../common'
import { Link, LinkProps } from '../../../link'
import { MenuTip } from '../../../menu-tip'
import { linkAnimations, linkLabelAnimations } from './animations'

export interface MainMenuLinkProps {
  label: string
  icon: IconType
  path: string
  exact?: boolean
}

export interface MainMenuLinkItemProps extends MainMenuLinkProps {
  closeMenu: VoidFunction
  isCollapsed?: boolean
}

const LinkWithRef = forwardRef((props: LinkProps, ref: React.ForwardedRef<HTMLAnchorElement>) => (
  <Link ref={ref} {...props}>
    {props.children}
  </Link>
))

export function MainMenuLink({ label, icon, path, exact, closeMenu, isCollapsed }: MainMenuLinkItemProps) {
  const MotionComponent = motion(LinkWithRef)

  return (
    <MotionComponent
      path={path}
      exact={exact}
      className='c-main-menu__link'
      onClick={closeMenu}
      activeClassName='c-main-menu__link--active'
      variants={linkAnimations}
    >
      <Icon role='img' icon={icon} ariaHidden={true} className='c-main-menu__link-icon' />
      <motion.span key={label} className='c-main-menu__link-label' variants={linkLabelAnimations}>
        {label}
      </motion.span>
      <MenuTip label={label} isEnabled={isCollapsed} />
    </MotionComponent>
  )
}
