import { useState, useEffect, useRef } from 'react'
import { validateLatLng, validateNZTM, nztmToLatLng } from '@msaf/maps-common'

export enum CoordinateTypes {
  NZTM = 'NZTM',
  LatLng = 'Latitude/Longitude',
  Lat = 'Latitude',
  Lng = 'Longitude',
  Easting = 'NZTM Easting',
  Northing = 'NZTM Northing',
}

interface UseInlineMapProps {
  xCoordinate?: string
  yCoordinate?: string
  projectionType?: string
}

export const LOCATION_FIELD_DEFAULT_ZOOM = {
  NO_LOCATION: 5,
  USER_LOCATION: 8,
  LOCATION_SET: 18,
  REGION_VIEW: 7,
  NZ_CENTRE: {
    easting: 1588000,
    northing: 5478000,
  },
}

export function validateCoordinates(
  coordinates: [string, string] | [number, number],
  projectionType?: string,
): boolean {
  const [x, y] = coordinates
  let isValid = false

  // Validate coordinates based on the selected projection (NZTM/LatLng)
  if (projectionType === CoordinateTypes.LatLng) {
    isValid = validateLatLng(x, y)
  } else {
    // Assumes NZTM as the default projection type
    isValid = validateNZTM(x, y)
  }

  return isValid
}

export default function useInlineMap({ xCoordinate, yCoordinate, projectionType }: UseInlineMapProps) {
  const [startPosition, setStartPosition] = useState<[number, number]>()
  // Default to undefined to allow new record validation
  const [isValidCoordinates, setIsValidCoordinates] = useState<boolean>()
  // Stores the latest projection type value
  // to be used inside the map's setLocation handler
  const projectionRef = useRef(projectionType)

  function updateProjectionRef(value: string) {
    projectionRef.current = value
  }

  useEffect(() => {
    if (xCoordinate && yCoordinate) {
      let isValid = validateCoordinates([xCoordinate, yCoordinate], projectionType)
      if (isValid) {
        // We can parse values to float safely as the validation has passed
        let startPos: [number, number] = [parseFloat(xCoordinate), parseFloat(yCoordinate)]
        if (projectionType !== CoordinateTypes.LatLng) {
          // Convert to Lat Lng
          try {
            startPos = nztmToLatLng(startPos)
          } catch {
            // Should ideally never reach here
            isValid = false
          }
        }
        setStartPosition(startPos)
      }
      setIsValidCoordinates(isValid)
    }

    // Update the projectype ref value
    if (projectionType) {
      updateProjectionRef(projectionType)
    }

    // Verify coordinates and set the start position on mount
  }, [xCoordinate, yCoordinate, projectionType])

  return {
    startPosition,
    projectionRef,
    isValidCoordinates,
    setIsValidCoordinates,
  }
}
