import React from 'react'
import { Heading } from '../../common'
import { BlockContainer, BlockContainerProps } from '../block-container'

export interface ContentBannerProps extends BlockContainerProps {
  heading?: string
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6
}

export function ContentBanner({
  heading,
  headingLevel = 1,
  children,
  blockWidth,
  blockColor,
}: React.PropsWithChildren<ContentBannerProps>) {
  return (
    <BlockContainer blockWidth={blockWidth} blockColor={blockColor}>
      <div className='c-content-banner'>
        {heading && (
          <Heading className='c-content-banner__heading' level={headingLevel}>
            {heading}
          </Heading>
        )}
        <div className='c-content-banner__body'>{children}</div>
      </div>
    </BlockContainer>
  )
}
