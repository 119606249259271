import React from 'react'

export interface ValidationMessageProps {
  id?: string
  errorMessage: string
}

export const ValidationMessage = ({ id, errorMessage }: ValidationMessageProps) => {
  const messageId = id ? `${id}--validation-msg` : undefined
  return (
    <div id={messageId} className='c-form-field__validation-msg' role='alert'>
      {errorMessage}
    </div>
  )
}
