import {
  BOOLEAN_STRING_MAP,
  DATE_RANGE_STRING_MAP,
  DEFAULT_OPERATOR_MAP,
  FILTER_STRING_MAP,
  BLANK_VALUE_STRING_MAP,
} from '../constants'
import {
  BooleanOperator,
  SupportedDisplayFilterType,
  StringifyConfigFilter as StringifyFilterConfig,
  DateFilterOperator,
  StandardFilterOperator,
} from '../types/base'
import { FilterInstance, FilterSet, FilterStringComponent, SearchQueryByFilter } from '../types/state'
import { SearchTemplate } from '../types/structure'
import { removeRedundantFilters } from './state'
import { formatDate } from '@msaf/core-common'

function _clone<T>(query: T): T {
  return JSON.parse(JSON.stringify(query))
}

/**
 * Creates a filter set with sensible defaults (no child filters, and OR boolean operator)
 * @param filters Child filters to add to the new filterset
 * @param booleanOperator Operator to use - defaults to OR
 * @returns FilterSet
 */
export function createFilterSet(
  filters: (FilterInstance | FilterSet)[] = [],
  booleanOperator: BooleanOperator = 'OR',
): FilterSet {
  return {
    filterSet: {
      booleanOperator,
      filters,
    },
  }
}

/**
 * Adds a value to an existing query, cloning the input so we don't mutate the original query
 * @param filterKey Filter to add value for
 * @param query Search query to add filter to
 * @param type Type of filter to be added
 * @param operator Operator to use for server comparison
 * @returns SearchQuery - a clone of the existing query
 */
export function addValue(
  filterKey: string,
  query: SearchQueryByFilter,
  type: SupportedDisplayFilterType,
  operator?: StandardFilterOperator | DateFilterOperator,
): SearchQueryByFilter {
  // Early exit if the desired filter key doesn't exist
  if (!(filterKey in query)) {
    return query
  }

  const q = _clone(query)

  let filterOperator = operator

  if (filterOperator === undefined) {
    filterOperator = DEFAULT_OPERATOR_MAP[type]
  }

  q[filterKey].push({ filterKey, filterOperator })
  return q
}

/**
 * Sets the value of a filter in a search query
 * @param filterKey Filter to set value for
 * @param index Index of filter state to set value of
 * @param value Value to set
 * @param query Query to set value on
 * @returns Search Query, clone of existing so as not to mutate state
 */
export function setValue(
  filterKey: string,
  index: number,
  value: string | FilterSet,
  query: SearchQueryByFilter,
): SearchQueryByFilter {
  const q = _clone(query)

  if (typeof value === 'string') {
    q[filterKey][index] = {
      filterKey,
      filterValue: value,
      filterOperator: (q[filterKey][index] as FilterInstance).filterOperator ?? '=',
    }
  } else {
    q[filterKey][index] = value as FilterSet
  }

  return q
}

/**
 * Removes filter value from a search query.
 * @param filterKey Filter to remove value from
 * @param index Index of filter
 * @param query Query to remove value from
 * @returns Updated search query - cloned so as not to mutate
 */
export function removeValue(filterKey: string, index: number, query: SearchQueryByFilter): SearchQueryByFilter {
  let q = _clone(query)

  if (q[filterKey].length === 1) {
    q = removeAllValues(filterKey, query)
    return q
  }

  q[filterKey].splice(index, 1)
  return q
}

/**
 * Removes all instances of a filter from a query
 * @param filterKey Filter to remove
 * @param query Query to remove the filter from
 * @returns
 */
export function removeAllValues(filterKey: string, query: SearchQueryByFilter): SearchQueryByFilter {
  const q = _clone(query)

  q[filterKey] = []
  return q
}

/**
 * Clears all filter instances from a search query
 * @param query Search query to update
 * @returns Clean search query
 */
export function resetSearch(query: SearchQueryByFilter): SearchQueryByFilter {
  const q = _clone(query)

  Object.keys(q).forEach((k) => {
    q[k] = []
  })
  return q
}

/**
 * Returns config with (theoretically) all operator string equivalents populated.
 * @param config Stringification config with any overrides for operator values - used for translations, etc.
 * @returns Populated config
 */
function getConfigWithDefaults(config: Partial<StringifyFilterConfig>): StringifyFilterConfig {
  let base = { ...config }

  base.baseFilters = Object.assign({}, FILTER_STRING_MAP, base.baseFilters ?? {})
  base.dateRange = Object.assign({}, DATE_RANGE_STRING_MAP, base.dateRange ?? {})
  base.booleans = Object.assign({}, BOOLEAN_STRING_MAP, base.booleans ?? {})
  base.blank_value = base.blank_value ?? BLANK_VALUE_STRING_MAP

  return base as StringifyFilterConfig
}

/**
 * Formats a filter's value to a nice value that can be used when displaying filter pills
 * @param value Value to "stringify" - formats date / lookup values
 * @param type Type of filter value that is being stringified
 * @param config Config to use when stringifying
 * @returns Stringified / formatted filter value
 */
export async function stringifyValue(
  value: string,
  type: SupportedDisplayFilterType,
  config: StringifyFilterConfig,
): Promise<string> {
  if (['date', 'date-range'].includes(type)) {
    return config.dateResolver ? config.dateResolver(value) : formatDate(value)
  }

  if (!value && config.interpretBlankAsNull) {
    return config.blank_value
  }

  if (['select', 'typeahead', 'typeahead-selector'].includes(type)) {
    return config.lookupResolver ? config.lookupResolver(value, config.optionsKey, config.searchTypeKey) : value
  }

  return value
}

/**
 * Returns a formatted value/operator pair for displaying the content of this filter
 * @param filter Filter instance to stringify
 * @param type Type of filter that this is
 * @param config Stringification config to use
 * @returns Stringified operator and value for use in displaying filter pills
 * @throws Exception if no value for the given filter
 * @throws Exception if no operator for the given filter
 * @throws Exception if no valid string mapping for the operator
 */
export async function stringifyFilter(
  filter: FilterInstance,
  type: SupportedDisplayFilterType,
  config?: Partial<StringifyFilterConfig>,
): Promise<FilterStringComponent[]> {
  const cfg = getConfigWithDefaults(config ?? {})
  let operator: string

  if (filter.filterOperator === undefined) {
    throw new Error('Stringified filters must have an operator')
  }

  if (type === 'date-range') {
    operator = cfg.dateRange[filter.filterOperator as DateFilterOperator]
  } else {
    operator = cfg.baseFilters[filter.filterOperator]
  }

  if (operator === undefined) {
    throw new Error('No valid operator mapping has been passed for this filter')
  }

  return [
    {
      operator,
    },
    { value: await stringifyValue(filter.filterValue ?? '', type, cfg) },
  ]
}

/**
 * Stringifies a filter set, returning formatted string values
 * @param filter Filter set to stringify
 * @param type Type of filter to be stringified
 * @param config Stringification config to use
 */
export async function stringifyFilterSet(
  filter: FilterSet,
  type: SupportedDisplayFilterType,
  config?: Partial<StringifyFilterConfig>,
): Promise<FilterStringComponent[]> {
  const cfg = getConfigWithDefaults(config ?? {})
  const { booleanOperator } = filter.filterSet

  let operator = cfg.booleans[booleanOperator]

  if (type === 'date-range') {
    operator = ''
  }

  const children = await Promise.all(filter.filterSet.filters.map((f) => stringifyQueryPart(f, type, config)))

  return children.reduce((prev, curr) => {
    // If the first of the date values in a date range has been added, add a space to break things up
    if (prev.length > 0 && operator) {
      prev.push({ operator })
    }
    return prev.concat(...curr)
  }, [])
}

/**
 * Stringifies a query part, either a filter or a filterSet.
 * @param filter part of the query to stringify. Must be either a filterSet or a filter
 * @param type Type of filter to stringify
 * @param config Stringification config to use
 * @returns Display string parts for this filter
 */
export async function stringifyQueryPart(
  filter: FilterInstance | FilterSet,
  type: SupportedDisplayFilterType,
  config?: Partial<StringifyFilterConfig>,
): Promise<FilterStringComponent[]> {
  const cfg = getConfigWithDefaults(config ?? {})
  if ('filterSet' in filter) {
    return stringifyFilterSet(filter, type, cfg)
  }
  return stringifyFilter(filter, type, cfg)
}

/**
 * Stringifies a query by splitting up the selected filters into bits and then stringifying each
 * @param query Search query to map to a set of pills for display
 * @param template SearchTemplate this query relates to
 * @param config Configuration for the stringification - add overrides for the defaults here
 * @returns Filter display string components broken down by filter
 */
export async function flattenFiltersToSelected(
  query: SearchQueryByFilter,
  template: SearchTemplate,
  config: Partial<StringifyFilterConfig> = {},
): Promise<{ [filter: string]: FilterStringComponent[][] }> {
  const cfg = getConfigWithDefaults(config ?? {})

  let filters = Object.keys(query)
  let flattened: { [key: string]: FilterStringComponent[][] } = {}

  for (let key of filters) {
    const filterTemplate = template.filters.find((f) => f.filterKey === key)

    // Ignore the filter if no matching filter is found in the template
    // This may happen when the search template changes dynamically and the filters need to re-render
    // or due to a misconfiguration. In either case, we want the rest of the filters to render.
    if (filterTemplate) {
      let items = await Promise.all(
        query[key]
          // Only bother to stringify those that are valid to stringify
          .filter((f) => {
            return (
              'filterSet' in f || filterTemplate.interpretBlankAsNull || ('filterValue' in f && f.filterValue !== '')
            )
          })
          // Return a promise of the parts for each filter
          .map((f) => removeRedundantFilters(f, template, true, true))
          .filter((f) => f !== undefined)
          .map((f) =>
            // Not null assertion here as we're removing undefined values in previous filter
            // The filter type, at this point, should only the supported display filter types
            stringifyQueryPart(f!, filterTemplate.type as SupportedDisplayFilterType, {
              ...cfg,
              searchTypeKey: template.searchTypeKey,
              optionsKey: filterTemplate.optionsKey,
              interpretBlankAsNull: filterTemplate.interpretBlankAsNull,
            }),
          ),
      )

      if (items.length) {
        flattened[key] = items
      }
    }
  }

  return flattened
}

/**
 * Constructs the applied filters by filter key from the search query
 * @param appliedFilters Applied filters from the search query
 * @param queryByFilter The global variable that will aggregate and store the applied filters across the recursive function calls
 * @returns void The passed `queryByFilter` will store the result
 */
export function constructAppliedQueryByFilter(
  appliedFilters: (FilterInstance | FilterSet)[],
  queryByFilter: SearchQueryByFilter,
): void {
  for (const filter of appliedFilters) {
    if ('filterSet' in filter) {
      constructAppliedQueryByFilter(filter.filterSet.filters, queryByFilter)
      // If the boolean operator in the filter set is 'AND',
      // it means the filters are meant to be reconstructed and stored as FilterSet instead of individual FilterInstances
      // The following code consolidates the individiuals filters for the particular filterKey into a filterSet,
      // with the templateFilterOperator, if present
      if (
        filter.filterSet.booleanOperator === 'AND' &&
        filter.filterSet.filters.length &&
        'filterKey' in filter.filterSet.filters[0]
      ) {
        // Get the filterKey for which templateFilterOperator is set
        const { filterKey } = filter.filterSet.filters[0]
        if (queryByFilter[filterKey]) {
          // Get the individual filter instances
          const filters = queryByFilter[filterKey]
          // Construct a filterSet
          const filterSet = createFilterSet(filters, 'AND')
          // Set the templateFilterOperator from the original applied filters
          if (filter.templateFilterOperator) {
            filterSet.templateFilterOperator = filter.templateFilterOperator
          }
          // Reset the value with the newly constructed filter set
          queryByFilter[filterKey] = [filterSet]
        }
      }
    } else {
      const key = filter.filterKey
      const value = { ...filter }
      if (key in queryByFilter) {
        queryByFilter[key].push(value)
      } else {
        queryByFilter[key] = [value]
      }
    }
  }
}

/**
 * Filter out the hidden filters so that the rest of the logic need not worry about it
 * @param searchTemplate The active search template
 * @returns The search template without the hidden filters
 */
export const getSearchTemplateWithDisplayFilters = (searchTemplate: SearchTemplate) => {
  const searchFilters = searchTemplate.filters.filter((f) => f.type !== 'hidden')
  searchTemplate.filters = searchFilters
  return searchTemplate
}
