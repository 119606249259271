import React from 'react'
import { TextInput, TextInputProps } from '@msaf/core-react'
import { FeatureMetaConfig } from '@msaf/maps-common'

export interface FeatureTextProps extends TextInputProps {
  config: FeatureMetaConfig
}

export function FeatureText(props: FeatureTextProps) {
  const { config, ...textInputProps } = props
  return (
    <TextInput type={config.type === 'number' ? 'number' : 'text'} isRequired={config.required} {...textInputProps} />
  )
}
