import React from 'react'
import { NavPanel } from '../../nav-panel'
import { AppHeaderMenu } from '../app-header-menu'
import { PageHeaderMenu } from '../page-header-menu'
import { ToolbarMenu } from '../toolbar-menu'
import { BodyTextSkeleton } from '../../../common/body-text-skeleton'
import { ContentContainer } from '../../../layout'

export function PageSkeleton({ children }: { children: React.ReactNode }) {
  return (
    <div className='l-page'>
      <NavPanel isMenuHidden={false} closeMenu={() => {}} isSkeleton />
      <div className='l-page__header'>
        <header className='c-app-header'>
          <AppHeaderMenu isSkeleton>
            <ToolbarMenu isSkeleton />
            <ToolbarMenu isSkeleton />
            <ToolbarMenu isSkeleton />
          </AppHeaderMenu>
          <PageHeaderMenu isSkeleton />
        </header>
      </div>
      <div className='l-page__content'>
        <ContentContainer>{children ? children : <BodyTextSkeleton />}</ContentContainer>
      </div>
    </div>
  )
}
