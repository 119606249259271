import React from 'react'
import { AppLogo, AppLogoProps } from '@msaf/core-react'
import { MapNavBarButton } from './map-nav-bar-button'
import { MapMenuConfig } from '../../component'

export interface MapNavBarProps {
  appLogo?: AppLogoProps
  menuConfig: Array<MapMenuConfig>
  activeMenuKey: string
  openMenuPanel: (menuKey: string) => void
  isMenuPanelOpen: boolean
  closeMenuPanel: () => void
}

export function MapNavBar({
  appLogo,
  menuConfig,
  activeMenuKey,
  openMenuPanel,
  isMenuPanelOpen,
  closeMenuPanel,
}: MapNavBarProps) {
  return (
    <nav className='c-map-nav-bar'>
      {appLogo && <AppLogo includeMarginBottom={false} {...appLogo} />}
      <ul className='c-map-nav-bar__items'>
        {menuConfig.map((menuItem) => (
          <li className='c-map-nav-bar__item' key={menuItem.menuName}>
            <MapNavBarButton
              menuKey={menuItem.menuKey}
              menuName={menuItem.menuName}
              buttonIcon={menuItem.menuIcon}
              openMenuPanel={openMenuPanel}
              activeMenuKey={activeMenuKey}
              badgeCount={menuItem.badgeCount}
              isMenuPanelOpen={isMenuPanelOpen}
              closeMenuPanel={closeMenuPanel}
            />
          </li>
        ))}
      </ul>
    </nav>
  )
}
