import classNames from 'classnames'
import React, { useEffect } from 'react'
import { InputWidth } from '../../../types'
import { Button } from '../../common'
import { FormField, LabelWithIdProps } from '../form-field'

export interface RepeatableFieldProps extends Omit<LabelWithIdProps, 'children'> {
  components: JSX.Element[] | undefined
  componentProps?: object
  fieldSize?: InputWidth
  removeField: (index: number) => void
  addField: () => void
  allowRemoveLast?: boolean
  isSkeleton?: boolean
  isDisabled?: boolean
}

export function RepeatableField({
  components,
  componentProps,
  fieldSize = InputWidth.default,
  removeField,
  addField,
  allowRemoveLast,
  isSkeleton,
  helpText,
  isDisabled,
  ...props
}: RepeatableFieldProps) {
  const inputClasses = classNames('c-repeatable-field__input', {
    [`c-repeatable-field__input--${fieldSize}`]: !!fieldSize,
  })

  useEffect(() => {
    // If component list is empty, add default fields to initialise the field
    if (!components?.length) {
      addField()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [components?.length])

  return (
    <>
      <FormField isSkeleton={isSkeleton} {...props}>
        {components &&
          components.map((component, idx) => (
            <div key={component.key} className='c-repeatable-field'>
              <div className={inputClasses}>{component}</div>
              {!isDisabled && (
                <div className='c-repeatable-field__controls'>
                  {(components.length > 1 || allowRemoveLast) && (
                    <Button
                      type='button'
                      className='c-repeatable-field__control'
                      icon='trash'
                      iconAriaLabel='Remove field'
                      buttonStyle='secondary'
                      onClick={() => removeField(idx)}
                      isSkeleton={isSkeleton}
                    />
                  )}
                  {idx === components.length - 1 && (
                    <Button
                      type='button'
                      className='c-repeatable-field__control'
                      icon='add'
                      iconAriaLabel='Add field'
                      buttonStyle='secondary'
                      onClick={() => addField()}
                      isSkeleton={isSkeleton}
                    />
                  )}
                </div>
              )}
            </div>
          ))}
        {helpText && (
          <div id={`${props.labelId}--help-text`} className='c-repeatable-field__help-text'>
            {helpText}
          </div>
        )}
      </FormField>
    </>
  )
}
