import React from 'react'
import classNames from 'classnames'
import { BlockContainer, BlockContainerProps } from '../block-container'
import { Heading } from '../../common'
import { Link } from '../../navigation'

export interface ContentImageProps {
  src: string
  altText: string // Pass in empty string if image is purely decorative
  linkPath?: string
}

export interface ContentBlockProps extends BlockContainerProps {
  heading?: string
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6
  isTextRight?: boolean
  image?: ContentImageProps
  // Allows for custom components to be output in place of standard image (i.e. custom map SVG)
  imageAlternative?: React.ReactElement
}

export function ContentBlock({
  heading,
  headingLevel = 2,
  isTextRight,
  image,
  imageAlternative,
  blockWidth,
  blockColor,
  children,
}: React.PropsWithChildren<ContentBlockProps>) {
  const blockClasses = classNames('c-content-block', {
    'c-content-block--text-right': isTextRight,
  })

  return (
    <BlockContainer blockWidth={blockWidth} blockColor={blockColor}>
      <div className={blockClasses}>
        <div className='c-content-block__text'>
          {heading && (
            <Heading className='c-content-banner__heading' level={headingLevel}>
              {heading}
            </Heading>
          )}
          {children}
        </div>
        {image &&
          (image.linkPath ? (
            <Link className='c-content-block__img-container' path={image.linkPath}>
              <img className='c-content-block__img' src={image.src} alt={image.altText} />
            </Link>
          ) : (
            <div className='c-content-block__img-container'>
              <img className='c-content-block__img' src={image.src} alt={image.altText} />
            </div>
          ))}
        {imageAlternative && <div className='c-content-block__img-container'>{imageAlternative}</div>}
      </div>
    </BlockContainer>
  )
}
