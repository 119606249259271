import { ContentContainer, Heading, Button, BodyTextContainer } from '@msaf/core-react'

export default function SessionTimeout() {
  return (
    <>
      <ContentContainer>
        <BodyTextContainer>
          <Heading level={1}>You are not signed-in</Heading>
          <p>Please sign in to continue using the app.</p>
          <p>
            Please <a href='/contact-us'>contact us</a> if you are facing problems signing into the app.
          </p>
          <p>
            <Button buttonStyle='primary' label='Sign in' />
          </p>
        </BodyTextContainer>
      </ContentContainer>
    </>
  )
}
