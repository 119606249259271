import { AuthEventHandler } from '../types'
import { AuthMethod } from './base'

export interface LocalStorageAuthOptions {
  localStorageKey: string
}

export class LocalStorageAuth extends AuthMethod {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  changePassword: () => Promise<void> = () => new Promise(() => {})
  tokenIdentifier = 'Token'
  key: string

  constructor(options?: LocalStorageAuthOptions, eventHandler?: AuthEventHandler) {
    super(eventHandler)
    this.key = options?.localStorageKey ?? 'AUTH_TOKEN'
  }

  private getToken() {
    return localStorage.getItem(this.key) ?? undefined
  }

  use = () => {
    this.token = this.getToken()
    return this.token !== undefined
  }
  init = () => {
    this._eventHandler('authenticated')
  }
  refresh = async () => {
    throw new Error('Refresh not supported for LocalStorageAuth')
  }
  login = async () => {
    throw new Error('Login not supported for LocalStorageAuth')
  }
  logout = async () => {
    throw new Error('Logout not supported for LocalStorageAuth')
  }
}
