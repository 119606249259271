import React from 'react'
import { Heading } from '../heading'

export function BodyTextSkeleton() {
  return (
    <>
      <Heading isSkeleton level={2} />
      <div className='c-skeleton c-skeleton--body-text'></div>
      <div className='c-skeleton c-skeleton--body-text'></div>
      <div className='c-skeleton c-skeleton--body-text'></div>
      <div className='c-skeleton c-skeleton--body-text'></div>
    </>
  )
}
