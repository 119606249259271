import React from 'react'

export interface KeyValuePairProps {
  label: string
  value: string
}

export function KeyValuePair({ label, value }: KeyValuePairProps) {
  return (
    <div className='c-key-value-pair'>
      <div className='c-key-value-pair__key'>{label}</div>
      <div className='c-key-value-pair__label'>{value}</div>
    </div>
  )
}
