import React, { useCallback } from 'react'
import { usePassedState } from '@msaf/core-react'
import { FeatureMetaConfig } from '@msaf/maps-common'
import { FeatureFormField } from './components/feature-form-field'

export interface CategoryFeature {
  key: string
  label: string
}

export interface Category {
  key: string
  label: string
  features: Array<CategoryFeature>
}

export interface FeatureFormProps {
  config?: Array<FeatureMetaConfig>
  data?: Record<string, string>
  activeFeature?: CategoryFeature
}

export function FeatureForm({ config, data }: FeatureFormProps) {
  const { state: internalState, setState: setInternalState } = usePassedState<Record<string, string>>(data ?? {})

  const setState = useCallback(
    (data: Record<string, string>) => {
      setInternalState(data)
    },
    [setInternalState],
  )

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    /** TODO: Perform form submission */
  }

  function setValue(key: string, value: string) {
    setState({ ...internalState, [key]: value })
  }

  return config && config.length > 0 ? (
    <form className='c-context-panel-form' onSubmit={handleSubmit}>
      {config?.map((c) => (
        <FeatureFormField key={c.key} config={c} value={internalState[c.key]} setValue={setValue} />
      ))}
    </form>
  ) : null
}
