import { ERROR_MESSAGES } from '../components/errors/messages'
import { ValidationErrors } from '../hooks/useEditForm'
import { FertiliserPurchaseItem, UnitsOfMeasure } from '../types/form-types'

export const getToastErrorMessage = (validationErrors: ValidationErrors, isSaveError: boolean) => {
  if (Object.keys(validationErrors).length) {
    return ERROR_MESSAGES.VALIDATION_ERROR
  } else if (isSaveError) {
    return ERROR_MESSAGES.SAVE_FAILED
  } else {
    return ERROR_MESSAGES.FETCH_FAILED
  }
}

// Helper function to offset id, htmlFor correctly within a repeatable section
export const offsetByIndex = (fieldId: string, index: number) => {
  return `${fieldId}${index}`
}

// Helper function to open help-centre in a new tab
export const addAttributesToOpenHelpInNewTab = (className: string) => {
  document.querySelectorAll(className).forEach((link) => {
    const isHelpLink = link.getAttribute('href')?.includes('/help-centre')
    const willOpenInNewTab = link.getAttribute('target') == '_blank'
    if (isHelpLink && !willOpenInNewTab) {
      link.setAttribute('target', '_blank')
      link.setAttribute('rel', 'noreferrer noopener')
      // Prevent react-router handling the link click
      link.addEventListener('click', (e) => {
        e.stopPropagation()
        return false
      })
    }
  })
}

export const displayedPurchaseQuantity = (item: FertiliserPurchaseItem) => {
  if (item?.unit == null) {
    return 'Not supplied'
  }

  const uomLabel =
    item?.measure === UnitsOfMeasure.litres.value ? UnitsOfMeasure.litres.label : UnitsOfMeasure.tonnes.label
  return `${item?.unit} ${uomLabel}`
}
