import React from 'react'
import classNames from 'classnames'
import { MAP_NAVIGATION_BREAKPOINT, useMediaQuery, IconType, IconWithBadge, MenuTip } from '@msaf/core-react'

export interface MapNavBarButtonProps {
  menuKey: string
  menuName: string
  buttonIcon: IconType
  activeMenuKey?: string
  badgeCount?: number
  isMenuPanelOpen: boolean
  openMenuPanel: (activeMenuKey: string) => void
  closeMenuPanel: () => void
}

export function MapNavBarButton({
  menuKey,
  menuName,
  buttonIcon,
  activeMenuKey,
  badgeCount,
  isMenuPanelOpen,
  openMenuPanel,
  closeMenuPanel,
}: MapNavBarButtonProps) {
  // Only show menu tips (on menu button hover) on portrait tablet up:
  const isDesktop = useMediaQuery(`(min-width: ${MAP_NAVIGATION_BREAKPOINT})`)

  const btnClassNames = classNames('c-toolbar-btn', {
    'c-toolbar-btn--active': menuKey === activeMenuKey && isMenuPanelOpen,
  })

  const handleClick = () => {
    if (menuKey === activeMenuKey && isMenuPanelOpen) {
      closeMenuPanel()
    } else {
      openMenuPanel(menuKey)
    }
  }

  return (
    <button className={btnClassNames} onClick={() => handleClick()}>
      <IconWithBadge icon={buttonIcon} iconAriaLabel={menuName} badgeCount={badgeCount} />

      {/* Show tooltip for menu item on hover (if not currently active or if menu panel closed) */}
      {isDesktop && (menuKey !== activeMenuKey || !isMenuPanelOpen) && (
        <MenuTip label={menuName} isEnabled={isDesktop} />
      )}
    </button>
  )
}
