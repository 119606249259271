import type { Transition } from 'history'
import { Dispatch, SetStateAction, useCallback, useRef } from 'react'
import { useBlocker } from './useBlocker'

/**
 * This is a custom implementation based on the original React Router usePrompt implementation and
 * an example implementation added by the community in the open Github issue to add the`useBlocker` and `usePrompt` hooks back the React Router.
 *
 * TODO: Replace this implementation once `usePrompt` hook is added back to the React Router codebase. https://github.com/remix-run/react-router/issues/8139
 *
 * @source
 * Original implementation: https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874
 * Community implementation: https://github.com/remix-run/react-router/issues/8139#issuecomment-1147980133
 */
export function usePrompt(setOpen: Dispatch<SetStateAction<boolean>>, when = true) {
  const cbReject = useRef<() => void>()
  const cbResolve = useRef<() => void>()

  const showPrompt = useCallback(
    () =>
      new Promise<void>((resolve, reject) => {
        cbReject.current = reject
        cbResolve.current = resolve
        setOpen(true)
      }),
    [],
  )

  const onClick = async (action: 'confirm' | 'cancel', callbackFn?: () => Promise<void>) => {
    await callbackFn?.()
    if (action === 'confirm') {
      cbResolve.current?.()
    } else {
      cbReject.current?.()
    }
    setOpen(false)
  }

  const blocker = useCallback(
    async (tx: Transition) => {
      try {
        await showPrompt()
        tx.retry()
      } catch (e) {
        // Do nothing
      }
    },
    [showPrompt],
  )

  useBlocker(blocker, when)

  return {
    onClick,
  }
}
