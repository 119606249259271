import { Button, ButtonWithoutIcon } from '@msaf/core-react'

export type FormButtonLabels =
  | 'Add another'
  | 'Save'
  | 'Save and continue'
  | 'Save and return'
  | 'Save and add another'
  | 'Save and close'
  | 'Cancel'
  | 'Return to view'
  | 'Continue'
  | 'Submit for approval'
  | 'Publish'
  | 'Submit'
  | 'Delete'

export interface ActionProps extends Omit<ButtonWithoutIcon, 'label'> {
  label: FormButtonLabels
}
interface EditButtonsProps {
  actions: Array<ActionProps>
  isLoading?: boolean
  isDisabled?: boolean
}

export default function FormEditButtons({ actions, isLoading = false, isDisabled = false }: EditButtonsProps) {
  return (
    <>
      {/** `index` is fine as a key here as the list of buttons are unlikely to change once rendered */}
      {actions.map((action: ButtonWithoutIcon, index) => (
        <Button key={index} isDisabled={isDisabled} isSkeleton={isLoading} {...action} />
      ))}
    </>
  )
}
