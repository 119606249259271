import classNames from 'classnames'
import React from 'react'
import { SkeletonTag } from './skeleton-tag'

export interface TagProps {
  label: string
  tagStyle?: 'info' | 'error' | 'success' | 'disabled'
  isSkeleton?: boolean
}

export function Tag({ label, tagStyle = 'info', isSkeleton }: TagProps) {
  if (isSkeleton) {
    return <SkeletonTag />
  }

  const tagClasses = classNames('c-tag', {
    [`c-tag--${tagStyle}`]: tagStyle,
  })

  return <div className={tagClasses}>{label}</div>
}
