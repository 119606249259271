// Use this version of inline map as we are using the Wells component as a base.
import * as L from 'leaflet'
import { MAP_DEFAULTS } from '@msaf/maps-common'
import { useEffect, useState } from 'react'
import { LeafletMap } from '@msaf/maps-react'

interface InlineMapProps {
  setLocation?: (coordinates: [number, number]) => void
  startPosition?: [number, number]
  markerIcon?: L.Icon<L.IconOptions>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getBaseLayers?: () => L.LayerGroup<any>
  gotoStartPosition?: boolean
  zoom?: number
  isMarkerDraggable?: boolean
  showMarker?: boolean
  viewMode?: boolean
}

export default function InlineMap({
  setLocation,
  startPosition,
  markerIcon,
  getBaseLayers,
  gotoStartPosition = false,
  zoom,
  viewMode = false,
  showMarker,
}: InlineMapProps) {
  const [map, setMap] = useState<L.Map | undefined>()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [marker, setMarker] = useState<L.Marker<any> | undefined>()
  const [coordinates, setCoordinates] = useState<L.LatLng>()
  const [isLocationChangedByUser, setIsLocationChangedByUser] = useState(false)

  const setMapView = (map: L.Map, startPosition: [number, number]) => {
    const [x, y] = startPosition
    if (x && y) {
      const latLngExpression = new L.LatLng(x, y)
      setCoordinates(latLngExpression)
      const currentZoom = map.getZoom()
      const newZoom = zoom && currentZoom > zoom ? currentZoom : zoom
      map.setView(latLngExpression, newZoom)
    }
  }

  useEffect(() => {
    if (showMarker && !marker && startPosition && map && !isLocationChangedByUser) {
      const startMarker = new L.Marker(startPosition, { icon: markerIcon })
      startMarker.addTo(map)
      setMarker(startMarker)
    } else if (map && coordinates && marker) {
      marker.setLatLng(coordinates)
    }
  }, [map, coordinates])

  useEffect(() => {
    if (map && startPosition && !isLocationChangedByUser) {
      setMapView(map, startPosition)
    }
    // Disable due to suggestion of setMapView which causes infinite re-renders
  }, [map, startPosition, isLocationChangedByUser])

  useEffect(() => {
    if (map && startPosition && gotoStartPosition) {
      setMapView(map, startPosition)
    }
    // Disable due to suggestion of setMapView which causes infinite re-renders
  }, [map, startPosition, gotoStartPosition])

  useEffect(() => {
    const initialiseMap = (map: L.Map) => {
      if (viewMode && startPosition) {
        const startMarker = new L.Marker(startPosition, { icon: markerIcon })
        startMarker.addTo(map)
        map.dragging.disable()
        map.zoomControl.remove()
        map.scrollWheelZoom.disable()
        map.keyboard.disable()
        map.doubleClickZoom.disable()
        return
      }

      map.on('click', (event: L.LeafletMouseEvent) => {
        const latlng = event.latlng

        if (marker) {
          marker.setLatLng(latlng)
        } else if (!marker) {
          const newMarker = new L.Marker(latlng, { icon: markerIcon })
          newMarker.addTo(map)
          setMarker(newMarker)
        }

        if (setLocation) {
          setLocation([latlng.lat, latlng.lng])
        }
        // Prevents it from entering an infinite loop where the
        // start position gets reset each time the user sets a new location
        setIsLocationChangedByUser(true)
      })

      //gis.stackexchange.com/questions/111887/leaflet-mouse-wheel-zoom-only-after-click-on-map
      // Sensible defaults for in-line map to prevent map scrolling on page scroll
      map.scrollWheelZoom.disable()
      map.on('focus', () => {
        map.scrollWheelZoom.enable()
      })
      map.on('blur', () => {
        map.scrollWheelZoom.disable()
      })
    }

    if (map) {
      initialiseMap(map)
    }

    // Excluding all other dependencies as the map initialisation should happen only once, on mount.
  }, [map, marker])

  return (
    <LeafletMap
      options={{
        center: MAP_DEFAULTS.DEFAULT_CENTER,
        crs: MAP_DEFAULTS.CRS,
        ...(getBaseLayers && { baseLayers: getBaseLayers() }),
        zoom,
      }}
      setMap={setMap}
      isInlineMap={true}
    />
  )
}
