export enum MapPanels {
  SEARCH = 'Search filters',
  LOCATION = 'Go to location',
  FEATURES = 'Add features',
  LAYERS = 'Layers',
}

export enum MarkerPanelStates {
  HIDDEN = 'hidden',
  VISIBLE = 'visible',
  EXPANDED = 'expanded',
}
