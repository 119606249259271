import classNames from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'
import { mobileMenuAnimations, desktopMenuAnimations } from './animations'
import React, { useState, useEffect } from 'react'
import { useMediaQuery } from '../../../../hooks/media-query'
import { useClickOutside } from '../../../../hooks/click-outside'
import { NAVIGATION_BREAKPOINT } from '../../../../constants'
import { IconWithBadge } from '../../../common/icon-with-badge'
import { IconType } from '../../../../types/icon-types'
import { Icon } from '../../../common/icon'

interface IconWithBadgeProps {
  icon: IconType
  iconAriaLabel: string
  badgeCount?: number
}

export interface ToolbarMenuProps extends IconWithBadgeProps {
  userInitials?: undefined
  isSkeleton?: boolean
  closeMenu?: boolean
}

export interface ToolbarUserMenuProps {
  userInitials?: string
  icon?: undefined
  iconAriaLabel?: undefined
  badgeCount?: undefined
  isSkeleton?: boolean
  closeMenu?: boolean
}

export function ToolbarMenu({
  icon,
  iconAriaLabel,
  badgeCount,
  userInitials,
  isSkeleton,
  children,
  closeMenu = false,
}: React.PropsWithChildren<ToolbarMenuProps | ToolbarUserMenuProps>) {
  const [isOpen, setIsOpen] = useState(false)

  // Conditionally select animation behaviour based on viewport size
  const isDesktop = useMediaQuery(`(min-width: ${NAVIGATION_BREAKPOINT})`)
  const menuAnimations = isDesktop ? desktopMenuAnimations : mobileMenuAnimations

  // Automatically close menu if user clicks outside component area
  const node = useClickOutside<HTMLDivElement>(() => setIsOpen(false))

  useEffect(() => {
    setIsOpen(false)
  }, [closeMenu])

  if (isSkeleton) {
    return (
      <div className='c-toolbar-menu'>
        <div className='c-skeleton c-skeleton--circle-small'></div>
      </div>
    )
  }

  const menuClasses = classNames('c-toolbar-menu', {
    'c-toolbar-menu--open': isOpen,
  })

  return (
    <div ref={node} className={menuClasses}>
      {userInitials && (
        <button className='c-toolbar-btn c-toolbar-btn--avatar' onClick={() => setIsOpen(!isOpen)}>
          <span className='c-avatar'>
            <span className='c-avatar__initials'>{userInitials}</span>
            <Icon
              className='c-avatar__dropdown-icon'
              icon='chevron-down'
              ariaLabel={'Open user menu'}
              isIconRotated180={isOpen}
            />
          </span>
        </button>
      )}
      {icon && iconAriaLabel && (
        <button className='c-toolbar-btn' onClick={() => setIsOpen(!isOpen)}>
          <IconWithBadge icon={icon} iconAriaLabel={iconAriaLabel} badgeCount={badgeCount} />
        </button>
      )}
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className='c-toolbar-menu__menu-container'
            initial={menuAnimations.initial}
            animate={menuAnimations.animate}
            exit={menuAnimations.initial}
          >
            <div className='c-toolbar-menu__content'>
              {/* Mobile only 'close' link (for full screen panel) */}
              <button
                className='c-back-link c-back-link--mobile-only c-back-link--right'
                onClick={() => setIsOpen(!isOpen)}
              >
                <Icon className='c-back-link__icon' icon='chevron-right' ariaHidden={true} />
                <span className='c-back-link__text'>Close</span>
              </button>

              {/* Output menu header, links etc here */}
              {children}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
