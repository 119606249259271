export const dropAreaDefaultVariants = {
  default: {
    opacity: 1,
    y: 0,
  },
  hover: {
    opacity: 0,
    y: -10,
    transition: {
      delay: 0.5,
    },
  },
}

export const dropAreaHoverVariants = {
  default: {
    opacity: 0,
  },
  hover: {
    opacity: 1,
    transition: {
      delay: 0.5,
    },
  },
}
