import classNames from 'classnames'
import React from 'react'

export type LabelStyle = 'default' | 'uppercase'

export interface LabelProps {
  labelText: string
  labelTag?: 'label' | 'legend' | 'div'
  htmlFor?: string
  labelId?: string
  labelStyle?: LabelStyle
  className?: string
  isSkeleton?: boolean
}

export function Label({
  labelTag: LabelTag = 'label',
  htmlFor,
  labelId,
  labelText,
  labelStyle = 'default',
  className,
  isSkeleton,
  children,
}: React.PropsWithChildren<LabelProps>) {
  if (isSkeleton) return <div className='c-skeleton c-skeleton--label' />

  const classes = classNames('c-label', {
    [`c-label--${labelStyle}`]: labelStyle !== 'default',
    [`${className}`]: !!className,
  })

  return (
    <LabelTag className={classes} htmlFor={htmlFor} id={labelId}>
      <span className='c-label__text'>{labelText}</span>
      {children}
    </LabelTag>
  )
}
