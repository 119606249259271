import { DISPLAY_DATE_FORMAT, formatDate } from '@msaf/core-common'
import classNames from 'classnames'
import React, { useMemo } from 'react'
import { Button } from '../../../../../common'
import { Icon } from '../../../../../common/icon'
import { DownloadAction, FileState, NewOrUploadedFile } from '../../../types'
import { formatBytes, getFileExtension } from '../../../utils'

export interface FileSummaryProps {
  file: NewOrUploadedFile
  isSkeleton?: boolean
  isDisabled?: boolean
  downloadAction: DownloadAction | null
}

export function FileSummary({ file, isDisabled, downloadAction }: FileSummaryProps) {
  const containerClasses = classNames('c-file-summary', {
    'c-file-summary--disabled': isDisabled,
  })

  const fileLinkClass = 'c-file-summary__file-link'

  const DownloadLink = useMemo(() => {
    if (isDisabled || file.state === FileState.Uploading) {
      return <span className={fileLinkClass}>{file.originalName}</span>
    }

    if (!downloadAction) {
      return (
        <a className={fileLinkClass} href={file.url} download={file.originalName}>
          {file.originalName}
        </a>
      )
    }

    return (
      <Button
        buttonStyle='text-link'
        label={file.originalName}
        className={fileLinkClass}
        onClick={(e) => {
          downloadAction?.(file)
          e.preventDefault()
        }}
      />
    )
  }, [file, downloadAction, file.originalName, file.id, file.state])

  return (
    <div className={containerClasses}>
      {/* TODO: should thumb link to file when clicked? Update this to link if so */}
      <div className='c-file-summary__thumb'>
        {file.state !== FileState.Uploading && file.thumbnailUrl ? (
          // TODO: image files would need a resized copy created at upload. Thumbnails not required for NWP at this stage
          <img className='c-file-summary__thumb-img' src={file.thumbnailUrl} alt='' />
        ) : (
          <Icon className='c-file-summary__icon' icon='file' ariaHidden />
        )}
      </div>
      <div className='c-file-summary__text'>
        {DownloadLink}
        {file.state !== FileState.Uploading && file.isVirusScanned ? (
          <div className='c-file-summary__details'>
            <span className='c-file-summary__detail-item'>
              {getFileExtension(file.originalName)?.toUpperCase()} {file.size > 0 && formatBytes(file.size)}
            </span>
            <span className='c-file-summary__detail-item'>
              {formatDate(file.createdTimestamp, DISPLAY_DATE_FORMAT)}
            </span>
          </div>
        ) : (
          <div className='c-file-summary__details'>
            <span className='c-file-summary__detail-item'>Virus scan pending</span>
          </div>
        )}
      </div>
    </div>
  )
}
