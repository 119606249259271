import { Button, ButtonsContainer, Heading, InlineNotification, Modal } from '@msaf/core-react'

interface ConfirmDeletionModalProps {
  submit: () => void
  handleCancel: () => void
  isOpen: boolean
  isLoading: boolean
  headingText: string
  bodyText: string
}

export const ConfirmDeletionModal = ({
  isOpen,
  handleCancel,
  submit,
  isLoading,
  headingText,
  bodyText,
}: ConfirmDeletionModalProps) => {
  return (
    <Modal isOpen={isOpen} contentLabel='Confirm deletion modal' onRequestClose={handleCancel}>
      <Heading isSkeleton={isLoading} level={2}>
        {headingText}
      </Heading>
      <InlineNotification isDismissable={false} messageType='warning'>
        {bodyText}
      </InlineNotification>

      <ButtonsContainer verticalSpacing='small' containerStyle='right'>
        <Button isSkeleton={isLoading} onClick={submit} label='Confirm' />
        <Button isSkeleton={isLoading} onClick={handleCancel} label='Cancel' buttonStyle='text-action' />
      </ButtonsContainer>
    </Modal>
  )
}
