import React from 'react'
import classNames from 'classnames'
import { InputWidth } from '../../../types/input-width-types'
import { SkeletonTextInput } from '../text-input/skeleton-text-input'
import { FieldTypes } from '../../../types'
export interface ReadOnlyFieldProps {
  fieldSize?: InputWidth
  // Required for a11y - id of label for field
  labelledBy: string
  isSkeleton?: boolean
}

export interface ReadOnlyFieldWithValueProps extends ReadOnlyFieldProps {
  value: FieldTypes
  children?: never
}

export interface ReadOnlyFieldWithChildrenProps extends ReadOnlyFieldProps {
  value?: never
}

export function formatBooleanField(fieldVal: boolean | undefined): string {
  let returnVal = ''
  if (fieldVal === true) {
    returnVal = 'Yes'
  } else if (fieldVal === false) {
    returnVal = 'No'
  }

  return returnVal
}

export function ReadOnlyField({
  value,
  fieldSize = InputWidth.default,
  labelledBy,
  isSkeleton,
  children,
}: ReadOnlyFieldWithValueProps | React.PropsWithChildren<ReadOnlyFieldWithChildrenProps>) {
  if (isSkeleton) {
    return <SkeletonTextInput />
  }

  const inputClasses = classNames('c-read-only-field', {
    [`c-read-only-field--${fieldSize}`]: !!fieldSize,
  })

  const readValue = typeof value === 'boolean' ? formatBooleanField(value) : value

  return (
    <div className={inputClasses} aria-labelledby={labelledBy}>
      {children}
      {!children && readValue}
    </div>
  )
}
