import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'

export interface GroupContainerProps {
  horizontalAlignment?: 'left' | 'right'
  verticalAlignment?: 'top' | 'center' | 'bottom'
  // Should match up with `$vertical-spacing--<size>` variables in client/src/styles/0-settings/_sizes.scss:
  verticalSpacing?: 'none' | 'default' | 'small' | 'x-small'
  orientation?: 'horizontal' | 'vertical'
  maxWidth?: 'none' | 'small' | 'medium' | 'large'
}

// First pass at layout helper/container. Can add optional config as component layout needs arise.
export function GroupContainer({
  horizontalAlignment = 'left',
  verticalAlignment = 'top',
  verticalSpacing = 'none',
  orientation = 'horizontal',
  maxWidth = 'none',
  children,
  ...props
}: PropsWithChildren<GroupContainerProps>) {
  const containerClasses = classNames('l-group-container', {
    [`l-group-container--${horizontalAlignment}`]: horizontalAlignment,
    [`l-group-container--vert-${verticalAlignment}`]: verticalAlignment,
    [`l-group-container--vert-spacing-${verticalSpacing}`]: verticalSpacing !== 'none',
    [`l-group-container--${orientation}`]: orientation !== 'horizontal',
    [`l-group-container--width-${maxWidth}`]: maxWidth !== 'none',
  })

  return (
    <div className={containerClasses} {...props}>
      {children}
    </div>
  )
}
