import React from 'react'
import { SkeletonHeading } from './skeleton-heading'

export interface HeadingProps {
  level: 1 | 2 | 3 | 4 | 5 | 6
  className?: string
  isSkeleton?: boolean
}

export function Heading({ level, className, isSkeleton, children }: React.PropsWithChildren<HeadingProps>) {
  if (isSkeleton) {
    return <SkeletonHeading level={level} />
  }

  const HeadingTag = `h${level}` as 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

  return <HeadingTag className={className}>{children}</HeadingTag>
}
