import React from 'react'
import { Heading } from '../../common/heading'

export interface DropdownHeaderProps {
  headingText: string
  subheadingText?: string
}

export function DropdownHeader({ headingText, subheadingText }: DropdownHeaderProps) {
  return (
    <div className='c-dropdown-header'>
      <Heading level={3} className='c-dropdown-header__heading'>
        {headingText}
      </Heading>
      {subheadingText && <div className='c-dropdown-header__subheading'>{subheadingText}</div>}
    </div>
  )
}
