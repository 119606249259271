import * as L from 'leaflet'
import 'leaflet.markercluster'
import { MAPBOX_BASEMAP_URL } from '../../constants'
import markerIcon from '../../../assets/map-pin-solid.svg'

export const defaultIcon = new L.Icon({ iconUrl: markerIcon, iconSize: new L.Point(25, 30) }) as L.Icon<L.IconOptions>

export const selectedIcon = new L.Icon({
  iconUrl: markerIcon,
  iconSize: new L.Point(50, 60),
  iconAnchor: new L.Point(25, 40),
}) as L.Icon<L.IconOptions>

export const getBaseLayers = () => {
  const baseLayer = L.tileLayer(MAPBOX_BASEMAP_URL, {
    tileSize: 512,
    zoomOffset: -1,
    maxZoom: 20,
    subdomains: 'abc', // This will allow Leaflet to get tiles from all subdomains
    attribution: `
      © <a href="https://www.mapbox.com/about/maps/">Mapbox</a>
      © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>
      <strong><a href="https://www.mapbox.com/map-feedback/" target="_blank">Improve this map</a></strong>
    `,
  })

  return L.layerGroup([baseLayer])
}
