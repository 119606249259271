import { formatDistanceToNow } from 'date-fns'

/**
 * Returns a nice string indicating how long between the current date and the given date.
 * Uses date-fns formatDistanceToNow
 * @param date Date to compare to
 * @param locale Locale to use
 * @returns Formatted distance to now
 */
export function formatDateRelative(date?: string | Date, locale?: Locale): string {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
        locale,
        includeSeconds: false,
      })
    : '-'
}
