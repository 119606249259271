import React from 'react'

// TODO: Update @msaf/core-react with this prop type strategy
export function ContentContainer({ children }: { children: React.ReactNode }) {
  return (
    <div id='a11y-main-content' className='l-content-container'>
      {children}
    </div>
  )
}
