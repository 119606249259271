import React, { useEffect } from 'react'
import { ContentPageHeader, ContentPageHeaderProps } from '../content-page-header'

export function ContentPage({
  appLogo,
  menuLinks,
  headerActions,
  backgroundColor,
  children,
}: React.PropsWithChildren<ContentPageHeaderProps>) {
  // Set background of body element to white on content pages
  useEffect(() => {
    document.body.style.backgroundColor = '#ffffff'

    // Revert to default style on unmount:
    return function cleanup() {
      document.body.style.backgroundColor = ''
    }
  })

  return (
    <div className='l-content-page'>
      <ContentPageHeader
        appLogo={appLogo}
        menuLinks={menuLinks}
        headerActions={headerActions}
        backgroundColor={backgroundColor}
      />
      <div id='a11y-main-content' className='l-content-page__content'>
        {children}
      </div>
    </div>
  )
}
