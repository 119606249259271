import { useMutation, useQuery, UseQueryResult } from 'react-query'
import { useAuth } from '../auth'
import fetchApi from '../services/api'
import { HttpVerb } from '../types/api-types'
import { FarmOperation } from '../types/form-types'

export function useGetFarmData<T = FarmOperation[]>(
  url: string,
  extraQueryFields: Array<string> = [],
  isEnabled = true,
): UseQueryResult<T, Error> {
  const { authStrategy } = useAuth()
  const headers = authStrategy ? authStrategy.toHeader() : {}
  return useQuery([url, ...extraQueryFields], () => fetchApi({ url, additionalHeaders: headers }), {
    enabled: isEnabled,
    refetchOnMount: 'always',
    refetchOnWindowFocus: false,
    staleTime: 0,
    cacheTime: 50,
  })
}

export function useSaveFarmData(url: string, method: HttpVerb = HttpVerb.PUT) {
  const { authStrategy } = useAuth()
  const headers = authStrategy ? authStrategy.toHeader() : {}
  return useMutation(async (fields: string) => fetchApi({ url, method, body: fields, additionalHeaders: headers }))
}

export function useDeleteData(url: string, body: Record<string, string> = {}) {
  const { authStrategy } = useAuth()
  const headers = authStrategy ? authStrategy.toHeader() : {}
  return useMutation(async () =>
    fetchApi({ url, method: HttpVerb.DELETE, additionalHeaders: headers, body: JSON.stringify(body) }),
  )
}

export function useGetBannerData() {
  const url = '/banner.json'
  return useQuery([url], () => fetchApi({ url, prefixApi: false }))
}
