import classNames from 'classnames'
import React from 'react'
import { toast, ToastContainer as ToastifyContainer, ToastContainerProps } from 'react-toastify'
import { IconType } from '../../../types/icon-types'
import { ButtonsContainer } from '../../layout/buttons-container'
import { Button } from '../button'
import { Heading } from '../heading'
import { Icon } from '../icon'

// Toast message default settings
export const TOAST_MESSAGE_AUTO_CLOSE = 20000

export const createToastMessage = toast

export interface ToastMessageActionProps {
  label: string
  onClick: () => void
}

export interface ToastMessageLinkProps {
  label: string
  path: string
}

export interface ToastMessageProps {
  title: string
  message?: string
  actions?: Array<ToastMessageActionProps | ToastMessageLinkProps>
  messageType: 'info' | 'success' | 'error'
}

function CloseButton({ closeToast }: { closeToast: () => void }) {
  return (
    <button className='c-toast__close-btn' onClick={closeToast}>
      <Icon icon='close' className='c-toast__close-icon' ariaLabel='Dismiss notification' />
    </button>
  )
}

export function ToastContainer(toastOptions: ToastContainerProps) {
  return (
    <ToastifyContainer
      position='bottom-left'
      closeButton={CloseButton}
      className='c-toast-container'
      toastClassName='c-toast'
      bodyClassName='c-toast__body'
      progressClassName='c-toast__progress-bar'
      autoClose={TOAST_MESSAGE_AUTO_CLOSE}
      {...toastOptions}
    />
  )
}

export function ToastMessage({ title, message, messageType, actions }: ToastMessageProps) {
  const iconOptions: { [key: string]: IconType } = {
    info: 'information',
    success: 'tick',
    error: 'exclamation',
  }

  const toastIconClasses = classNames('c-toast__icon', {
    'c-toast__icon--info': messageType === 'info',
    'c-toast__icon--success': messageType === 'success',
    'c-toast__icon--error': messageType === 'error',
  })

  return (
    <>
      <div className={toastIconClasses}>
        <Icon icon={iconOptions[messageType]} ariaHidden={true} />
      </div>
      <div className='c-toast__content'>
        <Heading className='c-toast__title' level={4}>
          {title}
        </Heading>
        {message && <div className='c-toast__message'>{message}</div>}
        {actions && (
          <ButtonsContainer containerStyle='left'>
            {actions.map((props) => (
              <Button key={props.label} buttonStyle='text-action' {...props} />
            ))}
          </ButtonsContainer>
        )}
      </div>
    </>
  )
}
