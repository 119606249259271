import * as L from 'leaflet'
import type _ from '@geoman-io/leaflet-geoman-free'
import { DrawOptions, SupportedDrawnLayers } from '../types'
import { mapDrawOptionsForGeoman } from './helpers'

/*
Wraps the enableDraw from geoman-io to capture the creation events and return
the object that has been created.

Allows all options apart from 'continueDrawing' as only a single create object
is returned at the moment. This could be extended in future to return all
objects.

Errors if the draw has been canceled by some other means.
Errors if the resulting shape didn't match the original this is a
crude validation that we haven't got another shapes draw result.
*/
export const asyncDrawShape = (
  map: L.Map,
  shape: L.PM.SUPPORTED_SHAPES,
  drawOptions?: Partial<DrawOptions>,
): Promise<SupportedDrawnLayers> => {
  const result = new Promise<L.Marker>((resolve, reject) => {
    const checkShapeMismatchError = (eventShape: string) => {
      if (shape !== eventShape) reject('MAP-6: Draw finished for a different shape.')
    }

    const handler: L.PM.CreateEventHandler = ({ shape: eventShape, layer }) => {
      checkShapeMismatchError(eventShape)
      resolve(layer as L.Marker)
    }

    map.once('pm:create', handler)

    map.once('pm:drawend', ({ shape: eventShape }) => {
      checkShapeMismatchError(eventShape)
      /*
      The following handles the case if a shape is not drawn. Give that the `pm:create`
      handler is triggered to fire once, it will removed when it first fires on the
      successful creation of a shape. Because it was removed by the create the fall to
      remove it here will case an exception to be thrown, which we then catch and
      suppress. On the other hand if the draw was in some way canceled and the `pm:create`
      handler didn't fire the handler removal will succeed and this promise is rejected.
      */
      try {
        map.off('pm:create', handler)
        reject('MAP-8: Draw canceled.')
      } catch {}
    })
  })

  // Enable drawing
  map.pm.enableDraw(shape, {
    ...(drawOptions ? mapDrawOptionsForGeoman(drawOptions) : {}),
    continueDrawing: false,
  })

  return result
}

const isSingleLinePolyline = (layer: L.Polyline) => {
  for (const latLng of layer.getLatLngs()) {
    if (!(latLng instanceof L.LatLng)) return false
  }
  return true
}

export const isSupportedDrawnLayer = (layer: L.Layer): layer is SupportedDrawnLayers => {
  return (
    layer instanceof L.Marker ||
    layer instanceof L.Polygon ||
    (layer instanceof L.Polyline && isSingleLinePolyline(layer))
  )
}
