import classNames from 'classnames'
import React from 'react'
import { Button } from '../../../common/button'
import { ActionsBar } from '../../../forms/actions-bar'
import { ButtonsContainer } from '../../buttons-container'

export interface RecordFooterProps {
  notes?: React.ReactNode
  areActionsFixed?: boolean
}

export function RecordFooter({ notes, areActionsFixed, children }: React.PropsWithChildren<RecordFooterProps>) {
  const footerClasses = classNames('c-record-footer', {
    'c-record-footer--has-notes': !!notes,
  })

  const scrollToTop = () => {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' })
  }

  return (
    <div className={footerClasses}>
      {notes && <div className='c-notes'>{notes}</div>}

      {areActionsFixed && (
        <div className='l-fixed-actions'>
          <div className='l-fixed-actions__fixable-element'>
            <ActionsBar>
              <Button
                onClick={scrollToTop}
                className='c-record-footer__top-btn'
                icon='arrow-up'
                iconAriaLabel='Scroll to top of page'
              />
              {children}
            </ActionsBar>
          </div>
        </div>
      )}
      {!areActionsFixed && (
        <>
          <ButtonsContainer containerStyle='right'>{children}</ButtonsContainer>
          <div className='l-fixed-actions l-fixed-actions--mobile-only'>
            <div className='l-fixed-actions__fixable-element'>
              <Button
                onClick={scrollToTop}
                className='c-record-footer__top-btn'
                icon='arrow-up'
                iconAriaLabel='Scroll to top of page'
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}
