import { MAP_NAVIGATION_BREAKPOINT, useMediaQuery } from '@msaf/core-react'
import { motion } from 'framer-motion'
import React from 'react'
import { mobileContextPanelAnimations } from './animations'
import { MapContextPanelContent, MapContextContentProps } from './map-context-panel-content'

export interface MapContextPanelProps {
  isPanelOpen: boolean
  setIsPanelOpen: (isOpen: boolean) => void
  contextPanelConfig: MapContextContentProps
}

export function MapContextPanel({
  isPanelOpen,
  setIsPanelOpen,
  contextPanelConfig,
}: React.PropsWithChildren<MapContextPanelProps>) {
  const isDesktop = useMediaQuery(`(min-width: ${MAP_NAVIGATION_BREAKPOINT})`)
  const { dragConstraints, dragElastic, dragTransition } = mobileContextPanelAnimations

  return isDesktop || !isPanelOpen ? (
    <div className='c-map-context-panel'>
      <MapContextPanelContent closePanel={() => setIsPanelOpen(false)} {...contextPanelConfig} />
    </div>
  ) : (
    <motion.div
      className='c-map-context-panel'
      drag='y'
      dragConstraints={dragConstraints}
      dragElastic={dragElastic}
      dragTransition={dragTransition}
    >
      <MapContextPanelContent closePanel={() => setIsPanelOpen(false)} {...contextPanelConfig} />
    </motion.div>
  )
}
