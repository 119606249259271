import classNames from 'classnames'
import React, { useState } from 'react'
import { NAVIGATION_BREAKPOINT } from '../../../constants'
import { useMediaQuery } from '../../../hooks'
import { blockColorOption } from '../../../types/content-page-types'
import { Icon } from '../../common/icon'
import { Button, ButtonWithIcon, ButtonWithoutIcon } from '../../common/button'
import { Link, MainMenuItemProps, NavPanel } from '../../navigation'
import { AppLogo, AppLogoProps } from '../../navigation/nav-panel/app-logo'

export type ContentPageHeaderActions = Array<ButtonWithIcon | ButtonWithoutIcon>

export interface ContentPageHeaderProps {
  appLogo: AppLogoProps
  menuLinks: Array<MainMenuItemProps>
  headerActions?: ContentPageHeaderActions
  backgroundColor?: blockColorOption
}

export function ContentPageHeader({ appLogo, menuLinks, headerActions, backgroundColor }: ContentPageHeaderProps) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const isDesktop = useMediaQuery(`(min-width: ${NAVIGATION_BREAKPOINT})`)

  const headerClasses = classNames('c-content-page-header', {
    [`c-content-page-header--${backgroundColor}`]: !!backgroundColor,
  })

  const menuClasses = classNames('c-content-page-header__menu', {
    'c-content-page-header__menu--open': isMobileMenuOpen,
  })

  const activeMenuLinkClass = !!backgroundColor ? 'c-content-menu__link--active-light' : 'c-content-menu__link--active'

  return (
    <div className={headerClasses}>
      {/* Logo / mobile menu toggle button */}
      <div className='c-content-page-header__logo-container'>
        {/* This logo hidden on mobile (displayed within menu panel below instead) */}
        {appLogo.fullLogoImagePath && (
          <Link className='c-content-page-header__logo-link' path='/' exact>
            <img className='c-content-page-header__logo' src={appLogo.fullLogoImagePath} alt={appLogo.altText} />
          </Link>
        )}
        {/* Fallback if using icon instead of custom logo image: */}
        {!appLogo.fullLogoImagePath && <AppLogo isHorizontalLayout {...appLogo} />}

        {/* Toggle mobile menu panel (hidden on desktop) */}
        <button className='c-content-page-header__menu-btn' onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
          <Icon className='c-content-page-header__menu-btn-icon' icon='list' ariaHidden={true} />
          Menu
        </button>
      </div>

      {/* Menu area */}
      <nav className={menuClasses}>
        {/*
          Note: menu has different layouts on mobile vs desktop:
          * Mobile very similar to mobile menu used in rest of app (excludes option to toggle between contracted/full layout)
          * Desktop layout displays menu links horizontally in center of header. Note that there will be limitations on number of links/length of link text with this layout.
        */}
        {isDesktop ? (
          <ul className='c-content-menu'>
            {menuLinks.map(({ label, path }) => (
              <li key={label} className='c-content-menu__item'>
                <Link path={path} className='c-content-menu__link' activeClassName={activeMenuLinkClass} exact>
                  {label}
                </Link>
              </li>
            ))}
          </ul>
        ) : (
          <NavPanel
            appLogo={appLogo}
            mainMenuLinks={menuLinks}
            isMenuHidden={false}
            closeMenu={() => setIsMobileMenuOpen(false)}
          />
        )}
      </nav>

      {/* Menu actions (login, sign up etc) */}
      {Array.isArray(headerActions) && (
        <div className='c-content-page-header__actions'>
          {headerActions.map((actionProps) => (
            <Button key={actionProps.label} {...actionProps} />
          ))}
        </div>
      )}
    </div>
  )
}
