// Framer Motion animations (see: https://www.framer.com/api/motion/animation/#variants)

export const mobileContextPanelAnimations = {
  transition: {
    type: 'spring',
    stiffness: 100,
    duration: 0.25,
  },
  dragConstraints: {
    // -55% of window height (to add to initial 45% position of pop-up panel), minus 70px space between top of viewport and panel
    top: (window.innerHeight * 0.55 - 70) * -1,
    bottom: 0,
  },
  dragTransition: {
    bounceStiffness: 700,
    bounceDamping: 30,
  },
  dragElastic: 0.05,
}
