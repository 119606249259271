import { Heading, Link, Card } from '@msaf/core-react'
import { Contact } from '../constants'

export default function ContactCard({ name, additionalResources, image }: Omit<Contact, 'link'>) {
  return (
    <Card hasTabbedContent key={name} className='c-contact-card'>
      <div className='c-contact-card__content'>
        <div className='c-contact-card__text'>
          <Heading level={2}>{name}</Heading>
          {Array.isArray(additionalResources) && additionalResources.length > 0 && (
            <>
              <h3 className='c-contact-card__subheading'>Contact details</h3>
              {additionalResources.map((resource) => {
                return (
                  <div key={resource?.label}>
                    <Link
                      target='_blank'
                      rel='noreferrer noopener'
                      key={resource?.label}
                      path={resource?.link ?? ''}
                      isExternal
                    >
                      {resource?.label}
                    </Link>
                    <br />
                  </div>
                )
              })}
            </>
          )}
        </div>
        {image && (
          <a href={image.linkPath} target='_blank' rel='noreferrer' className='c-contact-card__logo-link'>
            <img src={image.src} className='c-contact-card__logo' alt={name} />
          </a>
        )}
      </div>
    </Card>
  )
}
