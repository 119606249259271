import React, { useState, useEffect } from 'react'
import { OptionsType, OptionTypeBase } from 'react-select'
import { AUTOCOMPLETE_MIN_CHARS } from '../../../constants'
import { CheckableField } from '../checkable-fields/checkable-field'
import { FormField } from '../form-field'
import { ReadOnlyField } from '../read-only-field'
import { TextInput } from '../text-input'
import { Typeahead, TypeaheadOption } from '../typeahead'

export type EsriAddressData = {
  FullAddress: string
  AddressLine1: string
  AddressLine2: string
  AddressLine3: string
  AddressLine4: string
  AddressLine5: string
  PostCode: string
}

export type AddressFields = {
  addressLine1: string
  addressLine2: string
  addressLine3: string
  addressLine4: string
  addressLine5: string
  addressPostcode: string
  isManualEntry?: boolean
}

export type AddressData = {
  value: string
  label: string
  [key: string]: string
}

export type AddressType = 'All' | 'Physical' | 'Postal'

export type AddressSearchProps<T> = {
  isSkeleton?: boolean
  labelledBy: string
  addressFields: AddressFields
  setAddressFields: (addressFields: AddressFields) => void
  isDisabled?: boolean
  autoFocus?: boolean
  getAddressSuggestions: (query: string) => Promise<{ addresses: Array<EsriAddressData> }>
  autoCompleteMinChars?: number
  isReadOnly?: boolean
  allowManualEntry?: boolean
  validationFieldErrors: T
  isRequired?: boolean
}

/**
 * Concatenate address fields to form full address for display
 * @param addressFields Individual address fields
 * @returns string Full address
 */
export function getFullAddressFromFields(addressFields: AddressFields) {
  const { isManualEntry, ...addressLines } = addressFields

  return Object.keys(addressLines)
    .filter((fieldKey: string) => !!addressFields[fieldKey as keyof AddressFields])
    .reduce((fullAddress: string, fieldKey: string) => {
      return !!fullAddress
        ? `${fullAddress}, ${addressFields[fieldKey as keyof AddressFields]}`
        : `${addressFields[fieldKey as keyof AddressFields]}`
    }, '')
}

export const mapAddressSuggestionsToDisplayData = (suggestions: Array<EsriAddressData>) => {
  const options: AddressData[] = suggestions.map(
    ({
      FullAddress,
      AddressLine1,
      AddressLine2,
      AddressLine3,
      AddressLine4,
      AddressLine5,
      PostCode,
    }: EsriAddressData) => ({
      label: FullAddress,
      value: FullAddress,
      addressLine1: AddressLine1,
      addressLine2: AddressLine2,
      addressLine3: AddressLine3,
      addressLine4: AddressLine4,
      addressLine5: AddressLine5,
      addressPostcode: PostCode,
    }),
  )
  return options
}

export function AddressSearch<T>({
  isSkeleton,
  addressFields,
  isDisabled,
  autoFocus = false,
  isReadOnly = false,
  labelledBy,
  autoCompleteMinChars = AUTOCOMPLETE_MIN_CHARS,
  getAddressSuggestions,
  setAddressFields,
  allowManualEntry = true,
  isRequired = false,
}: AddressSearchProps<T>) {
  const [selectedOption, setSelectedOption] = useState<TypeaheadOption>({ label: '', value: '' })
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!addressFields.isManualEntry || isReadOnly) {
      setIsLoading(true)
      const fullAddress = getFullAddressFromFields(addressFields)
      setSelectedOption({ label: fullAddress, value: fullAddress })
      setIsLoading(false)
    } else {
      setSelectedOption({ label: '', value: '' })
    }
  }, [addressFields, isReadOnly])

  const handleAddressSelection = ({
    addressLine1,
    addressLine2,
    addressLine3,
    addressLine4,
    addressLine5,
    addressPostcode,
  }: AddressData) => {
    setAddressFields({
      addressLine1,
      addressLine2,
      addressLine3,
      addressLine4,
      addressLine5,
      addressPostcode,
    })
  }

  const resetAddressFields = (isManualEntry: boolean = false) => {
    setAddressFields({
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      addressLine4: '',
      addressLine5: '',
      addressPostcode: '',
      isManualEntry,
    })
  }

  const fetchSuggestions = async (inputValue: string, callback: (options: AddressData[]) => void) => {
    const response = await getAddressSuggestions(inputValue)
    const suggestions = response?.addresses
    if (suggestions) {
      const displayOptions: AddressData[] = mapAddressSuggestionsToDisplayData(suggestions)
      callback(displayOptions)
    }
  }

  const loadOptions = (inputValue: string, callback: (options: OptionsType<OptionTypeBase>) => void) => {
    if (inputValue == null || inputValue === '' || inputValue.length < autoCompleteMinChars) return callback([])
    if (inputValue.length >= autoCompleteMinChars) {
      fetchSuggestions(inputValue, callback)
    }
  }

  const setFieldValue = (field: keyof AddressFields, value: string | boolean) => {
    setAddressFields({ ...addressFields, [field]: value })
  }

  const toggleManualEntry = () => {
    resetAddressFields(!addressFields.isManualEntry)
    setSelectedOption({ label: '', value: '' })
  }

  if (isReadOnly) {
    return (
      <FormField isSkeleton={isLoading} labelId={labelledBy} label='Address'>
        <ReadOnlyField isSkeleton={isSkeleton || isLoading} labelledBy={labelledBy} value={selectedOption.value} />
      </FormField>
    )
  }

  const { addressLine1, addressLine2, addressLine3, addressLine4, addressPostcode, isManualEntry } = addressFields

  return (
    <>
      <FormField isSkeleton={isSkeleton} label='Address' htmlFor='address' isRequired={isRequired}>
        <Typeahead
          isSkeleton={isSkeleton || isLoading}
          selectType='async'
          labelledBy={labelledBy}
          handleChange={handleAddressSelection}
          selectedOption={selectedOption}
          options={[]}
          loadOptions={loadOptions}
          isClearable
          isDisabled={isDisabled || isManualEntry}
          autoFocus={autoFocus}
        />
      </FormField>
      {allowManualEntry && (
        <FormField isSkeleton={isSkeleton} label='' htmlFor='isManualEntryCheckbox'>
          <CheckableField
            id='isManualEntryCheckbox'
            isSkeleton={isSkeleton}
            name='isManualEntryCheckbox'
            type='checkbox'
            label='Enter address manually'
            checked={isManualEntry}
            onChange={toggleManualEntry}
          />
        </FormField>
      )}
      {isManualEntry && (
        <>
          <FormField isSkeleton={isSkeleton} label='Address line 1' htmlFor='addressLine1' isRequired={isRequired}>
            <TextInput
              isSkeleton={isSkeleton}
              id='addressLine1'
              value={addressLine1}
              setValue={(value) => setFieldValue('addressLine1', value)}
            />
          </FormField>
          <FormField isSkeleton={isSkeleton} label='Address line 2' htmlFor='addressLine2' isRequired>
            <TextInput
              isSkeleton={isSkeleton}
              id='addressLine2'
              value={addressLine2}
              setValue={(value) => setFieldValue('addressLine2', value)}
            />
          </FormField>
          <FormField isSkeleton={isSkeleton} label='Suburb' htmlFor='addressLine3' isRequired={isRequired}>
            <TextInput
              isSkeleton={isSkeleton}
              id='addressLine3'
              value={addressLine3}
              setValue={(value) => setFieldValue('addressLine3', value)}
            />
          </FormField>
          <FormField isSkeleton={isSkeleton} label='Town / City' htmlFor='addressLine4' isRequired>
            <TextInput
              isSkeleton={isSkeleton}
              id='addressLine4'
              value={addressLine4}
              setValue={(value) => setFieldValue('addressLine4', value)}
            />
          </FormField>
          <FormField isSkeleton={isSkeleton} label='Postcode' htmlFor='addressPostcode' isRequired={isRequired}>
            <TextInput
              isSkeleton={isSkeleton}
              id='addressPostcode'
              value={addressPostcode}
              setValue={(value) => setFieldValue('addressPostcode', value)}
            />
          </FormField>
        </>
      )}
    </>
  )
}
