import classNames from 'classnames'
import React from 'react'
import { Heading } from '../../common/heading'

export interface HeadingRowProps {
  headingText: string | React.ReactNode
  headingLevel: 1 | 2 | 3 | 4 | 5 | 6
  headingClassNames?: string
  // Should match up with `$vertical-spacing--<size>` variables in client/src/styles/0-settings/_sizes.scss:
  verticalSpacing?: 'none' | 'default' | 'small' | 'x-small'
  isSkeleton?: boolean
}

export function HeadingRow({
  headingText,
  headingLevel,
  headingClassNames,
  verticalSpacing = 'small',
  isSkeleton,
  children,
}: React.PropsWithChildren<HeadingRowProps>) {
  const rowClasses = classNames('l-heading-row', {
    [`l-heading-row--vert-spacing-${verticalSpacing}`]: verticalSpacing !== 'none',
  })

  const headingClasses = classNames('l-heading-row__heading', {
    [`${headingClassNames}`]: !!headingClassNames,
  })

  return (
    <div className={rowClasses}>
      <div className='l-heading-row__heading-container'>
        <Heading level={headingLevel} className={headingClasses} isSkeleton={isSkeleton}>
          {headingText}
        </Heading>
      </div>
      <div className='l-heading-row__actions-container'>{children}</div>
    </div>
  )
}
