import { Heading } from '@msaf/core-react'
import { Icon } from '@msaf/core-react'

// TODO: Move to core-react.

export const LoadingPage = ({ header }: { header?: string }) => {
  return (
    <div
      style={{
        margin: 'auto',
        width: 300,
        height: 100,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        textAlign: 'center',
      }}
    >
      <Icon icon='spinner' size='large' ariaHidden />
      <Heading level={1}>{header ?? 'Loading...'}</Heading>
    </div>
  )
}
