import * as L from 'leaflet'
import proj4, { ProjectionDefinition, TemplateCoordinates } from 'proj4'
import { DEFAULT_MARKER_ICON } from '../constants'
import { MapViewOptions, setMapView, setMapViewWithMarker } from './map'

export const MAP_DEFAULTS = {
  MAX_ZOOM: 20,
  MIN_ZOOM: 0,
  DEFAULT_ZOOM: 4,
  INLINE_MAP_DEFAULT_ZOOM: 11,
  MAX_CLUSTERING_ZOOM_LEVEL: 13,
  /**
   * Default Center to use.
   */
  // This is centered on the subway station of the future
  DEFAULT_CENTER: L.latLng(-37.787009, 175.278426),
  // WGS 84 / Pseudo-Mercator -- Spherical Mercator, Google Maps, OpenStreetMap, Bing, ArcGIS, ESRI
  CRS: L.CRS.EPSG3857,
}

// Default projections allowed by proj4, as well as NZTM
const ALLOWED_PROJECTIONS = [
  'EPSG:4326', // World Geodetic System 1984
  'WGS84', // Alias for EPSG:4326

  'EPSG:4269', // NAD83 -- Coordinate system for North America

  'NZTM', // NZ Transverse Mercator

  // The following are all WGS-84 (Web Mercator) Pseudo-Mercator
  'EPSG:3857',
  'EPSG:3785',
  'GOOGLE',
  'EPSG:900913',
  'EPSG:102113',
]

proj4.defs(
  'NZTM',
  '+proj=tmerc +lat_0=0 +lon_0=173 +k=0.9996 +x_0=1600000 +y_0=10000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs',
)

/**
 * Defines a projection for use in the system.
 * Also adds the projection to the list of allowed projections for use in projection validation
 * @param name Projection name
 * @param projection Projection definition
 */
export function defineProjection(name: string, projection: string | ProjectionDefinition): void {
  proj4.defs(name, projection)
  ALLOWED_PROJECTIONS.push(name)
}

/**
 * @throws Throws an error if not a valid projection
 * @param projection Projection name
 */
function validateProjection(projection: string): void {
  if (!ALLOWED_PROJECTIONS.includes(projection)) {
    throw new Error(
      `Invalid projection specified, please specify one of the following projections, or define a new projection using the "defineProjection" method\n${JSON.stringify(
        ALLOWED_PROJECTIONS,
        undefined,
        2,
      )}`,
    )
  }
}

/**
 * Translates coordinates from one projection to another
 * @throws Throws an error if the projection is not in the list of allowed projections
 * @param fromProjection Projection name to translate from
 * @param toProjection Projection name to translate to
 * @param coordinates Coordinates to translate
 */
export function convertProjection<T extends TemplateCoordinates>(
  fromProjection: string,
  toProjection: string,
  coordinates: T,
): T
export function convertProjection(
  fromProjection: string,
  toProjection: string,
  coordinates: [number, number],
): [number, number] {
  validateProjection(fromProjection)
  validateProjection(toProjection)
  return proj4(fromProjection, toProjection, coordinates)
}

/**
 * Checks the lat/lng provided fall within bounds for a valid lat / lng (WGS84)
 * @param lat Latitude
 * @param lng Longitude
 */
export function validateLatLng(lat: number | string, lng: number | string): boolean {
  if (isNaN(parseFloat(`${lat}`)) || isNaN(parseFloat(`${lng}`))) return false

  // https://epsg.io/4326
  return lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180
}

/**
 * Checks the easting / northing provided fall within bounds for a valid easing / northing
 * @param easting Easting
 * @param northing Northing
 */
export function validateNZTM(easting: number | string, northing: number | string): boolean {
  if (isNaN(parseFloat(`${easting}`)) || isNaN(parseFloat(`${northing}`))) return false

  return easting >= 1000000 && easting <= 2100000 && northing >= 4700000 && northing <= 6300000
}

/**
 * Convert NZTM coordinates to WGS84
 * @throws throws an error if coordinates are not within bounds for NZTM coordinates
 * @param coordinates NZTM coordinates
 */
export function nztmToLatLng(coordinates: [number, number]): [number, number] {
  const isValid = validateNZTM(...coordinates)

  if (!isValid) {
    throw new Error('NZTM coordinates provided do not appear to be valid.')
  }

  const latlng = convertProjection('NZTM', 'WGS84', coordinates)
  // Reverse the latlng array to get the latitude and longitude values in correct order
  latlng.reverse()

  return latlng
}

/**
 * Convert WGS84 to NZTM
 * @throws throws an error if coordinates are not within valid bounds for WGS84
 * @param coordinates WGS84 lat/lng
 */
export function latLngToNZTM(coordinates: [number, number]): [number, number] {
  const isValid = validateLatLng(...coordinates)

  if (!isValid) {
    throw new Error('WGS84 coordinates provided do not appear to be valid.')
  }

  // Reverse the latlng array to get the correct NZTM values
  coordinates.reverse()
  return convertProjection('WGS84', 'NZTM', coordinates)
}

/**
 * Changes viewport to focus on Easting/Northing location
 * @param map L.Map object to move location on
 * @param easting Target easting
 * @param northing Target northing
 */
export function goToNZTM(map: L.Map, easting: number, northing: number): void {
  const [lat, lng] = nztmToLatLng([easting, northing])
  setMapView(map, new L.LatLng(lat, lng))
}

export function goToLatLng(
  map: L.Map,
  lat: number,
  lng: number,
  extents?: { latMin: number; lngMin: number; latMax: number; lngMax: number },
  locationIcon?: L.Icon<L.IconOptions> | L.DivIcon,
) {
  const position = new L.LatLng(lat, lng)
  const marker = new L.Marker(position, { icon: locationIcon ?? DEFAULT_MARKER_ICON, draggable: true })
  let mapOptions: MapViewOptions = { zoomLevel: MAP_DEFAULTS.MAX_ZOOM }
  if (extents) {
    const bounds = [
      [extents.latMin, extents.lngMin] as L.LatLngTuple,
      [extents.latMax, extents.lngMax] as L.LatLngTuple,
    ]
    mapOptions = {
      ...mapOptions,
      bounds,
    }
  }
  setMapViewWithMarker(map, marker, position, mapOptions)
}

export function resetDefaultView(map: L.Map) {
  setMapView(map, MAP_DEFAULTS.DEFAULT_CENTER, {
    zoomLevel: MAP_DEFAULTS.DEFAULT_ZOOM,
  })
}

export function getDefaultBaseLayer() {
  const base = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 20,
    attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
  })

  return L.layerGroup([base])
}

export function validateCoordinates(crs: string | undefined, easting: string | number, northing: string | number) {
  switch (crs) {
    case 'EPSG3857': // WGS84
      return validateLatLng(easting, northing)
    case 'EPSG2193': // NZTM
      return validateNZTM(easting, northing)
    default:
      return false
  }
}

export function convertToLatLng(crs: string | undefined, coordinates: [number, number]) {
  switch (crs) {
    case 'EPSG3857': // WGS84
      return coordinates
    case 'EPSG2193': // NZTM
      return nztmToLatLng(coordinates)
    default:
      throw Error('Invalid coordinate system supplied')
  }
}
