// Intended as a sensible default. May not be fit for all use cases
export type User = {
  username: string
  firstName?: string
  lastName?: string
  email?: string
  phoneNumber?: string
  allowedActions: string[]
  roleDisplayName: string
  authType?: string
  isStaffAuth?: boolean
}

export type Session<T extends User = User> = {
  loaded: Promise<boolean>
  isAuthenticated: boolean
  currentUser?: T
}

export enum YesNo {
  Yes = 'Yes',
  No = 'No',
}
