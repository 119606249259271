import {
  Button,
  ButtonsContainer,
  Card,
  ConfirmTransition,
  Divider,
  FormField,
  Heading,
  InlineNotification,
  Link,
  RecordFooter,
  RepeatableSection,
  Typeahead,
  TypeaheadOption,
} from '@msaf/core-react'

import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ConfirmDeletionModal } from '../../../../../../components/confirm-submit-form-modal'
import { ContentContainer } from '../../../../../../components/content-container'
import { FertiliserApplicationForm } from '../../../../../../components/fertiliser-application-form'
import FormEditButtons, { ActionProps } from '../../../../../../components/form-edit-buttons'
import { LoadingPage } from '../../../../../../components/loading-page'
import { useDeleteData, useGetFarmData, useSaveFarmData } from '../../../../../../hooks/useApi'
import { useBackLink } from '../../../../../../hooks/useBacklink'
import useEditForm, { ValidationErrors } from '../../../../../../hooks/useEditForm'
import { usePageHeading } from '../../../../../../hooks/usePageHeading'
import useToastMessage from '../../../../../../hooks/useToastMessage'
import { useUserPermission } from '../../../../../../services/permissions'
import { getToastErrorMessage } from '../../../../../../services/utils'
import { HttpVerb } from '../../../../../../types/api-types'
import { FertiliserApplication, FormFieldArrayTypesEdit, FormFieldsEdit } from '../../../../../../types/form-types'
import { UserPermissions } from '../../../../../../types/permissions'
import { RouteParams } from '../../../../../../types/route'

type FertiliserApplicationRouteParams = RouteParams & { fertiliserApplicationId: string }

export const FertiliserApplications = () => {
  const { id, fertiliserApplicationId } = useParams<FertiliserApplicationRouteParams>()
  const [canViewFarmOperations, isLoadingPermissions] = useUserPermission(UserPermissions.MANAGE_FARM)
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const [selectedContiguousLandHolding, setSelectedContiguousLandHolding] = useState<TypeaheadOption>()
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState<boolean>(false)
  const [fertiliserApplication, setFertiliserApplication] = useState<FertiliserApplication>()

  const isNewRecord = fertiliserApplicationId === 'new'

  usePageHeading('Add fertiliser applications')

  const url = `${id}/fertiliser-application/${fertiliserApplicationId}`

  const stateQueryResult = useGetFarmData(url)
  const saveMutationResult = useSaveFarmData(url, isNewRecord ? HttpVerb.POST : HttpVerb.PUT)
  const {
    mutate: deleteData,
    isSuccess: isDeleteSuccess,
    isError: isDeleteError,
  } = useDeleteData(url, { contiguousLandHoldingId: fertiliserApplication?.contiguousLandHoldingId ?? '' })

  const navigate = useNavigate()
  useBackLink({ path: '', label: 'Land holdings' })

  const {
    fields,
    isLoading,
    isError,
    isSaveError,
    isSaved,
    validationErrors,
    setValue,
    handleSave,
    addField,
    removeField,
  } = useEditForm<FormFieldsEdit & FormFieldArrayTypesEdit>({
    stateQueryResult,
    saveMutationResult,
    setIsDirty,
  })

  useEffect(() => {
    // Set the fertiliser application to display when this component is used for edit
    if (!isNewRecord) {
      fields.contiguousLandHoldings?.forEach((item) => {
        const result = item.fertiliserApplications?.find(
          (item) => item.fertiliserApplicationId === fertiliserApplicationId,
        )
        if (result) {
          setFertiliserApplication(result)
        }
      })
    }
  }, [fields])

  useEffect(() => {
    if (fertiliserApplication?.contiguousLandHoldingId) {
      setValue('contiguousLandHoldingId', fertiliserApplication?.contiguousLandHoldingId)
      // Make sure isDirty is set to false to prevent the confirm transition moddal as we are artificially setting the contiguousLandHoldingId
      setIsDirty(false)
    }
  }, [fertiliserApplication])

  useToastMessage({
    isError: isError || isSaveError || isDeleteError,
    isSuccess: isSaved || isDeleteSuccess,
    errorMessage: getToastErrorMessage(validationErrors, isSaveError),
  })

  const getContiguousLandHoldingOptions = () => {
    if (Array.isArray(fields.contiguousLandHoldings) && fields.contiguousLandHoldings.length) {
      return fields.contiguousLandHoldings.map((item) => {
        return { label: `${item.landName} - ${item.reportingYear}` ?? '', value: item.contiguousLandHoldingId ?? '' }
      })
    }
  }

  const formEditActions: Array<ActionProps> = [
    {
      label: 'Save',
      onClick: () => handleSave(() => navigate(`/farm/edit/${id}/fertiliser-applications`)),
    },
    {
      label: 'Cancel',
      onClick: () => navigate(`/farm/edit/${id}/fertiliser-applications`),
      buttonStyle: 'text-action',
    },
  ]

  if (!isNewRecord) {
    formEditActions.push({
      label: 'Delete',
      onClick: () => setIsDeleteConfirmationModalOpen(true),
      buttonStyle: 'danger',
    })
  }

  const handleDelete = async () => {
    deleteData()
  }

  useEffect(() => {
    // Ensure we wait until the delete has succeeded before
    // navigating to the list view, otherwise that can load
    // the items that are being deleted
    if (isDeleteSuccess) {
      setIsDeleteConfirmationModalOpen(false)
      navigate(`/farm/edit/${id}/fertiliser-applications`)
    }
    if (isDeleteError) {
      setIsDeleteConfirmationModalOpen(false)
    }
  }, [isDeleteSuccess, isDeleteError])

  if (isLoading || isLoadingPermissions) return <LoadingPage />
  return canViewFarmOperations ? (
    <>
      <ConfirmTransition
        when={isDirty}
        contentLabel='Unsaved changes confirmation dialog'
        actionConfig={[
          {
            key: 'continue',
            type: 'cancel',
            buttonStyle: 'primary',
            label: 'Continue editing',
          },
          {
            key: 'discard',
            type: 'confirm',
            buttonStyle: 'text-action',
            label: 'Discard changes',
          },
        ]}
      />
      <ContentContainer>
        <ButtonsContainer containerStyle='left'>
          <Button
            onClick={() => navigate(`/farm/edit/${id}/fertiliser-applications`)}
            label='Back'
            iconAlignment='left'
            icon='arrow-left'
            buttonStyle='text-action'
          />
        </ButtonsContainer>
        {isNewRecord && fields?.contiguousLandHoldings?.length ? (
          <>
            {!selectedContiguousLandHolding && (
              <InlineNotification isDismissable messageType='info'>
                Please select a contiguous land holding to submit your fertiliser applications
              </InlineNotification>
            )}
            <Heading level={2}>Contiguous Land Holding</Heading>
            <FormField
              isRequired
              isSkeleton={isLoading}
              label='Selected land holding'
              labelId='contiguousLandHolding'
              validationMessages={validationErrors?.contiguousLandHoldingId}
            >
              <Typeahead
                isSkeleton={isLoading}
                labelledBy='contiguousLandHolding'
                selectedOption={selectedContiguousLandHolding}
                options={getContiguousLandHoldingOptions() ?? [{ label: '', value: '' }]}
                handleChange={(option) => {
                  setSelectedContiguousLandHolding(option)
                  setValue('contiguousLandHoldingId', option.value)
                }}
                isInvalid={!!validationErrors?.contiguousLandHoldingId}
              />
            </FormField>

            {selectedContiguousLandHolding && (
              <>
                <Divider />
                <Heading level={2}>Fertiliser</Heading>
                <InlineNotification messageType='info' isDismissable={false}>
                  If you make an error while entering fertiliser application data you can delete that record by clicking
                  the Remove button and clicking Save at the bottom of the page. Prior to final submission you will be
                  able to review all information for accuracy.
                </InlineNotification>
                <RepeatableSection
                  heading=''
                  sectionName='Application'
                  removeField={(index: number) => removeField('fertiliserApplications', index)}
                  addField={() =>
                    // Default dateTimeApplied for a new section to todays date
                    addField('fertiliserApplications', {
                      dateTimeApplied: new Date().toISOString(),
                    })
                  }
                  allowEmpty={true}
                  isSkeleton={isLoading}
                >
                  {fields.fertiliserApplications?.map((field: Partial<FertiliserApplication>, index) => (
                    <FertiliserApplicationForm
                      key={index}
                      fields={field}
                      isLoading={isLoading}
                      index={index}
                      setValue={(key, value) => setValue('fertiliserApplications', value, key, index)}
                      validationErrors={validationErrors as Array<ValidationErrors>}
                    />
                  ))}
                </RepeatableSection>
              </>
            )}
          </>
        ) : (
          isNewRecord && (
            <InlineNotification messageType='warning' isDismissable={false}>
              You must create a contiguous land holding {<Link path={`/farm/edit/${id}/land-holding`}>here</Link>}{' '}
              before adding fertiliser applications
            </InlineNotification>
          )
        )}
        {!isNewRecord && fields.fertiliserApplications && (
          <Card>
            <FertiliserApplicationForm
              fields={fertiliserApplication ?? {}}
              isLoading={isLoading}
              index={1}
              setValue={setValue}
              validationErrors={validationErrors as Array<ValidationErrors>}
            />
          </Card>
        )}

        <RecordFooter>
          <FormEditButtons actions={formEditActions} isLoading={isLoading} />
        </RecordFooter>
      </ContentContainer>
      <ConfirmDeletionModal
        headingText='Confirm deletion'
        bodyText='Please confirm you wish to delete this fertiliser application record.'
        isOpen={isDeleteConfirmationModalOpen}
        isLoading={isLoading}
        handleCancel={() => setIsDeleteConfirmationModalOpen(false)}
        submit={() => handleDelete()}
      />
    </>
  ) : null
}
