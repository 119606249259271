import { AnimatePresence, motion } from 'framer-motion'
import React from 'react'
import { MapMenuConfig } from '../../component'
import { MapPanelContent } from './map-panel-content'

export interface MapMenuPanelProps {
  menuConfig: Array<MapMenuConfig>
  activeMenuKey: string
  closeMenuPanel: () => void
}

const animationVariants = {
  visible: { x: 0, opacity: 1, display: 'block' },
  hidden: { x: -10, opacity: 0, display: 'none' },
}

export function MapMenuPanel({
  menuConfig,
  activeMenuKey,
  closeMenuPanel,
}: React.PropsWithChildren<MapMenuPanelProps>) {
  return (
    <div className='c-map-menu-panel'>
      <AnimatePresence>
        {menuConfig.map(({ menuName, menuKey, menuComponent }) => {
          return (
            <motion.div
              key={menuName}
              className='c-map-menu-panel__content'
              animate={menuKey === activeMenuKey ? 'visible' : 'hidden'}
              variants={animationVariants}
            >
              <MapPanelContent heading={menuName} closePanel={closeMenuPanel}>
                {menuComponent}
              </MapPanelContent>
            </motion.div>
          )
        })}
      </AnimatePresence>
    </div>
  )
}
