import React from 'react'

export interface RecordContainerProps {
  workflowNav?: React.ReactNode
}

export function RecordContainer({ workflowNav, children }: React.PropsWithChildren<RecordContainerProps>) {
  return (
    <div className='l-record-container'>
      {workflowNav && <div className='l-record-container__nav'>{workflowNav}</div>}
      <div className='l-record-container__content'>{children}</div>
    </div>
  )
}
