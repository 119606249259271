import classNames from 'classnames'
import React from 'react'
import { ButtonsContainer } from '../../layout/buttons-container'

export interface ActionsBarProps {
  barStyle?: 'grey' | 'transparent'
}

export function ActionsBar({ barStyle = 'grey', children }: React.PropsWithChildren<ActionsBarProps>) {
  const classes = classNames('c-actions-bar', {
    [`c-actions-bar--${barStyle}`]: barStyle !== 'grey',
  })

  return (
    <div className={classes}>
      <ButtonsContainer containerStyle='right'>{children}</ButtonsContainer>
    </div>
  )
}
