import { ContentContainer } from '../../components/content-container'
import { LoadingPage } from '../../components/loading-page'
import { usePageHeading } from '../../hooks/usePageHeading'
import { useUserPermission } from '../../services/permissions'
import { UserPermissions } from '../../types/permissions'

import { Button, ButtonsContainer, Card, Heading, InlineNotification, Table, TableSortByProps } from '@msaf/core-react'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../auth'
import { ERROR_MESSAGES } from '../../components/errors/messages'
import { DEFAULT_TABLE_RESULTS_PER_PAGE } from '../../constants'
import { useGetFarmData } from '../../hooks/useApi'
import useToastMessage from '../../hooks/useToastMessage'
import fetchApi from '../../services/api'
import { HttpVerb } from '../../types/api-types'
import {
  COMPLETE_COLUMN_HEADERS,
  COMPLETE_ROW_CLICK_ACTION,
  FARM_COLUMN_HEADERS,
  FARM_ROW_CLICK_ACTION,
} from './table-config'
import { DashboardFarmOperations } from '../../types/form-types'

export const Dashboard = () => {
  usePageHeading('Dashboard')
  const [canCreateFarmOperation, isLoadingPermissions] = useUserPermission(UserPermissions.MANAGE_FARM)
  const { authStrategy } = useAuth()
  const [farms, setFarms] = useState<Record<string, string | number | boolean>[] | null>([])
  const [completedSubmissions, setCompletedSubmissions] = useState<Record<string, string | number | boolean>[] | null>(
    [],
  )
  const [currentPage, setCurrentpage] = useState(1)
  const navigate = useNavigate()

  const defaultSortBy: TableSortByProps = { orderColumn: 'reportingYear', orderDirection: 'desc' }
  const [sortBy, setSortBy] = useState<TableSortByProps>()

  const setIncompletePage = useCallback(
    (page: number) => {
      if (farms && page >= 1 && page <= farms?.length) {
        setCurrentpage(page)
      }
    },
    [farms],
  )

  const setCompletePage = useCallback(
    (page: number) => {
      if (completedSubmissions && page >= 1 && page <= completedSubmissions?.length) {
        setCurrentpage(page)
      }
    },
    [completedSubmissions],
  )

  const setSortColumn = (orderColumn: string, orderDirection: 'desc' | 'asc') => {
    setSortBy({ orderColumn, orderDirection })
  }

  const url = `farm-operation`
  const { data, isLoading, isError } = useGetFarmData<DashboardFarmOperations>(url)

  const createSubmission = async (farmOperationId: string) => {
    const headers = authStrategy ? authStrategy.toHeader() : {}

    const response = await fetchApi({
      url: `${farmOperationId}/submission/new`,
      method: HttpVerb.POST,
      body: JSON.stringify({ create: true }),
      additionalHeaders: headers,
    })

    if (response?.submissionCreated === true) {
      navigate(`/farm/edit/${farmOperationId}`)
    }
  }

  useEffect(() => {
    if (data?.farmOperations.length) {
      setFarms(data?.farmOperations as unknown as Record<string, string | number | boolean>[])

      setCompletedSubmissions(data?.completedSubmissions as unknown as Record<string, string | number | boolean>[])
    }
  }, [data?.farmOperations.length, data])

  useToastMessage({
    isError: isError,
    errorMessage: ERROR_MESSAGES.FETCH_FAILED,
  })

  if (isLoading || isLoadingPermissions) return <LoadingPage />
  return canCreateFarmOperation ? (
    <ContentContainer>
      <ButtonsContainer containerStyle='left'>
        <Button label='Create new farm' path='/farm/edit/new' icon='new' iconAlignment='left' />
      </ButtonsContainer>
      {farms?.length ? (
        <Card isFullWidth>
          <Heading level={2}>Farm records</Heading>
          <div className='c-remove-table-cursor'>
            <Table
              customActions={{ createSubmission }}
              setSortColumn={setSortColumn}
              setPage={setIncompletePage}
              defaultSortBy={sortBy ?? defaultSortBy}
              totalPages={Math.ceil(farms?.length / DEFAULT_TABLE_RESULTS_PER_PAGE)}
              currentPage={currentPage}
              pageSize={DEFAULT_TABLE_RESULTS_PER_PAGE}
              totalResults={farms.length}
              isSkeleton={isLoading}
              columnHeaders={FARM_COLUMN_HEADERS}
              tableData={farms}
              onRowClick={FARM_ROW_CLICK_ACTION}
              noResultsMessage='Please add a farm to continue'
            />
          </div>
        </Card>
      ) : null}

      {completedSubmissions?.length ? (
        <Card isFullWidth>
          <Heading level={2}>Completed Submissions</Heading>
          <Table
            setSortColumn={setSortColumn}
            setPage={setCompletePage}
            defaultSortBy={sortBy ?? defaultSortBy}
            totalPages={Math.ceil(completedSubmissions.length / DEFAULT_TABLE_RESULTS_PER_PAGE)}
            currentPage={currentPage}
            pageSize={DEFAULT_TABLE_RESULTS_PER_PAGE}
            totalResults={completedSubmissions.length}
            isSkeleton={isLoading}
            columnHeaders={COMPLETE_COLUMN_HEADERS}
            tableData={completedSubmissions}
            onRowClick={COMPLETE_ROW_CLICK_ACTION}
            noResultsMessage='No completed submissions'
          />
        </Card>
      ) : null}

      {!farms?.length && !completedSubmissions?.length && (
        <InlineNotification messageType='info' isDismissable={false}>
          No farms have been added, please add a farm to continue
        </InlineNotification>
      )}
    </ContentContainer>
  ) : null
}
