import classNames from 'classnames'
import React from 'react'
import { Tooltip } from '../../common'

export interface ButtonContainerProps {
  containerStyle?: 'full-width' | 'left' | 'right' | 'center'
  // Should match up with `$vertical-spacing--<size>` variables in client/src/styles/0-settings/_sizes.scss:
  verticalSpacing?: 'none' | 'default' | 'small' | 'x-small'
  tooltipMessage?: string
}

// First pass at layout helper/container. Can add optional config as component layout needs arise.
export function ButtonsContainer({
  containerStyle = 'full-width',
  verticalSpacing = 'none',
  tooltipMessage,
  children,
  ...props
}: React.PropsWithChildren<ButtonContainerProps>) {
  const containerClasses = classNames('l-btns-container', {
    [`l-btns-container--${containerStyle}`]: containerStyle,
    [`l-btns-container--vert-spacing-${verticalSpacing}`]: verticalSpacing !== 'none',
  })

  return (
    <div className={containerClasses} {...props}>
      {tooltipMessage && <Tooltip message={tooltipMessage} />}
      {children}
    </div>
  )
}
