import { Blocker, History, Transition } from 'history'
import { ContextType, useContext, useEffect } from 'react'
import { BaseNavigator, NavigationContext } from '@msaf/router-react'

interface Navigator extends BaseNavigator {
  block: History['block']
}

type NavigationContextWithBlock = ContextType<typeof NavigationContext> & {
  navigator: Navigator
}

/**
 * This is a custom implementation based on the original React Router useBlocker implementation and
 * an example implementation added by the community in the open Github issue to add the`useBlocker` and `usePrompt` hooks back the React Router.
 *
 * TODO: Replace this implementation once `useBlocker` hook is added back to the React Router codebase. https://github.com/remix-run/react-router/issues/8139
 *
 * @source
 * Original implementation: https://github.com/remix-run/react-router/commit/256cad70d3fd4500b1abcfea66f3ee622fb90874
 * Community implementation: https://github.com/remix-run/react-router/issues/8139#issuecomment-1147980133
 */
export function useBlocker(blocker: Blocker, when = true) {
  const { navigator } = useContext(NavigationContext) as NavigationContextWithBlock

  useEffect(() => {
    if (!when) {
      return
    }

    const unblock = navigator.block((tx: Transition) => {
      const autoUnblockingTx = {
        ...tx,
        retry() {
          unblock()
          tx.retry()
        },
      }

      blocker(autoUnblockingTx)
    })

    return unblock
  }, [navigator, blocker, when])
}
