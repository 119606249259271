import React from 'react'
import { Note } from './note'
import { ButtonsContainer } from '../../../layout'
import { Button } from '../../../common'
import { NoteType } from '../add-note'

export interface NotesListProps<T, R> {
  notes: Array<NoteType<T, R>>
  isEditMode?: boolean
  hasEditPermission?: (authorId: number) => boolean
  editNote?: (index: number) => void
  deleteNote?: (index: number) => void
}

export function NotesList<T, R>({ notes, isEditMode, hasEditPermission, editNote, deleteNote }: NotesListProps<T, R>) {
  return (
    <div className='c-notes__notes-list'>
      {notes
        .filter((note) => !note.isDeleted)
        .map((note, index) => (
          <Note key={index} {...note}>
            {isEditMode && hasEditPermission?.(note.author.id) && (
              <ButtonsContainer containerStyle='left'>
                <Button buttonStyle='text-action' type='button' label='Edit' onClick={() => editNote?.(index)} />
                <Button buttonStyle='text-action' type='button' label='Delete' onClick={() => deleteNote?.(index)} />
              </ButtonsContainer>
            )}
          </Note>
        ))}
    </div>
  )
}
