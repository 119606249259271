import classNames from 'classnames'
import React from 'react'

export interface SkeletonPillProps {
  isRightAligned?: boolean
}

export function SkeletonPill({ isRightAligned }: SkeletonPillProps) {
  const tagSkeletonClasses = classNames('c-skeleton', 'c-skeleton--pill', {
    'c-skeleton--pill-right-aligned': isRightAligned,
  })

  return (
    <div className={tagSkeletonClasses}>
      <div className='c-skeleton c-skeleton--pill-label' />
    </div>
  )
}
