import { formatDate } from '@msaf/core-common'
import {
  Button,
  ButtonsContainer,
  Card,
  ConfirmTransition,
  Divider,
  FormField,
  FormFieldRow,
  Heading,
  HeadingRow,
  InlineNotification,
  Link,
  ReadOnlyField,
} from '@msaf/core-react'
import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { ContentContainer } from '../../../../../components/content-container'
import { ERROR_MESSAGES } from '../../../../../components/errors/messages'
import { LoadingPage } from '../../../../../components/loading-page'
import { DISPLAY_DATE_FORMAT, REGIONAL_COUNCIL_OPTIONS } from '../../../../../constants'
import { useGetFarmData, useSaveFarmData } from '../../../../../hooks/useApi'
import { useBackLink } from '../../../../../hooks/useBacklink'
import useEditForm from '../../../../../hooks/useEditForm'
import useToastMessage from '../../../../../hooks/useToastMessage'
import { downloadPdf } from '../../../../../services/pdf'
import { useUserPermission } from '../../../../../services/permissions'
import { displayedPurchaseQuantity, offsetByIndex } from '../../../../../services/utils'
import { HttpVerb } from '../../../../../types/api-types'
import { FormFieldArrayTypesEdit, FormFieldsEdit } from '../../../../../types/form-types'
import { UserPermissions } from '../../../../../types/permissions'
import { RouteParams } from '../../../../../types/route'
import { usePageHeading } from '../../../../../hooks/usePageHeading'
import LocationFieldEdit from '../../../../../components/location-map'
import { LOCATION_FIELD_DEFAULT_ZOOM } from '../../../../../hooks/use-inline-map'

type SubmissionRouteParams = RouteParams & { submissionId: string }

export const Submission = () => {
  const { id, submissionId } = useParams<SubmissionRouteParams>()
  const [canViewFarmOperations, isLoadingPermissions] = useUserPermission(UserPermissions.MANAGE_FARM)
  const [isDirty, setIsDirty] = useState<boolean>(false)
  useBackLink({ path: 'Dashboard', label: 'Dashboard' })
  usePageHeading('Submission')
  const navigate = useNavigate()

  const url = `${id}/submission/${submissionId}`
  const stateQueryResult = useGetFarmData(url)
  const saveMutationResult = useSaveFarmData(url, HttpVerb.PUT)

  const { fields, isLoading, isError, setValue, handleSave, isSaved } = useEditForm<
    FormFieldsEdit & FormFieldArrayTypesEdit
  >({
    stateQueryResult,
    saveMutationResult,
    setIsDirty,
  })

  useToastMessage({
    isSuccess: isSaved,
    isError: isError,
    errorMessage: ERROR_MESSAGES.FETCH_FAILED,
  })

  const reportingRequirementsComplete = fields?.reportingRequirementsComplete

  // eslint-disable-next-line @typescript-eslint/ban-types
  const getFieldValue = (value: string | boolean | number | undefined | {}) => {
    if (value == null) return ''
    if (typeof value === 'number') return `${value}`
    if (typeof value === 'boolean') return value === true ? 'Yes' : 'No'
    if (typeof value === 'string') return value
  }

  useEffect(() => {
    // Ensure we wait until the submission has succeeded before
    // navigating to the list view.
    if (isSaved) {
      navigate('/dashboard')
    }
  }, [isSaved])

  const handleCompleteSubmission = () => {
    setValue('submissionComplete', true)
    handleSave()
  }

  if (isLoading || isLoadingPermissions) return <LoadingPage />

  return canViewFarmOperations ? (
    <>
      <ConfirmTransition
        when={isDirty}
        contentLabel='Unsaved changes confirmation dialog'
        actionConfig={[
          {
            key: 'continue',
            type: 'cancel',
            buttonStyle: 'primary',
            label: 'Continue editing',
          },
          {
            key: 'discard',
            type: 'confirm',
            buttonStyle: 'text-action',
            label: 'Discard changes',
          },
        ]}
      />
      <ContentContainer>
        <HeadingRow headingLevel={1} headingText='Submission summary' isSkeleton={isLoading}>
          {fields?.submissionComplete && (
            <Button
              onClick={() =>
                downloadPdf(
                  `${fields.organisationName} - ${fields.dairySupplyNumber} - ${new Date().getFullYear()}`,
                  'summaryPDF',
                )
              }
              label='Download PDF summary'
            />
          )}
        </HeadingRow>
        {reportingRequirementsComplete && !fields.submissionComplete && (
          <InlineNotification messageType='info'>
            You have completed all reporting requirements, please review and submit.
          </InlineNotification>
        )}
        {reportingRequirementsComplete ? (
          <>
            {fields?.submissionComplete && (
              <InlineNotification messageType='info'>
                Your nitrogen use reporting requirements are complete. You can now download a PDF of your submission
              </InlineNotification>
            )}
            <div id='summaryPDF' style={{ padding: '24px' }}>
              {/* Required to convert mm <-> px */}
              <div id='myMm' style={{ height: '1mm' }} />

              <HeadingRow
                headingLevel={2}
                headingText={`Farm: ${fields.organisationName} - Dairy supply number: ${fields.dairySupplyNumber}`}
              >
                {!fields.submissionComplete && <Link path={`/farm/edit/${id}`}>Edit</Link>}
              </HeadingRow>

              <Heading level={3}>Contact details</Heading>

              <FormField isSkeleton={isLoading} labelId='contactName' label='Contact name'>
                <ReadOnlyField
                  isSkeleton={isLoading}
                  labelledBy='contactName'
                  value={getFieldValue(fields.contactName)}
                />
              </FormField>

              <FormField isSkeleton={isLoading} labelId='contactPhone' label='Contact phone'>
                <ReadOnlyField
                  isSkeleton={isLoading}
                  labelledBy='contactPhone'
                  value={getFieldValue(fields.contactPhone)}
                />
              </FormField>

              <FormField isSkeleton={isLoading} labelId='contactEmail' label='Contact email'>
                <ReadOnlyField
                  isSkeleton={isLoading}
                  labelledBy='contactEmail'
                  value={getFieldValue(fields.contactEmailAddress)}
                />
              </FormField>

              <Heading level={3}>Farm details</Heading>

              <FormField isSkeleton={isLoading} labelId='nzbn' label='NZBN'>
                <ReadOnlyField isSkeleton={isLoading} labelledBy='nzbn' value={getFieldValue(fields.nzbn)} />
              </FormField>

              <FormFieldRow>
                <FormField isSkeleton={isLoading} labelId='organisationName' label='Dairy supply company name'>
                  <ReadOnlyField
                    isSkeleton={isLoading}
                    labelledBy='organisationName'
                    value={getFieldValue(fields.organisationName)}
                  />
                </FormField>

                <FormField isSkeleton={isLoading} labelId='dairySupplyNumber' label='Dairy supply number'>
                  <ReadOnlyField
                    isSkeleton={isLoading}
                    labelledBy='dairySupplyNumber'
                    value={getFieldValue(fields.dairySupplyNumber)}
                  />
                </FormField>
              </FormFieldRow>

              <FormField
                isSkeleton={isLoading}
                labelId='resourceConsent'
                label='Existing fertiliser related resource consent number(s)’'
              >
                <ReadOnlyField
                  isSkeleton={isLoading}
                  labelledBy='resourceConsent'
                  value={getFieldValue(fields.resourceConsent)}
                />
              </FormField>

              <FormField isSkeleton={isLoading} labelId='areaTotal' label='Total area(ha)'>
                <ReadOnlyField isSkeleton={isLoading} labelledBy='areaTotal' value={getFieldValue(fields.areaTotal)} />
              </FormField>

              <FormField isSkeleton={isLoading} labelId='areaEffective' label='AreaEffective'>
                <ReadOnlyField
                  isSkeleton={isLoading}
                  labelledBy='areaEffective'
                  value={getFieldValue(fields.areaEffective)}
                />
              </FormField>

              <FormField
                isSkeleton={isLoading}
                labelId='hasMetFertilizerPerGrazedPasture'
                label='Has met fertiliser per grazed pasture'
              >
                <ReadOnlyField
                  isSkeleton={isLoading}
                  labelledBy='hasMetFertilizerPerGrazedPasture'
                  value={getFieldValue(fields.hasMetFertilizerPerGrazedPasture)}
                />
              </FormField>

              <Heading level={3}>Data permission</Heading>
              <FormField
                isSkeleton={isLoading}
                labelId='hasDataConsent'
                label='Data consent (Did I give consent to share my data)'
              >
                <ReadOnlyField
                  isSkeleton={isLoading}
                  labelledBy='hasDataConsent'
                  value={getFieldValue(fields.hasDataConsent)}
                />
              </FormField>

              <Divider />

              <Heading level={2}>Proof of purchase</Heading>

              {fields?.proofOfPurchases?.length ? (
                fields.proofOfPurchases?.map((purchase, index) => {
                  return (
                    <div key={index} className='purchase-wrapper'>
                      <HeadingRow headingLevel={3} headingText={`Purchase ${index + 1}`}>
                        {!fields.submissionComplete && (
                          <Link path={`/farm/edit/${id}/proof-of-purchase/${purchase.proofOfPurchaseId}`}>Edit</Link>
                        )}
                      </HeadingRow>
                      <FormField isSkeleton={isLoading} labelId='supplierName' label='Supplier name'>
                        <ReadOnlyField
                          isSkeleton={isLoading}
                          labelledBy='supplierName'
                          value={getFieldValue(purchase.supplierName)}
                        />
                      </FormField>

                      <FormField isSkeleton={isLoading} labelId='dateTimePurchased' label='Date purchased'>
                        <ReadOnlyField
                          isSkeleton={isLoading}
                          labelledBy='dateTimePurchased'
                          value={formatDate(purchase.dateTimePurchased, DISPLAY_DATE_FORMAT)}
                        />
                      </FormField>

                      <Heading level={4}>Items</Heading>
                      {purchase.items?.map((item, index) => {
                        return (
                          <div key={index}>
                            <Card className={index === 0 ? 'c-card__first' : ''}>
                              <FormFieldRow>
                                <FormField isSkeleton={isLoading} labelId='description' label='Fertiliser name'>
                                  <ReadOnlyField
                                    isSkeleton={isLoading}
                                    labelledBy='description'
                                    value={getFieldValue(item.product)}
                                  />
                                </FormField>
                                <FormField isSkeleton={isLoading} labelId='quantity' label='Quantity'>
                                  <ReadOnlyField
                                    isSkeleton={isLoading}
                                    labelledBy='quantity'
                                    value={displayedPurchaseQuantity(item)}
                                  />
                                </FormField>
                              </FormFieldRow>
                            </Card>
                          </div>
                        )
                      })}
                    </div>
                  )
                })
              ) : (
                <InlineNotification messageType='info' isDismissable={false}>
                  No fertiliser purchases submitted.{' '}
                  {!fields.submissionComplete && <Link path={`/farm/edit/${id}/proof-of-purchase`}>Add</Link>}
                </InlineNotification>
              )}
              <Divider />

              <Heading level={2}>Contiguous land holdings</Heading>

              {fields?.contiguousLandHoldings?.length ? (
                fields.contiguousLandHoldings.map((landHolding, index) => {
                  return (
                    <div key={index}>
                      <HeadingRow headingLevel={3} headingText='Land Details'>
                        {!fields.submissionComplete && (
                          <Link path={`/farm/edit/${id}/land-holding/${landHolding.contiguousLandHoldingId}`}>
                            Edit
                          </Link>
                        )}
                      </HeadingRow>
                      <FormField isSkeleton={isLoading} labelId='landName' label='Land name'>
                        <ReadOnlyField
                          isSkeleton={isLoading}
                          labelledBy='landName'
                          value={getFieldValue(landHolding.landName)}
                        />
                      </FormField>

                      <FormField isSkeleton={isLoading} labelId='reportingYear' label='Reporting year'>
                        <ReadOnlyField
                          isSkeleton={isLoading}
                          labelledBy='reportingYear'
                          value={getFieldValue(landHolding.reportingYear)}
                        />
                      </FormField>

                      <FormField isSkeleton={isLoading} labelId='areaTotal' label='Total area(ha)'>
                        <ReadOnlyField
                          isSkeleton={isLoading}
                          labelledBy='areaTotal'
                          value={getFieldValue(landHolding.areaTotal)}
                        />
                      </FormField>

                      {landHolding.areaRegionalCouncil.map((council, index) => (
                        <FormField
                          key={index}
                          isSkeleton={isLoading}
                          labelId={offsetByIndex('reportingRegionalCouncil', index)}
                          label='Reporting regional council(s)'
                          hasInlineLabel
                        >
                          <ReadOnlyField
                            isSkeleton={isLoading}
                            labelledBy='reportingRegionalCouncil'
                            value={
                              typeof council === 'number'
                                ? REGIONAL_COUNCIL_OPTIONS.find((item) => item.value == council)?.label
                                : ''
                            }
                          />
                        </FormField>
                      ))}
                      <Heading level={3}>Land categories</Heading>

                      <Card className='c-card__first'>
                        <Heading level={4}>Grazed land</Heading>
                        <FormFieldRow>
                          <FormField isSkeleton={isLoading} labelId='areaGrazedLand' label='Area (ha)'>
                            <ReadOnlyField
                              isSkeleton={isLoading}
                              labelledBy='areaGrazedLand'
                              value={getFieldValue(landHolding.areaGrazedLand)}
                            />
                          </FormField>

                          <FormField
                            isSkeleton={isLoading}
                            labelId='appliedGrazedLand'
                            label='Fertiliser applied (N KG/Ha/y)'
                          >
                            <ReadOnlyField
                              isSkeleton={isLoading}
                              labelledBy='appliedGrazedLand'
                              value={getFieldValue(landHolding.appliedGrazedLand)}
                            />
                          </FormField>
                        </FormFieldRow>
                      </Card>

                      <Card>
                        <Heading level={4}>Grazed forage crops</Heading>
                        <FormFieldRow>
                          <FormField isSkeleton={isLoading} labelId='areaGrazedForageCrops' label='Area (ha)'>
                            <ReadOnlyField
                              isSkeleton={isLoading}
                              labelledBy='areaGrazedForageCrops'
                              value={getFieldValue(landHolding.areaGrazedForageCrops)}
                            />
                          </FormField>

                          <FormField
                            isSkeleton={isLoading}
                            labelId='appliedGrazedForageCrops'
                            label='Fertiliser applied (N KG/Ha/y)'
                          >
                            <ReadOnlyField
                              isSkeleton={isLoading}
                              labelledBy='appliedGrazedForageCrops'
                              value={getFieldValue(landHolding.appliedGrazedForageCrops)}
                            />
                          </FormField>
                        </FormFieldRow>
                      </Card>

                      <Card>
                        <Heading level={4}>Grazed non-forage land</Heading>
                        <FormFieldRow>
                          <FormField isSkeleton={isLoading} labelId='areaGrazedNonForageCrops' label='Area'>
                            <ReadOnlyField
                              isSkeleton={isLoading}
                              labelledBy='areaGrazedNonForageCrops'
                              value={getFieldValue(landHolding.areaGrazedNonForageCrops)}
                            />
                          </FormField>

                          <FormField
                            isSkeleton={isLoading}
                            labelId='appliedGrazedNonForageCrops'
                            label='Fertiliser applied (N KG/Ha/y)'
                          >
                            <ReadOnlyField
                              isSkeleton={isLoading}
                              labelledBy='appliedGrazedNonForageCrops'
                              value={getFieldValue(landHolding.appliedGrazedNonForageCrops)}
                            />
                          </FormField>
                        </FormFieldRow>
                      </Card>

                      <Card>
                        <Heading level={4}>Ungrazed land</Heading>
                        <FormFieldRow>
                          <FormField isSkeleton={isLoading} labelId='areaUngrazedLand' label='Area'>
                            <ReadOnlyField
                              isSkeleton={isLoading}
                              labelledBy='areaUngrazedLand'
                              value={getFieldValue(landHolding.areaUnGrazedLand)}
                            />
                          </FormField>

                          <FormField
                            isSkeleton={isLoading}
                            labelId='appliedUnGrazedLand'
                            label='Fertiliser applied (N KG/Ha/y)'
                          >
                            <ReadOnlyField
                              isSkeleton={isLoading}
                              labelledBy='appliedUnGrazedLand'
                              value={getFieldValue(landHolding.appliedUnGrazedLand)}
                            />
                          </FormField>
                        </FormFieldRow>
                      </Card>

                      {landHolding.easting && landHolding.northing && (
                        <Card className='c-wide-map'>
                          <LocationFieldEdit
                            isViewMode
                            zoomLevel={LOCATION_FIELD_DEFAULT_ZOOM.LOCATION_SET}
                            formFieldLabel='Land location'
                            labelledBy='coordinates'
                            isLoading={isLoading}
                            easting={{
                              fieldId: 'easting',
                              value: `${landHolding.easting ?? ''}`,
                              // eslint-disable-next-line @typescript-eslint/no-empty-function
                              setValue: () => {},
                            }}
                            northing={{
                              fieldId: 'northing',
                              value: `${landHolding.northing ?? ''}`,
                              // eslint-disable-next-line @typescript-eslint/no-empty-function
                              setValue: () => {},
                            }}
                          />
                        </Card>
                      )}

                      <h3>Fertiliser applications</h3>
                      {landHolding?.fertiliserApplications?.length ? (
                        landHolding.fertiliserApplications?.map((fertiliser, index) => {
                          return (
                            <div key={index}>
                              <Card className={index === 0 ? 'c-card__first' : ''}>
                                <HeadingRow headingLevel={3} headingText={`Application ${index + 1}`}>
                                  {!fields.submissionComplete && (
                                    <Link
                                      path={`/farm/edit/${id}/fertiliser-applications/${fertiliser.fertiliserApplicationId}`}
                                    >
                                      Edit
                                    </Link>
                                  )}
                                </HeadingRow>
                                <FormField isSkeleton={isLoading} labelId='dateTimeApplied' label='Date applied'>
                                  <ReadOnlyField
                                    isSkeleton={isLoading}
                                    labelledBy='dateTimeApplied'
                                    value={formatDate(fertiliser.dateTimeApplied, DISPLAY_DATE_FORMAT)}
                                  />
                                </FormField>
                                <FormField
                                  isSkeleton={isLoading}
                                  labelId='fertiliserManufacturer'
                                  label='Fertiliser manufacturer'
                                >
                                  <ReadOnlyField
                                    isSkeleton={isLoading}
                                    labelledBy='fertiliserManufacturer'
                                    value={getFieldValue(fertiliser.fertiliserManufacturer)}
                                  />
                                </FormField>
                                <FormField isSkeleton={isLoading} labelId='fertiliserType' label='Fertiliser type'>
                                  <ReadOnlyField
                                    isSkeleton={isLoading}
                                    labelledBy='fertiliserType'
                                    value={getFieldValue(fertiliser.fertiliserType)}
                                  />
                                </FormField>
                                <FormField
                                  isSkeleton={isLoading}
                                  labelId='nitrogenPercentRate'
                                  label='Nitrogen percent rate'
                                >
                                  <ReadOnlyField
                                    isSkeleton={isLoading}
                                    labelledBy='nitrogenPercentRate'
                                    value={getFieldValue(fertiliser.nitrogenPercentRate)}
                                  />
                                </FormField>
                              </Card>
                              {landHolding?.fertiliserApplications?.length &&
                                landHolding.fertiliserApplications.length - 1 === index && <Divider />}
                            </div>
                          )
                        })
                      ) : (
                        <InlineNotification isDismissable={false}>
                          No fertiliser applications have been made for this land holding.{' '}
                          {!fields.submissionComplete && (
                            <Link path={`/farm/edit/${id}/fertiliser-applications`}>Add</Link>
                          )}
                        </InlineNotification>
                      )}
                      {fields?.contiguousLandHoldings?.length &&
                        fields?.contiguousLandHoldings?.length - 1 !== index && <Divider />}
                    </div>
                  )
                })
              ) : (
                <InlineNotification messageType='info' isDismissable={false}>
                  No contiguous land holdings submitted{' '}
                  {!fields.submissionComplete && <Link path={`/farm/edit/${id}/land-holding`}>Add</Link>}
                </InlineNotification>
              )}
            </div>
          </>
        ) : (
          <InlineNotification messageType='warning' isDismissable={false}>
            At least one contiguous land holding must be added to this farm. Please click{' '}
            {<Link path={`/farm/edit/${id}/land-holding`}>here</Link>} to complete submission requirements.
          </InlineNotification>
        )}

        {reportingRequirementsComplete && !fields.submissionComplete && (
          <ButtonsContainer containerStyle='right'>
            <Button onClick={() => handleCompleteSubmission()} label='Submit' />
          </ButtonsContainer>
        )}
      </ContentContainer>
    </>
  ) : null
}
