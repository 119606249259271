import React from 'react'
import { Pagination, PaginationProps } from './pagination'
import { GoToControl as GoToPage } from './go-to-page'
import { NUM_VISIBLE_PAGINATION_LINKS } from '../../../../constants'

export function TableFooter({
  currentPage,
  maxPageLinksVisible,
  setPage,
  totalResults,
  totalPages,
  showResultsCount = true,
  resultNameSingular,
  resultNamePlural,
  isSkeleton,
}: PaginationProps) {
  // Only output 'Go to page' controls if number of pages exceeds maximum visible within pagination controls
  const maxPaginationLinks = maxPageLinksVisible || NUM_VISIBLE_PAGINATION_LINKS
  const showGotoPageControl = totalPages > maxPaginationLinks

  return (
    <div className='c-table__footer'>
      <Pagination
        currentPage={currentPage}
        maxPageLinksVisible={maxPageLinksVisible}
        setPage={setPage}
        totalResults={totalResults}
        totalPages={totalPages}
        showResultsCount={showResultsCount}
        resultNameSingular={resultNameSingular}
        resultNamePlural={resultNamePlural}
        isSkeleton={isSkeleton}
      />
      {showGotoPageControl && totalPages > 1 && <GoToPage setPage={setPage} isSkeleton={isSkeleton} />}
    </div>
  )
}
