import { ReactNode, useEffect } from 'react'
import { Context, useCallback, useMemo, useState } from 'react'
import { AuthMethod } from './methods'
import { AuthContext, AuthEvent, AuthStrategy, WhoAmI } from './types'
import { ErrorCode } from '../components/errors/constants'

export type AuthProviderState<T> = {
  user?: T
  error?: unknown
  authStrategy?: AuthStrategy
  isLoading: boolean
}

export const createAuthProvider =
  <T,>(AuthContext: Context<AuthContext<T> | null>, methods: [AuthMethod, ...AuthMethod[]], whoAmI: WhoAmI<T>) =>
  ({ children }: { children: ReactNode }) => {
    const [state, setState] = useState<AuthProviderState<T>>({ isLoading: true })

    const createAuthEventHandler = useCallback(
      (authMethod: AuthMethod) => (event: AuthEvent) => {
        if (event === 'authenticated') {
          const authStrategy = authMethod.authStrategy()
          ;(async () => {
            try {
              const user = await whoAmI(authStrategy)
              setState((prev) => ({ ...prev, user, error: undefined, isLoading: false, authStrategy }))
            } catch (error: unknown) {
              setState((prev) => ({ ...prev, user: undefined, error: error, isLoading: false, authStrategy }))
            }
          })()
        } else {
          setState((prev) => ({
            ...prev,
            user: undefined,
            isLoading: false,
            error: undefined,
          }))
        }
      },
      [whoAmI, setState],
    )

    const authMethod: AuthMethod = useMemo(() => {
      const selected = methods.find((m) => {
        m.setEventHandler(createAuthEventHandler(m))
        return m.use()
      })
      if (selected === undefined) throw new Error('No usable auth strategy.')
      return selected
    }, [methods])

    useEffect(() => {
      const init = async () => {
        try {
          await authMethod.init()
        } catch (err) {
          // Async errors are not caught by the error boundary. Set error into state and re-raise to be handled by the error boundary
          setState((prev) => ({ ...prev, error: ErrorCode.KEYCLOAK_ERROR }))
        }
      }
      init()
    }, [authMethod])

    const context = {
      ...state,
      isAuthenticated: state.user !== undefined,
      login: authMethod.login,
      logout: authMethod.logout,
      refresh: authMethod.refresh,
      changePassword: authMethod.changePassword,
      error: state.error,
    }

    return <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  }
