export type FileMetadataCallback = (file: any, isEditable: boolean, validationErrors: any) => JSX.Element

export enum FileState {
  Uploading,
  Uploaded,
  Saved,
}

export interface BaseFile {
  id: string
  state: FileState
  originalName: string
  size: number
  createdTimestamp: string
}

export interface NewFile extends BaseFile {
  state: FileState.Uploading
  file: File
  error?: any
  abortController: AbortController
}

export interface UploadedFile extends BaseFile {
  state: FileState.Uploaded | FileState.Saved
  uuid: string
  url?: string
  thumbnailUrl?: string
  isVirusScanned?: boolean
}

export type NewOrUploadedFile = NewFile | UploadedFile

export type FileSaveActionCallback = (file: NewFile) => Promise<Omit<UploadedFile, 'state'>>
export type FileUploadingCallback = (file: NewFile) => void
export type FileUploadedCallback = (file: UploadedFile, temp_id: string) => void
export type FileUploadFailedCallback = (file: NewFile, error: any) => void
export type DownloadAction = (file: UploadedFile) => void

export interface FileErrorResponse {
  file: string[]
}
