import {
  CheckableField,
  CheckableFields,
  ConfirmTransition,
  Divider,
  FormField,
  FormFieldRow,
  Heading,
  InlineNotification,
  Link,
  RecordFooter,
  TextInput,
} from '@msaf/core-react'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useAuth } from '../../../../auth'
import { ContentContainer } from '../../../../components/content-container'
import FormEditButtons from '../../../../components/form-edit-buttons'
import { LoadingPage } from '../../../../components/loading-page'
import { TOOLTIP_MESSAGE } from '../../../../constants'
import { useGetFarmData, useSaveFarmData } from '../../../../hooks/useApi'
import { useBackLink } from '../../../../hooks/useBacklink'
import useEditForm from '../../../../hooks/useEditForm'
import { usePageHeading } from '../../../../hooks/usePageHeading'
import useToastMessage from '../../../../hooks/useToastMessage'
import fetchApi from '../../../../services/api'
import { useUserPermission } from '../../../../services/permissions'
import { getToastErrorMessage } from '../../../../services/utils'
import { HttpVerb } from '../../../../types/api-types'
import { UserPermissions } from '../../../../types/permissions'
import { RouteParams } from '../../../../types/route'

export const FarmOperation = () => {
  useBackLink({ label: 'Dashboard', path: '/dashboard' })
  const { id } = useParams<RouteParams>()
  const [canViewFarmOperations, isLoadingPermissions] = useUserPermission(UserPermissions.MANAGE_FARM)
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const url = `farm-operation/${id}`
  const stateQueryResult = useGetFarmData(url)
  const saveMutationResult = useSaveFarmData(url, HttpVerb.POST)
  const navigate = useNavigate()

  const { authStrategy } = useAuth()

  const createSubmission = async (farmOperationId: string) => {
    const headers = authStrategy ? authStrategy.toHeader() : {}

    const response = await fetchApi({
      url: `${farmOperationId}/submission/new`,
      method: HttpVerb.POST,
      body: JSON.stringify({ create: true }),
      additionalHeaders: headers,
    })

    if (response?.submissionCreated === true) {
      navigate(`/farm/edit/${farmOperationId}/proof-of-purchase`)
    }
  }

  const { fields, isLoading, isError, isSaveError, isSaved, validationErrors, setValue, handleSave } = useEditForm({
    stateQueryResult,
    saveMutationResult,
    setIsDirty,
  })
  usePageHeading(id === 'new' ? 'Create a new farm' : fields?.organisationName ?? 'Edit farm')

  useToastMessage({
    isError: isError || isSaveError,
    isSuccess: isSaved,
    errorMessage: getToastErrorMessage(validationErrors, isSaveError),
  })

  if (isLoading || isLoadingPermissions) return <LoadingPage />
  return (
    <>
      <ConfirmTransition
        when={isDirty}
        contentLabel='Unsaved changes confirmation dialog'
        actionConfig={[
          {
            key: 'continue',
            type: 'cancel',
            buttonStyle: 'primary',
            label: 'Continue editing',
          },
          {
            key: 'discard',
            type: 'confirm',
            buttonStyle: 'text-action',
            label: 'Discard changes',
          },
        ]}
      />
      <ContentContainer>
        {canViewFarmOperations && <Heading level={2}>Contact Details</Heading>}

        <FormField
          isRequired
          isSkeleton={isLoading}
          label='Contact name'
          htmlFor='contactName'
          validationMessages={validationErrors?.contactName}
        >
          <TextInput
            isSkeleton={isLoading}
            id='contactName'
            value={`${fields?.contactName ?? ''}`}
            onBlur={(e) => setValue('contactName', e.target.value)}
            isInvalid={!!validationErrors?.contactName}
          />
        </FormField>

        <FormField
          isRequired
          isSkeleton={isLoading}
          label='Contact phone (+64XXXXXXXXXX)'
          htmlFor='contactPhone'
          validationMessages={validationErrors?.contactPhone}
        >
          <TextInput
            isSkeleton={isLoading}
            id='contactPhone'
            value={`${fields?.contactPhone ?? ''}`}
            onBlur={(e) => setValue('contactPhone', e.target.value)}
            isInvalid={!!validationErrors?.contactPhone}
          />
        </FormField>

        <FormField
          isRequired
          isSkeleton={isLoading}
          label='Contact email address'
          htmlFor='contactEmailAddress'
          validationMessages={validationErrors?.contactEmailAddress}
        >
          <TextInput
            isSkeleton={isLoading}
            id='contactEmailAddress'
            value={`${fields?.contactEmailAddress ?? ''}`}
            onBlur={(e) => setValue('contactEmailAddress', e.target.value)}
            isInvalid={!!validationErrors?.contactEmailAddress}
          />
        </FormField>

        <Divider />

        <Heading level={2}>Farm details</Heading>

        <Link
          isExternal
          target='_blank'
          rel='noreferrer noopener'
          path='https://www.nzbn.govt.nz/manage-your-nzbn/whats-mynzbn/'
        >
          Search for your NZBN here
        </Link>

        <FormField
          isRequired
          isSkeleton={isLoading}
          label='NZBN'
          htmlFor='nzbn'
          validationMessages={validationErrors?.nzbn}
          toolTipMessage='https://www.nzbn.govt.nz/manage-your-nzbn/whats-mynzbn/'
        >
          <TextInput
            isSkeleton={isLoading}
            id='nzbn'
            value={`${fields?.nzbn ?? ''}`}
            onBlur={(e) => setValue('nzbn', e.target.value)}
            isInvalid={!!validationErrors?.nzbn}
          />
        </FormField>

        <FormFieldRow>
          <FormField
            isRequired
            isSkeleton={isLoading}
            label='Dairy supply company name'
            htmlFor='organisationName'
            validationMessages={validationErrors?.organisationName}
          >
            <TextInput
              isSkeleton={isLoading}
              id='organisationName'
              value={`${fields?.organisationName ?? ''}`}
              onBlur={(e) => setValue('organisationName', e.target.value)}
              isInvalid={!!validationErrors?.organisationName}
            />
          </FormField>

          <FormField
            isRequired
            isSkeleton={isLoading}
            label='Dairy supply number'
            htmlFor='dairySupplyNumber'
            validationMessages={validationErrors?.dairySupplyNumber}
            toolTipMessage={TOOLTIP_MESSAGE.dairySupplyNumber}
          >
            <TextInput
              isSkeleton={isLoading}
              id='dairySupplyNumber'
              value={`${fields?.dairySupplyNumber ?? ''}`}
              onBlur={(e) => setValue('dairySupplyNumber', e.target.value)}
              isInvalid={!!validationErrors?.dairySupplyNumber}
            />
          </FormField>
        </FormFieldRow>

        <FormField
          isSkeleton={isLoading}
          label='Existing fertiliser related resource consent number(s)'
          htmlFor='resourceConsent'
          validationMessages={validationErrors?.resourceConsent}
          toolTipMessage={TOOLTIP_MESSAGE.resourceConsent}
        >
          <TextInput
            isSkeleton={isLoading}
            id='resourceConsent'
            value={`${fields?.resourceConsent ?? ''}`}
            onBlur={(e) => setValue('resourceConsent', e.target.value)}
            isInvalid={!!validationErrors?.resourceConsent}
          />
        </FormField>
        <FormField
          isRequired
          isSkeleton={isLoading}
          label='Total area (ha)'
          htmlFor='areaTotal'
          validationMessages={validationErrors?.areaTotal}
          toolTipMessage={TOOLTIP_MESSAGE.farmAreaTotal}
        >
          <TextInput
            type='number'
            min={0}
            isSkeleton={isLoading}
            id='areaTotal'
            value={`${fields?.areaTotal ?? ''}`}
            onBlur={(e) => setValue('areaTotal', e.target.value)}
            isInvalid={!!validationErrors?.areaTotal}
          />
        </FormField>

        <FormField
          isRequired
          isSkeleton={isLoading}
          label='Area effective (ha)'
          htmlFor='areaEffective'
          validationMessages={validationErrors?.areaEffective}
          toolTipMessage={TOOLTIP_MESSAGE.farmAreaEffective}
        >
          <TextInput
            min={0}
            type='number'
            isSkeleton={isLoading}
            id='areaEffective'
            value={`${fields?.areaEffective ?? ''}`}
            onBlur={(e) => setValue('areaEffective', e.target.value)}
            isInvalid={!!validationErrors?.areaEffective}
          />
        </FormField>

        <FormField
          isRequired
          validationMessages={validationErrors?.hasMetFertilizerPerGrazedPasture}
          isSkeleton={isLoading}
          label='Has met fertiliser limit of 190kg/N/ha per grazed pasture'
          labelId='hasMetFertilizerPerGrazedPastureLabel'
          toolTipMessage={TOOLTIP_MESSAGE.hasMetFertilizerPerGrazedPasture}
        >
          <CheckableFields ariaLabelledBy='hasMetFertilizerPerGrazedPastureLabel' isHorizontal>
            <CheckableField
              id='hasMetFertilizerPerGrazedPastureYes'
              name='hasMetFertilizerPerGrazedPasture'
              describedBy='hasMetFertilizerPerGrazedPasture'
              label='Yes'
              isSkeleton={isLoading}
              type='radio'
              checked={fields.hasMetFertilizerPerGrazedPasture ?? false}
              onChange={() => setValue('hasMetFertilizerPerGrazedPasture', true)}
            />
            <CheckableField
              id='hasMetFertilizerPerGrazedPastureNo'
              name='hasMetFertilizerPerGrazedPasture'
              describedBy='hasMetFertilizerPerGrazedPasture'
              label='No'
              isSkeleton={isLoading}
              type='radio'
              checked={fields.hasMetFertilizerPerGrazedPasture === false}
              onChange={() => setValue('hasMetFertilizerPerGrazedPasture', false)}
            />
          </CheckableFields>
        </FormField>

        <Divider />

        <Heading level={2}>Data permission</Heading>

        <div className='c-inline-notification__no-close in-form'>
          <InlineNotification messageType='warning' isDismissable={false}>
            Consent must be given in order to continue
            <br />
            <br />
            <a href='/help-centre#data-usage' target='_blank' rel='noreferrer noopener'>
              Find out where the data goes and how it is used
            </a>
          </InlineNotification>
        </div>

        <FormField
          isRequired
          validationMessages={validationErrors?.hasDataConsent}
          isSkeleton={isLoading}
          label='Data consent'
          labelId='hasDataConsentLabel'
        >
          <CheckableFields ariaLabelledBy='hasDataConsentLabel'>
            <CheckableField
              id='hasDataConsentYes'
              name='hasDataConsent'
              describedBy='hasDataConsent'
              label='Yes, I consent to the data provided being shared with the relevant regional council and aggregated information to MfE'
              isSkeleton={isLoading}
              type='checkbox'
              checked={fields.hasDataConsent ?? false}
              onChange={() => setValue('hasDataConsent', !fields.hasDataConsent)}
            />
          </CheckableFields>
        </FormField>

        <RecordFooter>
          <FormEditButtons
            actions={[
              {
                label: 'Save and continue',
                onClick: () => handleSave((id) => createSubmission(id as string)),
                isDisabled: !fields.hasDataConsent,
              },
              {
                label: 'Cancel',
                onClick: () => navigate('/dashboard'),
                buttonStyle: 'text-action',
              },
            ]}
            isLoading={isLoading}
          />
        </RecordFooter>
      </ContentContainer>
    </>
  )
}
