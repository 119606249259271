import React from 'react'

// Max-width content container for main page content.
// Note: likely to need updating once navigation design overhaul finalised
export function ContentContainer({ children }: { children: React.ReactNode }) {
  return (
    <div id='a11y-main-content' className='l-content-container'>
      {children}
    </div>
  )
}
