import React from 'react'
import classNames from 'classnames'
import { Icon } from '@msaf/core-react'

export interface FeatureButtonProps {
  iconElement?: JSX.Element
  isActive?: boolean
  onClick: VoidFunction
}

export function FeatureButton({
  iconElement,
  isActive,
  onClick,
  children,
}: React.PropsWithChildren<FeatureButtonProps>) {
  const classes = classNames('c-feature-btn', {
    'c-feature-btn--active': isActive,
  })

  return (
    <button className={classes} onClick={onClick}>
      <span className='c-feature-btn__marker-icon'>{iconElement}</span>
      <span className='c-feature-btn__label'>{children}</span>
      <Icon
        className='c-feature-btn__toggle-icon'
        icon={isActive ? 'close' : 'add'}
        ariaLabel={isActive ? 'Cancel' : 'Add feature'}
      />
    </button>
  )
}
