import React from 'react'
import { useState } from 'react'
import { BoilerplateModalProps, Button, ButtonStyle, Modal } from '../../common'
import { usePrompt } from './usePrompt'

export interface ConfirmTransitionProps extends Omit<BoilerplateModalProps, 'isOpen' | 'onRequestClose'> {
  when?: boolean
  confirmText?: string
  cancelText?: string
  actionConfig: Array<{
    type: 'confirm' | 'cancel'
    label: string
    buttonStyle: ButtonStyle
    onClick?: () => Promise<void>
    key?: string
  }>
}

export function ConfirmTransition({
  when,
  contentLabel = 'Unsaved changes confirmation dialog',
  heading = 'Unsaved changes',
  bodyText = 'This page has unsaved changes, are you sure you want to continue?',
  confirmText = 'Discard changes',
  cancelText = 'Continue editing',
  actionConfig,
  ...props
}: ConfirmTransitionProps) {
  const [open, setOpen] = useState(false)
  const { onClick } = usePrompt(setOpen, when)

  const actions = actionConfig.map((config) => {
    const { type, onClick: callbackFn, ...buttonProps } = config
    return (
      <Button {...buttonProps} key={buttonProps.key ?? buttonProps.label} onClick={() => onClick(type, callbackFn)} />
    )
  })

  const closeModal = () => {
    setOpen(false)
  }

  return (
    <Modal
      {...props}
      isOpen={open}
      onRequestClose={closeModal}
      contentLabel={contentLabel}
      heading={heading}
      bodyText={bodyText}
      buttons={<>{actions}</>}
    />
  )
}
