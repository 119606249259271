import React from 'react'
import { Icon } from '../../../common/icon'
import { Link } from '../../link'

interface AppHeaderBackLinkProps {
  path: string
  label: string
}
export interface AppHeaderMenuProps {
  backLink?: AppHeaderBackLinkProps
  openMenu?: () => void
  isSkeleton?: boolean
}

export function AppHeaderMenu({
  backLink,
  openMenu,
  children,
  isSkeleton,
}: React.PropsWithChildren<AppHeaderMenuProps>) {
  return isSkeleton ? (
    <div className='c-toolbar'>
      <button className='c-toolbar__main-menu-btn'></button>
      <div className='c-toolbar__back-link'></div>
      <div className='c-toolbar__menus'>{children}</div>
    </div>
  ) : (
    <div className='c-toolbar'>
      <button className='c-toolbar__main-menu-btn' onClick={openMenu}>
        <Icon className='c-toolbar__main-menu-icon' icon='list' ariaHidden={true} />
        Menu
      </button>

      {/* TODO: update to use BackLink component, check with Andrew re inconsistent icon used */}
      <div className='c-toolbar__back-link'>
        {backLink && (
          <Link className='c-back-link' path={backLink.path} exact>
            <Icon className='c-back-link__icon' icon='chevron-left' ariaHidden={true} />
            <span className='c-back-link__text'>{backLink.label}</span>
          </Link>
        )}
      </div>

      <div className='c-toolbar__menus'>{children}</div>
    </div>
  )
}
