import classNames from 'classnames'
import React from 'react'
import { SkeletonToggle } from './skeleton-toggle'

export interface ToggleProps {
  id: string
  name: string
  describedBy?: string
  isRequired?: boolean
  isDisabled?: boolean
  isSkeleton?: boolean
  isChecked?: boolean
  onChange: () => void
}

export const Toggle = React.forwardRef<HTMLInputElement, React.PropsWithChildren<ToggleProps>>(
  (
    {
      id,
      name,
      describedBy,
      isRequired,
      isDisabled,
      isSkeleton,
      isChecked,
      onChange,
      children,
    }: React.PropsWithChildren<ToggleProps>,
    ref,
  ) => {
    if (isSkeleton) {
      return <SkeletonToggle />
    }

    const toggleClasses = classNames('c-toggle', {
      'c-toggle--active': isChecked,
    })

    return (
      <label className={toggleClasses} htmlFor={id}>
        <span className='c-toggle__input-container'>
          <input
            ref={ref}
            type='checkbox'
            className='c-toggle__input'
            id={id}
            aria-describedby={describedBy}
            name={name}
            required={isRequired}
            disabled={isDisabled}
            onChange={onChange}
            checked={isChecked}
          />
        </span>
        <span className='c-toggle__label'>{children}</span>
      </label>
    )
  },
)
