export const formatBytes = (bytes: number) => {
  if (bytes === 0) return '0 b'
  const k = 1024
  const units = ['b', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  // If b or KB, omit decimal place. Otherwise, round to 2 decimal places
  const decimalPlaces = i < 2 ? 0 : 2
  return parseFloat((bytes / Math.pow(k, i)).toFixed(decimalPlaces)) + ' ' + units[i]
}

export const splitFileName = (fileName: string): string[] => {
  const fileNameParts = fileName.split('.')
  if (fileNameParts.length === 1) {
    return [fileName, '']
  }
  const extension = fileNameParts.pop() ?? ''
  return [fileNameParts.join(), extension]
}

export const getFileExtension = (fileName: string) => {
  return splitFileName(fileName).pop()
}
