import { usePassedState } from '../../../../hooks'
import React, { useState } from 'react'
import { Button, InlineNotification, TagProps } from '../../../common'
import { ButtonsContainer } from '../../../layout'
import { FormField } from '../../form-field'
import { TextArea } from '../../text-area'
import { Toggle } from '../../toggle'

export type NotesAuthor = {
  id: number
  firstName: string
  lastName: string
}

export type NoteType<T = string, R = string> = {
  id?: number
  note: string
  isPublic?: boolean
  form: T
  recordType: R
  isDeleted?: boolean
  author: NotesAuthor
  createdDate: string
  tags?: Array<TagProps>
}
export interface EditNoteProps<T, R> {
  note?: NoteType<T, R>
  /**
   * Include public/private toggle within add note UI
   */
  enablePrivateNotes?: boolean
  addNote?: (note: string, isPublic?: boolean) => void
  editNote?: (note: string, isPublic?: boolean) => void
  clearNote?: () => void
  editType: 'new' | 'update'
}

export function EditNote<T, R>({
  note,
  enablePrivateNotes,
  addNote,
  editNote,
  clearNote,
  editType,
}: EditNoteProps<T, R>) {
  const [isPublic, setIsPublic] = useState(false)
  const [isDirty, setIsDirty] = useState(false)
  const [isBlank, setIsBlank] = useState(false)
  const { state: internalNote, setState: setInternalNote } = usePassedState(note?.note ?? '')

  const togglePublicVisibility = () => {
    setIsPublic(!isPublic)
  }

  const resetState = () => {
    setInternalNote('')
    setIsBlank(false)
    setIsDirty(false)
    setIsPublic(false)
    clearNote?.()
  }

  const onCancel = () => {
    resetState()
  }

  const onAdd = () => {
    if (!internalNote) {
      setIsBlank(true)
    } else {
      addNote?.(internalNote, isPublic)
      resetState()
    }
  }

  const onEdit = () => {
    if (!internalNote) {
      setIsBlank(true)
    } else if (editType === 'update') {
      editNote?.(internalNote, isPublic)
      resetState()
    }
  }

  return (
    <div className='c-notes__add-note-form'>
      <FormField
        label='Note'
        htmlFor='new-note'
        helpText={
          isDirty ? `Remember to click '${editType === 'update' ? 'Update' : 'Add'} note' before saving changes.` : ''
        }
      >
        <TextArea
          id='new-note'
          value={internalNote}
          onChange={(e) => setInternalNote(e.target.value)}
          placeholder='Add note here...'
          onFocus={() => setIsDirty(true)}
          isInvalid={isDirty && !internalNote}
        />
        {isBlank && <InlineNotification messageType='warning'>Cannot add an empty note</InlineNotification>}
      </FormField>
      {enablePrivateNotes && (
        <div className='c-note-form__toggle'>
          <Toggle
            id='public-note-toggle'
            name='public-note-toggle'
            isChecked={isPublic}
            onChange={togglePublicVisibility}
          >
            <strong>{isPublic ? 'Public' : 'Private'}</strong> - this note will{isPublic ? '' : ' not'} be made public
          </Toggle>
        </div>
      )}
      {(isDirty || internalNote) && (
        <div className='c-note-button-wrapper'>
          <ButtonsContainer containerStyle='left'>
            {editType === 'update' ? (
              <Button buttonStyle='primary' type='button' label='Update note' onClick={onEdit} />
            ) : (
              <Button buttonStyle='primary' type='button' label='Add note' onClick={onAdd} />
            )}
            <Button buttonStyle='text-action' type='button' label='Cancel' onClick={onCancel} />
          </ButtonsContainer>
        </div>
      )}
    </div>
  )
}
