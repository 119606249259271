import React from 'react'
import classNames from 'classnames'
import { Divider } from '../../common/divider'
import { LinkProps } from '../../navigation/link'
import { blockColorOption } from '../../../types/content-page-types'
import { Heading } from '../../common'
import { HorizontalMenu, Link } from '../../navigation'

export interface FooterLogo {
  imgPath: string
  altText: string
  linkUrl?: string
}

export interface ContentPageFooterProps {
  appName: string
  menuLinks?: Array<LinkProps>
  logos?: Array<FooterLogo>
  backgroundColor?: blockColorOption
}

export function ContentPageFooter({ appName, menuLinks, logos, backgroundColor }: ContentPageFooterProps) {
  const footerClasses = classNames('c-content-page-footer', {
    'c-content-page-footer--blue-wash': backgroundColor === 'blue-wash',
  })

  return (
    <footer className={footerClasses}>
      <div className='c-content-page-footer__content'>
        <Heading level={3} className='c-content-page-footer__heading'>
          {appName} <span className='a11y--screen-reader-only'>footer navigation</span>
        </Heading>
        {menuLinks && (
          <div className='c-content-page-footer__menu'>
            <HorizontalMenu menuLinks={menuLinks} />
          </div>
        )}
        <Divider lineWeight='light' verticalSpacingSize='small' isFullWidth />
        {logos && (
          <div className='c-content-page-footer__logos'>
            {logos.map(({ imgPath, altText, linkUrl }) =>
              !!linkUrl ? (
                <Link key={imgPath} className='c-content-page-footer__logo-container' path={linkUrl}>
                  <img className='c-content-page-footer__logo' src={imgPath} alt={altText} />
                </Link>
              ) : (
                <div key={imgPath} className='c-content-page-footer__logo-container'>
                  <img className='c-content-page-footer__logo' src={imgPath} alt={altText} />
                </div>
              ),
            )}
          </div>
        )}
        <div className='c-content-page-footer__legal-text'>
          &copy; {appName} {new Date().getFullYear()}
        </div>
      </div>
    </footer>
  )
}
