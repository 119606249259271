import React from 'react'
import { Heading } from '../../common'
import { BlockContainer, BlockContainerProps } from '../block-container'
import { FeatureItem, FeatureItemProps } from './feature-item'

export interface FeaturesBlockProps extends BlockContainerProps {
  heading?: string
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6
  features: Array<FeatureItemProps>
}

export function FeaturesBlock({
  blockColor,
  blockWidth = 'narrow',
  heading,
  headingLevel = 2,
  features,
  children,
}: React.PropsWithChildren<FeaturesBlockProps>) {
  return (
    <BlockContainer blockColor={blockColor} blockWidth={blockWidth}>
      <div className='c-features-block'>
        {heading && (
          <Heading className='c-features-block__heading' level={headingLevel}>
            {heading}
          </Heading>
        )}

        {/* Children should be <FeatureBlock> components */}
        <div className='c-features-block__features'>
          {features.map(({ icon, heading, headingLevel, bodyText }, i) => (
            <FeatureItem
              key={`${heading}-${i}`}
              icon={icon}
              heading={heading}
              headingLevel={headingLevel}
              bodyText={bodyText}
            />
          ))}
        </div>

        {/* Optional content to be displayed underneath feature grid. Could be button container with CTA etc. */}
        {children && <div className='c-features-block__additional-content'>{children}</div>}
      </div>
    </BlockContainer>
  )
}
