import axios, { AxiosRequestHeaders } from 'axios'
import { SERVER_ADDRESS } from '../environment'
import { removeDuplicateSlashes } from './urls'

import { HttpVerb } from '../types/api-types'

import Cookies from 'js-cookie'
import { ErrorCode } from '../components/errors/constants'
// TODO Using raygun?
// import RaygunClient, { ErrorType } from './raygun'

export const getAPIEndpoint = (url: string, prefixApi = true): string => {
  const apiUrl = prefixApi ? `${SERVER_ADDRESS}/api/${url}` : url
  return removeDuplicateSlashes(apiUrl)
}

export const getData = async (url: string, headers: AxiosRequestHeaders) => {
  const { data } = await axios.get(getAPIEndpoint(url), {
    headers,
  })
  return data
}

type FetchApiProps = {
  url: string
  method?: HttpVerb
  body?: string | FormData
  additionalHeaders?: HeadersInit
  abortSignal?: AbortSignal
  prefixApi?: boolean
}

export default async function fetchApi({
  url,
  method = HttpVerb.GET,
  body,
  additionalHeaders,
  abortSignal,
  prefixApi = true,
}: FetchApiProps) {
  const apiUrl = getAPIEndpoint(url, prefixApi)
  let headers = {}
  if (!(body instanceof FormData)) {
    headers = { 'content-type': 'application/json', ...additionalHeaders }
  }

  // Send CSRF token to server, also requires "credentials: 'include'" to send/set cookies
  if (method !== HttpVerb.GET) {
    const csrfToken = Cookies.get('csrftoken') || ''
    headers = { ...headers, 'X-CSRFToken': csrfToken }
  }

  const options = { method, headers, body, signal: abortSignal, credentials: 'include' as RequestCredentials }
  const response = await fetch(apiUrl, options)

  if (!response.ok) {
    switch (response.status) {
      case 400:
        // 400 responses are caught and displayed as toast messages
        // inside `useEditForm` hook
        // TODO Using raygun?
        // RaygunClient.getInstance().trackError(new Error(ErrorCode.BAD_REQUEST), {
        //   type: ErrorType.API_ERROR,
        //   requestBody: body,
        // })
        // eslint-disable-next-line no-case-declarations
        const result = await response.json()
        throw result
      case 401:
        throw new Error(ErrorCode.SESSION_TIMEOUT)
      case 403:
        throw new Error(ErrorCode.PERMISSION_DENIED)
      case 404:
        throw new Error(ErrorCode.NOT_FOUND)
      case 504:
        throw new Error(ErrorCode.EXTERNAL_API_TIMEOUT)
      default:
        throw new Error(ErrorCode.SERVER_ERROR)
    }
  }

  return response.json()
}
