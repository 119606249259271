import React, { useState } from 'react'
import { EditNote, NotesAuthor, NoteType } from './add-note'
import { NotesList } from './notes-list'
import { DISPLAY_DATE_FORMAT, formatDate } from '@msaf/core-common'
import { Card, Heading } from '../../common'

interface NotesProps<T, R> {
  headingText?: string
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6
  notes: Array<NoteType<T, R>>
  /**
   * Include textarea to add new note to list
   */
  isEditMode?: boolean
  form: T
  recordType: R
  author: NotesAuthor
  enablePrivateNotes?: boolean
  hasEditPermission?: (authorId: number) => boolean
  onChange: (notes: Array<NoteType<T, R>>) => void
  deleteNote?: (index: number) => void
}

export type UpdatetNoteType<T, R> = Pick<NoteType<T, R>, 'note' | 'isPublic' | 'isDeleted'>

export function Notes<T = string, R = string>({
  headingText = 'Notes',
  headingLevel = 3,
  isEditMode,
  form,
  recordType,
  author,
  notes,
  enablePrivateNotes,
  hasEditPermission,
  onChange,
}: NotesProps<T, R>) {
  const [editNoteIndex, setEditNoteIndex] = useState<number | undefined>()

  const createNote = (newNote: UpdatetNoteType<T, R>, existingNote?: NoteType<T, R>): NoteType<T, R> => {
    return {
      form,
      recordType,
      author,
      createdDate: formatDate(new Date(), DISPLAY_DATE_FORMAT),
      ...(existingNote ?? {}),
      ...newNote,
      ...(enablePrivateNotes && { tags: [{ label: `${newNote.isPublic ? 'Public' : 'Private'}` }] }),
    }
  }

  const addNote = ({ note, isPublic = false }: UpdatetNoteType<T, R>) => {
    const newNote: NoteType<T, R> = createNote({
      note,
      isPublic,
    })
    const newNotes = [newNote, ...notes]
    onChange(newNotes)
  }

  const editNote = (
    index: number,
    { note, isPublic, isDeleted = false }: Pick<NoteType, 'note' | 'isDeleted' | 'isPublic'>,
  ) => {
    if (index >= notes.length) {
      throw Error('NOTES-2: Note index out of range.')
    }
    const newNotes = [...notes]
    const newNote = createNote({ note, isPublic, isDeleted }, notes[parseInt(`${index}`)])
    newNotes.splice(index, 1, newNote)
    onChange(newNotes)
  }

  const hardDeleteNote = (index: number) => {
    if (index >= notes.length) {
      throw Error('NOTES-2: Note index out of range.')
    }
    const newNotes = [...notes]
    newNotes.splice(index, 1)
    onChange(newNotes)
  }

  const deleteNote = (index: number) => {
    const note = notes[parseInt(`${index}`)]
    if (note.id) {
      // Soft delete saved note
      editNote(index, { note: note.note, isDeleted: true })
    } else {
      // Hard delete unsaved note
      hardDeleteNote(index)
    }
    clearNote()
  }

  const clearNote = () => {
    setEditNoteIndex(undefined)
  }

  return (
    <Card className='c-notes' cardStyle='grey'>
      <Heading className='c-notes__heading' level={headingLevel}>
        {headingText}
      </Heading>
      {isEditMode && (
        <EditNote<T, R>
          addNote={(note: string, isPublic?: boolean) => addNote({ note, isPublic })}
          editNote={(note: string, isPublic?: boolean) =>
            editNoteIndex !== undefined && editNote(editNoteIndex, { note, isPublic })
          }
          note={editNoteIndex !== undefined ? notes[parseInt(`${editNoteIndex}`)] : undefined}
          editType={editNoteIndex !== undefined ? 'update' : 'new'}
          clearNote={clearNote}
        />
      )}
      <NotesList<T, R>
        notes={notes}
        isEditMode={isEditMode}
        hasEditPermission={hasEditPermission}
        editNote={(index: number) => setEditNoteIndex(index)}
        deleteNote={(index: number) => deleteNote(index)}
      />
    </Card>
  )
}
