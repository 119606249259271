import * as L from 'leaflet'
import '@geoman-io/leaflet-geoman-free'
import { calculatePolylineLength } from '../../utils/helpers'
import { debounce, throttle } from 'lodash'
import * as classNames from 'classnames'

const MEASUREMENT_TOOLTIP_THROTTLE_MS = 100
const MEASUREMENT_TOOLTIP_DEBOUNCE_MS = 250

const MEASUREMENT_TOOLTIP_VALUE_PRECISION = 2

export const MSPolyline = L.PM.Draw.Line.extend({
  initialize(map) {
    L.PM.Draw.Line.prototype.initialize.call(this, map)
    this._shape = 'MSPolyline'
  },
  enable(options) {
    L.PM.Draw.Line.prototype.enable.call(this, options)
    this._hintMarker.on('move', throttle(this._setMeasurementTooltipText, MEASUREMENT_TOOLTIP_THROTTLE_MS), this)
    this._hintMarker.on('move', debounce(this._setMeasurementTooltipText, MEASUREMENT_TOOLTIP_DEBOUNCE_MS), this)

    // Add CSS class and set the tooltip options.
    const tooltip = this._hintMarker.getTooltip()
    if (tooltip) {
      const element = tooltip.getElement()
      const content = tooltip.getContent() ?? ''
      element.className = classNames(element.className, 'c-draw-tooltip')
      element.style.opacity = null

      L.Util.setOptions(tooltip, { direction: 'auto', opacity: null, offset: L.point(0, 0) })
    }

    this._measurementsTooltipElement = document.createElement('div')
    this._measurementsTooltipElement.className = classNames('c-draw-tooltip__measurements')
  },
  _calculateTotalLength() {
    return this._calculateSegmentLength() + calculatePolylineLength(this._layer)
  },
  _calculateSegmentLength() {
    return calculatePolylineLength(this._hintline)
  },
  _createVertex(e) {
    L.PM.Draw.Line.prototype._createVertex.call(this, e)
    // Re-calculated to ensure the segment length is reset to 0.
    this._setMeasurementTooltipText()
  },
  _setMeasurementTooltipText() {
    if (!this.options.mediaSuite?.measurement) return

    // Reset the measurement element.
    this._measurementsTooltipElement.innerHTML = ''

    if (this.options.mediaSuite?.measurement?.segmentLength) {
      const len = this._calculateSegmentLength().toFixed(MEASUREMENT_TOOLTIP_VALUE_PRECISION)
      this._measurementsTooltipElement.insertAdjacentHTML(
        'beforeend',
        `<div class='c-draw-tooltip__value'>${len} m</div>`,
      )
    }
    if (this.options.mediaSuite?.measurement?.totalLength && this._layer.getLatLngs().flat().length > 1) {
      // Show the total length if a segment has been drawn.
      const len = this._calculateTotalLength().toFixed(MEASUREMENT_TOOLTIP_VALUE_PRECISION)
      this._measurementsTooltipElement.insertAdjacentHTML(
        'beforeend',
        `<div class='c-draw-tooltip__value'>${len} m</div>`,
      )
    }
    // Re-bind the tooltip to update its new position now we have inserted new content.
    this._hintMarker.bindTooltip()
  },
  _setTooltipText() {
    // Call the parent class function to set the tooltip with helper text.
    L.PM.Draw.Line.prototype._setTooltipText.call(this)

    const tooltip = this._hintMarker.getTooltip()
    const element = tooltip?.getElement()
    if (!element) return

    // Extract the current tooltip content and add it to a new div
    const helpText = document.createElement('div')
    helpText.className = 'c-draw-tooltip__help-text'
    helpText.innerHTML = tooltip.getContent()

    // Update the new tooltip structure with the measurement element
    element.innerHTML = ''
    if (this._measurementsTooltipElement) element.appendChild(this._measurementsTooltipElement)
    element.appendChild(helpText)
  },
})
