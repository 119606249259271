import classNames from 'classnames'
import { motion } from 'framer-motion'
import React, { useState } from 'react'
import { NAVIGATION_BREAKPOINT } from '../../../constants'
import { useMediaQuery } from '../../../hooks/media-query'
import { Icon } from '../../common/icon'
import { hideOnCollapseAnimations, navPanelAnimations } from './animations'
import { AppLogo, AppLogoProps } from './app-logo'
import { BrandingLogo, BrandingLogoProps } from './branding-logo'
import { MainMenu } from './main-menu'

interface NavPanelProps extends AppLogoProps {
  isMenuHidden: boolean
  closeMenu: () => void
  mainMenuLinks?: Array<any>
  appLogo?: AppLogoProps
  brandingLogos?: Array<BrandingLogoProps>
  isSkeleton?: boolean
}

export function NavPanel({
  isMenuHidden,
  closeMenu,
  mainMenuLinks,
  appLogo,
  brandingLogos,
  isSkeleton,
}: NavPanelProps) {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)

  // Media query to auto-collapse nav panel on narrow desktops (to allow content more space)
  // TODO: update to collapse by default at this range but still allow toggling
  const isNarrowDesktop = useMediaQuery(`(min-width: ${NAVIGATION_BREAKPOINT}) and (max-width: 1260px)`)

  // Toggle menu between expanded and collapsed (icon only links) view
  const navPanelClasses = classNames('c-nav-panel', {
    'c-nav-panel--collapsed': isCollapsed || isNarrowDesktop,
  })

  // Control state of expand/contract button via CSS classes
  const collapseBtnClasses = classNames('c-collapse-btn', {
    'c-collapse-btn--expand': isCollapsed,
  })

  // Hide menu if on full-screen page with alternative navigation (i.e. mapping tools etc)
  if (isMenuHidden) {
    return null
  }

  return (
    <div className='l-page__menu'>
      <motion.div
        className={navPanelClasses}
        variants={navPanelAnimations}
        initial='default'
        animate={isCollapsed || isNarrowDesktop ? 'collapsed' : 'default'}
      >
        {/* Expand/collapse menu items button */}
        <button className={collapseBtnClasses} onClick={() => setIsCollapsed(!isCollapsed)}>
          <Icon icon='collapse' className='c-collapse-btn__icon' ariaLabel='collapse' />
        </button>

        {/* Home Link */}
        <AppLogo {...appLogo} isIconOnlyVersion isSkeleton={isSkeleton} />

        {/* Close panel button (mobile only) */}
        <button onClick={closeMenu} className='c-nav-panel__close-btn'>
          <Icon icon='close' className='c-nav-panel__close-btn-icon' ariaHidden={true} />
          Close
        </button>

        {/* Main menu */}
        <div className='c-nav-panel__menu'>
          <MainMenu links={mainMenuLinks} closeMenu={closeMenu} isCollapsed={isCollapsed} isSkeleton={isSkeleton} />
        </div>

        <motion.div className='c-nav-panel__branding' variants={hideOnCollapseAnimations}>
          {/* Org branding and module switch menu */}
          {isSkeleton && <BrandingLogo isSkeleton={isSkeleton} />}
          {!isSkeleton &&
            brandingLogos?.map(({ imagePath, altText, link, logoLayout }) => (
              <BrandingLogo key={altText} imagePath={imagePath} altText={altText} link={link} logoLayout={logoLayout} />
            ))}
        </motion.div>
      </motion.div>
    </div>
  )
}
