import { RecordContainer, WorkflowNav } from '@msaf/core-react'
import { Outlet, useLocation, useParams } from 'react-router-dom'
import { getWorkflowLinks } from '../config/workflow-nav'
import { useBackLink } from '../hooks/useBacklink'
import { RouteParams } from '../types/route'

export const WorkflowLayout = () => {
  const { id, mode } = useParams<RouteParams>()
  useBackLink({ label: 'Dashboard', path: '/dashboard' })
  const { pathname } = useLocation()

  const workflowNavLinks = id && mode ? getWorkflowLinks(id, mode, pathname) : []

  return (
    <RecordContainer workflowNav={<WorkflowNav sections={workflowNavLinks} />}>
      <Outlet />
    </RecordContainer>
  )
}
