import React from 'react'
import ReactModal from 'react-modal'
import { ButtonsContainer } from '../../layout/buttons-container'
import { Heading } from '../heading'

// See https://reactcommunity.org/react-modal/accessibility/

export interface BaseModalProps {
  isOpen: boolean
  onRequestClose: VoidFunction
  contentLabel: string // Indicates how the modal should be announced to screen readers
}

export interface BoilerplateModalProps extends BaseModalProps {
  heading?: string
  bodyText?: string
  buttons?: JSX.Element
  children?: never
}

export interface CustomModalProps extends BaseModalProps {
  heading?: never
  bodyText?: never
  buttons?: never
}

export type ModalProps = BoilerplateModalProps | React.PropsWithChildren<CustomModalProps>

export function Modal({ isOpen, onRequestClose, contentLabel, heading, bodyText, buttons, children }: ModalProps) {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      className='c-modal'
      overlayClassName='c-modal__overlay'
      portalClassName='c-modal__portal'
    >
      {/* Allow custom content inside modal: */}
      {children}

      {/* Boilerplate style - pass in heading, body text and buttons */}
      {!children && (
        <>
          {heading && (
            <Heading level={3} className='c-modal__heading'>
              {heading}
            </Heading>
          )}
          {bodyText && <div className='c-modal__body-text'>{bodyText}</div>}
          {buttons && <ButtonsContainer containerStyle='right'>{buttons}</ButtonsContainer>}
        </>
      )}
    </ReactModal>
  )
}
