import React, { useEffect } from 'react'
import { Button } from '../../common/button'
import { Card } from '../../common/card'
import { Heading } from '../../common/heading'
import { HeadingRow } from '../../layout/heading-row'
import { ValidationMessage } from '../validation-message'

export interface RepeatableSectionProps {
  heading: string
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6
  sectionName: string
  // Add button will lowercase item name unless this set to true:
  retainSectionNameCasing?: boolean
  // Allow all fields to be removed (defaults to false)
  allowEmpty?: boolean
  removeField?: (index: number) => void
  addField?: () => void
  validationMessages?: Array<string>
  isSkeleton?: boolean
  disableNumberedSections?: boolean
}

export function RepeatableSection({
  heading,
  headingLevel = 3,
  sectionName,
  retainSectionNameCasing = false,
  allowEmpty = false,
  children,
  removeField,
  addField,
  validationMessages = [],
  isSkeleton,
  disableNumberedSections = false,
}: React.PropsWithChildren<RepeatableSectionProps>) {
  useEffect(() => {
    // If component list is empty, add default fields to initialise the field
    if (!allowEmpty && !React.Children.count(children) && addField) {
      addField()
    }
  }, [allowEmpty, addField, children])

  return (
    <div className='c-repeatable-section'>
      <Heading isSkeleton={isSkeleton} level={headingLevel}>
        {heading}
      </Heading>
      {React.Children.map(children, (sectionFields, id) => (
        <Card key={id}>
          <HeadingRow
            headingText={disableNumberedSections ? sectionName : `${sectionName} ${id + 1}`}
            headingLevel={4}
            isSkeleton={isSkeleton}
            verticalSpacing={'x-small'}
          >
            {removeField && (allowEmpty || React.Children.count(children) > 1) && (
              <Button
                type='button'
                label='Remove'
                icon='trash'
                iconAlignment='left'
                buttonStyle='secondary'
                onClick={() => removeField(id)}
                isSkeleton={isSkeleton}
              />
            )}
          </HeadingRow>
          {sectionFields}
        </Card>
      ))}
      {addField && (
        <Button
          type='button'
          label={`Add ${retainSectionNameCasing ? sectionName : sectionName.toLowerCase()}`}
          icon='add'
          iconAlignment='left'
          buttonStyle='secondary'
          onClick={() => addField()}
          isSkeleton={isSkeleton}
        />
      )}
      {validationMessages?.map((errorMessage) => (
        <ValidationMessage errorMessage={errorMessage} key={errorMessage} />
      ))}
    </div>
  )
}
