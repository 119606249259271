import { isValid } from 'date-fns'

type BannerApi = {
  message: string
  messagetype?: string
  expiry: string
}

const BANNER_LOCAL_STORAGE_KEY = 'closedBanners'

const getClosedBanners = (): string[] => {
  const bannerInfo = localStorage.getItem(BANNER_LOCAL_STORAGE_KEY)
  const bannerExpiries = !!bannerInfo ? JSON.parse(bannerInfo) : []
  // Make sure we get rid of any closed banner records that have expired
  const currentBanners = bannerExpiries.filter((expiry: string) => bannerCurrent(expiry))
  saveClosedBanners(currentBanners)
  return currentBanners
}

const saveClosedBanners = (closedBanners: string[]) => {
  localStorage.setItem(BANNER_LOCAL_STORAGE_KEY, JSON.stringify(closedBanners))
}

export const onBannerClose = (expiry: string) => {
  const closedBanners = getClosedBanners()
  closedBanners.push(expiry)
  saveClosedBanners(closedBanners)
}

export const isBannerDataValid = (data: BannerApi) => {
  if (!data.message || data.message.length < 1) {
    console.log("Banner message can't be empty")
    return false
  }

  if (!data.expiry || !isValid(Date.parse(data.expiry))) {
    console.log('Banner expiry date not formatted correctly, must be YYYY-MM-DD HH:mm:ss')
    return false
  }

  return true
}

const bannerCurrent = (expiry: string) => {
  return Date.now() < Date.parse(expiry)
}

export const shouldShowBanner = (banner: BannerApi) => {
  return bannerCurrent(banner.expiry) && !getClosedBanners().includes(banner.expiry)
}

export const determineMessageType = (msg?: string) => (msg === 'warning' ? 'warning' : 'info')
