import html2canvas from 'html2canvas'
import jsPDF, { jsPDFOptions } from 'jspdf'

// https://shivekkhurana.medium.com/how-to-create-pdfs-from-react-components-client-side-solution-7f506d9dfa6d

const pxToMm = (px: number) => {
  const mmElement = document.getElementById('myMm')
  if (!mmElement) {
    throw new Error('PDF Generation - Unable to find an element to map mm to px ')
  }
  return Math.floor(px / mmElement.offsetHeight)
}

const BASE_PDF_OPTIONS: jsPDFOptions = {
  orientation: 'portrait',
  unit: 'mm',
  format: 'a4',
  compress: true,
}

export const downloadPdf = (fileName: string, pdfTargetId: string) => {
  // Disabling as element will always be available
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const targetElement = document.getElementById(pdfTargetId)
  if (!targetElement) {
    throw new Error('PDF Generation - Unable to find a target element')
  }

  const inputHeightMm = pxToMm(targetElement.offsetHeight)
  const a4WidthMm = 210
  const a4HeightMm = 297

  // Add a class to standardise/style the element how we want it
  // See _submission-page.scss
  targetElement.classList.add('rendering')

  html2canvas(targetElement)
    .then((canvas) => {
      let pdf
      const imgData = canvas.toDataURL()

      if (inputHeightMm > a4HeightMm) {
        // elongated a4 (system print dialog will handle page breaks)
        const options = { ...BASE_PDF_OPTIONS, format: [inputHeightMm + 16, a4WidthMm] }
        pdf = new jsPDF(options)
      } else {
        // standard a4
        pdf = new jsPDF(BASE_PDF_OPTIONS)
      }

      pdf.addImage({ imageData: imgData, x: 0, y: 0, width: a4WidthMm, height: inputHeightMm, format: 'PNG' })
      pdf.save(`${fileName}.pdf`)
    })
    .finally(() => {
      targetElement.classList.remove('rendering')
    })
}
