import * as L from 'leaflet'
import markerIcon from '../assets/map-pin-solid.svg'
import userLocationIcon from '../assets/map-pin-user-location.svg'

export const USER_LOCATION_MARKER_ICON = new L.Icon({
  iconUrl: userLocationIcon,
  iconSize: new L.Point(25, 30),
  draggable: false,
})

export const DEFAULT_MARKER_ICON = new L.Icon({
  iconUrl: markerIcon,
  iconSize: new L.Point(50, 60),
  iconAnchor: new L.Point(25, 40),
})
