import {
  FieldTypes,
  FormField,
  FormFieldRow,
  Heading,
  InlineNotification,
  ReadOnlyField,
  ToastMessage,
  Toggle,
  createToastMessage,
} from '@msaf/core-react'
import { latLngToNZTM, nztmToLatLng } from '@msaf/maps-common'
import { useState } from 'react'
import useInlineMap, { CoordinateTypes, LOCATION_FIELD_DEFAULT_ZOOM } from '../../hooks/use-inline-map'
import { ValidationErrors } from '../../hooks/useEditForm'
import { FormFieldsEdit } from '../../types/form-types'
import AddressLookup from '../address-lookup'
import InlineMap from './inline-map'
import { defaultIcon, getBaseLayers } from './map-config'

export type LocationFieldEditType = {
  fieldId: keyof FormFieldsEdit
  value: string
  setValue: (value: FieldTypes) => void
}

export interface LocationFieldEditProps {
  isViewMode?: boolean
  zoomLevel?: number
  formFieldLabel?: string
  labelledBy: string
  easting: LocationFieldEditType
  northing: LocationFieldEditType
  isLoading?: boolean
  validationFieldErrors?: ValidationErrors
  isRequired?: boolean
  index?: number
}

export default function LocationFieldEdit({
  isViewMode = false,
  zoomLevel,
  formFieldLabel,
  easting,
  northing,
  index,
  isLoading,
  validationFieldErrors = {},
  isRequired = false,
}: LocationFieldEditProps) {
  const { startPosition, projectionRef, isValidCoordinates, setIsValidCoordinates } = useInlineMap({
    xCoordinate: easting.value,
    yCoordinate: northing.value,
    projectionType: CoordinateTypes.NZTM,
  })

  // Keeps track of changes made through the map interface or the form inputs
  const [isLocationSetFromMap, setIsLocationSetFromMap] = useState(isViewMode)
  const [isUsingBrowserLocation, setIsUsingBrowserLocation] = useState(false)

  const convertMapCoordinates = (coordinates: [number, number]) => {
    let [x, y] = coordinates
    // Using the projectionRef value instead of the props to avoid using stale props due to closure
    if (projectionRef.current !== CoordinateTypes.LatLng) {
      try {
        ;[x, y] = latLngToNZTM(coordinates)
      } catch {
        setIsValidCoordinates(false)
      }
    }
    return [x, y]
  }

  const getBrowserLocation = () => {
    setIsUsingBrowserLocation(!isUsingBrowserLocation)
    // Remove previous values for Easting / Northing prior to using geolocation
    easting.setValue('')
    northing.setValue('')

    navigator.geolocation.getCurrentPosition(
      (position) => {
        updateLocationFromMap([position.coords.latitude, position.coords.longitude], false)
      },
      (error: GeolocationPositionError) => {
        createToastMessage(
          <ToastMessage messageType='error' title='Error' message='Could not determine your current location' />,
        )
        throw new Error(error.message)
      },
    )
  }

  // Set the easting northing value in the form fields
  const updateLocationFromMap = (coordinatesInLatLng: [number, number], isLocationSetFromMap = true) => {
    let [y, x] = coordinatesInLatLng
    if (x && y) {
      // Convert value based on projection type
      // Assumes NZTM as the default projection type
      ;[x, y] = convertMapCoordinates(coordinatesInLatLng)
      easting.setValue(x.toFixed(2))
      northing.setValue(y.toFixed(2))
      // Coordinates updated through the map UI
      setIsLocationSetFromMap(isLocationSetFromMap)
    }
  }

  const getMapZoom = () => {
    if (zoomLevel) return zoomLevel
    // No start position - zoom out
    if (!startPosition) return LOCATION_FIELD_DEFAULT_ZOOM.NO_LOCATION + 1
    // User selected a position - zoom in slightly
    if (isLocationSetFromMap) return LOCATION_FIELD_DEFAULT_ZOOM.USER_LOCATION
    return LOCATION_FIELD_DEFAULT_ZOOM.LOCATION_SET
  }

  const getValidationMessages = () => {
    if (!isValidCoordinates && startPosition) {
      return ['Coordinates are not valid, please select a valid location']
    } else if (easting.fieldId in validationFieldErrors) {
      ;[validationFieldErrors[easting.fieldId as keyof typeof validationFieldErrors]]
    } else if (northing.fieldId in validationFieldErrors) {
      ;[validationFieldErrors[northing.fieldId as keyof typeof validationFieldErrors]]
    }

    return undefined
  }

  return (
    <>
      {formFieldLabel && (
        <Heading isSkeleton={isLoading} level={4}>
          {formFieldLabel}
        </Heading>
      )}

      {!isViewMode && (
        <>
          <div className='wide-notification'>
            <InlineNotification messageType='info' isDismissable={false}>
              Please indicate the approximate centre of the land holding on the map. <br />
              <br />
              You can do so by searching via address, using your current location, or selecting a position on the map by
              zooming/panning to the area of the land holding.
            </InlineNotification>
          </div>
          <FormField label='' isSkeleton={isLoading} htmlFor='farmLocation'>
            <Toggle
              id='farmLocation'
              isChecked={isUsingBrowserLocation}
              isSkeleton={isLoading}
              onChange={() => getBrowserLocation()}
              name='farmLocation'
            >
              Use current location
            </Toggle>
          </FormField>

          {!isUsingBrowserLocation && (
            <FormField isSkeleton={isLoading} label='Enter your address' labelId='addressLookupId'>
              <AddressLookup
                isSkeleton={isLoading}
                labelledBy='addressLookupID'
                autoFocus={false}
                updateLocationFromMap={(coordinates) => updateLocationFromMap(coordinates, false)}
              />
            </FormField>
          )}
        </>
      )}

      <FormFieldRow>
        <FormField
          toolTipMessage={'Select the centre of the land holding'}
          isRequired={isRequired}
          isSkeleton={isLoading}
          label={CoordinateTypes.Easting}
          htmlFor={`${easting.fieldId}${index !== undefined ? index + 1 : ''}`}
          validationMessages={getValidationMessages()}
        >
          <ReadOnlyField
            value={easting.value}
            labelledBy={`${easting.fieldId}${index !== undefined ? index + 1 : ''}`}
          />
        </FormField>
        <FormField
          toolTipMessage={'Select the centre of the land holding'}
          isRequired={isRequired}
          isSkeleton={isLoading}
          label={CoordinateTypes.Northing}
          htmlFor={`${northing.fieldId}${index !== undefined ? index + 1 : ''}`}
          validationMessages={getValidationMessages()}
        >
          <ReadOnlyField
            value={northing.value}
            labelledBy={`${northing.fieldId}${index !== undefined ? index + 1 : ''}`}
          />
        </FormField>
      </FormFieldRow>

      <InlineMap
        setLocation={updateLocationFromMap}
        startPosition={
          !startPosition
            ? nztmToLatLng([
                LOCATION_FIELD_DEFAULT_ZOOM.NZ_CENTRE.easting,
                LOCATION_FIELD_DEFAULT_ZOOM.NZ_CENTRE.northing,
              ])
            : startPosition
        }
        markerIcon={defaultIcon}
        getBaseLayers={getBaseLayers}
        gotoStartPosition={!isLocationSetFromMap}
        zoom={getMapZoom()}
        showMarker={isViewMode || !!startPosition}
        viewMode={isViewMode}
      />
    </>
  )
}
