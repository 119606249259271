import React from 'react'
import { Heading } from '../../../common/heading'
import { Icon } from '../../../common/icon'
import { NavLink, NavLinkProps } from '../nav-link'

interface WithLinks {
  links: NavLinkProps[]
  adviceText?: undefined
}

interface WithHelp {
  links?: undefined
  adviceText: string
}

export type SectionConfig = (WithLinks | WithHelp) & {
  heading?: string
  isSectionComplete?: boolean
}

export type NavSectionProps = SectionConfig & {
  showDivider?: boolean
}

export function NavSection({ heading, isSectionComplete, links, adviceText, showDivider }: NavSectionProps) {
  const headingText = heading != null && heading.trim() !== '' ? heading : null

  return (
    <div className='c-workflow-nav__section'>
      {showDivider && <div className='c-workflow-nav__section-divider' />}
      {headingText && (
        <Heading level={3} className='c-workflow-nav__section-heading'>
          <span className='c-workflow-nav__heading-text'>{headingText}</span>
          {isSectionComplete && (
            <Icon
              className='c-workflow-nav__heading-icon'
              role='img'
              icon='check-solid'
              ariaLabel='Section completed'
            />
          )}
        </Heading>
      )}

      {links?.map((link) => (
        <NavLink key={`${link.label}--${link.path}`} {...link} />
      ))}

      {adviceText && <span className='c-workflow-nav__section-advice'>{adviceText}</span>}
    </div>
  )
}
