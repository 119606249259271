import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'
import { useClickOutside } from '../../../hooks/click-outside'
import { Button, ButtonWithIcon } from '../../common/button'
import { desktopMenuAnimations as menuAnimations } from '../app-shell/toolbar-menu/animations'

export interface ActionsMenuProps extends ButtonWithIcon {
  label: string
  isLeftAligned?: boolean
}

export function ActionsMenu({
  label,
  isLeftAligned = false,
  buttonStyle,
  isDisabled,
  isSkeleton,
  children,
}: ActionsMenuProps) {
  const [isOpen, setIsOpen] = useState(false)

  // Automatically close menu if user clicks outside component area
  const node = useClickOutside<HTMLDivElement>(() => setIsOpen(false))

  const menuClasses = classNames('c-dropdown-container', {
    'c-dropdown-container--left-aligned': isLeftAligned,
  })

  return (
    <div ref={node} className='c-actions-menu'>
      <Button
        className='c-actions-menu__btn'
        label={label}
        icon='chevron-down'
        iconAriaLabel='Toggle menu'
        buttonStyle={buttonStyle}
        isDisabled={isDisabled}
        isSkeleton={isSkeleton}
        isIconRotated180={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      />
      <AnimatePresence>
        {isOpen && (
          <motion.nav
            className={menuClasses}
            aria-label={`${label} actions`}
            initial={menuAnimations.initial}
            animate={menuAnimations.animate}
            exit={menuAnimations.initial}
          >
            {children}
          </motion.nav>
        )}
      </AnimatePresence>
    </div>
  )
}
