import { useMemo } from 'react'
import { useUser } from '../auth'
import { UserPermissions } from '../types/permissions'
import { User } from '../types/user'

/*
Checks a given user have any of the specified permissions.
*/
export function canUser(user: User, permissions: UserPermissions | UserPermissions[]) {
  const permissionsList = Array.isArray(permissions) ? permissions : [permissions]
  return user.permissions.some((perm) => permissionsList.includes(perm))
}

/*
Hook to check if the current user has any of the specified permissions.
Depends on useUsers so should only be used within ProtectedRoutes.
*/
export function useUserPermission(
  permissions: UserPermissions | UserPermissions[],
): [undefined, true] | [boolean, false] {
  const [user, isLoading] = useUser()

  const hasPermission = useMemo(() => !!user && canUser(user, permissions), [user, permissions])

  if (isLoading) return [undefined, isLoading]

  return [hasPermission, isLoading]
}
