import { FilterInstance, FilterSet } from '../types/state'
import { FilterStructure, SearchTemplate } from '../types/structure'

/**
 * Creates a filterset with all filters that have default values within the template extracted and applied
 * @param filters Filters from a search template
 * @returns Filter set with default values applied
 */
export const createDefaultFilterSet = (filters: FilterStructure[]): FilterSet[] => {
  const defaultSet: FilterSet[] = []

  filters.forEach((filter) => {
    if (filter.defaultValue) {
      defaultSet.push({
        filterSet: {
          booleanOperator: 'OR',
          filters: [
            {
              filterKey: `${filter.filterKey}`,
              filterOperator: filter.filterOperator,
              filterValue: `${filter.defaultValue}`,
            },
          ],
        },
      })
    }
  })

  return defaultSet
}

/**
 * Simplifies a FilterSet object by collapsing/removing children if they are logically redundant
 * @param filter FilterSet to simplify
 * @param removeEmpty set to true to remove empty filters, so (AND ) becomes ()
 * @param removeSingleton set to true to collapse singleton filters, so (AND (OR (EQ 'colName' 2)) becomes (EQ 'colName' 2)
 * @returns Simplified FilterSet
 */
export function removeRedundantFilters(
  filter: FilterSet | FilterInstance,
  searchTemplate: SearchTemplate,
  removeEmpty = true,
  removeSingleton = false,
): FilterSet | FilterInstance | undefined {
  // a filter has two types: a single filter (filterKey), or a set of filters (filterSet)
  if ('filterSet' in filter && filter.filterSet) {
    const filteredFilters = filter.filterSet.filters
      .map((f) => removeRedundantFilters(f, searchTemplate, removeEmpty, removeSingleton))
      .filter((f: FilterSet | FilterInstance | undefined) => {
        // Remove filters that are undefined or have empty filter value
        return !(f === undefined || (removeEmpty && 'filterValue' in f && !f.filterValue))
      })

    if (removeEmpty && filteredFilters.length === 0) {
      // remove filterSets with no filters
      return undefined
    } else if (removeSingleton && filteredFilters.length === 1) {
      // collapse filterSets with a single filter
      return filteredFilters[0]
    } else {
      // base case for filterSet - return filterSet with removeRedundantFilters(filters)
      return Object.assign({}, filter, {
        filterSet: {
          booleanOperator: filter.filterSet.booleanOperator,
          filters: filteredFilters,
        },
      })
    }
  } else if ('filterKey' in filter && filter.filterKey) {
    // strip out any blank filters unless interpretBlankAsNull option is set on the search template for the filter
    if (removeEmpty && (filter.filterValue === undefined || filter.filterValue.trim() === '')) {
      const filterStructure = searchTemplate.filters.find((t) => t.filterKey === filter.filterKey)

      // Return a null filter or undefined to be stripped out.
      return filterStructure?.interpretBlankAsNull
        ? { filterOperator: 'is null', filterKey: filter.filterKey }
        : undefined
    }
    return filter
  }

  return undefined
}
