import classNames from 'classnames'
import React, { KeyboardEventHandler, WheelEvent, FocusEvent } from 'react'
import { IconType } from '../../../types/icon-types'
import { InputWidth } from '../../../types/input-width-types'
import { Icon } from '../../common/icon'
import { SkeletonTextInput } from './skeleton-text-input'

export interface TextInputProps extends React.ComponentPropsWithoutRef<'input'> {
  id: string
  name?: string
  type?: 'email' | 'hidden' | 'number' | 'password' | 'search' | 'tel' | 'text' | 'url'
  fieldSize?: InputWidth
  describedBy?: string | undefined
  isRequired?: boolean
  isDisabled?: boolean
  isInvalid?: boolean
  setValue?: (value: string) => void
  placeholder?: string
  autocomplete?: string
  icon?: IconType
  isSkeleton?: boolean
  autoFocus?: boolean
  onKeyDown?: KeyboardEventHandler
}

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      id,
      name,
      type,
      fieldSize = InputWidth.default,
      describedBy = `${id}--help-text ${id}--validation-msg`,
      isRequired,
      isDisabled,
      isInvalid,
      value,
      setValue,
      placeholder,
      autocomplete,
      icon,
      isSkeleton,
      autoFocus = false,
      onKeyDown,
      ...props
    }: TextInputProps,
    ref,
  ) => {
    if (isSkeleton) {
      return <SkeletonTextInput />
    }

    const inputClasses = classNames('c-text-input', {
      'c-text-input--invalid': isInvalid,
      'c-text-input--disabled': isDisabled,
      'c-text-input--has-icon': icon !== undefined,
      [`c-text-input--${fieldSize}`]: !!fieldSize,
    })

    /**
     * Prevents field values being changed when the field is still in focus and the page is scrolled
     * https://medium.com/modernnerd-code/how-to-disabled-scrolling-on-html-number-input-in-react-6548841166fb
     */
    const preventChangeOnScroll = (e: WheelEvent<HTMLInputElement> & FocusEvent<HTMLInputElement>) => {
      // Prevent the input value change only if the element is focused.
      if (e.target === document.activeElement && e.target.type === 'number') {
        e.target.blur()
      }
    }

    const renderInput = () => {
      return (
        <input
          ref={ref}
          className={inputClasses}
          id={id}
          name={name}
          type={type}
          aria-describedby={describedBy}
          defaultValue={value}
          required={isRequired}
          disabled={isDisabled}
          placeholder={placeholder}
          autoComplete={autocomplete}
          onKeyDown={onKeyDown}
          onWheel={preventChangeOnScroll}
          {...props}
        />
      )
    }

    return (
      <>
        {!icon && renderInput()}
        {icon && (
          <div className='c-input-container'>
            <Icon className='c-input-container__icon' icon={icon} ariaHidden={true} />
            {renderInput()}
          </div>
        )}
      </>
    )
  },
)
