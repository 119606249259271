import { Feature } from '@turf/helpers'
import MapUIConfig, { PathComposition, PointFeature, PolygonFeature, PolylineFeature } from '../types/config'

export const compositionToDashArray = (
  composition: PathComposition | undefined,
  weight: number,
): string | undefined => {
  if (!composition || composition === 'solid') return undefined

  if (composition === 'dashesNarrowSpace') {
    return `${weight * 2.5} ${weight * 1.5}`
  } else if (composition === 'dashesMediumSpace' || composition === 'dashes') {
    return `${weight * 2.5} ${weight * 3}`
  } else if (composition === 'dashesWideSpace') {
    return `${weight * 2.5} ${weight * 6}`
  } else if (composition === 'narrowDashesNarrowSpace') {
    return `${weight * 2} ${weight * 1.5}`
  } else if (composition === 'narrowDashesMediumSpace') {
    return `${weight * 2} ${weight * 2}`
  } else if (composition === 'narrowDashesWideSpace') {
    return `${weight * 2} ${weight * 6}`
  } else if (composition === 'dotsNarrowSpace') {
    return `1 ${weight * 1.5}`
  } else if (composition === 'dotsMediumSpace' || composition === 'dots') {
    return `1 ${weight * 2}`
  } else if (composition === 'dotsWideSpace') {
    return `1 ${weight * 3}`
  }

  throw new Error('MAP-5: Unknown PathComposition')
}

export const getConfigForType = (
  config: MapUIConfig,
  type: string,
): PointFeature | PolygonFeature | PolylineFeature => {
  const fCfg = config.featureConfig[type]
  if (!fCfg) throw new Error('MAP-1: No config available for the given feature')
  return fCfg
}

export const getConfigForFeature = (config: MapUIConfig, feature?: Feature<any, any>) => {
  // Get feature meta config from config
  return getConfigForType(config, feature?.properties?.type)
}
