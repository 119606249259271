import classNames from 'classnames'
import React from 'react'

export interface CardProps {
  hasTabbedContent?: boolean
  verticalSpacing?: 'default' | 'small'
  isFullWidth?: boolean
  cardStyle?: 'default' | 'grey'
  className?: string
}

export function Card({
  children,
  hasTabbedContent,
  verticalSpacing = 'small',
  isFullWidth,
  cardStyle = 'default',
  className = '',
}: React.PropsWithChildren<CardProps>) {
  const cardClasses = classNames('c-card', {
    [`c-card--vert-spacing-${verticalSpacing}`]: verticalSpacing !== 'default',
    'c-card--full-width': isFullWidth,
    [`${className}`]: className != null && className !== '',
    [`c-card--${cardStyle}`]: cardStyle !== 'default',
  })

  return (
    <div className={cardClasses}>
      {/* Card content container required for padding */}
      {!hasTabbedContent && <div className='c-card__content'>{children}</div>}
      {/* If card contains tabbed content, the tabs need to run to the edge of the card and handle content padding internally */}
      {hasTabbedContent && children}
    </div>
  )
}
