import React from 'react'
import { IconType } from '../../../../types/icon-types'
import { Heading, Icon } from '../../../common'

export interface FeatureItemProps {
  icon: IconType
  heading?: string
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6
  bodyText: string | JSX.Element
}

export function FeatureItem({ icon, heading, headingLevel = 3, bodyText }: FeatureItemProps) {
  return (
    <div className='c-feature-item'>
      <div className='c-feature-item__icon-container'>
        <Icon className='c-feature-item__icon' icon={icon} ariaHidden />
      </div>
      <div className='c-feature-item__body'>
        {heading && (
          <Heading className='c-feature-item__heading' level={headingLevel}>
            {heading}
          </Heading>
        )}
        <div className='c-feature-item__body-text'>{bodyText}</div>
      </div>
    </div>
  )
}
