import React, { ErrorInfo, PropsWithChildren } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import handleAPIError from '../../handler'
import FallbackComponent from '../fallback-component'

// eslint-disable-next-line @typescript-eslint/ban-types
export default function AppErrorBoundary({ children }: PropsWithChildren<{}>) {
  return (
    <ErrorBoundary
      FallbackComponent={FallbackComponent}
      onReset={() => window.location.reload()}
      // TODO pass in user here to report error to raygun?
      onError={(error: Error, info: ErrorInfo) => handleAPIError(error, info)}
    >
      {children}
    </ErrorBoundary>
  )
}
