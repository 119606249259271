import React from 'react'
import { TabNav, TabItemProps } from '../../tab-nav'

interface AppHeaderTabsProps {
  tabs?: Array<TabItemProps>
}

export function AppHeaderTabs({ tabs }: AppHeaderTabsProps) {
  return (
    <>
      {/* TODO: output TabNav component here for navigation within section */}
      {tabs && (
        <div className='c-page-header__tabs'>
          {/** Setting `exact` to false so that the tab link is set as `active` when its descendant paths are matched */}
          <TabNav menuName='Record sections' tabs={tabs} exact={false} />
        </div>
      )}
    </>
  )
}
