import classNames from 'classnames'
import React from 'react'
import { Icon } from '../icon'
import { SkeletonPill } from './skeleton-pill'

export interface PillProps {
  label: string
  value?: string
  isRightAligned?: boolean
  isSelectable?: boolean
  isSelected?: boolean
  handleClick?: () => void
  isSkeleton?: boolean
}

export function Pill({
  label,
  value,
  isRightAligned,
  isSelectable = true,
  isSelected,
  isSkeleton,
  handleClick,
}: React.PropsWithChildren<PillProps>) {
  if (isSkeleton) {
    return <SkeletonPill isRightAligned={isRightAligned} />
  }

  const pillClasses = classNames('c-pill', {
    'c-pill--right-aligned': isRightAligned,
    'c-pill--selectable': isSelectable,
    'c-pill--selected': isSelected,
  })

  const pillContents = (
    <>
      <span className='c-pill__label'>
        {label}
        {!!value && ':'}
      </span>
      {value && <span className='c-pill__value'>&nbsp;{value}</span>}
      {isSelected && isSelectable && <Icon className='c-pill__icon' icon='close' ariaLabel='Deselect' />}
    </>
  )

  const Element = isSelectable ? 'button' : 'div'

  return (
    <Element className={pillClasses} onClick={isSelectable ? handleClick : undefined}>
      {pillContents}
    </Element>
  )
}
