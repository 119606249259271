/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable security/detect-object-injection */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { getRandomID } from '@msaf/core-common'
import { useState, useEffect } from 'react'
import {
  QueryObserverResult,
  RefetchOptions,
  RefetchQueryFilters,
  UseMutationResult,
  UseQueryResult,
} from 'react-query'
import { FieldTypes, FormFieldsEdit, FormFieldArrayTypesEdit } from '../types/form-types'

export interface UseEditFormProps {
  stateQueryResult?: UseQueryResult<any, Error>
  saveMutationResult?: UseMutationResult<any, unknown, string, unknown>
  setIsDirty: (isDirty: boolean) => void
  backUrl?: string
}

function isValueChanged<T>(oldVal: T[keyof T] | undefined, newVal: T[keyof T] | undefined): boolean {
  // Values are some combination of null and undefined, which we're treating as equivalent
  if (oldVal == null && newVal == null) return false

  return String(oldVal) !== String(newVal)
}

export type ValidationErrorsArray<T = FormFieldsEdit> = {
  [Property in keyof T]?: Array<{ [Property in keyof T]?: Array<string> }>
}

export type ValidationErrors<T = FormFieldsEdit> = {
  [Property in keyof T]?: Array<string>
}

type QueryRefetchFunction =
  | (<TPageData>(
      options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
    ) => Promise<QueryObserverResult<any, Error>>)
  | undefined

export type AddField<T> = (
  fieldId: keyof T,
  initFields: { [key: string]: string },
  prepend?: boolean | undefined,
) => void
export type EditField<T> = (
  fieldId: keyof T,
  initFields: { [key: string]: string },
  index: number,
  update?: boolean,
) => void
export type EditFieldById<T> = (
  fieldId: keyof T,
  initFields: { [key: string]: string },
  id: string,
  update?: boolean,
) => void
export type RemoveField<T> = (fieldId: keyof T, index: number) => void
export type RemoveFieldById<T> = (fieldId: keyof T, id: string) => void
export type SetValue<T> = (
  fieldId: keyof T,
  value: FieldTypes | Record<string, unknown>,
  key?: string,
  index?: number,
) => void
export type HandleSave = (callback?: (id: number | string, refetch?: QueryRefetchFunction) => void) => void
export type ResetUseEditFormState = () => void

export default function useEditForm<T = FormFieldsEdit, A = FormFieldArrayTypesEdit>({
  stateQueryResult,
  saveMutationResult,
  setIsDirty,
}: UseEditFormProps) {
  const [fields, setFields] = useState<Partial<T>>({})
  const [validationErrors, setValidationErrors] = useState<ValidationErrors<T>>({})
  const {
    isLoading,
    isError,
    isSuccess,
    data,
    error: queryError,
    refetch,
  } = stateQueryResult || { isLoading: false, isError: false }

  const {
    mutate,
    isError: isSaveError,
    isLoading: isSaving,
    isSuccess: isSaved,
    error: saveError,
    reset: resetMutationState,
  } = saveMutationResult || { isLoading: false, isError: false }

  useEffect(() => {
    if (isSuccess && data?.state) {
      setFields(data?.state)
      setValidationErrors(data?.errors ?? {})
    }
  }, [isSuccess, data?.state, data?.errors])

  useEffect(() => {
    if (isSaveError && saveError) {
      setValidationErrors(saveError as ValidationErrors<T>)
    }
  }, [isSaveError, saveError])

  const setValue: SetValue<T> = (fieldId, value, key?, index?) => {
    // Stop empty strings being passed to the server for empty number fields
    let newValue: any = typeof value === 'string' && value.trim() === '' ? null : value
    let isUpdated = false

    if (key && index !== undefined && fields[fieldId] && Array.isArray(fields[fieldId])) {
      // Set value inside an array
      // Clone the form field's state
      const fieldState = [...(fields[fieldId] as unknown as Array<A>)]

      if (!fieldState.length) {
        // Note: In theory, code should not drop in here as adding a new empty repeatable element should create the
        // array item
        // @ts-ignore
        fieldState.push({ [key]: newValue, id: getRandomID() })
        isUpdated = true
        // @ts-ignore
      } else if (isValueChanged<T>(fields[fieldId][index][key], newValue)) {
        // @ts-ignore
        fieldState[index][key] = newValue
        isUpdated = true
      }

      // Set the value in form state
      newValue = fieldState
    } else if (key && fields[fieldId] && typeof fields[fieldId] === 'object') {
      // @ts-ignore
      if (isValueChanged<T>(fields[fieldId][key], newValue)) {
        newValue = { ...fields[fieldId], [key]: newValue }
        isUpdated = true
      }
    } else {
      //  Normal not-in-an-object-or-array root key/value update
      isUpdated = isValueChanged<T>(fields[fieldId], newValue)
    }

    if (isUpdated) {
      setFields((prevValues) => ({
        ...prevValues,
        [fieldId]: newValue,
      }))
      setIsDirty(true)
    }
  }
  const handleSave: HandleSave = (callback?) => {
    // Mutation side effects: https://react-query.tanstack.com/guides/mutations#mutation-side-effects
    mutate &&
      mutate(JSON.stringify(fields), {
        onSuccess: (data) => {
          setIsDirty && setIsDirty(false)
          callback && callback(data.id, refetch)
        },
      })
  }

  const addField: AddField<T> = (fieldId, initFields, prepend?) => {
    setFields((prevValues) => {
      if (!prevValues[fieldId] || !Array.isArray(prevValues[fieldId])) {
        return prevValues
      }
      // Quick and dirty way of deep cloning form fields
      const formState = JSON.parse(JSON.stringify(prevValues))
      const fieldArray: Array<{ [key: string]: string }> = [...(formState[fieldId] as Array<{ [key: string]: string }>)]
      if (prepend) {
        fieldArray.unshift(initFields)
      } else {
        fieldArray.push({ id: `${getRandomID()}`, ...initFields })
      }

      return { ...prevValues, [fieldId]: fieldArray }
    })
  }

  const modifyFieldByIndexCallback = (
    fieldId: keyof T,
    indexCallback: (fieldArray?: Array<{ [key: string]: string }>) => number,
    initFields?: { [key: string]: string },
    update = true,
  ) => {
    setFields((prevValues) => {
      if (!prevValues[fieldId] || !Array.isArray(prevValues[fieldId])) {
        return prevValues
      }
      // Clone fields
      const formState = JSON.parse(JSON.stringify(prevValues))
      const fieldArray: Array<{ [key: string]: string }> = [...(formState[fieldId] as [])]
      const index: number = indexCallback(fieldArray)
      if (initFields) {
        // edit note
        if (update) {
          fieldArray[index] = { ...fieldArray[index], ...initFields }
        } else {
          fieldArray[index] = initFields
        }
      } else {
        // remove note
        fieldArray.splice(index, 1)
      }
      return { ...formState, [fieldId]: fieldArray }
    })
  }

  const modifyFieldById = (fieldId: keyof T, id: string, initFields?: { [key: string]: string }, update = true) => {
    modifyFieldByIndexCallback(
      fieldId,
      (fieldArray) => {
        const index = fieldArray?.findIndex((a) => a.id === id)
        if (index === undefined) {
          throw new Error(`No field array entry with id ${id}`)
        }
        return index
      },
      initFields,
      update,
    )
  }

  const modifyField = (fieldId: keyof T, index: number, initFields?: { [key: string]: string }, update = true) => {
    modifyFieldByIndexCallback(fieldId, () => index, initFields, update)
  }

  const removeField: RemoveField<T> = (fieldId, index) => {
    modifyField(fieldId, index)
  }

  // used to replace whole object inside of array
  const editField: EditField<T> = (fieldId, initFields, index, update = true) => {
    modifyField(fieldId, index, initFields, update)
  }

  const editFieldById: EditFieldById<T> = (fieldId, initFields, id, update = true) => {
    modifyFieldById(fieldId, id, initFields, update)
  }

  const removeFieldById: RemoveFieldById<T> = (fieldId, id) => {
    modifyFieldById(fieldId, id)
  }

  const resetUseEditFormState = () => {
    resetMutationState && resetMutationState()
    setFields({})
    setValidationErrors({})
  }

  return {
    fields,
    error: queryError || saveError,
    queryError,
    saveError,
    isLoading: isLoading || isSaving,
    isError: isError,
    isSaveError: isSaveError,
    isSuccess: isSuccess,
    isSaved: isSaved,
    refetch,
    setValue,
    addField,
    editField,
    editFieldById,
    removeField,
    removeFieldById,
    handleSave,
    resetUseEditFormState,
    validationErrors,
  }
}
