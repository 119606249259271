import React from 'react'
import { ButtonsContainer } from '../../layout'
import { ButtonWithIcon, ButtonWithoutIcon, ButtonOnlyIcon, Button } from '../button'

export interface ImageWithActionsProps {
  /**
   * Pass in <img /> element or custom component (e.g. embedded map)
   */
  imageElement: React.ReactNode
  actions: Array<ButtonWithIcon | ButtonWithoutIcon | ButtonOnlyIcon>
  isSkeleton?: boolean
}

export function ImageWithActions({ imageElement, actions, isSkeleton }: ImageWithActionsProps) {
  return (
    <div className='c-image-with-actions'>
      {/* TODO: test with map element if/when required. Styles may need adjusting */}
      <div className='c-image-with-actions__image'>{imageElement}</div>
      <div className='c-image-with-actions__actions'>
        <ButtonsContainer containerStyle='left'>
          {actions.map((actionProps) => (
            <Button
              key={actionProps.label}
              {...actionProps}
              iconAlignment='left'
              buttonStyle='tertiary'
              size='small'
              isSkeleton={isSkeleton}
            />
          ))}
        </ButtonsContainer>
      </div>
    </div>
  )
}
