import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'
import TetherComponent from 'react-tether'

export interface MenuTipProps {
  label: string
  // Used to disable if nav panel is not collapsed/text labels already visible
  isEnabled?: boolean
}

export function MenuTip({ label, isEnabled }: MenuTipProps) {
  const [isMenuTipOpen, setIsMenuTipOpen] = useState<boolean>(false)

  const showMenuTip = () => {
    if (isEnabled) setIsMenuTipOpen(true)
  }

  const hideMenuTip = () => {
    if (isEnabled) setIsMenuTipOpen(false)
  }

  return (
    <TetherComponent
      attachment='middle left'
      renderTarget={(ref: any) => (
        <span ref={ref} onMouseEnter={showMenuTip} onMouseLeave={hideMenuTip} className='c-tip-trigger' />
      )}
      renderElement={(ref: any) => (
        <AnimatePresence>
          {isMenuTipOpen && (
            <motion.span
              ref={ref}
              key={label}
              className='c-menu-tip'
              initial={{ opacity: 0, x: 0 }}
              animate={{ opacity: 1, x: 10 }}
              exit={{ opacity: 0, x: 0 }}
            >
              {label}
            </motion.span>
          )}
        </AnimatePresence>
      )}
    />
  )
}
