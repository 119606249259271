import { Context, useContext } from 'react'
import { AuthContext } from './types'

export const createUseAuth =
  <T,>(AuthContext: Context<AuthContext<T> | null>) =>
  (): AuthContext<T> => {
    const context = useContext<AuthContext<T> | null>(AuthContext)
    if (!context) throw new Error('useAuth call outside of an AuthProvider')
    return context
  }

export const createUseUser =
  <T,>(useAuth: () => AuthContext<T>) =>
  (): [T, false] | [undefined, true] => {
    const { user, isLoading } = useAuth()
    if (isLoading) return [undefined, isLoading]
    if (!user) throw new Error('Not authenticated. Are you using useUser outside of a protected router?')
    return [user, isLoading]
  }
