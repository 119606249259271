import * as L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import classNames from 'classnames'
import React, { ForwardedRef, forwardRef, useEffect, useRef, useState } from 'react'
import { MapCreationOptions, createMapAndBaseLayers } from '@msaf/maps-common'

export interface LeafletMapProps {
  options: MapCreationOptions
  setMap?: (map?: L.Map) => void
  addInitialLayersAndMarkers?: (map: L.Map) => void
  isInlineMap?: boolean
}

const useForwardedRef = function <T>(ref: ForwardedRef<T>) {
  const innerRef = useRef<T>(null!)
  useEffect(() => {
    if (!ref) {
      return
    }

    if (typeof ref === 'function') {
      ref(innerRef.current)
    } else {
      ref.current = innerRef.current
    }
  }, [ref])

  return innerRef
}

export const LeafletMap = forwardRef<HTMLDivElement, LeafletMapProps>(function (
  { options, setMap, addInitialLayersAndMarkers, isInlineMap = false },
  ref,
) {
  const safeRef = useForwardedRef<HTMLDivElement>(ref)
  const [map, setInternalMap] = useState<L.Map | undefined>()
  const isRenderedRef = useRef<boolean>(false)

  useEffect(() => {
    const element = safeRef.current
    /**
     * Checks if map has already been instantiated
     *
     * React 18 in strict mode simulates an extra unmount + mount cycle in every component's first mount,
     * which results in the rendering effect to run twice with the same conditions, causing a double call to `L.map`
     * which leads to an exception in the leaflet library.
     *
     * Solution taken from the `react-leaflet`fix for the same issue.
     * Visit the following link for further explanations:
     * https://github.com/PaulLeCam/react-leaflet/pull/964/commits/7285837d45eafc26820c4968bc1a593e4e9f3993
     */
    if (element && !isRenderedRef.current) {
      // Initialize map
      const map = createMapAndBaseLayers(element, options)

      // Add initial layers and markers
      addInitialLayersAndMarkers && addInitialLayersAndMarkers(map)

      // Set map for cleanup
      setMap?.(map)
      setInternalMap(map)
      isRenderedRef.current = true
    }

    return () => {
      // On unmount, clean up the map
      if (map) {
        // Clean up in parent component
        setMap?.()

        // Kill leaflet object
        map.remove()

        // Clean up internal state
        setInternalMap(undefined)
      }
    }
    // Disabling linting on exhaustive deps, as this hook creates some of the dependencies listed,
    // and we only want it to run on mount/unmount, not on each time the map control updates
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [safeRef.current])

  const mapClasses = classNames('c-leaflet-map', {
    'c-leaflet-map--inline': isInlineMap,
  })

  return (
    <div className={mapClasses}>
      <div className='c-leaflet-map__map-element' ref={(r) => (safeRef.current = r!)} />
    </div>
  )
})
