import classNames from 'classnames'
import React from 'react'

export interface FormFieldRangeProps {
  formFields: Array<React.ReactNode>
  verticalSpacing?: 'none' | 'small' | 'x-small'
  separator?: string
}

export function FormFieldRange({ formFields, verticalSpacing = 'none', separator = '-' }: FormFieldRangeProps) {
  const classes = classNames('l-form-field-range', {
    [`l-form-field-range--vert-spacing-${verticalSpacing}`]: verticalSpacing !== 'none',
  })

  return (
    <div className={classes}>
      {formFields.map((formField, index) => (
        <>
          {index > 0 && separator} {formField}
        </>
      ))}
    </div>
  )
}
