import React from 'react'
import { IconType } from '../../../../types/icon-types'
import { Icon } from '../../../common/icon'
import { Link, LinkProps } from '../../link'

export interface MenuLinkContentsProps {
  label: string
  icon?: IconType
}

export interface MenuActionProps extends MenuLinkContentsProps {
  action: VoidFunction
  path?: string
  isExternal?: boolean
  exact?: boolean
}

export interface MenuLinkProps extends LinkProps {
  label: string
  icon?: IconType
  action?: undefined | VoidFunction
}

function ItemContents({ icon, label }: MenuLinkContentsProps) {
  return (
    <>
      {icon && <Icon className='c-dropdown-menu__link-icon' icon={icon} ariaHidden={true} />}
      <span className='c-dropdown-menu__link-label'>{label}</span>
    </>
  )
}

export function MenuItem({ label, action, path, icon, ...props }: MenuActionProps | MenuLinkProps) {
  return (
    <>
      {action && (
        <button className='c-dropdown-menu__link' onClick={action}>
          <ItemContents icon={icon} label={label} />
        </button>
      )}
      {path && (
        <Link className='c-dropdown-menu__link' path={path} {...props}>
          <ItemContents icon={icon} label={label} />
        </Link>
      )}
    </>
  )
}
