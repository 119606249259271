import { AppShell, Button, ButtonsContainer, NavPanel } from '@msaf/core-react'
import { useCallback, useEffect, useState } from 'react'
import { APP_LOGO, BASE_BRANDING_LOGO, NAV_PANEL_LINKS, UNAUTHENTICATED_MAIN_MENU_LINKS } from '../constants'
import classNames from 'classnames'
import { useAuth } from '../auth'
import { Outlet } from 'react-router-dom'
import { useLocation, useNavigate } from '@msaf/router-react'
import { addAttributesToOpenHelpInNewTab } from '../services/utils'
import { ErrorCode } from './errors/constants'

const UnauthenticatedUserComponent = () => {
  const { login } = useAuth()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const loginWithRedirect = useCallback(() => login({ redirectPath: '/dashboard' }), [login, pathname])
  return (
    <ButtonsContainer containerStyle='right'>
      <Button buttonStyle='secondary' label='Sign in' onClick={loginWithRedirect} />
      <Button label='Register' onClick={() => navigate('/?register=true')} />
    </ButtonsContainer>
  )
}

export default function Page() {
  const [isMenuHidden, setIsMenuHidden] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { logout, user, changePassword, isAuthenticated, error: authError } = useAuth()

  const getUnauthenticatedUserComponent = () => {
    // Remove the Register and sign-in buttons if logged in or keycloak initialisation timed out
    const keycloakFailed = authError === ErrorCode.KEYCLOAK_ERROR
    if (isAuthenticated || keycloakFailed) {
      return <></>
    }
    return <UnauthenticatedUserComponent />
  }
  const appUser = user
    ? {
        initials: `${user.firstName[0]}${user.lastName[0]}`,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        hasValidToken: true,
      }
    : undefined

  const userMenuLinks = [
    {
      path: '',
      label: 'Change password',
      icon: 'settings',
      action: () => changePassword(),
    },
    {
      label: 'Logout',
      icon: 'exit',
      action: () => logout({ redirectPath: '/' }),
    },
  ]

  useEffect(() => {
    addAttributesToOpenHelpInNewTab('.c-main-menu__link')
  })

  return (
    // Note, we need the l-page--menu-open class in order for the NavPanel component to work correctly.
    // TODO: Extract shell of `Page` into a `BasePage` msaf component that does nothing other than forward
    // on the NavPanel (and maybe AppShell) props.
    <div className={classNames('l-page', { 'l-page--menu-open': isMenuOpen })}>
      <NavPanel
        appLogo={APP_LOGO}
        brandingLogos={[BASE_BRANDING_LOGO]}
        mainMenuLinks={isAuthenticated ? NAV_PANEL_LINKS : UNAUTHENTICATED_MAIN_MENU_LINKS}
        isMenuHidden={isMenuHidden}
        closeMenu={() => setIsMenuOpen(false)}
      />
      <AppShell
        hideMenu={(isHidden: boolean) => setIsMenuHidden(isHidden)}
        openMenu={() => setIsMenuOpen(true)}
        userMenuLinks={userMenuLinks}
        unauthenticatedUserComponent={getUnauthenticatedUserComponent()}
        isNotificationsMenu={false}
        isDownloadsMenu={false}
        isSearchMenu={false}
        user={appUser}
      >
        <Outlet />
      </AppShell>
    </div>
  )
}
