import {
  Button,
  ButtonsContainer,
  Card,
  ConfirmTransition,
  Divider,
  FieldTypes,
  FormField,
  FormFieldRow,
  Heading,
  InlineNotification,
  InputWidth,
  RecordFooter,
  RepeatableField,
  TextInput,
  Typeahead,
  TypeaheadOption,
} from '@msaf/core-react'

import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ConfirmDeletionModal } from '../../../../../../components/confirm-submit-form-modal'
import { ContentContainer } from '../../../../../../components/content-container'
import FormEditButtons, { ActionProps } from '../../../../../../components/form-edit-buttons'
import { LoadingPage } from '../../../../../../components/loading-page'
import LocationFieldEdit from '../../../../../../components/location-map'
import { LANDHOLDING_YEAR_OPTIONS, REGIONAL_COUNCIL_OPTIONS, TOOLTIP_MESSAGE } from '../../../../../../constants'
import { useDeleteData, useGetFarmData, useSaveFarmData } from '../../../../../../hooks/useApi'
import { useBackLink } from '../../../../../../hooks/useBacklink'
import useEditForm from '../../../../../../hooks/useEditForm'
import { usePageHeading } from '../../../../../../hooks/usePageHeading'
import useToastMessage from '../../../../../../hooks/useToastMessage'
import { useUserPermission } from '../../../../../../services/permissions'
import { getToastErrorMessage } from '../../../../../../services/utils'
import { HttpVerb } from '../../../../../../types/api-types'
import { FormFieldArrayTypesEdit, FormFieldsEdit } from '../../../../../../types/form-types'
import { UserPermissions } from '../../../../../../types/permissions'
import { RouteParams } from '../../../../../../types/route'

type LandHoldingRouteParams = RouteParams & { contiguousLandHoldingId: string }

export const LandHolding = () => {
  const { contiguousLandHoldingId, id } = useParams<LandHoldingRouteParams>()
  const [canViewFarmOperations, isLoadingPermissions] = useUserPermission(UserPermissions.MANAGE_FARM)
  const [isDirty, setIsDirty] = useState<boolean>(false)

  const isNewRecord = contiguousLandHoldingId === 'new'

  const url = `${id}/land-holding/${contiguousLandHoldingId}`
  const stateQueryResult = useGetFarmData(url)
  const saveMutationResult = useSaveFarmData(url, isNewRecord ? HttpVerb.POST : HttpVerb.PUT)
  const {
    mutate: deleteData,
    isSuccess: isDeleteSuccess,
    isError: isDeleteError,
  } = useDeleteData(`/${id}/land-holding/${contiguousLandHoldingId}`)

  const navigate = useNavigate()
  useBackLink({ label: 'Dashboard', path: '/dashboard' })

  const [selectedCouncilOptions, setSelectedCouncilOptions] = useState<Array<TypeaheadOption | undefined>>([])
  const [selectedReportingYearOption, setSelectedReportingYear] = useState<TypeaheadOption>()
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState<boolean>(false)

  const {
    fields,
    isLoading,
    isError,
    isSaveError,
    isSaved,
    validationErrors,
    setValue,
    handleSave,
    addField,
    removeField,
  } = useEditForm<FormFieldsEdit & FormFieldArrayTypesEdit>({
    stateQueryResult,
    saveMutationResult,
    setIsDirty,
  })

  usePageHeading(id === 'new' ? 'Create land holding' : fields?.landName ?? 'View land holding')

  useToastMessage({
    isError: isError || isSaveError || isDeleteError,
    isSuccess: isSaved || isDeleteSuccess,
    errorMessage: getToastErrorMessage(validationErrors, isSaveError),
  })

  useEffect(() => {
    if (Array.isArray(fields.areaRegionalCouncil) && fields.areaRegionalCouncil.length) {
      const options = fields.areaRegionalCouncil.map((field) =>
        REGIONAL_COUNCIL_OPTIONS.find((item) => item.value === field.council),
      )

      setSelectedCouncilOptions(options)
    }
  }, [fields.areaRegionalCouncil])

  useEffect(() => {
    if (fields.reportingYear) {
      setSelectedReportingYear({ value: String(fields.reportingYear), label: String(fields.reportingYear) })
    }
  }, [fields?.reportingYear])

  const handleChange = (option: TypeaheadOption | null, index: number) => {
    // Need to check existence of both label and value otherwise selected option being set as {} - empty object
    if (option?.label && option.value) {
      const newSelection = [...selectedCouncilOptions]
      // eslint-disable-next-line security/detect-object-injection
      newSelection[index] = option
      setSelectedCouncilOptions(newSelection)
    } else {
      setSelectedCouncilOptions([{ label: '', value: '' }])
    }
    setValue('areaRegionalCouncil', option?.value ?? '', 'council', index)
  }

  const formEditActions: Array<ActionProps> = [
    {
      label: 'Save',
      onClick: () => handleSave(() => navigate(`/farm/edit/${id}/land-holding/`)),
    },
    {
      label: 'Cancel',
      onClick: () => navigate(`/farm/edit/${id}/land-holding`),
      buttonStyle: 'text-action',
    },
  ]

  if (!fields.easting || !fields.northing) {
    Object.assign(formEditActions[0], { isDisabled: true })
  }

  if (!isNewRecord) {
    formEditActions.push({
      label: 'Delete',
      onClick: () => setIsDeleteConfirmationModalOpen(true),
      buttonStyle: 'danger',
    })
  }

  const handleDelete = async () => {
    deleteData()
  }

  useEffect(() => {
    // Ensure we wait until the delete has succeeded before
    // navigating to the list view, otherwise that can load
    // the items that are being deleted
    if (isDeleteSuccess) {
      setIsDeleteConfirmationModalOpen(false)
      navigate(`/farm/edit/${id}/fertiliser-applications`)
    }
    if (isDeleteError) {
      setIsDeleteConfirmationModalOpen(false)
    }
  }, [isDeleteSuccess, isDeleteError])

  if (isLoading || isLoadingPermissions) return <LoadingPage />
  return canViewFarmOperations ? (
    <>
      <ConfirmTransition
        when={isDirty}
        contentLabel='Unsaved changes confirmation dialog'
        actionConfig={[
          {
            key: 'continue',
            type: 'cancel',
            buttonStyle: 'primary',
            label: 'Continue editing',
          },
          {
            key: 'discard',
            type: 'confirm',
            buttonStyle: 'text-action',
            label: 'Discard changes',
          },
        ]}
      />
      <ContentContainer>
        <ButtonsContainer containerStyle='left'>
          <Button
            onClick={() => navigate(`/farm/edit/${id}/land-holding`)}
            label='Back'
            iconAlignment='left'
            icon='arrow-left'
            buttonStyle='text-action'
          />
        </ButtonsContainer>
        <Heading level={2}>Land details</Heading>
        <FormField
          isRequired
          isSkeleton={isLoading}
          label='Land name'
          htmlFor='landName'
          validationMessages={validationErrors?.landName}
        >
          <TextInput
            isSkeleton={isLoading}
            id='landName'
            value={`${fields?.landName ?? ''}`}
            onBlur={(e) => setValue('landName', e.target.value)}
            isInvalid={!!validationErrors?.landName}
          />
        </FormField>

        <FormField
          isRequired
          isSkeleton={isLoading}
          label='Reporting year - 1 July – 30 June (select the year as at 30 June)'
          labelId='reportingYear'
          validationMessages={validationErrors?.reportingYear}
        >
          <Typeahead
            isSkeleton={isLoading}
            labelledBy='reportingYear'
            options={LANDHOLDING_YEAR_OPTIONS}
            selectedOption={selectedReportingYearOption}
            handleChange={(option) => {
              setSelectedReportingYear(option)
              setValue('reportingYear', option.value ?? '')
            }}
            isInvalid={!!validationErrors?.reportingYear}
          />
        </FormField>

        <FormField
          isSkeleton={isLoading}
          label='Total area (ha)'
          htmlFor='areaTotal'
          validationMessages={validationErrors?.areaTotal}
          isRequired
        >
          <TextInput
            width={InputWidth.small}
            type='number'
            isSkeleton={isLoading}
            id='areaTotal'
            value={`${fields?.areaTotal ?? ''}`}
            onBlur={(e) => setValue('areaTotal', e.target.value)}
            isInvalid={!!validationErrors?.areaTotal}
          />
        </FormField>

        <RepeatableField
          label='Reporting regional council(s)'
          labelId='areaRegionalCouncil'
          isSkeleton={isLoading}
          isRequired
          removeField={(index: number) => removeField('areaRegionalCouncil', index)}
          addField={() => addField('areaRegionalCouncil', {})}
          toolTipMessage={TOOLTIP_MESSAGE.areaRegionalCouncil}
          components={fields?.areaRegionalCouncil?.map((_, index) => (
            <div key={index}>
              <Typeahead
                isSkeleton={isLoading}
                labelledBy='areaRegionalCouncil'
                options={REGIONAL_COUNCIL_OPTIONS}
                // eslint-disable-next-line security/detect-object-injection
                selectedOption={selectedCouncilOptions[index]}
                handleChange={(val) => handleChange(val, index)}
                // eslint-disable-next-line security/detect-object-injection
                isInvalid={!!validationErrors?.areaRegionalCouncil?.[index]}
              />
              {validationErrors?.areaRegionalCouncil?.map((errorMessage, idx) => (
                <div key={`${idx}--validation-msg`} className='c-form-field__validation-msg' role='alert'>
                  {errorMessage}
                </div>
              ))}
            </div>
          ))}
        />

        <Divider />
        <Heading isSkeleton={isLoading} level={2}>
          Land categories
        </Heading>

        <InlineNotification messageType='info'>
          If you do not have the details from your spreading company of kg/Ha of fertiliser applied you can download the
          N-Cap{' '}
          <a href='/help-centre#spreadsheet' target='_blank' rel='noreferrer noopener'>
            spreadsheet
          </a>{' '}
          to calculate this yourself. Once calculated you can enter the data in this section
        </InlineNotification>

        <Card>
          <Heading isSkeleton={isLoading} level={3}>
            Grazed land
          </Heading>
          <FormFieldRow>
            <FormField
              isSkeleton={isLoading}
              label='Area (ha)'
              htmlFor='areaGrazedLand'
              validationMessages={validationErrors?.areaGrazedLand}
              toolTipMessage={TOOLTIP_MESSAGE.areaGrazedLand}
              isRequired
            >
              <TextInput
                type='number'
                isSkeleton={isLoading}
                id='areaGrazedLand'
                value={`${fields?.areaGrazedLand ?? ''}`}
                onBlur={(e) => setValue('areaGrazedLand', e.target.value)}
                isInvalid={!!validationErrors?.areaGrazedLand}
              />
            </FormField>

            <FormField
              isSkeleton={isLoading}
              label='Fertiliser applied (N KG/Ha/y)'
              htmlFor='appliedGrazedLand'
              validationMessages={validationErrors?.appliedGrazedLand}
              toolTipMessage={TOOLTIP_MESSAGE.appliedGrazedLand}
              isRequired
            >
              <TextInput
                min={0}
                type='number'
                isSkeleton={isLoading}
                id='appliedGrazedLand'
                value={`${fields?.appliedGrazedLand ?? ''}`}
                onBlur={(e) => setValue('appliedGrazedLand', e.target.value)}
                isInvalid={!!validationErrors?.appliedGrazedLand}
              />
            </FormField>
          </FormFieldRow>
        </Card>

        <Card>
          <Heading isSkeleton={isLoading} level={3}>
            Grazed forage crops
          </Heading>
          <FormFieldRow>
            <FormField
              isRequired
              isSkeleton={isLoading}
              label='Area (ha)'
              htmlFor='areaGrazedForageCrops'
              validationMessages={validationErrors?.areaGrazedForageCrops}
              toolTipMessage={TOOLTIP_MESSAGE.areaGrazedForageCrops}
            >
              <TextInput
                type='number'
                isSkeleton={isLoading}
                id='areaGrazedForageCrops'
                value={`${fields?.areaGrazedForageCrops ?? ''}`}
                onBlur={(e) => setValue('areaGrazedForageCrops', e.target.value)}
                isInvalid={!!validationErrors?.areaGrazedForageCrops}
              />
            </FormField>

            <FormField
              isSkeleton={isLoading}
              label='Fertiliser applied (N KG/Ha/y)'
              htmlFor='appliedGrazedForageCrops'
              validationMessages={validationErrors?.appliedGrazedForageCrops}
              toolTipMessage={TOOLTIP_MESSAGE.appliedGrazedForageCrops}
              isRequired
            >
              <TextInput
                min={0}
                type='number'
                isSkeleton={isLoading}
                id='appliedGrazedForageCrops'
                value={`${fields?.appliedGrazedForageCrops ?? ''}`}
                onBlur={(e) => setValue('appliedGrazedForageCrops', e.target.value)}
                isInvalid={!!validationErrors?.appliedGrazedForageCrops}
              />
            </FormField>
          </FormFieldRow>
        </Card>

        <Card>
          <Heading isSkeleton={isLoading} level={3}>
            Grazed non-forage land
          </Heading>
          <em className='is-subheading'>This includes both grazed harvest crops and grazed pasture</em>

          <FormFieldRow>
            <FormField
              isRequired
              isSkeleton={isLoading}
              label='Area (ha)'
              htmlFor='areaGrazedNonForageCrops'
              validationMessages={validationErrors?.areaGrazedNonForageCrops}
              toolTipMessage={TOOLTIP_MESSAGE.areaGrazedNonForageCrops}
            >
              <TextInput
                type='number'
                isSkeleton={isLoading}
                id='areaGrazedNonForageCrops'
                value={`${fields?.areaGrazedNonForageCrops ?? ''}`}
                onBlur={(e) => setValue('areaGrazedNonForageCrops', e.target.value)}
                isInvalid={!!validationErrors?.areaGrazedNonForageCrops}
              />
            </FormField>

            <FormField
              isSkeleton={isLoading}
              label='Fertiliser applied (N KG/Ha/y)'
              htmlFor='appliedGrazedNonForageCrops'
              validationMessages={validationErrors?.appliedGrazedNonForageCrops}
              toolTipMessage={TOOLTIP_MESSAGE.appliedGrazedNonForageCrops}
              isRequired
            >
              <TextInput
                min={0}
                type='number'
                isSkeleton={isLoading}
                id='appliedGrazedNonForageCrops'
                value={`${fields?.appliedGrazedNonForageCrops ?? ''}`}
                onBlur={(e) => setValue('appliedGrazedNonForageCrops', e.target.value)}
                isInvalid={!!validationErrors?.appliedGrazedNonForageCrops}
              />
            </FormField>
          </FormFieldRow>
        </Card>

        <Card>
          <Heading isSkeleton={isLoading} level={3}>
            Ungrazed land
          </Heading>
          <FormFieldRow>
            <FormField
              isSkeleton={isLoading}
              label='Area (ha)'
              htmlFor='areaUnGrazedLand'
              validationMessages={validationErrors?.areaUnGrazedLand}
              toolTipMessage={TOOLTIP_MESSAGE.areaUnGrazedLand}
              isRequired
            >
              <TextInput
                type='number'
                isSkeleton={isLoading}
                id='areaUnGrazedLand'
                value={`${fields?.areaUnGrazedLand ?? ''}`}
                onBlur={(e) => setValue('areaUnGrazedLand', e.target.value)}
                isInvalid={!!validationErrors?.areaUnGrazedLand}
              />
            </FormField>

            <FormField
              isSkeleton={isLoading}
              label='Fertiliser applied (N KG/Ha/y)'
              htmlFor='appliedUnGrazedLand'
              validationMessages={validationErrors?.appliedUnGrazedLand}
              toolTipMessage={TOOLTIP_MESSAGE.appliedUnGrazedLand}
              isRequired
            >
              <TextInput
                min={0}
                type='number'
                isSkeleton={isLoading}
                id='appliedUnGrazedLand'
                value={`${fields?.appliedUnGrazedLand ?? ''}`}
                onBlur={(e) => setValue('appliedUnGrazedLand', e.target.value)}
                isInvalid={!!validationErrors?.appliedUnGrazedLand}
              />
            </FormField>
          </FormFieldRow>
        </Card>

        <Card className='c-wide-map'>
          <LocationFieldEdit
            isRequired
            formFieldLabel='Land location'
            validationFieldErrors={validationErrors}
            labelledBy='coordinates'
            isLoading={isLoading}
            easting={{
              fieldId: 'easting',
              value: `${fields.easting ?? ''}` ?? '',
              setValue: (value: FieldTypes) => setValue('easting', value),
            }}
            northing={{
              fieldId: 'northing',
              value: `${fields.northing ?? ''}` ?? '',
              setValue: (value: FieldTypes) => setValue('northing', value),
            }}
          />
        </Card>

        <RecordFooter>
          <FormEditButtons actions={formEditActions} isLoading={isLoading} />
        </RecordFooter>
      </ContentContainer>
      <ConfirmDeletionModal
        headingText='Confirm deletion'
        bodyText='Please confirm you wish to delete this land holding record.'
        isOpen={isDeleteConfirmationModalOpen}
        isLoading={isLoading}
        handleCancel={() => setIsDeleteConfirmationModalOpen(false)}
        submit={() => handleDelete()}
      />
    </>
  ) : null
}
