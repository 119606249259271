import classNames from 'classnames'
import React from 'react'
import { InputWidth } from '../../../types/input-width-types'
import { SkeletonTextInput } from '../text-input/skeleton-text-input'

export interface OptionProps {
  value: string
  label?: string
}

export interface SelectProps extends Omit<React.ComponentPropsWithoutRef<'select'>, 'css' | 'ref'> {
  id: string
  name?: string
  fieldSize?: InputWidth
  describedBy?: string | undefined
  isRequired?: boolean
  isDisabled?: boolean
  isInvalid?: boolean
  /*
    Determines whether placeholder option is added and whether it can be re-selected once changed.
    Defaults to 'hidden' - meaning it will be displayed initially but can't be selected again once
    other value selected.
  */
  placeholderType: 'none' | 'hidden' | 'selectable' | undefined
  /*
    Text to appear within placeholder option. Defaults to 'Please select...'. Won't appear at all
    if placeholderOption above set to 'none'.
  */
  placeholder?: string
  value?: string
  options?: Array<OptionProps>
  isSkeleton?: boolean
}

export const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
  (
    {
      id,
      name,
      fieldSize = InputWidth.default,
      describedBy = `${id}--help-text ${id}--validation-msg`,
      isRequired,
      isDisabled,
      isInvalid,
      placeholderType = 'hidden',
      placeholder = 'Please select' + String.fromCharCode(8230), // &hellip; equivalent
      value,
      options,
      isSkeleton,
      ...props
    },
    ref,
  ) => {
    if (isSkeleton) {
      return <SkeletonTextInput />
    }

    const selectClasses = classNames('c-text-input', 'c-text-input--select', {
      'c-text-input--invalid': isInvalid,
      'c-text-input--disabled': isDisabled,
      [`c-text-input--${fieldSize}`]: !!fieldSize,
    })

    return (
      <select
        className={selectClasses}
        id={id}
        name={name}
        aria-describedby={describedBy}
        required={isRequired}
        disabled={isDisabled}
        ref={ref}
        value={value}
        {...props}
      >
        {placeholderType !== 'none' && (
          <option value='' hidden={placeholderType === 'hidden'}>
            {placeholder}
          </option>
        )}
        {options &&
          options.map(({ label, value: optionValue }) => {
            return (
              <option key={`${id}-${optionValue.replace(' ', '-')}`} value={optionValue}>
                {label ? label : value}
              </option>
            )
          })}
      </select>
    )
  },
)
