import classNames from 'classnames'
import React from 'react'
import { IconType } from '../../../types'
import { Icon } from '../../common'
import { Link, LinkProps } from '../link'

export interface TabItemProps extends LinkProps {
  isNewSection?: boolean
  icon?: IconType
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
}

export interface TabNavProps {
  menuName: string
  tabs: Array<TabItemProps>
  borderBottomStyle?: 'none' | 'solid' | 'shadow'
  exact?: boolean
}

export function TabNav({ menuName, tabs, borderBottomStyle = 'none', exact = true }: TabNavProps) {
  const tabsClasses = classNames('c-tabs', {
    'c-tabs--shadow-style': borderBottomStyle === 'shadow',
    'c-tabs--bordered-style': borderBottomStyle === 'solid',
  })

  return (
    <nav className={tabsClasses} aria-label={menuName}>
      <ul className='c-tabs__items'>
        {tabs &&
          tabs.map(({ label, icon, path, isNewSection }) => {
            const tabItemClasses = classNames('c-tabs__item', {
              'c-tabs__item--new-section': isNewSection,
            })

            return (
              <li key={label} className={tabItemClasses}>
                <Link path={path} className='c-tabs__link' activeClassName='c-tabs__link--active' exact={exact}>
                  <span className='c-tabs__link-label'>
                    {icon && <Icon className='c-tabs__link-icon' icon={icon} ariaHidden />}
                    {label}
                  </span>
                </Link>
              </li>
            )
          })}
      </ul>
    </nav>
  )
}
