import React from 'react'
import classNames from 'classnames'

export interface DividerProps {
  isFullWidth?: boolean
  verticalSpacingSize?: 'default' | 'small'
  lineWeight?: 'default' | 'light'
}

export function Divider({ isFullWidth, verticalSpacingSize = 'default', lineWeight = 'default' }: DividerProps) {
  const dividerClasses = classNames('c-divider', {
    'c-divider--full-width': isFullWidth,
    'c-divider--small-vert-spacing': verticalSpacingSize === 'small',
    'c-divider--light-weight': lineWeight === 'light',
  })

  return <div className={dividerClasses} />
}
