/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Button,
  ButtonsContainer,
  CheckableField,
  CheckableFields,
  ConfirmTransition,
  DateField,
  Divider,
  FormField,
  FormFieldRow,
  Heading,
  InlineNotification,
  InputWidth,
  RecordFooter,
  RepeatableSection,
  TextInput,
} from '@msaf/core-react'
import { parseDate } from '@msaf/generic-search-common'
import { useNavigate } from '@msaf/router-react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ConfirmDeletionModal } from '../../../../../../components/confirm-submit-form-modal'
import { ContentContainer } from '../../../../../../components/content-container'
import FormEditButtons, { ActionProps } from '../../../../../../components/form-edit-buttons'
import { LoadingPage } from '../../../../../../components/loading-page'
import { DATEPICKER_POPPER_OPTS, DISPLAY_DATE_FORMAT, TOOLTIP_MESSAGE } from '../../../../../../constants'
import { useDeleteData, useGetFarmData, useSaveFarmData } from '../../../../../../hooks/useApi'
import { useBackLink } from '../../../../../../hooks/useBacklink'
import useEditForm from '../../../../../../hooks/useEditForm'
import useToastMessage from '../../../../../../hooks/useToastMessage'
import { useUserPermission } from '../../../../../../services/permissions'
import { getToastErrorMessage, offsetByIndex } from '../../../../../../services/utils'
import { HttpVerb } from '../../../../../../types/api-types'
import {
  FormFieldArrayTypesEdit,
  FormFieldsEdit,
  UnitOfMeasure,
  UnitsOfMeasure,
} from '../../../../../../types/form-types'
import { UserPermissions } from '../../../../../../types/permissions'
import { RouteParams } from '../../../../../../types/route'
import { usePageHeading } from '../../../../../../hooks/usePageHeading'

type ProofOfPurchaseRouteParams = RouteParams & { proofOfPurchaseId: string }

export const FertiliserProofOfPurchase = () => {
  const [canViewFarmOperations, isLoadingPermissions] = useUserPermission(UserPermissions.MANAGE_FARM)
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState<boolean>(false)

  useBackLink({ label: 'Dashboard', path: '/dashboard' })
  usePageHeading('Fertiliser Proof of Purchase')

  const { id, proofOfPurchaseId } = useParams<ProofOfPurchaseRouteParams>()
  const isNewRecord = proofOfPurchaseId === 'new'

  const [isDirty, setIsDirty] = useState<boolean>(false)
  const navigate = useNavigate()
  const url = `${id}/proof-of-purchase/${proofOfPurchaseId}`
  const stateQueryResult = useGetFarmData(url, ['proofOfPurchase'])
  const saveMutationResult = useSaveFarmData(url, isNewRecord ? HttpVerb.POST : HttpVerb.PUT)
  const {
    mutate: deleteData,
    isSuccess: isDeleteSuccess,
    isError: isDeleteError,
  } = useDeleteData(`/${id}/proof-of-purchase/${proofOfPurchaseId}`)

  const formEditActions: ActionProps[] = [
    {
      label: 'Save',
      onClick: () => handleSave(() => navigate(`/farm/edit/${id}/proof-of-purchase`)),
    },
    {
      label: 'Cancel',
      onClick: () => navigate(`/farm/edit/${id}/proof-of-purchase`),
      buttonStyle: 'text-action',
    },
  ]

  if (!isNewRecord) {
    formEditActions.push({
      label: 'Delete',
      onClick: () => setIsDeleteConfirmationModalOpen(true),
      buttonStyle: 'danger',
    })
  }

  const {
    fields,
    isLoading,
    isError,
    isSaveError,
    isSaved,
    validationErrors,
    setValue,
    handleSave,
    addField,
    removeField,
  } = useEditForm<FormFieldsEdit & FormFieldArrayTypesEdit>({
    stateQueryResult,
    saveMutationResult,
    setIsDirty,
  })

  useToastMessage({
    isError: isError || isSaveError || isDeleteError,
    isSuccess: isSaved || isDeleteSuccess,
    errorMessage: getToastErrorMessage(validationErrors, isSaveError),
  })

  useEffect(() => {
    // Default the measure to tonnes for all null items
    if (fields?.items) {
      fields.items = fields.items.map(({ measure, ...item }) => ({
        ...item,
        measure: measure ?? UnitOfMeasure.TONNES,
      }))
    }
  }, [fields?.items?.length])

  const itemValidationMessage = (index: number, fieldName: string) => {
    // @ts-ignore
    return validationErrors[`items[${index}].${fieldName}`]
  }

  const handleDelete = async () => {
    deleteData()
  }

  useEffect(() => {
    // Ensure we wait until the delete has succeeded before
    // navigating to the list view, otherwise that can load
    // the items that are being deleted
    if (isDeleteSuccess) {
      setIsDeleteConfirmationModalOpen(false)
      navigate(`/farm/edit/${id}/proof-of-purchase`)
    }
    if (isDeleteError) {
      setIsDeleteConfirmationModalOpen(false)
    }
  }, [isDeleteSuccess, isDeleteError])

  if (isLoading || isLoadingPermissions) return <LoadingPage />
  return canViewFarmOperations ? (
    <>
      <ConfirmTransition
        when={isDirty}
        contentLabel='Unsaved changes confirmation dialog'
        actionConfig={[
          {
            key: 'continue',
            type: 'cancel',
            buttonStyle: 'primary',
            label: 'Continue editing',
          },
          {
            key: 'discard',
            type: 'confirm',
            buttonStyle: 'text-action',
            label: 'Discard changes',
          },
        ]}
      />
      <ContentContainer>
        <ButtonsContainer containerStyle='left'>
          <Button
            onClick={() => navigate(`/farm/edit/${id}/proof-of-purchase`)}
            label='Back'
            iconAlignment='left'
            icon='arrow-left'
            buttonStyle='text-action'
          />
        </ButtonsContainer>
        <Heading level={2} className='has-subheading'>
          Proof of purchase
        </Heading>
        <em className='is-subheading'>We suggest you keep your receipts</em>
        <FormField
          isRequired
          isSkeleton={isLoading}
          label='Supplier name'
          htmlFor='supplierName'
          validationMessages={validationErrors?.supplierName}
        >
          <TextInput
            isSkeleton={isLoading}
            id='supplierName'
            value={`${fields?.supplierName ?? ''}`}
            onBlur={(e) => setValue('supplierName', e.target.value)}
            isInvalid={!!validationErrors?.supplierName}
          />
        </FormField>
        <FormField
          isSkeleton={isLoading}
          label='Date purchased'
          labelId='datePurchased'
          validationMessages={validationErrors?.dateTimePurchased}
          isRequired
        >
          <DateField
            //https://github.com/Hacker0x01/react-datepicker/issues/1246
            popperPlacement='bottom'
            popperModifiers={DATEPICKER_POPPER_OPTS}
            isSkeleton={isLoading}
            fieldSize={InputWidth.large}
            dateFormat={DISPLAY_DATE_FORMAT}
            selectedDate={parseDate(fields.dateTimePurchased)}
            setSelectedDate={(date: Date) => setValue('dateTimePurchased', date?.toISOString())}
            isInvalid={!!validationErrors?.dateTimePurchased}
          />
        </FormField>
        <Divider />
        <InlineNotification messageType='info' isDismissable={false}>
          If you make an error while entering fertiliser purchase data you can delete that record by clicking the Remove
          button and clicking Save at the bottom of the page. Prior to final submission you will be able to review all
          information for accuracy.
        </InlineNotification>
        `
        <RepeatableSection
          heading='Fertiliser purchases'
          sectionName='Item'
          removeField={(index: number) => removeField('items', index)}
          addField={() => addField('items', {})}
          allowEmpty={true}
          isSkeleton={isLoading}
        >
          {fields?.items?.map((item, index) => (
            <div key={index}>
              <FormField
                isRequired
                isSkeleton={isLoading}
                label='Fertiliser name'
                htmlFor={offsetByIndex('product', index)}
                validationMessages={itemValidationMessage(index, 'product')}
              >
                <TextInput
                  isSkeleton={isLoading}
                  id={offsetByIndex('product', index)}
                  value={`${item?.product ?? ''}`}
                  onBlur={(e) => setValue('items', e.target.value, 'product', index)}
                  isInvalid={!!itemValidationMessage(index, 'product')}
                />
              </FormField>
              <FormFieldRow>
                <FormField
                  isRequired
                  isSkeleton={isLoading}
                  label='Quantity'
                  htmlFor={offsetByIndex('unit', index)}
                  validationMessages={itemValidationMessage(index, 'unit')}
                  toolTipMessage={TOOLTIP_MESSAGE.purchaseQuantity}
                >
                  <TextInput
                    type='number'
                    min={0}
                    isSkeleton={isLoading}
                    id={offsetByIndex('unit', index)}
                    value={`${item?.unit ?? ''}`}
                    onBlur={(e) => setValue('items', Number(e.target.value), 'unit', index)}
                    isInvalid={!!itemValidationMessage(index, 'unit')}
                  />
                </FormField>

                <FormField
                  isRequired
                  validationMessages={itemValidationMessage(index, 'measure')}
                  isSkeleton={isLoading}
                  label='Unit of measure'
                  labelId={offsetByIndex('measure', index)}
                  toolTipMessage={TOOLTIP_MESSAGE.measure}
                >
                  <CheckableFields ariaLabelledBy={offsetByIndex('measure', index)} isHorizontal>
                    <CheckableField
                      id={`${offsetByIndex('measure', index)}${UnitsOfMeasure.tonnes.value}`}
                      name={`${offsetByIndex('measure', index)}`}
                      describedBy={`${offsetByIndex('measure', index)}`}
                      label={UnitsOfMeasure.tonnes.label}
                      isSkeleton={isLoading}
                      type='radio'
                      checked={item?.measure === UnitsOfMeasure.tonnes.value}
                      onChange={() => setValue('items', UnitsOfMeasure.tonnes.value, 'measure', index)}
                    />
                    <CheckableField
                      id={`${offsetByIndex('measure', index)}${UnitsOfMeasure.litres.value}`}
                      name={`${offsetByIndex('measure', index)}`}
                      describedBy={`${offsetByIndex('measure', index)}`}
                      label={UnitsOfMeasure.litres.label}
                      type='radio'
                      checked={item?.measure === UnitsOfMeasure.litres.value}
                      onChange={() => setValue('items', UnitsOfMeasure.litres.value, 'measure', index)}
                    />
                  </CheckableFields>
                </FormField>
              </FormFieldRow>
              {item.measure === UnitsOfMeasure.tonnes.value && (
                <div className='remove-notification-padding'>
                  <InlineNotification messageType='warning' isDismissable={false}>
                    Please be certain that the Quantity is in <strong>metric tonnes</strong>, not kilograms
                  </InlineNotification>
                </div>
              )}
            </div>
          ))}
        </RepeatableSection>
        <RecordFooter>
          <FormEditButtons actions={formEditActions} isLoading={isLoading} />
        </RecordFooter>
      </ContentContainer>
      <ConfirmDeletionModal
        headingText='Confirm deletion'
        bodyText='Please confirm you wish to delete these fertiliser purchase records. If you only wish to delete one record, use the Remove button and Save.'
        isOpen={isDeleteConfirmationModalOpen}
        isLoading={isLoading}
        handleCancel={() => setIsDeleteConfirmationModalOpen(false)}
        submit={() => handleDelete()}
      />
    </>
  ) : null
}
