import { ColumnHeader, TableAction } from '@msaf/core-react'
import { Cell } from 'react-table'

interface FarmData {
  hasActiveSubmission?: boolean
}

const FARM_ACTIONS = [
  {
    label: 'Start new submission',
    type: 'createSubmission',
    args: [{ elementKey: 'farmOperationId' }],
    modifier: (cell: Cell, self: TableAction) => {
      const farmInfo = cell?.row?.original as FarmData
      if (farmInfo?.hasActiveSubmission) {
        return {
          label: 'Continue submission',
          type: 'createSubmission',
          args: [{ elementKey: 'farmOperationId' }],
        }
      }

      return self
    },
  },
]

export const FARM_COLUMN_HEADERS: Array<ColumnHeader> = [
  {
    elementKey: 'farmOperationId',
    columnHeading: 'Farm operation id',
    viewColumn: 'farmOperationId',
    type: 'hidden',
  },
  {
    elementKey: 'organisationName',
    columnHeading: 'Dairy supply company name',
    viewColumn: 'organisationName',
    type: 'text',
  },
  {
    elementKey: 'dairySupplyNumber',
    columnHeading: 'Dairy supply number',
    viewColumn: 'dairySupplyNumber',
    type: 'text',
  },
  {
    elementKey: 'nzbn',
    columnHeading: 'NZBN',
    viewColumn: 'nzbn',
    type: 'text',
  },
  {
    elementKey: 'contactName',
    columnHeading: 'Contact name',
    viewColumn: 'contactName',
    type: 'text',
  },
  {
    elementKey: 'areaTotal',
    columnHeading: 'Total area (ha)',
    viewColumn: 'areaTotal',
    type: 'text',
  },
  {
    elementKey: 'actions',
    columnHeading: 'Actions',
    viewColumn: 'actions',
    type: 'actions-no-data',
    actions: FARM_ACTIONS as TableAction[],
  },
]

export const FARM_ROW_CLICK_ACTION: TableAction = {
  label: '',
  type: 'transitionAction',
  args: [{ constant: `dashboard` }],
}

export const COMPLETE_COLUMN_HEADERS: Array<ColumnHeader> = [
  {
    elementKey: 'submissionId',
    columnHeading: 'Submissionid',
    viewColumn: 'submissionId',
    type: 'hidden',
  },
  {
    elementKey: 'organisationName',
    columnHeading: 'Dairy supply company name',
    viewColumn: 'organisationName',
    type: 'text',
  },
  {
    elementKey: 'reportingYear',
    columnHeading: 'Reporting year',
    viewColumn: 'reportingYear',
    type: 'tag',
  },
  {
    elementKey: 'farmOperationId',
    columnHeading: 'Farm operation id',
    viewColumn: 'farmOperationId',
    type: 'hidden',
  },
  {
    elementKey: 'submissionStatus',
    columnHeading: 'Submission status',
    viewColumn: 'submissionStatus',
    type: 'tag',
  },
  {
    elementKey: 'completedAt',
    columnHeading: 'Completion Date',
    viewColumn: 'completedAt',
    type: 'date',
  },
]

export const COMPLETE_ROW_CLICK_ACTION: TableAction = {
  label: '',
  type: 'transitionAction',
  args: [
    { constant: `farm/view` },
    { elementKey: 'farmOperationId' },
    { constant: 'submission' },
    { elementKey: 'submissionId' },
  ],
}
