import React from 'react'
import { BackLink, BackLinkProps, Button, ButtonWithIcon, ButtonWithoutIcon, ButtonsContainer } from '@msaf/core-react'

export interface MapHeaderProps {
  backLinkConfig?: BackLinkProps
  headerActionsConfig?: Array<ButtonWithIcon | ButtonWithoutIcon>
}

export function MapHeader({ backLinkConfig, headerActionsConfig }: MapHeaderProps) {
  return (
    <div className='c-map-header'>
      {backLinkConfig && <BackLink {...backLinkConfig} />}
      {headerActionsConfig && (
        <ButtonsContainer containerStyle='right'>
          {headerActionsConfig.map((action) => (
            <Button {...action} size='small' />
          ))}
        </ButtonsContainer>
      )}
    </div>
  )
}
