import { matchPath, useLocation } from '@msaf/router-react'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useMemo, useState } from 'react'
import { Waypoint } from 'react-waypoint'
import { NAVIGATION_BREAKPOINT } from '../../../constants'
import { useClickOutside } from '../../../hooks/click-outside'
import { useMediaQuery } from '../../../hooks/media-query'
import { Icon } from '../../common/icon'
import { NavSection, SectionConfig } from './nav-section'

export interface WorkflowNavProps {
  sections: SectionConfig[]
}

export const WorkflowNav = React.memo(({ sections }: WorkflowNavProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isFixedToViewport, setIsFixedToViewport] = useState<boolean>(false)
  const node = useClickOutside<HTMLDivElement>(() => setIsOpen(false))
  const { pathname } = useLocation()

  // Conditionally select animation behaviour based on viewport size
  const isDesktop = useMediaQuery(`(min-width: ${NAVIGATION_BREAKPOINT})`)

  // Get info on current location from react-router, and use this to get the toggle
  // display value for mobile devices
  const currentLocation = useMemo(() => {
    return sections
      .flatMap((s) => s.links ?? [])
      .filter((l) => {
        return !!matchPath(pathname, l.path, l.exact ?? true)
      })
  }, [sections, pathname])

  const fixableClassNames = classNames('c-workflow-nav', {
    'c-workflow-nav--fixed': !isDesktop && isFixedToViewport,
    'c-workflow-nav--open': isDesktop || isOpen,
  })

  const menuAnimations = {
    closed: {
      opacity: 0,
      y: -10,
      transition: {
        when: 'afterChildren',
      },
    },
    open: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.25,
      },
    },
  }

  return (
    <>
      <Waypoint
        onEnter={() => setIsFixedToViewport(false)}
        onLeave={() => setIsFixedToViewport(true)}
        scrollableAncestor={window}
        topOffset={0}
      />
      <div ref={node} className={fixableClassNames}>
        <button className='c-workflow-nav__toggle' onClick={() => setIsOpen(!isOpen)}>
          {currentLocation[0]?.label}
          <Icon className='c-workflow-nav__toggle-icon' icon='chevron-left' color='primary' ariaHidden={true} />
        </button>
        <AnimatePresence>
          {(isOpen || isDesktop) && (
            <motion.div
              initial='closed'
              animate={isOpen || isDesktop ? 'open' : 'closed'}
              variants={menuAnimations}
              onClick={() => setIsOpen(false)}
              className='c-workflow-nav__animation-container'
            >
              <nav className='c-workflow-nav__container' aria-label='Workflow'>
                {sections.map((section, index) => (
                  // showDivider done like this to allow styling as an individual component without needing to use pseudo selectors
                  // or negative margins
                  <NavSection key={`section--${index}`} {...section} showDivider={index > 0} />
                ))}
              </nav>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  )
})
