import classNames from 'classnames'
import React from 'react'

export function SkeletonTag() {
  const tagSkeletonClasses = classNames('c-skeleton', 'c-skeleton--tag')

  return (
    <div className={tagSkeletonClasses}>
      <div className='c-skeleton c-skeleton--tag-label'></div>
    </div>
  )
}
