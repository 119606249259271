import React from 'react'
import { Link, LinkProps } from '../link'

export interface HorizontalMenuProps {
  menuLinks: Array<LinkProps>
}

export function HorizontalMenu({ menuLinks }: HorizontalMenuProps) {
  return (
    <ul className='c-horizontal-menu'>
      {menuLinks.map((linkProps) => (
        <li key={`hor-menu-${linkProps.label}`} className='c-horizontal-menu__item'>
          <Link className='c-horizontal-menu__link' {...linkProps} />
        </li>
      ))}
    </ul>
  )
}
