import React from 'react'
import { CheckableFieldTypeProps } from '../'

export function SkeletonCheckableField({ type }: CheckableFieldTypeProps) {
  return (
    <>
      <div className={`c-skeleton c-skeleton--${type}`}></div>
      <div className='c-skeleton c-skeleton--checkable-label'></div>
    </>
  )
}
