import classNames from 'classnames'
import React from 'react'
import { blockColorOption } from '../../../types/content-page-types'

export interface BlockContainerProps {
  blockWidth?: 'default' | 'narrow'
  blockColor?: blockColorOption
  className?: string
}

export function BlockContainer({
  blockWidth = 'default',
  blockColor,
  className,
  children,
}: React.PropsWithChildren<BlockContainerProps>) {
  const containerClass = classNames('c-block-container', {
    'c-block-container--blue-wash': blockColor === 'blue-wash',
    [`${className}`]: className,
  })

  const contentWidthClass = classNames('c-block-container__content', {
    'c-block-container__content--narrow': blockWidth === 'narrow',
    'c-block-container__content--blue-wash': blockColor === 'blue-wash',
  })

  return (
    <div className={containerClass}>
      <div className={contentWidthClass}>{children}</div>
    </div>
  )
}
