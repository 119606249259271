import React, { useCallback } from 'react'
import { DEFAULT_MIME_TYPES } from '../../../constants'
import { FileList, RemoveFileCallback } from './file-list'
import { FileUpload } from './file-upload'
import { FileValidator } from './file-upload/validators'
import {
  FileMetadataCallback,
  FileSaveActionCallback,
  FileState,
  FileUploadedCallback,
  FileUploadingCallback,
  NewOrUploadedFile,
  DownloadAction,
} from './types'

export * from './file-list'
export * from './file-upload'
export * from './types'

export interface FileManagerProps {
  isEditable?: boolean
  isUploadAllowed?: boolean
  isSkeleton?: boolean
  getMetadataElement?: FileMetadataCallback
  fileSaveAction?: FileSaveActionCallback
  fileValidators?: FileValidator[]
  validationErrors?: any
  allowMultiple?: boolean
  heading?: string
  allowedFileTypes?: string[]
  files: Array<NewOrUploadedFile>
  onFileUploading?: FileUploadingCallback
  onFileUploaded?: FileUploadedCallback
  onFileRemove?: RemoveFileCallback
  downloadAction?: DownloadAction | null
}

export function FileManager({
  files = [],
  heading = 'Uploaded files',
  isEditable = false,
  validationErrors,
  isUploadAllowed = true,
  isSkeleton = false,
  getMetadataElement,
  fileSaveAction,
  onFileUploading,
  onFileUploaded,
  onFileRemove,
  fileValidators,
  allowMultiple = true,
  allowedFileTypes = DEFAULT_MIME_TYPES,
  downloadAction = null,
}: FileManagerProps) {
  const showUpload = isEditable && isUploadAllowed

  if (isEditable && !fileSaveAction) {
    throw new Error('REACTCORE-2 isEditable is set but no fileSaveAction has been provided.')
  }

  const removeFileHandler = useCallback((file: NewOrUploadedFile) => {
    if (file.state === FileState.Uploading) file.abortController.abort()
    onFileRemove?.(file)
  }, [])

  return (
    <div className='c-file-manager'>
      <FileList
        heading={heading}
        files={files}
        isSkeleton={isSkeleton}
        isEditable={isEditable}
        removeFile={onFileRemove}
        validationErrors={validationErrors}
        getMetadataElement={getMetadataElement}
        downloadAction={downloadAction}
      />
      {showUpload && !!fileSaveAction && (
        <FileUpload
          allowedFileTypes={allowedFileTypes}
          isSkeleton={isSkeleton}
          allowMultiple={allowMultiple}
          fileSaveAction={fileSaveAction}
          onFileUploading={onFileUploading}
          onFileUploaded={onFileUploaded}
          onFileUploadFailed={removeFileHandler}
          fileValidators={fileValidators}
        />
      )}
    </div>
  )
}
