import { useLocation } from '@msaf/router-react'
import { useEffect } from 'react'

// https://reactrouter.com/web/guides/scroll-restoration
export function ScrollToTop({ targetDiv, behavior = 'smooth' }: { targetDiv: string; behavior?: ScrollBehavior }) {
  const { pathname } = useLocation()

  // Note target must have overflow set to auto for this to work.
  useEffect(() => {
    document.querySelector(targetDiv)?.scrollTo({
      top: 0,
      left: 0,
      behavior,
    })
  }, [pathname, targetDiv, behavior])

  return null
}
