import React from 'react'
import { Button } from '../../../../button'

export interface SkeletonPaginationProps {
  numPageLinks?: number
}

export function SkeletonPagination({ numPageLinks = 5 }: SkeletonPaginationProps) {
  return (
    <div className='c-skeleton-pagination'>
      <Button icon='chevron-left' iconAriaLabel='' isDisabled isSkeleton />
      {Array(numPageLinks)
        .fill(null)
        .map((_, index) => (
          <div key={index} className='c-skeleton c-skeleton--pagination-page-link' />
        ))}
      <Button icon='chevron-right' iconAriaLabel='' isDisabled isSkeleton />
    </div>
  )
}
