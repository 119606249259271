import classNames from 'classnames'
import React from 'react'
import { InputWidth } from '../../../types/input-width-types'
import { SkeletonTextarea } from './skeleton-textarea'

export interface TextAreaProps extends React.ComponentPropsWithoutRef<'textarea'> {
  id: string
  name?: string
  fieldSize?: InputWidth
  describedBy?: string | undefined
  isRequired?: boolean
  isDisabled?: boolean
  isInvalid?: boolean
  placeholder?: string
  autocomplete?: string
  isSkeleton?: boolean
}

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  (
    {
      id,
      name,
      fieldSize = InputWidth.default,
      describedBy = `${id}--help-text ${id}--validation-msg`,
      isRequired,
      isDisabled,
      isInvalid,
      placeholder,
      autocomplete,
      isSkeleton,
      ...props
    }: TextAreaProps,
    ref,
  ) => {
    if (isSkeleton) {
      return <SkeletonTextarea />
    }

    const inputClasses = classNames('c-text-input', 'c-text-input--textarea', {
      'c-text-input--invalid': isInvalid,
      'c-text-input--disabled': isDisabled,
      [`c-text-input--${fieldSize}`]: !!fieldSize,
    })

    return (
      <textarea
        ref={ref}
        className={inputClasses}
        id={id}
        name={name}
        aria-describedby={describedBy}
        required={isRequired}
        disabled={isDisabled}
        placeholder={placeholder}
        autoComplete={autocomplete}
        {...props}
      />
    )
  },
)
