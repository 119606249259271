import React from 'react'
import { FormField } from '@msaf/core-react'
import { FeatureMetaConfig, SupportedFeatureMetaType } from '@msaf/maps-common'
import { FeatureDate } from '../feature-date'
import { FeatureSelect } from '../feature-select'
import { FeatureText } from '../feature-text'
import { FeatureTextArea } from '../feature-text-area'

const elementMap: Record<SupportedFeatureMetaType, React.ElementType<any>> = {
  text: FeatureText,
  number: FeatureText,
  select: FeatureSelect,
  'text-area': FeatureTextArea,
  date: FeatureDate,
}

interface FeatureFormFieldProps {
  value: string
  setValue: (key: string, value: string) => void
  config: FeatureMetaConfig
}

export function FeatureFormField({ value, setValue, config }: FeatureFormFieldProps) {
  const Field = elementMap[config.type]
  if (!Field) throw new Error(`Element of type ${config.type} is not supported.`)
  return (
    <FormField labelId={config.key} label={config.label}>
      <Field id={config.key} config={config} value={value} setValue={(value: string) => setValue(config.key, value)} />
    </FormField>
  )
}
