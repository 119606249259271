import React from 'react'

export function SkeletonToggle() {
  return (
    <div className='c-toggle'>
      <div className='c-skeleton c-skeleton--toggle'>
        <div className='c-skeleton c-skeleton--toggle-handle'></div>
      </div>
      <div className='c-skeleton c-skeleton--checkable-label'></div>
    </div>
  )
}
