// Framer Motion animations (see: https://www.framer.com/api/motion/animation/#variants)
export const linkAnimations = {
  default: {
    width: '100%',
    transition: {
      duration: 0.25,
    },
  },
  collapsed: {
    width: '40px',
    transition: {
      duration: 0.25,
    },
  },
}

export const linkLabelAnimations = {
  default: {
    opacity: 1,
    transition: {
      duration: 0.25,
    },
  },
  collapsed: {
    opacity: 0,
    transition: {
      duration: 0.25,
    },
  },
}
