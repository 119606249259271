import React from 'react'
import { useMediaQuery } from '../../../hooks/media-query'
import { Icon } from '../../common/icon'
import { Link, LinkProps } from '../link'
import { Button } from '../../common/button'
import { MOBILE_BREAKPOINT } from '../../../constants'

export interface BackLinkProps extends Partial<LinkProps> {
  label: string
  /** If label is too long to fit on one line on small screens, use a shorter label for viewports < 600px (e.g. 'Back') */
  mobileLabel?: string
  onClick?: () => void
}

export function BackLink({ label, mobileLabel, path, exact, onClick }: BackLinkProps) {
  const isMobile = useMediaQuery(`(max-width: ${MOBILE_BREAKPOINT})`)

  return path ? (
    <Link className='c-back-link' path={path} exact={exact}>
      <Icon className='c-back-link__icon' icon='arrow-left' ariaHidden={true} />
      <span className='c-back-link__text'>{!!mobileLabel && isMobile ? mobileLabel : label}</span>
    </Link>
  ) : (
    <Button buttonStyle='text-action' icon='arrow-left' iconAlignment='left' label={label} onClick={onClick} />
  )
}
