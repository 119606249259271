import { ContentContainer, Heading, Button, BodyTextContainer } from '@msaf/core-react'
import { FallbackProps } from 'react-error-boundary'

export default function ServerError(props: Partial<FallbackProps>) {
  return (
    <ContentContainer>
      <BodyTextContainer>
        <Heading level={1}>Something went wrong</Heading>
        <p>
          Please use the 'Try again' button below to reload the page.&nbsp; Please <a href='/contact-us'>contact us</a>{' '}
          if the problem persists.
        </p>
        {props.resetErrorBoundary && (
          <Button buttonStyle='primary' label='Retry' onClick={props.resetErrorBoundary}>
            Try again
          </Button>
        )}
      </BodyTextContainer>
    </ContentContainer>
  )
}
