import { ContentContainer, BodyTextContainer } from '@msaf/core-react'
import { FallbackProps } from 'react-error-boundary'
import { ErrorCode } from '../../constants'
import ExternalApiError from '../external-api-error'
import KeycloakInitialisationError from '../keycloak-initialisation-error'
import NotFound from '../not-found'
import PermissionDenied from '../permission-denied'
import ServerError from '../server-error'
import SessionTimeout from '../session-timeout'

export default function ErrorBoundaryFallbackComponent({ error, resetErrorBoundary }: FallbackProps) {
  let component = null
  switch (error.message) {
    case ErrorCode.SESSION_TIMEOUT:
      component = <SessionTimeout />
      break
    case ErrorCode.PERMISSION_DENIED:
      component = <PermissionDenied />
      break
    case ErrorCode.NOT_FOUND:
      component = <NotFound />
      break
    case ErrorCode.EXTERNAL_API_TIMEOUT:
      component = <ExternalApiError resetErrorBoundary={resetErrorBoundary} />
      break
    case ErrorCode.KEYCLOAK_ERROR:
      component = <KeycloakInitialisationError />
      break
    default:
      component = <ServerError resetErrorBoundary={resetErrorBoundary} />
  }
  return (
    <ContentContainer>
      <BodyTextContainer>{component}</BodyTextContainer>
    </ContentContainer>
  )
}
