import React from 'react'
import classNames from 'classnames'
import { Link } from '../../link'

export interface BrandingLogoProps {
  isSkeleton?: boolean
  link?: string
  altText?: string
  imagePath?: string
  logoLayout?: 'rectangle' | 'wide-rectangle'
}

export function BrandingLogo({ isSkeleton, link, altText, imagePath, logoLayout = 'rectangle' }: BrandingLogoProps) {
  const logoClasses = classNames('c-nav-panel__branding-link', {
    'c-nav-panel__branding-link--wide-rectangle': logoLayout === 'wide-rectangle',
  })

  return isSkeleton ? (
    <div className='c-skeleton c-skeleton--rectangle'></div>
  ) : (
    <Link className={logoClasses} path={link ?? ''} isExternal={true}>
      <img className='c-nav-panel__branding-img' src={imagePath} alt={altText} />
    </Link>
  )
}
