import { AUTOCOMPLETE_MIN_CHARS, ReadOnlyField, Typeahead, TypeaheadOption } from '@msaf/core-react'
import debounce from 'lodash.debounce'
import { useMemo, useState } from 'react'
import { OptionsType, OptionTypeBase } from 'react-select'
import { useAuth } from '../../auth'
import { DEBOUNCE_DELAY } from '../../constants'
import fetchApi from '../../services/api'

interface AddressLookupProps {
  isSkeleton?: boolean
  labelledBy: string
  isReadOnly?: boolean
  isDisabled?: boolean
  autoFocus?: boolean
  autoCompleteMinChars?: number
  updateLocationFromMap: (coordinates: [number, number]) => void
}

interface AWSAddressResult {
  Text: string
  PlaceId: string
}

export default function AddressLookup({
  isSkeleton = false,
  labelledBy,
  isReadOnly = false,
  isDisabled = false,
  autoFocus = false,
  autoCompleteMinChars = AUTOCOMPLETE_MIN_CHARS,
  updateLocationFromMap,
}: AddressLookupProps) {
  const [address, setAddress] = useState<TypeaheadOption>({ label: '', value: '' })

  const selectedOption = useMemo(() => {
    return { label: address.label, value: address.value }
  }, [address])

  const { authStrategy } = useAuth()

  const getAddressSuggestions = useMemo(
    () =>
      debounce(async (query: string, callback: (options: OptionsType<OptionTypeBase>) => void) => {
        const { addresses } = await fetchApi({
          url: `address/lookup/?query=${encodeURIComponent(query)}`,
          additionalHeaders: authStrategy?.toHeader(),
        })

        callback(
          addresses.map((item: AWSAddressResult) => {
            return { label: item.Text, value: item.PlaceId }
          }),
        )
      }, DEBOUNCE_DELAY),
    [authStrategy?.token],
  )

  const geocodeAddress = async (query: string) => {
    const result = await fetchApi({
      url: `address/geocode/?query=${encodeURIComponent(query)}`,
      additionalHeaders: authStrategy?.toHeader(),
    })
    return result
  }

  const loadOptions = (inputValue: string, callback: (options: OptionsType<OptionTypeBase>) => void) => {
    if (inputValue == null || inputValue === '' || inputValue.length < autoCompleteMinChars) return callback([])
    if (inputValue.length >= autoCompleteMinChars) {
      getAddressSuggestions(inputValue, callback)
    }
  }

  const handleAddressSelection = async ({ label, value }: TypeaheadOption) => {
    if (!label) {
      setAddress({ label: '', value: '' })
    } else {
      const { latitude, longitude } = await geocodeAddress(label)
      updateLocationFromMap([longitude, latitude])
      setAddress({ label, value })
    }
  }

  if (isReadOnly) {
    return <ReadOnlyField isSkeleton={isSkeleton} labelledBy={labelledBy} value={selectedOption.value} />
  }

  return (
    <Typeahead
      isSkeleton={isSkeleton}
      selectType='async'
      labelledBy={labelledBy}
      handleChange={handleAddressSelection}
      selectedOption={selectedOption}
      options={[]}
      loadOptions={loadOptions}
      isClearable
      isDisabled={isDisabled}
      autoFocus={autoFocus}
    />
  )
}
