import * as L from 'leaflet'
import type _ from '@geoman-io/leaflet-geoman-free'
import { LineString } from '@turf/helpers'
import { LatLng } from 'leaflet'
import { DrawOptions } from '../types'
import { deepOmitBy } from '@msaf/core-common'
import { isUndefined } from 'lodash'

/*
Calculate the length of a Polyline. Only LineStrings are supported, MutiLineString Polylines are not.
Distance returned is in meters.
*/
export const calculatePolylineLength = (layer: L.Polyline<LineString>) => {
  /*
  Ignore the reduce error caused by getLatLngs possibly retuning nested arrays
  for MultLineStrings. We exclude them in the function signature.
  */
  return (
    layer
      .getLatLngs()
      //@ts-ignore
      .reduce<number>(
        (sum: number, currentValue: LatLng, index: number, array: LatLng[]) =>
          index === 0 ? sum : sum + currentValue.distanceTo(array[index - 1]),
        0,
      )
  )
}

export const mapDrawOptionsForGeoman = (options: Partial<DrawOptions>): Partial<L.PM.DrawModeOptions> => {
  const strippableResults: L.PM.DrawModeOptions = {
    snappable: options.allowSnapping,
    snapDistance: options.snapDistance,
    tooltips: options.showTooltip,
    mediaSuite: {
      measurement: options.measurement,
    },
  }

  /*
  Strip out any undefined values as they might be specified as part of the global options.

  pathOptions and markerStyle are excluded because we don't want to selectively apply their
  options and it breaks icons.
  */
  return {
    ...deepOmitBy(strippableResults, isUndefined),
    pathOptions: options.pathOptions,
    markerStyle: options.markerStyle,
  }
}
