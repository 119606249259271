import classNames from 'classnames'
import React from 'react'
import { FormFieldSkeletonProps } from '../index'

export function SkeletonFormField({
  children,
  helpText,
  hasInlineLabel,
  hasInputWithButton,
}: React.PropsWithChildren<FormFieldSkeletonProps>) {
  const containerClasses = classNames('c-form-field', {
    'c-form-field--inline-label c-form-field--inline-label--skeleton': hasInlineLabel,
  })

  const inputClasses = classNames('c-form-field__input', {
    'c-form-field__input--multiple': hasInputWithButton,
  })

  return (
    <div className={containerClasses}>
      <div className='c-skeleton c-skeleton--label'></div>
      <div className={inputClasses}>{children}</div>
      {helpText && <div className='c-skeleton c-skeleton--help-text'></div>}
    </div>
  )
}
