import classNames from 'classnames'
import React from 'react'

export interface TableSkeletonProps {
  numRows?: number
  numCols?: number
}

export function TableSkeleton({ numRows = 10, numCols = 6 }: TableSkeletonProps) {
  // Function to output one row of columns
  const getCols = (isHeaderRow: boolean, rowNum: number) => {
    const colContentClasses = classNames('c-skeleton c-skeleton--table-content', {
      'c-skeleton--table-header-content': isHeaderRow,
    })
    return Array(numCols)
      .fill(null)
      .map((_, i) => (
        <div className='c-table-skeleton__col' key={`table-skeleton-row-${rowNum}-col-${i}`}>
          <div className={colContentClasses} />
        </div>
      ))
  }

  return (
    <div className='c-table-skeleton'>
      {Array(numRows)
        .fill(null)
        .map((_, i) => (
          <div className='c-table-skeleton__row' key={`table-skeleton-row-${i}`}>
            {getCols(i === 0, i)}
          </div>
        ))}
    </div>
  )
}
