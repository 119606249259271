import classNames from 'classnames'
import React, { useState } from 'react'
import { IconType } from '../../../types/icon-types'
import { Icon } from '../icon'

export interface InlineNotificationProps {
  messageType?: 'info' | 'success' | 'warning' | 'error'
  isDismissable?: boolean
}

export function InlineNotification({
  messageType = 'info',
  isDismissable = true,
  children,
}: React.PropsWithChildren<InlineNotificationProps>) {
  const [isDismissed, setIsDismissed] = useState(false)
  const messageClasses = classNames('c-inline-notification', {
    [`c-inline-notification--${messageType}`]: !!messageType,
  })

  const iconOptions: { [key: string]: IconType } = {
    info: 'information-circle',
    success: 'check-circle',
    warning: 'warning',
    error: 'warning',
  }

  return isDismissed ? null : (
    <div className={messageClasses} role='alert' aria-label={`${messageType} notification message`}>
      <Icon icon={iconOptions[messageType]} className='c-inline-notification__icon' ariaHidden={true} />
      <div className='c-inline-notification__message'>{children}</div>
      {isDismissable && (
        <button className='c-inline-notification__dismiss-btn' onClick={() => setIsDismissed(true)}>
          <Icon icon='close' ariaLabel='Dismiss notification' />
        </button>
      )}
    </div>
  )
}
