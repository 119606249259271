// Framer Motion animations (see: https://www.framer.com/api/motion/animation/#variants)
export const navPanelAnimations = {
  default: {
    width: '256px',
    paddingLeft: '16px',
    paddingRight: '16px',
    x: 0,
    transition: {
      duration: 0.2,
    },
  },
  collapsed: {
    width: '64px',
    paddingLeft: '12px',
    paddingRight: '12px',
    x: -4,
    transition: {
      duration: 0.2,
    },
  },
}

export const appLogoAnimations = {
  default: {
    x: '8px',
    transition: { duration: 0.25 },
  },
  collapsed: {
    x: '0',
    transition: { duration: 0.25 },
  },
}

export const hideOnCollapseAnimations = {
  default: {
    opacity: 1,
    transition: {
      duration: 0.15,
    },
  },
  collapsed: {
    opacity: 0,
    transition: {
      duration: 0.15,
    },
  },
}
