import { BodyTextContainer, Button, ContentContainer, Divider, Link, useTitleChange } from '@msaf/core-react'
import { kebabCase } from 'lodash'
import { useEffect } from 'react'
import ReactMarkdown from 'react-markdown'
import { useAuth } from '../../auth'
import { TableOfContents } from '../../components/table-of-contents'
import { TableOfContentsSection } from '../../components/table-of-contents/table-of-contents-section'
import { usePageHeading } from '../../hooks/usePageHeading'
import { MARKDOWN_CONTENT } from './content'

export const HelpCentre = () => {
  useTitleChange('Help centre')
  usePageHeading('Help centre')

  const { isLoading } = useAuth()

  const TABLE_OF_CONTENTS = [
    'What is the N percentage in Ballance and Ravensdown products?',
    'Spreadsheet',
    'Help and guidance',
    'About the cap',
    'Data usage',
    'Contiguous Landholding',
    'Land Categories',
    'N-Cap Application Rates',
    'Other terms',
    'Ministry for the Environment Information',
    'NZ Business Number',
  ].map((heading) => {
    return { label: heading, anchorLink: `#${kebabCase(heading.toLowerCase())}` }
  })

  useEffect(() => {
    // Navigating to the page with a fragment in the url doesn't scroll the page
    // as the markdown hasn't rendered at this point.
    const targetLocation = location.hash?.split('&')?.[0]
    if (targetLocation && !isLoading) {
      const navItem: HTMLAnchorElement | null = document.querySelector(`a[href="${targetLocation}"]`)
      navItem && navItem.click()
    }
  }, [isLoading])

  return (
    <ContentContainer>
      <TableOfContents heading='Contents' headingLevel={2}>
        <TableOfContentsSection headingLevel={3} items={TABLE_OF_CONTENTS} />
      </TableOfContents>
      <BodyTextContainer>
        <div className='help-markdown'>
          <ReactMarkdown
            components={{
              a: (props) => {
                if (props.href?.includes('s3')) {
                  return (
                    <Link isExternal path={props.href}>
                      <Button label='Download now' icon='download' iconAlignment='left' />
                    </Link>
                  )
                } else {
                  return <a {...props} target='_blank' rel='noreferrer noopener' />
                }
              },
              h1: ({ node, ...props }) => {
                return (
                  <h1
                    className='help-centre-anchor'
                    id={'value' in node.children[0] ? `${kebabCase(node.children?.[0].value.toLowerCase())}` : ''}
                    {...props}
                  />
                )
              },
              img: (props) => <img {...props} style={{ maxWidth: '100%' }} />,
              hr: () => <Divider isFullWidth />,
            }}
          >
            {MARKDOWN_CONTENT}
          </ReactMarkdown>
        </div>
      </BodyTextContainer>
    </ContentContainer>
  )
}
