import React from 'react'
import { TextAreaProps, TextArea } from '@msaf/core-react'
import { FeatureMetaConfig } from '@msaf/maps-common'

export interface FeatureTextAreaProps extends TextAreaProps {
  config: FeatureMetaConfig
}

export function FeatureTextArea(props: FeatureTextAreaProps) {
  const { config, ...textAreaProps } = props
  return <TextArea isRequired={config.required} {...textAreaProps} />
}
