import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import { Icon } from '../icon'

export interface BannerProps {
  message: string
  messageType: 'info' | 'warning'
  shouldDisplay: boolean
  onClose?: () => void
}

export function Banner({ message, messageType, shouldDisplay, onClose }: BannerProps) {
  const [isVisible, setIsVisible] = useState(shouldDisplay)

  useEffect(() => {
    setIsVisible(shouldDisplay)
  }, [shouldDisplay])

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
    setIsVisible(false)
  }

  const bannerClasses = classNames('c-banner', {
    [`c-banner--${messageType}`]: messageType,
  })

  return (
    <AnimatePresence initial={false}>
      {isVisible && (
        <motion.div className={bannerClasses} initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
          <Icon
            icon={messageType === 'warning' ? 'warning' : 'information-circle'}
            className='c-banner__icon'
            ariaHidden={true}
          />
          <div className='c-banner__content'>
            <div className='c-banner__message' role='alert'>
              {message}
            </div>
          </div>
          <button className='c-banner__close-btn' onClick={handleClose}>
            <Icon icon='close' className='c-banner__close-icon' ariaLabel='Dismiss banner' />
          </button>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
