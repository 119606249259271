import React from 'react'
import { Tag } from '../../../common/tag'
import { Heading } from '../../../common/heading'
import { ActionsMenu } from '../../actions-menu'
import { DropdownMenu } from '../../dropdown-menu'
import { MenuItem } from '../../dropdown-menu/menu-item'

// TODO: Update type during implementation
export interface ActionsMenuItem {
  label: string
  action: () => void
}
export interface PageHeaderMenuProps {
  pageHeading?: string
  tags?: Array<string> // TODO: Update type during implementation
  actionsMenuItems?: Array<ActionsMenuItem>
  isSkeleton?: boolean
}

export function PageHeaderMenu({ pageHeading, tags, actionsMenuItems, isSkeleton }: PageHeaderMenuProps) {
  return isSkeleton ? (
    <div className='c-page-header'>
      <div className='c-page-header__info'>
        <Heading isSkeleton level={1} className='c-page-header__heading' />
      </div>
    </div>
  ) : (
    <div className='c-page-header'>
      <div className='c-page-header__info'>
        <Heading isSkeleton={!pageHeading} level={1} className='c-page-header__heading'>
          {pageHeading}
        </Heading>
        {tags && (
          <div className='c-page-header__tags'>
            {tags.map((tag: string) => (
              <Tag key={tag} label={tag} />
            ))}
          </div>
        )}
      </div>
      {actionsMenuItems && (
        <div className='c-page-header__menu'>
          <ActionsMenu label='Actions' buttonStyle='secondary'>
            <DropdownMenu>
              {actionsMenuItems.map((menuItem) => (
                <MenuItem key={menuItem.label} {...menuItem} />
              ))}
            </DropdownMenu>
          </ActionsMenu>
        </div>
      )}
    </div>
  )
}
