import React, { ReactNode } from 'react'
import { Heading, Icon } from '@msaf/core-react'

export interface MapContextContentProps {
  heading?: string
  headingIcon?: ReactNode
  headingAction?: ReactNode
  panelContent?: ReactNode
}

export interface MapContextPanelContentProps extends MapContextContentProps {
  closePanel: () => void
}

export function MapContextPanelContent({
  heading,
  headingIcon,
  headingAction,
  closePanel,
  panelContent,
}: MapContextPanelContentProps) {
  return (
    <>
      <div className='c-map-context-panel__header'>
        <div className='c-map-context-panel__heading-content'>
          {headingIcon && <div className='c-map-context-panel__heading-icon'>{headingIcon}</div>}
          <Heading level={2} className='c-map-context-panel__heading'>
            {heading}
          </Heading>
        </div>
        <div className='c-map-context-panel__header-actions'>
          {headingAction}
          {/* TODO: this action should also deselect the active marker, item etc */}
          <button className='c-map-context-panel__close-btn' onClick={() => closePanel()}>
            <Icon icon='close' ariaLabel='Close menu panel' />
          </button>
        </div>
      </div>
      <div className='c-map-context-panel__content'>{panelContent}</div>
    </>
  )
}
