import { ContentContainer, Divider, Heading, Link, useTitleChange, BodyTextContainer } from '@msaf/core-react'
import ContactCard from '../../components/contact-card'
import { CONTACT_DATA } from '../../constants'
import { usePageHeading } from '../../hooks/usePageHeading'

export const getContactData = () => {
  return CONTACT_DATA.map((item) => {
    const result = {
      name: item.councilName,
      additionalResources: [
        {
          label: `Email: ${item.email}`,
          link: `mailto:${item.email}`,
        },
      ],
    }

    if (item.phone) {
      result.additionalResources.push({
        label: `Phone: ${item.phone}`,
        link: `tel:${item.phone}`,
      })
    }

    return result
  })
}

export default function ContactUs() {
  useTitleChange('Contact us')
  usePageHeading('Contact us')

  return (
    <ContentContainer>
      <BodyTextContainer>
        <Heading level={2}>Need some help using the Nitrogen Use Recording Tool?</Heading>
        <p>
          Try our{' '}
          {
            <Link target='_blank' rel='noopener noreferrer' path='/help-centre'>
              Help Centre
            </Link>
          }{' '}
          for useful guides to get you going and help you access and use the data.
        </p>
      </BodyTextContainer>
      <BodyTextContainer>
        <Divider isFullWidth />
        <Heading level={2}>Found an issue?</Heading>
        <p>
          If you have found an issue with data or need help understanding the data, contact the relevant Regional or
          Unitary council.
        </p>
        <p>
          If something in the Nitrogen Use Recording Tool isn't working properly, contact your closest Regional or
          Unitary council from the list below.
        </p>
      </BodyTextContainer>

      {getContactData().map((item) => {
        return <ContactCard key={item.name} {...item} />
      })}
    </ContentContainer>
  )
}
