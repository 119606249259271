const DRF_LOGIN_NEEDED_ERROR = 'Authentication credentials were not provided.'

/**
 * Constructs a querystring from a dictionary
 * @param query Querystring parameters as an object
 * @returns constructed querystring
 */
export function buildQueryString(query?: { [key: string]: string | number | boolean | object }): string {
  if (query === undefined) return ''
  return Object.keys(query)
    .map((key) => ({ key, value: query[key] }))
    .filter(({ value }) => value !== undefined)
    .map(({ key, value }) => `${key}=${encodeURIComponent(JSON.stringify(value))}`)
    .join('&')
}

/**
 * Checks if the error string is equal to the DRF unauthenticated error
 * @param error Error string
 * @returns is DRF unauthenticated error
 */
export function isUnauthenticatedError(error: string): boolean {
  return error === DRF_LOGIN_NEEDED_ERROR
}
