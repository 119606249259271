import { Heading, Icon } from '@msaf/core-react'

export interface TableOfContentsProps {
  heading: string
  headingLevel: 1 | 2 | 3 | 4 | 5 | 6
}

export function TableOfContents({ heading, headingLevel, children }: React.PropsWithChildren<TableOfContentsProps>) {
  return (
    <nav className='c-table-of-contents'>
      <Heading className='c-table-of-contents__heading' level={headingLevel}>
        <Icon icon='information-solid' ariaHidden /> {heading}
      </Heading>
      <div className='c-table-of-contents__sections'>{children}</div>
    </nav>
  )
}
