import {
  addDays,
  addMonths,
  addYears,
  endOfMonth,
  endOfYear,
  startOfMonth,
  startOfYear,
  parseISO,
  isValid,
} from 'date-fns'
import { formatDate } from '@msaf/core-common'

// Pre-defined Date range filter options
export enum DateRangeFilterOption {
  None = 'None',
  Today = 'Today',
  Tomorrow = 'Tomorrow',
  Yesterday = 'Yesterday',
  ThisMonth = 'This calendar month',
  LastMonth = 'Last calendar month',
  NextMonth = 'Next calendar month',
  NextThreeMonths = 'Next 3 calendar months',
  NextSixMonths = 'Next 6 calendar months',
  LastCalendarMonth = 'Last calendar month',
  ThisCalendarMonth = 'This calendar month',
  NextSevenDays = 'Next 7 days',
  NextFourteenDays = 'Next 14 days',
  NextTwentyEightDays = 'Next 28 days',
  LastSixMonths = 'Last 6 months',
  LastTwelveMonths = 'Last 12 months',
}

// Allow date range filter options outside of the predefined DateRangeFilterOption
export type DateRangeFilterType = DateRangeFilterOption | string

export type DateFilter = {
  getISOLower?: () => string | null
  getISOUpper?: () => string | null
  getPillText?: () => string
  label: string
  value: string
}

export class DateRange {
  private constructISOGetterGeneric(
    isPast: boolean,
    isSameDay: boolean,
    quantity: number,
    periodType: 'days' | 'years' | 'months',
  ): (() => string)[] {
    const now = new Date()
    let upper: Date
    let lower: Date
    switch (periodType) {
      case 'days':
        if (isPast) {
          upper = isSameDay ? addDays(now, -quantity) : now
          lower = addDays(now, -quantity)
        } else {
          upper = addDays(now, quantity)
          lower = isSameDay ? addDays(now, quantity) : now
        }
        break
      case 'months':
        if (isPast) {
          upper = endOfMonth(now)
          lower = startOfMonth(addMonths(now, -quantity))
        } else {
          upper = endOfMonth(addMonths(now, quantity))
          lower = startOfMonth(now)
        }
        break
      case 'years':
        if (isPast) {
          upper = endOfYear(now)
          lower = startOfYear(addYears(now, -quantity))
        } else {
          upper = endOfYear(addYears(now, quantity))
          lower = startOfYear(now)
        }
        break
      default:
        break
    }

    return [() => formatDate(lower), () => formatDate(upper)]
  }

  private contructISOGetters(range: string): (() => string | null)[] {
    switch (range.trim()) {
      case DateRangeFilterOption.Today:
        return this.constructISOGetterGeneric(false, true, 0, 'days')
      case DateRangeFilterOption.Tomorrow:
        return this.constructISOGetterGeneric(false, true, 1, 'days')
      case DateRangeFilterOption.Yesterday:
        return this.constructISOGetterGeneric(true, true, 1, 'days')
      case DateRangeFilterOption.ThisMonth:
        return this.constructISOGetterGeneric(false, false, 0, 'months')
      case DateRangeFilterOption.LastMonth:
        return this.constructISOGetterGeneric(true, false, 1, 'months')
      case DateRangeFilterOption.NextMonth:
        return this.constructISOGetterGeneric(false, false, 1, 'months')
      case DateRangeFilterOption.None:
        return [() => null, () => null]
      case '':
        throw new Error('Date range specified is not valid')
      default:
        // If string doesn't match the allowed enum values, break down to check if able to use a custom value
        const parts = range.split(' ')
        /**
         * Date range filter option can be of the following two formats:
         *  1. [tense] [number] [periodType]
         *      Example: Next 14 days
         *  2. [tense] [number] calendar [periodType]
         *      Example: Next 3 calendar months
         */
        if (parts.length < 3 || parts.length > 4) {
          throw new Error('Date specified is not a properly formatted date for use in the filter object')
        }
        const [tense, number] = parts

        let periodType = parts[2]
        if (parts.length === 4) {
          periodType = parts[3]
        }

        const isPast = tense === 'Last' ? true : tense === 'Next' ? false : undefined
        if (isPast === undefined) throw new Error('Date specified does not contain a valid tense')

        const n = parseInt(number)
        if (isNaN(n)) throw new Error('Date specified does not contain a valid quantity')

        if (periodType !== 'days' && periodType !== 'months' && periodType !== 'years')
          throw new Error('Date specified does not contain a valid period')

        return this.constructISOGetterGeneric(isPast, false, n, periodType)
    }
  }

  /**
   * Constructs date range filters given the options that are displayable to the user
   * @param options Range of options to allow for the date range filters
   * @returns Re-constructed date range filter options
   * @throws Throws an error if there are no dates passed
   * @todo Allow translations to be passed for dates
   */
  constructFilters(options: DateRangeFilterType[]): DateFilter[] {
    if (!options || !options.length) {
      throw new Error('Must provide a list of options')
    }

    const filters: DateFilter[] = options.map((option) => {
      const [getISOLower, getISOUpper] = this.contructISOGetters(option)
      return {
        // kebab-case the option
        value: option.toLowerCase().split(' ').join('-'),
        label: option,
        getISOLower,
        getISOUpper,
      }
    })

    filters.unshift({
      value: '',
      label: 'Custom',
    })

    return filters
  }
}

/**
 * Parses date string to display correct date and handle inValid dates in the datepicker
 * @param value Date field value
 * @returns Date
 */
export function parseDate(value: string | undefined): Date | undefined {
  const date = value ? parseISO(value) : undefined
  return isValid(date) ? date : undefined
}
