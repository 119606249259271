import 'leaflet'
import '@geoman-io/leaflet-geoman-free'
import 'leaflet/dist/leaflet.css'
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css'
import { MSPolyline } from '../draw-wrappers'

L.PM.setOptIn(true)
;(() => {
  const newShapes = {
    MSPolyline,
  }

  const OriginalDraw = L.PM.Draw
  L.PM.Draw = L.PM.Draw.extend({
    initialize(map) {
      OriginalDraw.prototype.initialize.call(this, map)

      // Add our new shapes.
      this.shapes.push(...Object.keys(newShapes))
      for (const key in newShapes) {
        this[key] = new newShapes[key](this._map)
      }
    },
  })
})()
