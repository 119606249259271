import React from 'react'
import Select, { Action, NamedProps, OptionsType, OptionTypeBase, ValueType } from 'react-select'
import { InputWidth } from '../../../types/input-width-types'
import AsyncSelect from 'react-select/async'
import AsyncCreatableSelect from 'react-select/async-creatable'
import classNames from 'classnames'
import { SkeletonTextInput } from '../text-input/skeleton-text-input'

export type AsyncSelectType = 'async' | 'async-creatable'
export type SelectType = 'default' | 'creatable'

export interface TypeaheadOption {
  label: string
  value: string
  [key: string]: string
}

export interface TypeaheadOptionsProps extends NamedProps {
  options: Array<TypeaheadOption>
  fieldSize?: InputWidth
  labelledBy: string
  placeholder?: string
  isSkeleton?: boolean
  handleChange?: (option: TypeaheadOption, triggeredAction?: Action) => void
  selectedOption?: TypeaheadOption | null
  selectType?: SelectType | AsyncSelectType
  loadOptions?: (inputValue: string, callback: (options: OptionsType<OptionTypeBase>) => void) => Promise<any> | void
  defaultOptions?: TypeaheadOption[]
  defaultValue?: TypeaheadOption
  isInvalid?: boolean
  isDisabled?: boolean
  isClearable?: boolean
  isLoading?: boolean
  isSearchable?: boolean
  autoFocus?: boolean
  /*
    Portals are used to output the dropdown at the document root to avoid z-index/overflow issues.
    By default, the typeahead portal will be output in as a child of the document.body element. If
    outputting the element within a modal (which is also output via a portal at the root), it's important
    to set menuPortalTarget to null so that a portal is not used to render the dropdown (as it will appear
    behind the modal if it is).
  */
  menuPortalTarget?: HTMLElement | null
}

export function Typeahead({
  selectType = 'default',
  options,
  fieldSize = InputWidth.default,
  labelledBy,
  placeholder = 'Please select' + String.fromCharCode(8230),
  handleChange,
  selectedOption,
  isInvalid,
  isDisabled,
  isClearable = true,
  isLoading,
  isSearchable = true,
  autoFocus,
  isSkeleton,
  menuPortalTarget = document.body,
  ...props
}: TypeaheadOptionsProps) {
  const selectClasses = classNames('c-typeahead', {
    'c-typeahead--invalid': isInvalid,
    [`c-text-input--${fieldSize}`]: !!fieldSize,
  })

  const asyncOptions: { [Key: string]: any } = {}
  let SelectComponent: typeof React.Component = null!

  switch (selectType) {
    case 'async':
      SelectComponent = AsyncSelect
      asyncOptions.cacheOptions = false
      asyncOptions.loadOptions = props.loadOptions
      break
    case 'creatable':
      SelectComponent = AsyncCreatableSelect
      break
    case 'async-creatable':
      SelectComponent = AsyncCreatableSelect
      asyncOptions.cacheOptions = false
      asyncOptions.loadOptions = props.loadOptions
      break
    case 'default':
      SelectComponent = Select
      break
    default:
      return null
  }

  const onChange = (selectedOption: ValueType<OptionTypeBase, false>, triggeredAction: { action: Action }) => {
    if (triggeredAction.action === 'clear') {
      handleChange?.({} as TypeaheadOption)
    } else {
      handleChange?.(selectedOption as TypeaheadOption, triggeredAction.action)
    }
  }

  if (isLoading || isSkeleton) {
    return <SkeletonTextInput />
  }

  return (
    <SelectComponent
      {...asyncOptions}
      isClearable={isClearable}
      isLoading={isLoading}
      onChange={onChange}
      options={options}
      value={selectedOption}
      aria-labelledby={labelledBy}
      placeholder={placeholder}
      isSearchable={isSearchable}
      isDisabled={isDisabled}
      isInvalid={isInvalid}
      autoFocus={autoFocus}
      // Render dropdown at document root to avoid overflow issues:
      menuPortalTarget={menuPortalTarget}
      // Workaround for this issue: https://github.com/JedWatson/react-select/issues/4088 (update when resolved please)
      menuShouldBlockScroll={true}
      className={selectClasses}
      classNamePrefix='c-typeahead'
      // Theme overrides - see `client/src/styles/0-settings/_colors.scss`
      theme={(theme: any) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: '#4b63fb', // Selected option bg
          primary25: '#eef3fa', // Option hover bg
          primary50: '#eef3fa', // Option focus bg
          danger: '#df2e2e', // Default app error colour
        },
      })}
      autoComplete='none'
      {...props}
    />
  )
}
