/**
 * Trims whitespace from value, and returns value as a string
 * @param value String or number to format
 * @returns stringified value
 */
export function formatString(value: string | number): string {
  if (typeof value === 'string') {
    value = value.trim()
  }
  if (value === 0) value = '0'
  return `${value}` || '-'
}
