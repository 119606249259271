enum PathParts {
  PROOF_OF_PURCHASE = 'proof-of-purchase',
  LAND_HOLDING = 'land-holding',
  FERTILISER_APPLICATION = 'fertiliser-applications',
  SUBMISSION = 'submission',
}

export function getWorkflowLinks(id: string, mode: 'edit' | 'view', pathname: string) {
  if (mode === 'view' && pathname.includes(PathParts.SUBMISSION)) {
    return [
      {
        heading: 'Review submission',
        links: [{ path: pathname, label: 'Submission summary', exact: false }],
      },
    ]
  }

  const links = [
    {
      heading: 'Farm Information',
      links: [{ path: `/farm/${mode}/${id}`, label: 'Farm details and data permission', exact: true }],
    },
  ]

  if (id !== 'new') {
    links.push({
      heading: 'N-Cap Submission',
      links: [
        { path: `/farm/${mode}/${id}/proof-of-purchase`, label: 'Fertiliser purchase details', exact: false },
        { path: `/farm/${mode}/${id}/land-holding`, label: 'Contiguous land holdings', exact: false },
        { path: `/farm/${mode}/${id}/fertiliser-applications`, label: 'Fertiliser applications', exact: false },
        { path: `/farm/${mode}/${id}/submission/new`, label: 'Submission summary', exact: false },
      ],
    })
  }
  return links
}
