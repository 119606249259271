import React, { PropsWithChildren } from 'react'
import { Tag } from '../../../../common'
import { GroupContainer } from '../../../../layout'
import { NoteType } from '../../add-note'

export function Note<T, R>({ note, author, createdDate, tags, children }: PropsWithChildren<NoteType<T, R>>) {
  return (
    <div className='c-note'>
      <div className='c-note__bubble'>
        <div className='c-note__content'>{note}</div>
        {tags && (
          <GroupContainer horizontalAlignment='right'>
            {tags.map((tag, index) => (
              <Tag key={index} {...tag} />
            ))}
          </GroupContainer>
        )}
      </div>
      <div className='c-note__details'>
        <div className='c-note__author'>
          {author.firstName} {author.lastName}
        </div>
        <div className='c-note__date'>{createdDate}</div>
      </div>
      {children}
    </div>
  )
}
