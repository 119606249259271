import React from 'react'
import classNames from 'classnames'
import { Heading } from '../../common'
import { BlockContainer, BlockContainerProps } from '../block-container'
import { blockColorOption } from '../../../types/content-page-types'

export interface FloatingBlockProps extends BlockContainerProps {
  heading?: string
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6
  backgroundColorAbove?: blockColorOption
  backgroundColorBelow?: blockColorOption
}

export function FloatingBlock({
  heading,
  headingLevel = 1,
  backgroundColorAbove,
  backgroundColorBelow,
  children,
}: React.PropsWithChildren<FloatingBlockProps>) {
  const containerClass = classNames('c-floating-block', {
    'c-floating-block--blue-wash-above': !!backgroundColorAbove && backgroundColorAbove === 'blue-wash',
    'c-floating-block--blue-wash-below': !!backgroundColorBelow && backgroundColorBelow === 'blue-wash',
  })

  return (
    <BlockContainer className={containerClass}>
      <div className='c-floating-block__box'>
        {heading && (
          <Heading className='c-floating-block__heading' level={headingLevel}>
            {heading}
          </Heading>
        )}
        <div className='c-floating-block__body'>{children}</div>
      </div>
    </BlockContainer>
  )
}
