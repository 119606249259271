import React from 'react'
import { FeatureMetaConfig } from '@msaf/maps-common'
import { useCallback } from 'react'
import { DISPLAY_DATE_FORMAT, formatDate } from '@msaf/core-common'
import { DateField, DateFieldProps } from '@msaf/core-react'

export interface FeatureDateProps extends DateFieldProps {
  setValue: (value: string) => void
  config: FeatureMetaConfig
  dateFormat?: string
}

export function FeatureDate({ id, value, setValue, config, dateFormat = DISPLAY_DATE_FORMAT }: FeatureDateProps) {
  const setDate = useCallback(
    (date: Date) => {
      const dateString = formatDate(date, dateFormat)
      setValue(dateString)
    },
    [dateFormat, setValue],
  )
  return (
    <DateField
      // Accessibility
      ariaLabelledBy={id}
      // Value props
      setSelectedDate={(date: Date) => setDate(date)}
      value={value}
      dateFormat={dateFormat}
      required={config.required}
    />
  )
}
