import React, { useState } from 'react'
import Tippy from '@tippyjs/react/headless'
import classNames from 'classnames'
import { Heading } from '../heading'
import { Icon } from '../icon'

export interface TooltipProps {
  title?: string
  message: string
  ariaDescribedby?: string
  appendTo?: 'parent' | Element | ((ref: Element) => Element)
}

export function Tooltip({ title, message, ariaDescribedby, appendTo }: TooltipProps) {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <Tippy
      placement='top'
      visible={isVisible}
      onClickOutside={() => setIsVisible(false)}
      appendTo={appendTo}
      render={(attrs: Record<string, string>) => {
        const tooltipClasses = classNames('c-tooltip', {
          [`c-tooltip--${attrs['data-placement']}`]: attrs['data-placement'],
        })

        return (
          <div className={tooltipClasses}>
            <div className='c-tooltip__inner'>
              <div className='c-tooltip__content' aria-describedby={ariaDescribedby}>
                {title && (
                  <Heading className='c-tooltip__title' level={4}>
                    {title}
                  </Heading>
                )}
                <div className='c-tooltip__message'>{message}</div>
              </div>
              <button className='c-tooltip__close-btn' onClick={() => setIsVisible(false)}>
                <Icon icon='close' className='c-toast__close-icon' ariaLabel='Dismiss tooltip' />
              </button>
            </div>
            <div className='c-tooltip__arrow' data-popper-arrow></div>
          </div>
        )
      }}
    >
      <button className={'c-tooltip-trigger'} onClick={() => setIsVisible(!isVisible)}>
        <Icon icon='information-solid' ariaLabel='Show tooltip' className={'c-tooltip-trigger__icon'} />
      </button>
    </Tippy>
  )
}
