import React from 'react'
import classNames from 'classnames'

export interface CheckableFieldsProps {
  ariaLabelledBy: string
  isHorizontal?: boolean
  className?: string
}

export function CheckableFields({
  ariaLabelledBy,
  isHorizontal,
  className,
  children,
  ...props
}: React.PropsWithChildren<CheckableFieldsProps>) {
  const checkableFieldsClasses = classNames('c-checkable-fields', className, {
    'c-checkable-fields--horizontal': isHorizontal,
  })

  return (
    <div className={checkableFieldsClasses} role='group' aria-labelledby={ariaLabelledBy} {...props}>
      {children}
    </div>
  )
}
