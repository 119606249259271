import React from 'react'
import classNames from 'classnames'

export enum SortState {
  inactive = 'inactive',
  asc = 'asc',
  desc = 'desc',
}

export interface SortIconProps {
  sortState: SortState
}

export function SortIcon({ sortState = SortState.inactive }: SortIconProps) {
  const iconClasses = classNames('c-sort-icon', {
    [`c-sort-icon--${sortState}`]: sortState !== 'inactive',
  })

  return (
    <svg
      className={iconClasses}
      width='11'
      height='11'
      viewBox='0 0 11 11'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        className='c-sort-icon__arrow c-sort-icon__arrow--asc'
        d='M5.83804 1.38709L7.69776 3.57459C8.0002 3.92875 7.78804 4.53709 7.36374 4.53709H3.63978C3.21547 4.53709 2.99881 3.92875 3.30124 3.57459L5.16096 1.38709C5.1987 1.33638 5.24925 1.29492 5.30823 1.26627C5.36721 1.23763 5.43285 1.22266 5.4995 1.22266C5.56615 1.22266 5.63179 1.23763 5.69077 1.26627C5.74975 1.29492 5.8003 1.33638 5.83804 1.38709Z'
      />
      <path
        className='c-sort-icon__arrow c-sort-icon__arrow--desc'
        d='M5.16096 9.37456L3.30124 7.18706C2.99881 6.8329 3.21547 6.22873 3.63978 6.22873H7.36374C7.78804 6.22873 8.0002 6.8329 7.69776 7.18706L5.83804 9.37456C5.8003 9.42527 5.74975 9.46673 5.69077 9.49538C5.63179 9.52402 5.56615 9.53899 5.4995 9.53899C5.43285 9.53899 5.36721 9.52402 5.30823 9.49538C5.24925 9.46673 5.1987 9.42527 5.16096 9.37456Z'
      />
    </svg>
  )
}
