import React, { forwardRef, useCallback } from 'react'
import { NavLink, To } from '@msaf/router-react'
import classNames from 'classnames'

export interface LinkProps extends React.ComponentPropsWithRef<'a'> {
  path: To
  exact?: boolean
  label?: string
  activeClassName?: string
  isExternal?: boolean
}

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    { path, exact = true, label, isExternal = false, children, className = '', activeClassName = '', ...props },
    ref,
  ) => {
    const getClassNames = useCallback(
      ({ isActive }: { isActive: boolean }): string => classNames({ [className]: true, [activeClassName]: isActive }),
      [className, activeClassName],
    )

    if (children && label) throw new Error('REACTCORE-1: Exclusive props (children and label) provided for Link.')

    if (typeof path === 'string' && (path.startsWith('http://') || isExternal)) {
      return (
        <a href={path} ref={ref} className={className} {...props}>
          {children ?? label}
        </a>
      )
    } else {
      return (
        <NavLink to={path} ref={ref} exact={exact} className={getClassNames} {...props}>
          {children ?? label}
        </NavLink>
      )
    }
  },
)
