import { ContentContainer, Heading, BodyTextContainer } from '@msaf/core-react'

export default function PermissionDenied() {
  return (
    <ContentContainer>
      <BodyTextContainer>
        <Heading level={1}>Permission denied</Heading>
        <p>You do not have the required permissions to access this page or perform some actions on this page.</p>
        <p>This can be due to the information you are trying to access not being published yet.</p>
        <p>
          Please <a href='/contact-us'>contact us</a> if you think you should have the required permissions on this
          page.
        </p>
      </BodyTextContainer>
    </ContentContainer>
  )
}
