import React, { createContext, useContext, PropsWithChildren, Dispatch, SetStateAction, useState } from 'react'
import { TabItemProps } from '../../components/navigation/tab-nav'

type PageState = {
  backLink?: {
    path: string
    label: string
  }
  heading?: string
  isFullScreen?: boolean
  headerTabs?: Array<TabItemProps>
  tags?: Array<string>
}

const PageContext = createContext<
  { pageState: PageState; setPageState: Dispatch<SetStateAction<PageState>> } | undefined
>(undefined)

export function PageProvider({ children }: PropsWithChildren<{}>) {
  const [pageState, setPageState] = useState<PageState>({})
  const value = { pageState, setPageState }
  return <PageContext.Provider value={value}>{children}</PageContext.Provider>
}

function usePageState() {
  const context = useContext(PageContext)
  if (context === undefined) {
    throw new Error('usePageState must be used within a PageProvider')
  }
  return context
}

export { usePageState }
