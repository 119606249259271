import { formatDate } from '@msaf/core-common'
import {
  Button,
  Card,
  ColumnHeader,
  ConfirmTransition,
  HeadingRow,
  InlineNotification,
  RecordFooter,
  Table,
  TableAction,
  TableSortByProps,
} from '@msaf/core-react'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ContentContainer } from '../../../../../components/content-container'
import { ERROR_MESSAGES } from '../../../../../components/errors/messages'
import FormEditButtons from '../../../../../components/form-edit-buttons'
import { LoadingPage } from '../../../../../components/loading-page'
import { DEFAULT_TABLE_RESULTS_PER_PAGE, DISPLAY_DATE_FORMAT } from '../../../../../constants'
import { useGetFarmData, useSaveFarmData } from '../../../../../hooks/useApi'
import { useBackLink } from '../../../../../hooks/useBacklink'
import useEditForm from '../../../../../hooks/useEditForm'
import { usePageHeading } from '../../../../../hooks/usePageHeading'
import useToastMessage from '../../../../../hooks/useToastMessage'
import { useUserPermission } from '../../../../../services/permissions'
import { HttpVerb } from '../../../../../types/api-types'
import { FertiliserApplication } from '../../../../../types/form-types'
import { UserPermissions } from '../../../../../types/permissions'
import { RouteParams } from '../../../../../types/route'

export const FarmFertiliserApplication = () => {
  const { id } = useParams<RouteParams>()
  usePageHeading('Farm fertiliser applications')
  useBackLink({ label: 'Dashboard', path: '/dashboard' })
  const [canViewFarmOperations, isLoadingPermissions] = useUserPermission(UserPermissions.MANAGE_FARM)
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const url = `farm-operation/${id}/fertiliser-application`
  const stateQueryResult = useGetFarmData(url)
  const saveMutationResult = useSaveFarmData(url, HttpVerb.POST)
  const navigate = useNavigate()
  const [fields, setFields] = useState<Record<string, string | number | boolean>[] | null>([])

  const {
    fields: contiguousLandHoldings,
    isLoading,
    isError,
    isSaved,
  } = useEditForm<Array<FertiliserApplication>>({
    stateQueryResult,
    saveMutationResult,
    setIsDirty,
  })

  useEffect(() => {
    if (contiguousLandHoldings.length) {
      const tableData = contiguousLandHoldings.flat().map((item) => {
        return { ...item, appliedDisplayDate: formatDate(item?.dateTimeApplied, DISPLAY_DATE_FORMAT) }
      })
      setFields(tableData as unknown as Record<string, string | number | boolean>[])
    }
  }, [contiguousLandHoldings?.length, contiguousLandHoldings])

  useToastMessage({
    isError: isError,
    isSuccess: isSaved,
    errorMessage: ERROR_MESSAGES.FETCH_FAILED,
  })

  const [currentPage, setCurrentpage] = useState(1)
  const defaultSortBy: TableSortByProps = { orderColumn: 'landName', orderDirection: 'desc' }
  const [sortBy, setSortBy] = useState<TableSortByProps>()

  const setPage = useCallback(
    (page: number) => {
      if (fields && page >= 1 && page <= fields?.length) {
        setCurrentpage(page)
      }
    },
    [fields],
  )

  const rowClickAction: TableAction = {
    label: '',
    type: 'transitionAction',
    // This is a psuedo way to disable the onclick handler - navigate to same place and set cursor as pointer using CSS
    args: [{ constant: `farm/edit/${id}/fertiliser-applications` }, { elementKey: 'fertiliserApplicationId' }],
  }

  const setSortColumn = (orderColumn: string, orderDirection: 'desc' | 'asc') => {
    setSortBy({ orderColumn, orderDirection })
  }

  const actions: Array<TableAction> = [
    {
      label: 'Edit',
      type: 'transitionAction',
      args: [{ constant: `farm/edit/${id}/fertiliser-applications` }, { elementKey: 'fertiliserApplicationId' }],
    },
  ]

  const columnHeaders: Array<ColumnHeader> = [
    {
      elementKey: 'contiguousLandHoldingId',
      columnHeading: 'Contiguous land holding id',
      viewColumn: 'contiguousLandHoldingId',
      type: 'hidden',
    },
    {
      elementKey: 'fertiliserApplicationId',
      columnHeading: 'Fertiliser application id',
      viewColumn: 'fertiliserApplicationId',
      type: 'hidden',
    },
    {
      elementKey: 'landName',
      columnHeading: 'Land name',
      viewColumn: 'landName',
      sortable: true,
      type: 'text',
    },
    {
      elementKey: 'fertiliserType',
      columnHeading: 'Fertiliser type',
      viewColumn: 'fertiliserType',
      sortable: true,
      type: 'text',
    },
    {
      elementKey: 'fertiliserManufacturer',
      columnHeading: 'Fertiliser manufacturer',
      viewColumn: 'fertiliserManufacturer',
      sortable: true,
      type: 'text',
    },
    {
      elementKey: 'nitrogenPercentRate',
      columnHeading: 'Nitrogen %',
      viewColumn: 'nitrogenPercentRate',
      sortable: true,
      type: 'text',
    },
    {
      elementKey: 'appliedDisplayDate',
      columnHeading: 'Application date',
      viewColumn: 'appliedDisplayDate',
      sortable: true,
      type: 'tag',
    },
    {
      elementKey: 'actions',
      columnHeading: 'Actions',
      viewColumn: 'actions',
      type: 'actions-no-data',
      actions,
    },
  ]

  if (isLoading || isLoadingPermissions) return <LoadingPage />
  return (
    <>
      <ConfirmTransition
        when={isDirty}
        contentLabel='Unsaved changes confirmation dialog'
        actionConfig={[
          {
            key: 'continue',
            type: 'cancel',
            buttonStyle: 'primary',
            label: 'Continue editing',
          },
          {
            key: 'discard',
            type: 'confirm',
            buttonStyle: 'text-action',
            label: 'Discard changes',
          },
        ]}
      />
      <ContentContainer>
        {canViewFarmOperations && (
          <HeadingRow headingText='Fertiliser applications' headingLevel={2} isSkeleton={isLoading}>
            <Button
              iconAlignment='left'
              icon='add'
              label='Add new'
              onClick={() => navigate(`/farm/edit/${id}/fertiliser-applications/new`)}
            />
          </HeadingRow>
        )}

        {fields?.length ? (
          <>
            <Card isFullWidth>
              {/* TODO confirm table sorting operation */}
              <div className='c-remove-table-cursor'>
                <Table
                  onRowClick={rowClickAction}
                  setSortColumn={setSortColumn}
                  setPage={setPage}
                  defaultSortBy={sortBy ?? defaultSortBy}
                  totalPages={Math.ceil(fields.length / DEFAULT_TABLE_RESULTS_PER_PAGE)}
                  currentPage={currentPage}
                  pageSize={DEFAULT_TABLE_RESULTS_PER_PAGE}
                  totalResults={fields.length}
                  isSkeleton={isLoading}
                  columnHeaders={columnHeaders}
                  tableData={fields}
                  noResultsMessage='Please add a fertiliser application to your farm to continue'
                />
              </div>
            </Card>
          </>
        ) : (
          <InlineNotification isDismissable={false}>
            Please add a fertiliser application to your farm to continue
          </InlineNotification>
        )}
        <RecordFooter>
          <FormEditButtons
            actions={[
              {
                label: 'Save and continue',
                onClick: () => navigate(`/farm/edit/${id}/submission/new`),
              },
              {
                label: 'Cancel',
                onClick: () => navigate('/dashboard'),
                buttonStyle: 'text-action',
              },
            ]}
            isLoading={isLoading}
          />
        </RecordFooter>
      </ContentContainer>
    </>
  )
}
