export const MARKDOWN_CONTENT = `
---

# What is the N percentage in Ballance and Ravensdown products?

You can view and download the latest product list for Ballance at <https://ballance.co.nz/Fertiliser-Products/c/All-Product-Ranges>

You can view and download the latest product list for Ravensdown at <https://www.ravensdown.co.nz/services/product-availability/price-list> in the Fertiliser and Lime section.

The percentage of synthetic N for each product is stated, you can use that for your N-Cap fertiliser records.

---

# Spreadsheet

A spreadsheet can be downloaded by clicking the button here. Follow the steps in the Instructions
tab to enter data into the correct land use tab. You can then use those entries and the summary
information to help complete your synthetic nitrogen use record in this N-Cap tool.

[Download now](https://ncap-assets.s3.ap-southeast-2.amazonaws.com/n-cap-download-spreadsheet.xlsx)

---

# Help and guidance

From 1 July 2021, a cap on the use of synthetic nitrogen fertiliser
applies on any contiguous parcel of pastoral land. The cap has been set
at 190 kg per hectare per year.

The regulations require owners of dairy platforms to provide annual data
on synthetic nitrogen use to their regional council. The reporting
period is the 12 months to the end of June each year.

---

# About the cap

The cap applies to all grazed land (including grass and annual forage
crop) except for the grazing of livestock on the stubble of arable
crops, with the following conditions.

-   Each hectare of grass pasture has a limit of 190 kg/ha/yr - no area
    of pasture can exceed this level without a resource consent.

-   On land planted with fodder crops, the limit of 190 kg/ha/yr may be
    exceeded to meet the needs of some types of plants, but the average
    across the pastoral system must not exceed 190.

Farm managers need to record or retain information about the following
from 1 July 2021:

-   The area of land in pastoral land use, including the parts of that
    land used to grow annual forage crops, and in other uses.

-   The receipts of purchased synthetic nitrogen fertiliser.

-   The types of synthetic nitrogen fertiliser applied and, for each
    type, the percentage of the nitrogen component by dry weight.

-   The rate at which each type of synthetic nitrogen fertiliser was
    applied to the land in pastoral land use and, within that land, to
    the land used to grow annual forage crops and to other land.

-   The dates on which the synthetic nitrogen fertiliser was applied.

-   The reporting period is the 12 months to the end of June each year.

---

# Data usage

You are providing data in this N-Cap webform for the purposes of compliance with the
Resource Management (National Environmental Standards for Freshwater) Regulations 2020
Subpart 4—Application of synthetic nitrogen fertiliser to pastoral land regulations.

Environment Canterbury, as the national host of the N-Cap Reporting Tool will be the initial
recipient of the data which it will save in a secure datastore. Each regional and unitary
council will receive an automatic report of data for its region only from the datastore for
monitoring of compliance. Aggregated data, without any contact details, will be automatically
sent from the datastore to the Ministry for the Environment for compliance reporting by
councils. Regional Software Holdings Ltd, as system administrator, will have access to
aggregated data, without contact details, for the purposes of monitoring system activity.

This data, similar to all compliance data, can be requested under the Local Government
Official Information and Meetings Act 1987 (LGOIMA). Each request for data is considered
on its merits by a council to determine if there are grounds to withhold it under LGOIMA, and
with careful consideration of the principles of the Privacy Act 2020.

---

# Contiguous Landholding

A contiguous landholding is any block of joined-up land belonging to a
farming operation. Any blocks separated from each other by land
(excluding road, rail, stream or river) that does not belong to the farm
operation is considered to be a separate contiguous landholding.

### Identifying contiguous landholdings

A contiguous landholding is any block of connected land belonging to a
farming operation. If a farm has several unconnected blocks, separated
by land not belonging to the farm, each block is a separate contiguous
landholding.

This is important because the nitrogen application rate must be
calculated and managed separately for each contiguous landholding. If a
farm has several contiguous landholdings, the nitrogen application rate
cannot be averaged across them.

The area of a contiguous landholding may or may not be the same as the
title area. It can be in several titles provided that the land is
adjoining, is managed as part of a single farming operation, and can be
encompassed by a single perimeter line on a map.

A contiguous landholding may contain one paddock or many paddocks and
may have multiple land uses. It may be crossed by streams, roads or
railway lines but, as long as the adjoining land on either side of these
corridors belongs to the same farming operation, it remains a single
contiguous landholding. Figure 2 illustrates this with three farms in
Canterbury.

Figure 2: Contiguous landholdings

![Contiguous landholding example](/landholding-example.jpeg)

The **yellow farm** has one continuous block of land. Although it is
crossed by roads and streams, its paddocks adjoin both sides of those
roads and streams and so form one contiguous landholding (labelled A).
The **blue farm** has two contiguous landholdings (A and B) separated by
land not belonging to the farm. Each comprises a block of adjoining
paddocks, some of which straddle roads. The **red farm** has four
contiguous landholdings - three small ones (A, C and D) and one large
one (B) with multiple adjoining paddocks straddling roads.

---

# Land Categories

Your contiguous landholding is separated into different land categories,
the diagram below shows the different land categories and the
relationships between them. And definitions in the table below.

The key delineation in the land categories is whether grazing occurs on
the land and the fertilisers applied to promote growth for grazing in
situ versus harvested growth.

![Land categories example](/land-categories.jpeg)

**Grazed Land:** N-Cap specific term referencing the combination of all
grazed land categories used in the Synthetic Nitrogen Calculation.
(a.k.a 'Pastoral Use Land' in the regulations).

**Grazed Forage Crops:** Means a crop, other than pasture, whose primary use
is to be grazed as it grows. (a.k.a. Annual Forage Crops' in the
regulations)

**Grazed Non-forage Land:** Combination of Grazed Pasture and Grazed Harvest
Crops. (a.k.a. 'Other Land' in the regulations)

**Grazed Harvest Crops:** Any intermittently grazed land whose primary use
is plant propagation for harvest eg arable, seed crops, horticultural,
hay, silage, 'cut and carry', forestry. (a subset of Pastoral Use
Land, not elsewhere specified)

**Grazed Pasture:** Grassland whose primary use is to be grazed as it grows.
(a.k.a. 'Pasture' in the regulations)

---

# N-Cap Application Rates:

**Grazed Land N-Cap Application Rate:** The total N (kg) of Synthetic
Nitrogen Fertiliser - N-Cap applied to all Grazed Land divided by the
total area (ha) of that land over the reporting period (previous 12
months ending 30 June of reported year).

**Grazed Forage Crops Land N-Cap Application Rate:** The total N (kg) of
Synthetic Nitrogen Fertiliser - N-Cap applied to Grazed Forage Crop
divided by the total area (ha) of that land over the reporting period
(previous 12 months ending 30 June of reported year).

**Grazed Non-forage Land N-Cap Application Rate:** The total N (kg) of
Synthetic Nitrogen Fertiliser - N-Cap applied to Grazed Non-forage Land
divided by the total area (ha) of that Land over the reporting period
(Previous 12 months ending 30 June of reported year).

**Un-grazed Land N-Cap Application Rate:** The total N (kg) of Synthetic
Nitrogen Fertiliser - N-Cap applied to Un-grazed Land divided by the
total area (ha) of that land over the reporting period (Previous 12
months ending 30 June of reported year).

---

# Other terms:

**Farm operation:** A farm operation is managed as a single operating unit (managed under same financial accounts) under responsibility of a Farm Operator, that is engaged in agricultural activity.

**Dairy Platform:** An area of landholding where dairy animals are being milked on and are kept during the milking season.

**Synthetic Nitrogen Fertiliser – N-Cap:** Means the person with ultimate responsibility for the operation of a farm (Note: person includes the Crown, a corporation sole, and also a body of persons, whether corporate or unincorporate - RMA definitions)
- (a) means any substance (whether solid or liquid) that—
    - (i) is more than 5.0% nitrogen by weight; and
    - (ii) is applied to any plant or soil as a source of nitrogen nutrition for plants; and
- (b) includes any manufactured urea, diammonium phosphate, or sulphate of ammonia to which paragraph (a) applies; but
- (c) does not include a compost, soil treatment, or fertiliser that—
    - (i) is wholly derived from plant or animal waste or residue; and
    - (ii) is minimally processed (for example, by being composted, mixed, dried, and pelleted).

**Biological Nitrogen Fertilisers:** These are any solid or liquid substance applied to plants or land whose nitrogen comes from animals or plants. Biological nitrogen fertiliser may include matter that has been minimally processed by being composted, mixed, liquified, dried, or pelleted, but does not include manufactured nitrogen. Dairy effluent that has not had any manufactured nitrogen added to it is considered a source of biological nitrogen, so is excluded from the nitrogen cap.

**Mixed Fertilisers:** Where manufactured and biological nitrogen fertilisers are mixed together, and their combined nitrogen content comes to more than 5.0% of their dry weight, they become synthetic nitrogen fertilisers and are covered by the nitrogen cap.

---

# Ministry for the Environment Information:

The following links provide information from The Ministry for the
Environment.

**NESF:**

<https://environment.govt.nz/acts-and-regulations/regulations/national-environmental-standards-for-freshwater/>

**Regulations:**

<https://www.legislation.govt.nz/regulation/public/2020/0174/latest/LMS364252.html>

**Guidance material:**

<https://environment.govt.nz/acts-and-regulations/freshwater-implementation-guidance/agriculture-and-horticulture/synthetic-nitrogen-fertiliser-cap-in-place-from-1-july/>


---


# NZ Business Number

[Link to NZBN website](https://www.nzbn.govt.nz)

## The NZBN is a unique identifier for your business

The New Zealand Business Number (NZBN) is a globally unique identifier,
available to every Kiwi business. Whether you're self-employed or a
major corporation, there's an NZBN for you.

Your NZBN makes doing business faster and easier because it links to
your core business information (known as [Primary Business
Data](https://www.nzbn.govt.nz/whats-an-nzbn/how-primary-business-data-works/)).
This includes things such as your trading name, phone number or email.

That means anyone you do business or interact with (such as suppliers or
customers) can easily, quickly and digitally access all the details they
need about your business in order to work with you. You won't have to
keep repeating this information or updating it in multiple places. With
an NZBN your business becomes part of a single, searchable register for
all Kiwi businesses.

The New Zealand Business Number (NZBN) Act came into effect in 2016,
enabling all businesses to be allocated or register for an NZBN. That
includes government agencies, who are working to implement the NZBN into
their systems and processes. The flow-on effect will be that agencies
can get core business information from the NZBN Register, eliminating
the need for businesses to provide and update it.

I don't have an NZBN (NZ Business number) what do I do?

-   If you are a company, you would have automatically assigned a
    number. You can search by your company name to find your number [here](https://www.nzbn.govt.nz/).

-   If you do not have an NZBN please follow this [link](https://www.nzbn.govt.nz/get-an-nzbn/applying-for-an-nzbn/)
    or click **Get an NZBN** on the website

-   Click on the relevant link to start your application

-   Applying for an NZBN is free. Until your NZBN has been issued you can enter ‘0000’ into the NZBN field
`
