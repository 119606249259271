import React, { KeyboardEvent, useRef, useState } from 'react'
import { InputWidth } from '../../../../../types'
import { TextInput } from '../../../../forms'
import { FormField } from '../../../../forms/form-field'
import { Button } from '../../../button'

export interface GoToControlProps {
  setPage: (page: number) => void
  isSkeleton?: boolean
}

export function GoToControl({ setPage, isSkeleton }: GoToControlProps) {
  const [inputValue, setInputValue] = useState<string>('')
  const buttonEl = useRef<HTMLButtonElement>(null)

  const updatePageNumber = () => {
    const pageNum = parseInt(inputValue)
    if (!isNaN(pageNum)) {
      setPage(pageNum)
    }
  }

  // On enter within input, go to page entered
  // Note: due to internal behaviour of TextInput component, we have to force a blur to get current state of input
  // TODO: make ^ less gross?
  const handleKeyboardSubmit = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') return
    buttonEl.current?.focus() // force blur event to update internal state of input
    buttonEl.current?.click()
  }

  return (
    <FormField label='Go to page' htmlFor='go-to-control' hasInputWithButton hasInlineLabel isSkeleton={isSkeleton}>
      <TextInput
        type='text'
        fieldSize={InputWidth.tiny}
        id='go-to-control'
        defaultValue={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        isSkeleton={isSkeleton}
        onKeyDown={handleKeyboardSubmit}
      />
      <Button ref={buttonEl} label='Go' buttonStyle='secondary' onClick={updatePageNumber} isSkeleton={isSkeleton} />
    </FormField>
  )
}
