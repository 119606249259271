import React from 'react'
import { Map } from 'leaflet'
import { MapCreationOptions } from '@msaf/maps-common'
import { LeafletMap } from '../../../leaflet-map'

export interface MapAreaProps {
  mapOptions: MapCreationOptions
  addInitialLayersAndMarkers?: (map: Map) => void
  setMap?: (map?: Map) => void
}

export function MapArea({ mapOptions, addInitialLayersAndMarkers, setMap }: MapAreaProps) {
  return <LeafletMap options={mapOptions} setMap={setMap} addInitialLayersAndMarkers={addInitialLayersAndMarkers} />
}
