import React from 'react'
import classNames from 'classnames'
import { MarkerIconProps } from '../index'

export interface InitialsIconProps extends MarkerIconProps {
  initials: string
  customType?: string
  isLarge?: boolean
}

export function InitialsMarkerIcon({ initials, customType, markerStyle, isLarge }: InitialsIconProps) {
  const classes = classNames('c-marker-icon', {
    [`c-marker-icon--${customType}`]: !!customType,
    [`c-marker-icon--${markerStyle}`]: !!markerStyle,
    'c-marker-icon--large': isLarge,
  })

  return <span className={classes}>{initials}</span>
}
