import { Heading } from '@msaf/core-react'

export interface AnchorLinkProps {
  label: string
  anchorLink: string
}

export interface TableOfContentsSectionProps {
  heading?: string
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6
  items: AnchorLinkProps[]
}

export function TableOfContentsSection({ heading, headingLevel, items }: TableOfContentsSectionProps) {
  return items.length ? (
    <div className='c-table-of-contents__section'>
      {heading && headingLevel && (
        <Heading className='c-table-of-contents__section-heading' level={headingLevel}>
          {heading}
        </Heading>
      )}
      <ul className='c-table-of-contents__section-list'>
        {items.map(({ label, anchorLink }) => (
          <li className='c-table-of-contents__item' key={`anchor-${label}`}>
            <a className='c-table-of-contents__link' href={anchorLink}>
              {label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  ) : null
}
