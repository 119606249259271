import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'

export interface TabProps {
  label: string
  tabContent: React.ReactNode
  isNewSection?: boolean
}

export interface TabbedContentProps {
  tabs: Array<TabProps>
  tabsHeading?: React.ReactNode
  ariaLabel: string
  borderBottomStyle?: 'none' | 'solid' | 'shadow'
  verticalSpacing?: 'default' | 'small' | 'none'
  includeHorizontalPadding?: boolean
  activeTab?: number
  onTabChange?: (index: number) => void
}

/**
 * The tabbed content should:
 * 1. Allow users to share a particular tab as a url
 *    TODO: Consider adding the activeTab state to the url and update the component logic to identify the active tab from the url query param.
 * 2. Allow sharing of state between tabs
 *    The responsibility of sharing the state between the tabs should lie with the parent component.
 * 3. Allow different configurations for each tab content
 *    The responsibility of loading different configurations should lie with the individual tab components.
 * @param param0
 * @returns
 */
export function TabbedContent({
  tabs,
  tabsHeading,
  ariaLabel,
  borderBottomStyle,
  verticalSpacing = 'default',
  includeHorizontalPadding,
  activeTab = 0,
  onTabChange,
}: TabbedContentProps) {
  const [tabState, setTabState] = useState({ activeTab, prevActiveTab: 0 })

  useEffect(() => {
    setTabState((prevTabState) => ({
      activeTab,
      prevActiveTab: prevTabState.activeTab,
    }))
  }, [activeTab])

  const tabbedContentClasses = classNames('c-tabbed-content', {
    [`c-tabbed-content--vert-spacing-${verticalSpacing}`]: verticalSpacing !== 'default',
  })

  const tabsClasses = classNames('c-tabs', {
    'c-tabs--shadow-style': borderBottomStyle === 'shadow',
    'c-tabs--bordered-style': borderBottomStyle === 'solid',
  })

  const tabContentPaneClasses = classNames('c-tabbed-content__panes', {
    'c-tabbed-content__panes--pad-horizontal': includeHorizontalPadding,
  })

  const handleTabChange = (index: number) => {
    setTabState({ activeTab: index, prevActiveTab: tabState.activeTab })
    onTabChange && onTabChange(index)
  }

  return (
    <div className={tabbedContentClasses}>
      {tabsHeading && <div className='c-tabbed-content__heading'>{tabsHeading}</div>}
      <nav className={tabsClasses} aria-label={ariaLabel}>
        <ul className='c-tabs__items'>
          {tabs.map((tab, index) => {
            const tabLinkClasses = classNames('c-tabs__link', {
              'c-tabs__link--active': index === tabState.activeTab,
            })

            const tabItemClasses = classNames('c-tabs__item', {
              'c-tabs__item--new-section': tab.isNewSection,
            })

            return (
              <li key={tab.label} className={tabItemClasses}>
                <button className={tabLinkClasses} onClick={() => handleTabChange(index)}>
                  <span className='c-tabs__link-label'>{tab.label}</span>
                </button>
              </li>
            )
          })}
        </ul>
      </nav>

      <div className={tabContentPaneClasses}>
        {tabs.map((tab, index) => {
          if (index === tabState.activeTab) {
            // Determine animation direction based on whether new tab is to left or right of old one:
            const initialX = tabState.activeTab > tabState.prevActiveTab ? 20 : -20

            return (
              <AnimatePresence key={tab.label}>
                <motion.div
                  initial={{ x: initialX, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: initialX * -1, opacity: 0 }}
                >
                  {tab.tabContent}
                </motion.div>
              </AnimatePresence>
            )
          } else {
            return undefined
          }
        })}
      </div>
    </div>
  )
}
