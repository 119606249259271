import React from 'react'

interface ConditonalWrapperProps {
  children: React.ReactElement
  condition?: boolean
  wrapper: (children: React.ReactElement) => JSX.Element
}

// https://gist.github.com/kitze/23d82bb9eb0baabfd03a6a720b1d637f
export function ConditionalCardWrapper({ condition, wrapper, children }: ConditonalWrapperProps) {
  return condition ? wrapper(children) : children
}
