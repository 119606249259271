import React from 'react'
import classNames from 'classnames'
import { Icon } from '../icon'

export interface LoadingIndicatorProps {
  hideTextOnMobile?: boolean
}

export function LoadingIndicator({
  hideTextOnMobile = true,
  children,
}: React.PropsWithChildren<LoadingIndicatorProps>) {
  const containerClasses = classNames('c-loading-indicator', {
    'c-loading-indicator--show-text-on-mob': !hideTextOnMobile,
  })

  return (
    <div className={containerClasses}>
      <Icon className='c-loading-indicator__icon' icon='spinner' ariaHidden />
      <div className='c-loading-indicator__text'>{children}</div>
    </div>
  )
}
