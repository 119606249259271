import { usePageState } from '@msaf/core-react'
import { useEffect } from 'react'

export const useBackLink = ({ label, path }: { label: string; path: string }) => {
  const { setPageState } = usePageState()

  useEffect(() => {
    setPageState((prevState) => ({ ...prevState, backLink: { label, path } }))
    return () => setPageState((prevState) => ({ ...prevState, backLink: undefined }))
  }, [setPageState, label, path])
}
