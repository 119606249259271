import React, { ReactElement } from 'react'
import classNames from 'classnames'
import { SkeletonPagination } from './skeleton-pagination'
import { NUM_VISIBLE_PAGINATION_LINKS } from '../../../../../constants'
import { Button } from '../../../button'

export interface PaginationProps {
  currentPage: number
  maxPageLinksVisible?: number
  setPage: (page: number) => void
  totalResults: number
  totalPages: number
  resultNameSingular?: string
  resultNamePlural?: string
  showResultsCount?: boolean
  isSkeleton?: boolean
}

export function Pagination({
  currentPage,
  maxPageLinksVisible = NUM_VISIBLE_PAGINATION_LINKS,
  setPage,
  totalResults,
  totalPages,
  showResultsCount = true,
  resultNameSingular = 'result',
  resultNamePlural = 'results',
  isSkeleton = false,
}: PaginationProps) {
  // If no results or only one page and no result count to output, omit component:
  if (!totalResults || (totalPages === 1 && !showResultsCount)) {
    return null
  }

  // Work out first page link visible based on current page position
  const pageLinksMidPoint = Math.ceil(maxPageLinksVisible / 2)
  let firstVisiblePageLink = currentPage - pageLinksMidPoint
  if (currentPage <= pageLinksMidPoint) {
    firstVisiblePageLink = 0
  } else if (currentPage > totalPages - pageLinksMidPoint) {
    firstVisiblePageLink = totalPages - maxPageLinksVisible
    if (firstVisiblePageLink < 0) firstVisiblePageLink = 0
  }

  // Create visible page link items based on current page position
  const numPageLinks = totalPages > maxPageLinksVisible ? maxPageLinksVisible : totalPages
  const paginationLinks: Array<ReactElement> = []
  for (let i = 1; i <= numPageLinks; i++) {
    const pageNum = firstVisiblePageLink + i
    const pageItemClasses = classNames('c-pagination__page-item', {
      'c-pagination__page-item--active': pageNum === currentPage,
    })
    const btnClasses = classNames('c-pagination__btn', {
      'c-pagination__btn--active': pageNum === currentPage,
    })
    paginationLinks.push(
      <li key={`pagination-item-${i}`} className={pageItemClasses}>
        <button type='button' className={btnClasses} onClick={() => setPage(pageNum)}>
          {pageNum}
        </button>
      </li>,
    )
  }

  return isSkeleton ? (
    <SkeletonPagination numPageLinks={maxPageLinksVisible} />
  ) : (
    <div className='c-pagination'>
      {totalPages > 1 && (
        <ul className='c-pagination__page-list'>
          <li className='c-pagination__page-item c-pagination__page-item--pager'>
            <Button
              type='button'
              buttonStyle='secondary'
              icon='chevron-left'
              iconAriaLabel='Previous page'
              onClick={() => setPage(currentPage - 1)}
              isDisabled={currentPage === 1}
            />
          </li>
          {paginationLinks.map((pageButton) => pageButton)}
          <li className='c-pagination__page-item c-pagination__page-item--pager'>
            <Button
              type='button'
              buttonStyle='secondary'
              icon='chevron-right'
              iconAriaLabel='Next page'
              onClick={() => setPage(currentPage + 1)}
              isDisabled={currentPage === totalPages}
            />
          </li>
        </ul>
      )}
      {showResultsCount && (
        <div className='c-pagination__results-count'>
          {totalResults} {totalResults === 1 ? resultNameSingular : resultNamePlural}
        </div>
      )}
    </div>
  )
}
