/* eslint-disable @typescript-eslint/no-empty-function */
import {
  ButtonsContainer,
  Heading,
  Icon,
  InlineNotification,
  Modal,
  TextInput,
  FormField,
  Button,
} from '@msaf/core-react'
import { useState } from 'react'
import axios from 'axios'
import { getAPIEndpoint } from '../services/api'

interface RegisterModalInterface {
  isOpen: boolean
  closeModal: () => void
}

interface UserData {
  email?: string
  firstName?: string
  lastName?: string
}

type UserDataValidationErrors = {
  [Property in keyof UserData]?: Array<string>
}

export default function RegisterModal({ isOpen, closeModal }: RegisterModalInterface) {
  const [userData, setUserData] = useState<UserData>()
  const [isSaved, setIsSaved] = useState<boolean>()
  const [isLoading, setIsLoading] = useState<boolean>()
  const [validationErrors, setValidationErrors] = useState<UserDataValidationErrors>()

  const handleCancel = () => {
    closeModal()
    setUserData(undefined)
    setValidationErrors(undefined)
    setIsSaved(false)
    setIsLoading(false)
  }

  const setValue = (key: keyof UserData, value: string) => {
    const newValue: UserData = {}
    // eslint-disable-next-line security/detect-object-injection
    newValue[key] = value
    setUserData({ ...userData, ...newValue })
  }

  const submitForm = async () => {
    try {
      setIsLoading(true)
      const response = await axios.post(getAPIEndpoint('/user/register'), userData)
      if (response.status === 201) {
        setIsSaved(true)
        setUserData(undefined)
      } else if (response.status === 400) {
        const result = await response.data
        throw result
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setValidationErrors(err?.response.data as UserDataValidationErrors)
    } finally {
      setIsLoading(false)
    }
  }

  const SuccessComponent = () => (
    <>
      <ButtonsContainer containerStyle='right'>
        <Icon onClick={handleCancel} icon='close' ariaLabel='Close modal' />
      </ButtonsContainer>
      <InlineNotification messageType='success' isDismissable={false}>
        <Heading level={4}>Success</Heading>
        <p>
          You have been successfully added to N-Cap.
          <br />
          Please check your email for a link to complete the registration process.
        </p>

        <p>Thank you.</p>
        <em>
          Note: the link will expire in 24 hours. If this happens you will need to restart the registration process.
        </em>
      </InlineNotification>
    </>
  )

  return (
    <>
      <Modal isOpen={isOpen} contentLabel='Register' onRequestClose={handleCancel}>
        {isSaved ? (
          <SuccessComponent />
        ) : (
          <>
            <Heading isSkeleton={isLoading} level={1}>
              Register
            </Heading>
            <FormField
              isSkeleton={isLoading}
              label='Email address'
              labelId='email'
              isRequired
              validationMessages={validationErrors?.email}
            >
              <TextInput
                autoFocus={true}
                isSkeleton={isLoading}
                id='email'
                value={userData?.email}
                isInvalid={!!validationErrors?.email}
                onChange={(e) => setValue('email', e.target.value)}
              />
            </FormField>
            <FormField
              label='First name'
              labelId='firstName'
              isRequired
              validationMessages={validationErrors?.firstName}
              isSkeleton={isLoading}
            >
              <TextInput
                isInvalid={!!validationErrors?.firstName}
                isSkeleton={isLoading}
                id='firstName'
                value={userData?.firstName}
                onChange={(e) => setValue('firstName', e.target.value)}
              />
            </FormField>
            <FormField
              isSkeleton={isLoading}
              labelId='lastName'
              label='Last name'
              isRequired
              validationMessages={validationErrors?.lastName}
            >
              <TextInput
                isSkeleton={isLoading}
                id='lastName'
                value={userData?.lastName}
                isInvalid={!!validationErrors?.lastName}
                onChange={(e) => setValue('lastName', e.target.value)}
              />
            </FormField>
            <ButtonsContainer containerStyle='right'>
              <Button isSkeleton={isLoading} onClick={submitForm} label='Submit' />
              <Button isSkeleton={isLoading} onClick={handleCancel} label='Cancel' buttonStyle='secondary' />
            </ButtonsContainer>
          </>
        )}
      </Modal>
    </>
  )
}
