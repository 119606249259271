export const ERROR_MESSAGES = Object.freeze({
  VALIDATION_ERROR: 'One or more fields in the form have invalid or empty values.',
  SAVE_FAILED: 'Could not save the form. Please validate the form fields and retry.',
  DELETE_FAILED: 'Could not delete the record. Please refesh the page and try again.',
  PUBLISH_FAILED: 'Could not publish the farm. Please refesh the page and try again.',
  SUBMISSION_ERROR_FETCH_FAILED: 'Could not fetch submission errors. Please refesh the page and try again.',
  SUBMIT_FOR_APPROVAL_FAILED: 'Could not submit for approval. Please refesh the page and try again.',
  FETCH_FAILED: 'Could not fetch the required data. Please refesh the page and try again.',
  UPLOAD_FAILED: 'Could not upload the file. Please refresh the page and try again.',
  CREATE_FARM_PERMISSION_ERROR: 'You do not have permission to create a farm',
})
