import React from 'react'
import { SkeletonCheckableField } from './skeleton-checkable-field'

export interface CheckableFieldTypeProps extends React.ComponentPropsWithoutRef<'input'> {
  type: 'radio' | 'checkbox'
}

export interface CheckableFieldProps extends CheckableFieldTypeProps {
  id: string
  name: string
  label: string
  describedBy?: string | undefined
  isRequired?: boolean
  isDisabled?: boolean
  value?: string
  isSkeleton?: boolean
}

export const CheckableField = React.forwardRef<HTMLInputElement, CheckableFieldProps>(
  (
    {
      id,
      name,
      label,
      type,
      describedBy = `${id}--help-text ${id}--validation-msg`,
      isRequired,
      isDisabled,
      value,
      isSkeleton,
      disabled,
      ...props
    }: CheckableFieldProps,
    ref,
  ) => {
    return (
      <div className='c-checkable-field'>
        {isSkeleton && <SkeletonCheckableField type={type} />}
        {!isSkeleton && (
          <>
            <input
              ref={ref}
              className='c-checkable-field__input'
              id={id}
              type={type}
              name={name}
              disabled={isDisabled ?? disabled}
              {...props}
            />
            <label className='c-checkable-field__label' htmlFor={id}>
              {label}
            </label>
          </>
        )}
      </div>
    )
  },
)
