import { omitBy, ValueKeyIteratee } from 'lodash'

export const deepOmitBy = (obj: object, predicate: ValueKeyIteratee<any>): object =>
  omitBy(
    Object.fromEntries(
      Object.entries(obj).map(([key, value]) => {
        if (value instanceof Object) {
          const child = deepOmitBy(value, predicate)
          return [key, Object.keys(child).length > 0 ? child : undefined]
        }
        return [key, value]
      }),
    ),
    predicate,
  )
