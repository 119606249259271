import React from 'react'
import { Select, SelectProps } from '@msaf/core-react'
import { SelectFeatureMetaConfig } from '@msaf/maps-common'

export interface FeatureSelectProps extends SelectProps {
  setValue: (value: string) => void
  config: SelectFeatureMetaConfig
}

export function FeatureSelect({ id, value, setValue, config }: FeatureSelectProps) {
  return (
    <Select
      id={id}
      onChange={(e) => setValue(e.target.value)}
      value={value}
      options={config.options}
      placeholderType='none'
      isRequired={config.required}
    />
  )
}
