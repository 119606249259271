import { YesNo } from '../types'

/**
 * Converts a given value to Yes or No
 * @param value Value to evaluate
 * @returns Either Yes or No depending on whether the value passed is strictly equivalend to true
 */
export function formatBoolean(value?: boolean | 'true' | 'false'): YesNo {
  if (value === true || value === 'true') return YesNo.Yes
  return YesNo.No
}
