import React from 'react'
import { motion } from 'framer-motion'
import { appLogoAnimations, hideOnCollapseAnimations } from '../animations'
import { IconType } from '../../../../types'
import { Link } from '../../link'
import { Icon } from '../../../common'
import classNames from 'classnames'

export interface AppLogoProps {
  iconLogoImagePath?: string
  fullLogoImagePath?: string
  altText?: string
  logoTextLine1?: string
  logoTextLine2?: string
  icon?: IconType // Fallback option to use system icon when logo image not available
  isIconOnlyVersion?: boolean // Use minimal version of logo image (usually just icon or emblem/omits any text)
  includeMarginBottom?: boolean
  isHorizontalLayout?: boolean
  isSkeleton?: boolean
}

export function AppLogo({
  iconLogoImagePath,
  fullLogoImagePath,
  altText,
  logoTextLine1,
  logoTextLine2,
  icon,
  isIconOnlyVersion,
  includeMarginBottom = true,
  isHorizontalLayout,
  isSkeleton,
}: AppLogoProps) {
  const imagePath = isIconOnlyVersion ? iconLogoImagePath : fullLogoImagePath
  const classes = classNames('c-app-logo', {
    'c-app-logo--no-margin-btm': !includeMarginBottom,
    'c-app-logo--horizontal': isHorizontalLayout,
  })

  return isSkeleton ? (
    <div className={classes}>
      <div className='c-skeleton c-skeleton--circle'></div>
      <div className='c-skeleton c-skeleton--logo-label'></div>
    </div>
  ) : (
    <motion.div className={classes} variants={appLogoAnimations}>
      <Link path='/' exact className='c-app-logo__link'>
        {!!imagePath && (
          <span className='c-app-logo__img-container'>
            <img src={imagePath} alt={altText} />
          </span>
        )}
        {!imagePath && icon && (
          <span className='c-app-logo__bg-circle'>
            <Icon icon={icon} className='c-app-logo__icon' ariaHidden={true} />
          </span>
        )}
        {logoTextLine1 && (
          <motion.span variants={hideOnCollapseAnimations} className='c-app-logo__name'>
            <span className='c-app-logo__name-line-1'>{logoTextLine1}</span>
            {logoTextLine2 && <span className='c-app-logo__name-line-2'>{logoTextLine2}</span>}
          </motion.span>
        )}
      </Link>
    </motion.div>
  )
}
