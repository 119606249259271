export type FieldTypes = string | number | boolean

export interface DashboardFarmOperations {
  completedSubmissions: FarmOperation[]
  farmOperations: FarmOperation[]
}

export interface FarmOperation {
  organisationName: string
  nzbn: string
  dairySupplyNumber: string
  contactName: string
  contactPhone: string
  contactEmailAddress: string
  hasDataConsent: boolean
  hasMetFertilizerPerGrazedPasture: boolean
  resourceConsent: string
  areaTotal: number
  areaEffective: number
  submissionComplete?: boolean
  submissionStatus?: string
  reportingRequirementsComplete?: boolean
  reportingYear: number
}

export interface ContiguousLandHolding {
  contiguousLandHoldingId?: string
  landName: string
  reportingYear: number | string
  areaTotal: number
  areaBoundary: {
    type: 'GeometryCollection'
    geometries: [
      {
        type: 'Linestring'
        coordinates: [number[], number[]]
      },
      {
        type: 'Point'
        coordinates: number[]
      },
    ]
  }
  // Format of these fields is slightly different to API spec in client (fields are set in server) to allow use with our setValue function
  easting: string
  northing: string
  location: { easting: string; northing: string }
  //  This is different to the API due to needing to support repeatable fields.
  // The data is matched to/from external API in server code.
  areaRegionalCouncil: Array<{ id: string; council: string }>
  areaGrazedLand: number
  areaGrazedForageCrops: number
  areaGrazedNonForageCrops: number
  areaUnGrazedLand: number
  appliedGrazedLand: number
  appliedGrazedForageCrops: number
  appliedGrazedNonForageCrops: number
  appliedUnGrazedLand: number
  fertiliserApplications?: Array<FertiliserApplication>
}

export interface FertiliserApplication {
  contiguousLandHoldingId: string
  fertiliserApplicationId: string
  dateTimeApplied: string
  fertiliserManufacturer: string
  fertiliserType: string
  nitrogenPercentRate: number
}

export interface FertiliserPurchaseItem {
  unit: number
  product: string
  measure: UnitOfMeasure
}

export enum UnitOfMeasure {
  TONNES = 'MetricTon',
  LITRES = 'Litres',
}

export interface ProofOfPurchase {
  proofOfPurchaseId: string
  supplierName: string
  dateTimePurchased: string
  // This type is slightly different to the external API to allow use with our repeating sections.
  items: Array<FertiliserPurchaseItem>
}

export type FormFieldsEdit = FarmOperation & ContiguousLandHolding & FertiliserApplication & ProofOfPurchase

export interface FormFieldArrayTypesEdit {
  farmOperations: Array<FarmOperation>
  contiguousLandHoldings: Array<ContiguousLandHolding>
  fertiliserApplications: Array<FertiliserApplication>
  proofOfPurchases: Array<ProofOfPurchase>
}

export const UnitsOfMeasure = {
  litres: {
    label: 'litres',
    value: UnitOfMeasure.LITRES,
  },
  tonnes: {
    label: 'metric tonnes',
    value: UnitOfMeasure.TONNES,
  },
}
