import { ContentContainer, Heading, BodyTextContainer } from '@msaf/core-react'

export default function NotFound() {
  return (
    <ContentContainer>
      <BodyTextContainer>
        <Heading level={1}>Page not found</Heading>
        <p>
          The page you're looking for does not exist, or the information you are trying to access may not have not have
          been published yet.
        </p>
        <p>
          Return <a href='/dashboard'>home</a> or&nbsp;<a href='/contact-us'>contact us</a> if the problem persists.
        </p>
      </BodyTextContainer>
    </ContentContainer>
  )
}
