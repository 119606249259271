import React from 'react'
import { FileCard } from './file-card'
import { Heading, InlineNotification } from '../../../common'
import { FileMetadataCallback, NewOrUploadedFile, DownloadAction } from '../types'

export * from './file-card'
export type RemoveFileCallback = (file: NewOrUploadedFile) => void
export interface FileListProps {
  heading?: string
  headingLevel?: 1 | 2 | 3 | 4 | 5 | 6
  isEditable?: boolean
  files: Array<NewOrUploadedFile>
  isSkeleton?: boolean
  removeFile?: RemoveFileCallback
  getMetadataElement?: FileMetadataCallback
  validationErrors?: Array<Record<string, string[]>>
  downloadAction: DownloadAction | null
}

export function FileList({
  heading,
  headingLevel = 3,
  files,
  validationErrors,
  isSkeleton = true,
  removeFile,
  isEditable,
  getMetadataElement,
  downloadAction,
}: FileListProps) {
  return (
    <div className='c-file-list'>
      {heading && (
        <Heading isSkeleton={isSkeleton} className='c-file-list__heading' level={headingLevel}>
          {heading}
        </Heading>
      )}
      {!isSkeleton &&
        (files.length ? (
          <ul className='c-file-list__items'>
            {files.map((file: NewOrUploadedFile, index: number) => {
              return (
                <li className='c-file-list__item' key={file.id}>
                  <FileCard
                    validationErrors={validationErrors ? validationErrors[index] : undefined}
                    file={file}
                    isSkeleton={isSkeleton}
                    isEditable={isEditable}
                    removeFile={removeFile}
                    getMetadataElement={getMetadataElement}
                    downloadAction={downloadAction}
                  />
                </li>
              )
            })}
          </ul>
        ) : (
          <InlineNotification messageType='info' isDismissable={false}>
            No files uploaded.
          </InlineNotification>
        ))}
    </div>
  )
}
