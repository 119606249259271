/**
 * Checks whether the given number is even
 * @param value Number to check
 * @throws Will throw an error if the argument is not a number
 * @returns Whether the value is even
 */
export function isEven(value: number): boolean {
  if (isNaN(value)) {
    throw new Error('Must pass a number')
  }

  return value % 2 === 0
}
