import * as L from 'leaflet'
import type _ from '@geoman-io/leaflet-geoman-free'
import { getMarkerOptions, getPolygonPathOptions, getPolylinePathOptions } from '.'
import MapUIConfig, { SupportedFeature, SupportedFeatureType } from '../types/config'
import { asyncDrawShape, isSupportedDrawnLayer } from '../utils/edit'
import { getConfigForFeature, getConfigForType } from './utils'
import { calculatePolylineLength, mapDrawOptionsForGeoman } from '../utils/helpers'
import { DrawFeatureOptions, DrawOptions, SupportedDrawnLayers } from '../types'

const TYPE_SHAPE_MAP: { [key in SupportedFeatureType]: L.PM.SUPPORTED_SHAPES } = {
  Point: 'Marker',
  LineString: 'MSPolyline',
  Polygon: 'Polygon',
}

const getFeatureDrawOptions = (fCfg: SupportedFeature, options?: DrawFeatureOptions): Partial<DrawOptions> => {
  switch (fCfg.geometryType) {
    case 'Point':
      return {
        markerStyle: getMarkerOptions(fCfg, options?.customClassMap),
      }
    case 'LineString':
      return {
        pathOptions: getPolylinePathOptions(fCfg),
      }
    case 'Polygon':
      return {
        pathOptions: getPolygonPathOptions(fCfg),
      }
  }
}

export const makeLayerEditable = (config: MapUIConfig, layer: L.Layer) => {
  // Do nothing if we don't support editing the layer type.
  if (!isSupportedDrawnLayer(layer)) return
  // Do nothing if the layer doesn't have a feature spec (probably just created)
  if (!layer.feature) return

  const fCfg = getConfigForFeature(config, layer.feature)
  if (fCfg.editable === false) {
    throw new Error('MAP-9: Feature is not editable.')
  }
  layer.options.pmIgnore = false
  L.PM.reInitLayer(layer)
}

export const editableFeatureLayer = (config: MapUIConfig, featureLayer: L.GeoJSON) => {
  const setupLayer = (layer: SupportedDrawnLayers) => {
    try {
      makeLayerEditable(config, layer)
    } catch {}
    if (layer instanceof L.Polygon) {
      var tooltipElement: HTMLElement | undefined
      layer.on('pm:markerdragstart', () => {
        tooltipElement = layer.getTooltip()?.getElement()

        if (tooltipElement) tooltipElement.style.opacity = '0'
      })
      layer.on('pm:markerdragend', () => {
        const tooltip = layer.getTooltip()
        // Rebind the tooltip to trigger its center to update.
        if (tooltip) layer.unbindTooltip().bindTooltip(tooltip)
        if (tooltipElement) tooltipElement.style.opacity = '1'
      })
    }
    if (layer instanceof L.Polyline) {
      layer.on('pm:edit', () => {
        updateFeatureProperties(layer, calculateDynamicFeatureProperties(layer))
      })
    }
  }
  featureLayer.on('layeradd', (e) => setupLayer(e.layer))
  featureLayer.eachLayer((layer) => {
    if (isSupportedDrawnLayer(layer)) setupLayer(layer)
  })
}

export const drawFeature = async (
  config: MapUIConfig,
  map: L.Map,
  featureType: string,
  options?: DrawFeatureOptions,
): Promise<SupportedDrawnLayers> => {
  const fCfg = getConfigForType(config, featureType)

  const drawOptions = { ...options?.drawOptions, ...getFeatureDrawOptions(fCfg) }

  const layer = await asyncDrawShape(map, TYPE_SHAPE_MAP[fCfg.geometryType], drawOptions)

  if (fCfg.editable !== false) {
    layer.options.pmIgnore = false
    L.PM.reInitLayer(layer)
  }

  updateFeatureProperties(layer, {
    ...options?.featureProperties,
    type: featureType,
    ...calculateDynamicFeatureProperties(layer),
  })
  return layer
}

export const editFeature = (config: MapUIConfig, map: L.Map, featureLayer: SupportedDrawnLayers) => {
  // Disable any inprogress edits.
  map.pm.disableGlobalEditMode()

  const fCfg = getConfigForFeature(config, featureLayer.feature)
  if (fCfg.editable === false || featureLayer.pm === undefined) {
    throw new Error('MAP-7: Feature is not editable.')
  }
  featureLayer.pm.enable()
}

export const calculateDynamicFeatureProperties = (layer: SupportedDrawnLayers) => {
  if (layer instanceof L.Polygon) return {}
  if (layer instanceof L.Polyline) {
    const length = calculatePolylineLength(layer)

    return { length }
  }
  return {}
}

/*
Merge the feature properties with those already present.
*/
export const updateFeatureProperties = (layer: SupportedDrawnLayers, properties: any) => {
  const feature = layer.feature ?? layer.toGeoJSON()
  layer.feature = {
    ...feature,
    properties: {
      ...feature.properties,
      ...properties,
    },
  }
}

export const setDrawOptions = (map: L.Map, options: Partial<DrawOptions>) => {
  map.pm.setGlobalOptions({
    ...map.pm.getGlobalOptions(),
    ...mapDrawOptionsForGeoman(options),
  })
}

export const cancelEditing = (map: L.Map, layer?: SupportedDrawnLayers) => {
  map.pm.disableDraw()

  if (layer) {
    layer.pm.disable()
  } else {
    const layers = map.pm.getGeomanLayers() as L.Layer[]
    for (const l of layers) {
      if (isSupportedDrawnLayer(l)) l.pm.disable()
    }
  }
}
