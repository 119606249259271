import React from 'react'
import { CustomMarkerIcon, CustomMarkerIconProps } from './custom-marker-icon'
import { InitialsIconProps, InitialsMarkerIcon } from './initials-marker-icon'
import { PolygonMarkerIcon, PolygonMarkerIconProps, PolylineMarkerIconProps } from './polygon-marker-icon'

export interface MarkerIconProps {
  markerStyle?: string
}

export function MarkerIcon({
  marker,
  isLarge,
}: {
  marker: InitialsIconProps | CustomMarkerIconProps | PolygonMarkerIconProps | PolylineMarkerIconProps
  isLarge?: boolean
}) {
  let markerComponent = null

  if ('initials' in marker) {
    markerComponent = (
      <InitialsMarkerIcon
        initials={marker.initials}
        customType={marker.customType}
        markerStyle={marker.markerStyle}
        isLarge={isLarge}
      />
    )
  } else if ('iconFilePath' in marker) {
    markerComponent = <CustomMarkerIcon iconFilePath={marker.iconFilePath} isLarge={isLarge} />
  } else {
    // Note: used for both polygon and polyline features:
    markerComponent = <PolygonMarkerIcon marker={marker} isLarge={isLarge} />
  }

  return markerComponent
}
