import React from 'react'
import classNames from 'classnames'
import { SkeletonButton } from './skeleton-button'
import { IconType } from '../../../types'
import { Icon } from '../icon'
import { Link } from '../../navigation'

export interface ButtonWithIcon extends BaseButtonProps {
  label: string
  iconAlignment?: 'right' | 'left'
  iconAriaLabel?: string
}

export interface ButtonWithoutIcon extends BaseButtonProps {
  label: string
  iconAriaLabel?: undefined
  iconAlignment?: 'right' | 'left'
}

export interface ButtonOnlyIcon extends BaseButtonProps {
  label?: undefined
  iconAriaLabel: string
  iconAlignment?: 'right' | 'left'
}

/*
  TODO: document button styles better in Storybook, revisit text-action vs text-link styles.
  Primary: blue bg, white text
  Secondary: blue border/text, transparent fill
  Tertiary: white bg, blue text, box-shadow (use over maps etc)
  Text action: appears as text link but retains dimensions/padding of normal buttons (so will align well next to them)
  Text link: appears as text link, no extra padding etc to make it as big as buttons
*/
export type ButtonStyle = 'primary' | 'secondary' | 'tertiary' | 'text-action' | 'text-link' | 'danger'

export interface BaseButtonProps extends Omit<React.ComponentPropsWithRef<'button'>, 'css' | 'ref' | 'disabled'> {
  /**
    - `default`: ensures button is large size on mobile (for larger hit area) but small size on desktop
    - `small`: forces small size on both mobile and desktop (prefer default but use this where layout/space is an issue)
    - `large`: will use large size on both mobile and desktop
  */
  size?: 'default' | 'small' | 'large'
  icon?: IconType
  isIconRotated180?: boolean
  buttonStyle?: ButtonStyle
  isDisabled?: boolean
  isLoading?: boolean
  isSkeleton?: boolean
  path?: string
  exact?: boolean
  target?: string
}

export type ButtonProps = ButtonWithIcon | ButtonWithoutIcon | ButtonOnlyIcon

export interface ButtonContentsProps {
  label?: string
  isLoading?: boolean
  icon?: IconType
  iconAlignment?: 'right' | 'left'
  isIconRotated180?: boolean
  iconAriaLabel?: string
}

function ButtonContents({
  label,
  isLoading,
  icon,
  iconAlignment = 'right',
  isIconRotated180,
  iconAriaLabel,
}: ButtonContentsProps) {
  const iconClasses = classNames('c-btn__icon', {
    'c-btn__icon--align-left': iconAlignment === 'left',
    'c-btn__icon--only-child': !label,
  })

  const labelClasses = classNames('c-btn__label', {
    'c-btn__label--loading': isLoading,
  })

  const spinnerClasses = classNames('c-btn__icon', 'c-btn__icon--spinner')

  const ariaHidden: boolean = !!label
  const ariaLabel: string = iconAriaLabel && !ariaHidden ? iconAriaLabel : ''

  return (
    <>
      {isLoading && <Icon className={spinnerClasses} icon='spinner' ariaHidden={true} />}
      <span className={labelClasses}>{label}</span>
      {icon && (
        <Icon
          icon={icon}
          ariaHidden={ariaHidden}
          ariaLabel={ariaLabel}
          className={iconClasses}
          isIconRotated180={isIconRotated180}
        />
      )}
    </>
  )
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className = '',
      label,
      buttonStyle = 'primary',
      size = 'default',
      isDisabled,
      isLoading,
      icon,
      iconAlignment = 'right',
      isIconRotated180,
      iconAriaLabel,
      isSkeleton,
      path,
      target,
      exact,
      ...props
    },
    ref,
  ) => {
    if (isSkeleton) {
      return <SkeletonButton size={size} iconOnly={!!icon && !label} />
    }

    const btnClasses = classNames('c-btn', {
      [`c-btn--${buttonStyle}`]: buttonStyle !== 'primary',
      [`c-btn--icon-${iconAlignment}`]: iconAlignment !== 'right',
      'c-btn--force-small': size === 'small',
      'c-btn--large': size === 'large',
      'c-btn--disabled': isDisabled,
      'c-btn--loading': isLoading,
      'c-btn--icon-only': icon && !label,
      [`${className}`]: !!className,
    })

    // See: https://mathiasbynens.github.io/rel-noopener/
    const rel = !!target ? 'noopener noreferrer' : ''

    // Returns either Link component (for routing actions) or button element (for normal actions - delete, save etc)
    if (path && !isDisabled) {
      return (
        <Link className={btnClasses} path={path} exact={exact} target={target} rel={rel}>
          <ButtonContents
            label={label}
            isLoading={isLoading}
            icon={icon}
            iconAlignment={iconAlignment}
            isIconRotated180={isIconRotated180}
            iconAriaLabel={iconAriaLabel}
          />
        </Link>
      )
    } else {
      return (
        <button className={btnClasses} disabled={isDisabled} ref={ref} {...props}>
          <ButtonContents
            label={label}
            isLoading={isLoading}
            icon={icon}
            iconAlignment={iconAlignment}
            isIconRotated180={isIconRotated180}
            iconAriaLabel={iconAriaLabel}
          />
        </button>
      )
    }
  },
)
