import { Dispatch, useEffect, useState } from 'react'

export function usePassedState<T>(initial: T, setExternalState?: (v: T) => void): { state: T; setState: Dispatch<T> } {
  const [internalState, setInternalState] = useState<T>(initial)

  useEffect(() => {
    setInternalState(initial)
  }, [initial])

  useEffect(() => {
    if (setExternalState) {
      setExternalState(internalState)
    }
  }, [internalState, setExternalState])

  return { state: internalState, setState: setInternalState }
}
