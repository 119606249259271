import { DateField, FormField, InputWidth, TextInput } from '@msaf/core-react'
import { parseDate } from '@msaf/generic-search-common'
import { DATEPICKER_POPPER_OPTS, DISPLAY_DATE_FORMAT, TOOLTIP_MESSAGE } from '../constants'
import { SetValue, ValidationErrors } from '../hooks/useEditForm'
import { offsetByIndex } from '../services/utils'
import { FertiliserApplication } from '../types/form-types'
import { useCallback, useEffect, useState } from 'react'

interface FertiliserApplicationFormProps {
  fields: Partial<FertiliserApplication>
  isLoading: boolean
  validationErrors: Array<ValidationErrors<FertiliserApplication>>
  index: number
  setValue: SetValue<FertiliserApplication>
}

export const FertiliserApplicationForm = ({
  fields,
  isLoading,
  index,
  validationErrors,
  setValue,
}: FertiliserApplicationFormProps) => {
  const [clientValidationErrors, setClientValidationsErrors] = useState<Array<ValidationErrors<FertiliserApplication>>>(
    [],
  )

  useEffect(() => {
    setClientValidationsErrors(validationErrors)
  }, [])

  const handleNitrogenPercentValidation = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) > 100) {
      const updatedValidationErrors = clientValidationErrors
      // eslint-disable-next-line security/detect-object-injection
      updatedValidationErrors[index] = { nitrogenPercentRate: ['This field should not be over 100%'] }
      setClientValidationsErrors(updatedValidationErrors)
    } else {
      setClientValidationsErrors(clientValidationErrors)
    }
  }, [])

  return (
    <>
      <FormField
        isSkeleton={isLoading}
        label='Date applied'
        labelId={offsetByIndex('testDate', index)}
        // eslint-disable-next-line security/detect-object-injection
        validationMessages={validationErrors?.[index]?.dateTimeApplied}
        isRequired
      >
        <DateField
          popperPlacement='bottom'
          popperModifiers={DATEPICKER_POPPER_OPTS}
          isSkeleton={isLoading}
          fieldSize={InputWidth.large}
          dateFormat={DISPLAY_DATE_FORMAT}
          selectedDate={parseDate(fields.dateTimeApplied)}
          setSelectedDate={(date: Date) => setValue('dateTimeApplied', date?.toISOString())}
          // eslint-disable-next-line security/detect-object-injection
          isInvalid={!!validationErrors?.[index]?.dateTimeApplied}
        />
      </FormField>
      <FormField
        isRequired
        isSkeleton={isLoading}
        label='Fertiliser manufacturer'
        htmlFor={offsetByIndex('fertiliserManufacturer', index)}
        // eslint-disable-next-line security/detect-object-injection
        validationMessages={validationErrors?.[index]?.fertiliserManufacturer}
      >
        <TextInput
          isSkeleton={isLoading}
          id={offsetByIndex('fertiliserManufacturer', index)}
          value={`${fields?.fertiliserManufacturer ?? ''}`}
          onBlur={(e) => setValue('fertiliserManufacturer', e.target.value)}
          // eslint-disable-next-line security/detect-object-injection
          isInvalid={!!validationErrors?.[index]?.fertiliserManufacturer}
        />
      </FormField>
      <FormField
        isRequired
        isSkeleton={isLoading}
        label='Fertiliser name'
        htmlFor={offsetByIndex('fertiliserType', index)}
        // eslint-disable-next-line security/detect-object-injection
        validationMessages={validationErrors?.[index]?.fertiliserType}
        toolTipMessage={TOOLTIP_MESSAGE.fertiliserType}
      >
        <TextInput
          isSkeleton={isLoading}
          id={offsetByIndex('fertiliserType', index)}
          value={`${fields?.fertiliserType ?? ''}`}
          onBlur={(e) => setValue('fertiliserType', e.target.value)}
          // eslint-disable-next-line security/detect-object-injection
          isInvalid={!!validationErrors?.[index]?.fertiliserType}
        />
      </FormField>
      <FormField
        isSkeleton={isLoading}
        label='Nitrogen percent rate'
        htmlFor={offsetByIndex('nitrogenPercentRate', index)}
        validationMessages={
          // eslint-disable-next-line security/detect-object-injection
          validationErrors?.[index]?.nitrogenPercentRate ?? clientValidationErrors?.[index]?.nitrogenPercentRate
        }
        toolTipMessage={TOOLTIP_MESSAGE.nitrogenPercentRate}
        isRequired
      >
        <TextInput
          min={0}
          max={100}
          type='number'
          isSkeleton={isLoading}
          id={offsetByIndex('nitrogenPercentRate', index)}
          value={`${fields?.nitrogenPercentRate ?? ''}`}
          onChange={(e) => handleNitrogenPercentValidation(e)}
          onBlur={(e) => setValue('nitrogenPercentRate', e.target.value)}
          isInvalid={
            // eslint-disable-next-line security/detect-object-injection
            !!clientValidationErrors?.[index]?.nitrogenPercentRate || !!validationErrors?.[index]?.nitrogenPercentRate
          }
        />
      </FormField>
    </>
  )
}
