/**
 * Lowercases the given string, returning an empty string if no value is passed
 * @param value string to lowercase
 * @returns lowercased string - returns an empty string if no value is passed
 */
export function lowercaseString(value: string | null | undefined): string {
  if (value != null && typeof value !== 'string') {
    throw new Error('Must pass a string to the `lowercaseString` function')
  }

  return value ? value.toLowerCase() : ''
}
