/**
 * Helper to strip off html tags from string
 * Source: https://css-tricks.com/snippets/javascript/strip-html-tags-in-javascript/
 * @param value String containing tags
 * @returns Formatted string
 */
export function stripTags(value: string): string {
  if (typeof value === 'string') {
    value = value.replace(/(<([^>]+)>)/gi, '')
  }

  return value
}
