import { useCallback, useEffect, useRef } from 'react'

export function useClickOutside<T extends HTMLElement>(onClickOutside: () => void) {
  const node = useRef<T>(null!)

  // Declare callback
  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      // If node has not been assigned, can't tell if click event happened outside
      if (!node.current) {
        onClickOutside()
        return
      }

      // If target of click is within node, click target was inside
      if (e.target instanceof Node && node.current.contains(e.target)) return

      onClickOutside()
    },
    [onClickOutside],
  )

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handleClickOutside])

  return node
}
