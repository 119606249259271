import { Heading } from '@msaf/core-react'
import { PropsWithChildren } from 'react'

// TODO: Move to core-react.

export const ErrorPage = ({ header, children }: PropsWithChildren<{ header?: string }>) => {
  return (
    <div
      style={{
        margin: 'auto',
        width: 300,
        height: 100,
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        textAlign: 'center',
      }}
    >
      <Heading level={1}>{header}</Heading>
      {children && <div>{children}</div>}
    </div>
  )
}
