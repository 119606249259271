import classNames from 'classnames'
import React from 'react'
import { Icon } from '../../../common/icon'
import { Link } from '../../link'

export interface NavLinkProps extends React.ComponentPropsWithRef<'a'> {
  label: string
  path: string
  checked?: boolean
  exact?: boolean
  isHidden?: boolean
}

export function NavLink({ label, checked, className, isHidden, ...props }: NavLinkProps) {
  const classes = classNames([
    'c-workflow-nav__link',
    {
      [className ?? '']: !!className,
    },
  ])

  if (isHidden) return null

  return (
    <Link className={classes} activeClassName='c-workflow-nav__link--active' {...props}>
      <span className='c-workflow-nav__link-text'>{label}</span>
      <span className='c-workflow-nav__link-icon'>
        {checked ? (
          <Icon role='img' icon='check-circle' ariaLabel='Workflow completed' height='16px' width='16px' />
        ) : (
          <Icon role='img' icon='check-circle' ariaHidden={true} height='16px' width='16px' visibility='hidden' />
        )}
      </span>
    </Link>
  )
}
