import classNames from 'classnames'
import React from 'react'
import { MarkerIconProps } from '../index'

export interface PolygonMarkerIconProps extends MarkerIconProps {
  borderColor: string
}

export interface PolylineMarkerIconProps extends MarkerIconProps {
  color: string
}

export function PolygonMarkerIcon({
  marker,
  isLarge,
}: {
  marker: PolygonMarkerIconProps | PolylineMarkerIconProps
  isLarge?: boolean
}) {
  const classes = classNames('c-marker-icon c-marker-icon--polygon', {
    [`c-marker-icon--${marker.markerStyle}`]: !!marker.markerStyle,
    'c-marker-icon--large': isLarge,
  })

  let lineColor = ''
  // If polygon feature, use border color
  if ('borderColor' in marker) {
    lineColor = marker.borderColor
    // Else, use color of line for polylines
  } else if ('color' in marker) {
    lineColor = marker.color
  }

  return (
    <svg className={classes} width='23' height='27' viewBox='0 0 23 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
      {/* White background */}
      <path
        d='M11.75 1.56699L11.5 1.42265L11.25 1.56699L1.29071 7.31699L1.04071 7.46132V7.75V19.25V19.5387L1.29071 19.683L11.25 25.433L11.5 25.5774L11.75 25.433L21.7093 19.683L21.9593 19.5387V19.25V7.75V7.46132L21.7093 7.31699L11.75 1.56699Z'
        fill='#FFF'
        stroke='#FFF'
      />
      {/* Coloured stroke to represent feature border colour */}
      <path
        d='M2.54071 8.32735L11.5 3.1547L20.4593 8.32735V18.6726L11.5 23.8453L2.54071 18.6726V8.32735Z'
        stroke={lineColor}
        strokeWidth='2'
      />
    </svg>
  )
}
