import React, { useState } from 'react'
import { IconType, Button } from '@msaf/core-react'

type zoomControlId = 'zoom-in' | 'zoom-out'

export type ZoomControlOption = {
  id: zoomControlId
  iconType: IconType
  iconAriaLabel: string
  zoomAction: () => L.Map
  noop: (event: React.SyntheticEvent) => void
  disabled?: boolean
}

export type MapZoomControlProps = {
  zoomControlOptions: ZoomControlOption[]
}

export function MapZoomControl({ zoomControlOptions }: MapZoomControlProps) {
  const [btnDisableState, setBtnDisableState] = useState<{ [key in zoomControlId]: boolean }>({
    'zoom-in': false,
    'zoom-out': false,
  })

  const handleClick = (id: zoomControlId, zoomAction: () => L.Map) => {
    const map: L.Map = zoomAction()
    // Toggle disable state for the two zoom control btns depending on
    // the current zoom level and the action being triggered
    setBtnDisableState({
      'zoom-in': id === 'zoom-in' && map.getZoom() + 1 >= map.getMaxZoom(),
      'zoom-out': id === 'zoom-out' && map.getZoom() - 1 <= map.getMinZoom(),
    })
  }
  return (
    <div className='c-map-screen__zoom-controls'>
      {zoomControlOptions.map(({ id, iconType, iconAriaLabel, zoomAction, noop }: ZoomControlOption) => (
        <Button
          key={id}
          iconAriaLabel={iconAriaLabel}
          icon={iconType}
          onClick={() => handleClick(id, zoomAction)}
          onDoubleClick={noop}
          buttonStyle='tertiary'
          isDisabled={btnDisableState[id]}
        />
      ))}
    </div>
  )
}
