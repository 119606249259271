import { useAuth } from '../auth'

import {
  Banner,
  ButtonWithIcon,
  ButtonWithoutIcon,
  ContentBanner,
  ContentBlock,
  ContentPage,
  ContentPageFooter,
  FloatingBlock,
  InlineNotification,
  Link,
  NzMap,
  useTitleChange,
} from '@msaf/core-react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { APP_LOGO, APP_NAME, FOOTER_LOGOS, FOOTER_MENU_LINKS, UNAUTHENTICATED_MAIN_MENU_LINKS } from '../constants'
import { LoadingPage } from '../components/loading-page'
import RegisterModal from '../components/register-modal'
import { addAttributesToOpenHelpInNewTab } from '../services/utils'
import { ErrorCode } from '../components/errors/constants'
import { useGetBannerData } from '../hooks/useApi'
import { determineMessageType, isBannerDataValid, onBannerClose, shouldShowBanner } from '../services/banner'

export const LandingPage = () => {
  useTitleChange(APP_NAME)
  const [isRegisterModalOpen, setIsRegisterModalOpen] = useState(false)
  const [headerActions, setHeaderActions] = useState<Array<ButtonWithIcon | ButtonWithoutIcon>>()
  const { login, logout, isAuthenticated, isLoading, error } = useAuth()
  const { pathname, search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])
  const { data: bannerData } = useGetBannerData()

  const loginWithRedirect = useCallback(() => login({ redirectPath: '/dashboard' }), [login, pathname])

  // Open the register or modal on mount is ?register=true
  useEffect(() => {
    const registerParam = query.get('register')?.toLowerCase()
    if (registerParam) {
      setIsRegisterModalOpen(['1', 'true'].includes(registerParam))
    }
  }, [query])

  // Go to login page if query param ?login=true
  useEffect(() => {
    const loginParam = query.get('login')?.toLowerCase()
    if (loginParam && ['1', 'true'].includes(loginParam)) {
      loginWithRedirect()
    }
  }, [query])

  useEffect(() => {
    setTimeout(() => {
      addAttributesToOpenHelpInNewTab('.c-content-menu__link')
    }, 500)
  }, [])

  useEffect(() => {
    addAttributesToOpenHelpInNewTab('.c-horizontal-menu__link')
  })

  useEffect(() => {
    let actions: Array<ButtonWithIcon | ButtonWithoutIcon> | undefined = undefined
    if (isAuthenticated) {
      actions = [
        {
          label: 'Logout',
          onClick: () => logout({ redirectPath: '/' }),
          icon: 'exit',
          iconAlignment: 'left',
          buttonStyle: 'secondary',
        },
      ]
    } else {
      actions = [
        {
          label: 'Register',
          onClick: () => setIsRegisterModalOpen(true),
          buttonStyle: 'primary',
        },
        {
          label: 'Sign in',
          onClick: loginWithRedirect,
          buttonStyle: 'secondary',
        },
      ]
    }
    setHeaderActions(actions)

    // Excluded 'logout' function from dependency list to prevent
    // triggering multiple re-renders
  }, [isAuthenticated, history])

  // If Keycloak is not initialised throw Error to be handled by error boundary
  if (error === ErrorCode.KEYCLOAK_ERROR) {
    throw new Error(error)
  }

  if (isLoading) {
    return <LoadingPage />
  }

  // If user is already logged in, they should be redirected
  if (isAuthenticated) {
    return <Navigate to={'/dashboard'} />
  }

  return (
    <>
      {bannerData && isBannerDataValid(bannerData) && (
        <Banner
          message={bannerData?.message}
          messageType={determineMessageType(bannerData?.messageType)}
          shouldDisplay={shouldShowBanner(bannerData)}
          onClose={() => onBannerClose(bannerData.expiry)}
        />
      )}
      <ContentPage
        menuLinks={UNAUTHENTICATED_MAIN_MENU_LINKS}
        headerActions={headerActions}
        appLogo={APP_LOGO}
        backgroundColor='blue-wash'
      >
        <RegisterModal isOpen={isRegisterModalOpen} closeModal={() => setIsRegisterModalOpen(false)} />

        <ContentBanner
          heading='Welcome to the Regional Sector Synthetic Nitrogen Use Recording webform (N-Cap Webform)'
          blockColor='blue-wash'
        >
          <p>
            New rules in the National Environmental Standard for Freshwater (NESF) limiting the amount of synthetic
            nitrogen you can apply to pastoral land of 20ha or more came into effect on 1 July 2021. In summary the
            amount of synthetic nitrogen applied{' '}
            <strong>must&nbsp;not&nbsp;exceed&nbsp;190&nbsp;kilograms of Nitrogen per hectare per year</strong>,
            averaged across your grazed land area.
          </p>
          <p>
            The regulations require operators of dairy platforms to provide annual data on synthetic nitrogen use to
            their regional council. To collect this information from dairy farmers the Regional Sector has worked in
            collaboration with the fertiliser industry and industry groups to provide different channels for dairy
            farmers to choose to provide this information.
          </p>
          <p>
            We know most farmers have close ties to their fertiliser suppliers. If you use one of the major fertiliser
            suppliers, such as Ravensdown or Ballance, they have the tools to collate all the necessary information,
            ready for you to check and submit to your regional council. However, if you wish to provide the information
            directly this webform is another channel that can be used. The webform will require you to collect, collate
            and calculate the information before entering the data through the webform. Remember the recording period is
            for the 12 months prior to the 30th of June.
          </p>
          <div className='c-inline-notification__no-close theme-border'>
            <InlineNotification isDismissable={false}>
              <a href='/help-centre#data-usage' target='_blank' rel='noreferrer noopener'>
                Click here to find out where the data goes and how it is used
              </a>
            </InlineNotification>
          </div>
        </ContentBanner>

        <ContentBlock imageAlternative={<NzMap className='c-content-block__img' />} heading='How to submit'>
          <p>
            When you use the webform to create and save your records your data will automatically be submitted to a data
            store for reporting to your council.
          </p>
          The webform process uses the following steps.
          <ol>
            <li>
              <strong>Register as a user</strong>
              <ul>
                <li>
                  You will receive a one-time email for you to confirm your contact email. It has a link which expires
                  in 24 hours.
                </li>
                <li>
                  Registration allows you to come back and complete your recording or see your details and records each
                  time you use the tool
                </li>
              </ul>
            </li>
            <li>
              <strong>Create a farm entity and the associated farm and contact details</strong>
              <ul>
                <li>You can create multiple farms if required</li>
              </ul>
            </li>
            <li>
              <strong>Enter records of all of your fertiliser purchases for the year for that farm</strong>
              <ul>
                <li>You can create multiple purchase records if required</li>
              </ul>
            </li>
            <li>
              <strong>Create your land categories within your contiguous landholding on your farm</strong>
              <ul>
                <li>You can associate more than one contiguous landholding to a farm entity</li>
              </ul>
            </li>
            <li>
              <strong>Detail your fertiliser application dates for your contiguous landholding</strong>
              <ul>
                <li>
                  You can record multiple application dates and record multiple fertilisers for the same application
                  date if required
                </li>
                <li>
                  You can download the spreadsheet from the{' '}
                  <a href='/help-centre' target='_blank' rel='noreferrer noopener'>
                    Help centre
                  </a>{' '}
                  to assist you in calculating the required data for your N-Cap records
                </li>
              </ul>
            </li>
            <li>
              <strong>
                Review your N-Cap records that have been submitted to the appropriate council and download a PDF version
                for your records
              </strong>
            </li>
          </ol>
        </ContentBlock>
        <FloatingBlock backgroundColorBelow='blue-wash'>
          Throughout the process there is{' '}
          <a href='/help-centre' target='_blank' rel='noreferrer noopener'>
            help and guidance
          </a>{' '}
          available for you to review to help you, which includes a spreadsheet to help with calculations. If at any
          time you need help, please speak with your <Link path='/contact-us'>Regional Council</Link>.
        </FloatingBlock>

        <ContentPageFooter
          backgroundColor='blue-wash'
          appName={APP_NAME}
          menuLinks={FOOTER_MENU_LINKS}
          logos={FOOTER_LOGOS}
        />
      </ContentPage>
    </>
  )
}
