import React from 'react'
import classNames from 'classnames'
import { iconPaths, IconPathInformation } from './icon-paths'
import { IconType } from '../../../types/icon-types'

interface IconProps extends React.ComponentPropsWithoutRef<'svg'> {
  // Must provide either icon name from default icon set OR custom path information
  icon?: IconType
  customPath?: IconPathInformation | IconPathInformation[]
  // Default 'regular' size used if size prop omitted
  size?: 'small' | 'large'
  // Will inherit text colour of containing element if omitted
  color?: 'primary' | 'body-text' | 'white' | 'grey' | 'success' | 'error' | 'info'
  isIconRotated180?: boolean
}

export interface AriaLabelSVGIconProps extends IconProps {
  // Default to false
  ariaHidden?: boolean
  // Must provide as a standard accessibility requirement
  ariaLabel: string
}

export interface AriaHiddenSVGIconProps extends IconProps {
  // Default to true
  ariaHidden: true
  // Must provide as a standard accessibility requirement
  ariaLabel?: undefined
}

export function Icon({
  className = '',
  icon,
  customPath,
  size,
  color,
  isIconRotated180,
  ariaLabel,
  ariaHidden,
  ...props
}: AriaLabelSVGIconProps | AriaHiddenSVGIconProps) {
  const iconClasses = classNames('c-icon', {
    [`c-icon--${size}`]: size,
    [`c-icon--${color}`]: color,
    'c-icon--spinner': icon === 'spinner',
    'c-icon--rotated': isIconRotated180,
    [`${className}`]: className != null && className !== '',
  })

  // Default to empty path
  let pathData: IconPathInformation | IconPathInformation[] = {
    path: '',
  }

  // Determine whether custom path or one of the default icons being used
  if (!!customPath) {
    pathData = customPath
  } else if (!!icon && iconPaths.hasOwnProperty(icon)) {
    pathData = iconPaths[icon]
  }

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      className={iconClasses}
      aria-hidden={ariaHidden}
      aria-label={ariaLabel}
      width='24'
      height='24'
      viewBox='0 0 24 24'
      {...props}
    >
      {/* More complex paths will be an array of objects (each containing an individual path and fill-rule value) */}
      {Array.isArray(pathData) ? (
        pathData.map(({ path, fillRule }, i) => (
          <path key={`${icon}-${i}`} d={path} fillRule={!!fillRule ? fillRule : 'inherit'} />
        ))
      ) : (
        <path d={pathData.path} fillRule={pathData.fillRule} />
      )}
    </svg>
  )
}
