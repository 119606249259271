import classNames from 'classnames'
import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'
import { RemoveFileCallback } from '..'
import { MOBILE_BREAKPOINT } from '../../../../../constants'
import { useMediaQuery } from '../../../../../hooks'
import { Button } from '../../../../common'
import { LoadingIndicator } from '../../../../common/loading-indicator'
import { DownloadAction, FileMetadataCallback, FileState, NewOrUploadedFile } from '../../types'
import { expandCollapseVariants } from './animation'
import { FileSummary } from './file-summary'
export * from './file-summary'

export interface FileCardProps {
  file: NewOrUploadedFile
  isSkeleton?: boolean
  isEditable?: boolean
  removeFile?: RemoveFileCallback
  getMetadataElement?: FileMetadataCallback
  validationErrors?: Record<string, string[]>
  downloadAction: DownloadAction | null
}

export function FileCard({
  file,
  isSkeleton = true,
  isEditable = false,
  removeFile,
  validationErrors = {},
  getMetadataElement,
  downloadAction,
}: FileCardProps) {
  // Expand the file if it has not been saved and its not uploading
  const expandByDefault = file.state !== FileState.Saved && file.state !== FileState.Uploading
  const [isExpanded, setIsExpanded] = useState(expandByDefault)

  const isMobile = useMediaQuery(`(max-width: ${MOBILE_BREAKPOINT})`)

  // Conditionally show button label based on whether user is on mobile/desktop
  const detailsBtnLabel = isMobile ? '' : 'Details'
  const detailsBtnIconAriaLabel = isMobile ? 'Details' : undefined

  const cardClasses = classNames('c-file-card', {
    'c-file-card--pending': file.state === FileState.Uploading,
  })

  return (
    <div className={cardClasses}>
      <div className='c-file-card__header'>
        <FileSummary
          file={file}
          isSkeleton={isSkeleton}
          isDisabled={file.state === FileState.Uploading || !file.isVirusScanned}
          downloadAction={downloadAction}
        />
        <div className='c-file-card__actions'>
          {file.state === FileState.Uploading && <LoadingIndicator>Uploading</LoadingIndicator>}
          {isEditable && (
            <Button
              label={file.state === FileState.Saved ? 'Remove' : 'Cancel'}
              buttonStyle='text-action'
              onClick={() => removeFile?.(file)}
              type='button'
            />
          )}
          {/*
            Expand/contract button for meta fields only shows once uploaded files saved. Otherwise,
            expand by default to ensure user fills out any required fields
          */}
          {file.state === FileState.Saved && getMetadataElement && (
            <Button
              isSkeleton={isSkeleton}
              label={detailsBtnLabel}
              icon={'chevron-down'}
              isIconRotated180={isExpanded}
              iconAriaLabel={detailsBtnIconAriaLabel}
              buttonStyle='secondary'
              onClick={() => setIsExpanded(!isExpanded)}
              type='button'
            />
          )}
        </div>
      </div>

      <AnimatePresence exitBeforeEnter>
        {(isExpanded || file.state !== FileState.Saved) && (
          <motion.div
            className='c-file-card__extra-content'
            variants={expandCollapseVariants}
            initial='collapsed'
            animate={isExpanded ? 'expanded' : 'collapsed'}
            exit={expandCollapseVariants.collapsed}
          >
            {getMetadataElement && (
              <div className='c-file-card__meta-fields'>{getMetadataElement(file, isEditable, validationErrors)}</div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
