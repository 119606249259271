// Breakpoint for switching between mobile/desktop navigation patterns

import { NoteType } from '../components/forms'

// Should match `tablet-landscape-up` mixin here: client/src/styles/1-mixins/_breakpoints.scss
export const NAVIGATION_BREAKPOINT = '900px'
export const MAP_NAVIGATION_BREAKPOINT = '768px'
export const MOBILE_BREAKPOINT = '599px'

// Min Chars for autocomplete request
export const AUTOCOMPLETE_MIN_CHARS = 3

// Default number of page links visible before using next/prev controls
export const NUM_VISIBLE_PAGINATION_LINKS = 5

enum MimeTypes {
  // Image formats
  JPG = 'image/jpeg',
  JFIF = 'image/jpeg',
  PJPEG = 'image/jpeg',
  PJP = 'image/jpeg',
  JPEG = 'image/jpeg',
  PNG = 'image/png',

  // Document formats
  PDF = 'application/pdf',
  CSV = 'text/csv',

  // MS Document formats
  DOC = 'application/msword',
  DOT = 'application/msword',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  XLS = 'application/vnd.ms-excel',
  XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export const DEFAULT_FILE_EXTENSIONS: string[] = [...Object.keys(MimeTypes).map((ext) => ext.toLowerCase())]
export const DEFAULT_MIME_TYPES: string[] = Array.from(new Set(Object.values(MimeTypes)))

export const BRANDING_LOGOS = [
  {
    imagePath: '../assets/logo',
    altText: 'Example branding',
  },
]

export const EXAMPLE_NOTES: Array<NoteType> = [
  {
    note: 'Ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis, perferendis, corrupti quisquam doloribus quasi repellendus saepe sequi blanditiis recusandae accusantium laborum iusto animi ipsa numquam esse nam, voluptatibus vitae quidem.',
    author: {
      id: 1,
      firstName: 'Mike',
      lastName: 'Wazowski',
    },
    createdDate: '22 May 2022',
    form: 'Test',
    recordType: 'Test',
  },
  {
    note: 'Dolor sit amet consectetur adipisicing elit. Lorem ipsum.',
    author: {
      id: 2,
      firstName: 'James',
      lastName: 'P. Sullivan',
    },
    createdDate: '12 May 2022',
    form: 'Test',
    recordType: 'Test',
  },
  {
    note: 'Sit amet consectetur adipisicing elit. Perspiciatis, perferendis, corrupti quisquam doloribus quasi repellendus saepe sequi blanditiis recusandae accusantium laborum iusto animi ipsa numquam esse nam, voluptatibus vitae quidem. Lorem ipsum dolor sit amet consectetur adipisicing elit. Perspiciatis, perferendis, corrupti quisquam doloribus quasi repellendus saepe sequi blanditiis recusandae accusantium laborum iusto animi ipsa numquam esse nam, voluptatibus vitae quidem.',
    author: {
      id: 3,
      firstName: 'Randall',
      lastName: 'Boggs',
    },
    createdDate: '8 May 2022',
    form: 'Test',
    recordType: 'Test',
    tags: [
      {
        label: 'Tag',
        tagStyle: 'info',
      },
    ],
  },
]
