import { ClientCache } from '@msaf/core-common'
import { ScrollToTop, ToastContainer } from '@msaf/core-react'
import { useEffect } from 'react'
import ReactModal from 'react-modal'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AuthProvider } from './app/auth'
import AppErrorBoundary from './app/components/errors/components/error-boundary'
import NotFound from './app/components/errors/components/not-found'
import Page from './app/components/page'
import { WorkflowLayout } from './app/components/plan'
import { ProtectedRoute } from './app/components/protect-route'
import { errorBoundaryIncludes, SCROLL_TARGET } from './app/constants'
import { LandingPage } from './app/routes/'
import ContactUs from './app/routes/contact-us'
import { Dashboard } from './app/routes/dashboard'
import { FarmOperation } from './app/routes/farm/[mode]/[id]'
import { FarmFertiliserApplication } from './app/routes/farm/[mode]/[id]/fertiliser-applications'
import { FertiliserApplications } from './app/routes/farm/[mode]/[id]/fertiliser-applications/[fertiliserApplicationId]'
import { FarmLandHoldings } from './app/routes/farm/[mode]/[id]/land-holding'
import { LandHolding } from './app/routes/farm/[mode]/[id]/land-holding/[contiguousLandHoldingId]'
import { FarmFertiliserPurchase } from './app/routes/farm/[mode]/[id]/proof-of-purchase'
import { FertiliserProofOfPurchase } from './app/routes/farm/[mode]/[id]/proof-of-purchase/[proofOfPurchaseId]'
import { Submission } from './app/routes/farm/[mode]/[id]/submission'
import { HelpCentre } from './app/routes/help-centre'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
      useErrorBoundary: errorBoundaryIncludes,
    },
    mutations: {
      retry: false,
      useErrorBoundary: errorBoundaryIncludes,
    },
  },
})

ReactModal.setAppElement('#root')

function App() {
  useEffect(() => {
    // Initialise the app cache
    // `ClientCache` can be used to persist values across routes
    ClientCache.getInstance()
    // Clear cache on unmount
    return () => ClientCache.getInstance().flush()
  }, [])

  return (
    <AppErrorBoundary>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <BrowserRouter>
            <ScrollToTop targetDiv={SCROLL_TARGET} />
            <Routes>
              <Route path='/' element={<LandingPage />} />
              <Route element={<Page />}>
                <Route path='/help-centre' element={<HelpCentre />} />
                <Route path='/contact-us' element={<ContactUs />} />
              </Route>
              <Route element={<ProtectedRoute />}>
                <Route element={<Page />}>
                  <Route path='/dashboard'>
                    <Route index element={<Dashboard />} />
                  </Route>
                  <Route path='farm'>
                    <Route path=':mode/:id' element={<WorkflowLayout />}>
                      <Route index element={<FarmOperation />} />
                      <Route path='proof-of-purchase' element={<FarmFertiliserPurchase />} />
                      <Route path='proof-of-purchase/:proofOfPurchaseId'>
                        <Route index element={<FertiliserProofOfPurchase />} />
                      </Route>
                      <Route path='land-holding' element={<FarmLandHoldings />} />
                      <Route path='land-holding/:contiguousLandHoldingId'>
                        <Route index element={<LandHolding />} />
                      </Route>
                      <Route
                        path='fertiliser-applications/:fertiliserApplicationId'
                        element={<FertiliserApplications />}
                      />
                      <Route path='fertiliser-applications' element={<FarmFertiliserApplication />} />
                      <Route path='submission' element={<Submission />} />
                      <Route path='submission/:submissionId'>
                        <Route index element={<Submission />} />
                      </Route>
                    </Route>
                  </Route>
                  <Route path='*' element={<NotFound />} />
                </Route>
              </Route>
            </Routes>
            {/* Container for toast messages (needs to be rendered once in application tree) */}
            <ToastContainer />
          </BrowserRouter>
        </AuthProvider>
      </QueryClientProvider>
    </AppErrorBoundary>
  )
}

export default App
