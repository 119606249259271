import { ErrorPage } from '../../../error-page'

export default function KeycloakInitialisationError() {
  return (
    <ErrorPage header='Authentication error'>
      <p>We're having problems communicating with our authentication server.</p>
      <p>
        Please <a href='/contact-us'>contact us</a> if the problem persists.
      </p>
    </ErrorPage>
  )
}
