import classNames from 'classnames'
import React from 'react'
import { Label, LabelProps } from '../form-field/label'

export interface LabelledGroupProps extends LabelProps {
  className?: string
  labelTooltip?: JSX.Element
}

export function LabelledGroup({
  labelText,
  labelId,
  labelStyle,
  labelTooltip,
  isSkeleton,
  className,
  children,
}: React.PropsWithChildren<LabelledGroupProps>) {
  const classes = classNames('c-labelled-group', {
    [`${className}`]: !!className,
  })

  return (
    <fieldset className={classes}>
      <Label
        className='c-labelled-group__label'
        labelText={labelText}
        labelId={labelId ?? `${labelText?.replace(' ', '-')}-label`}
        labelTag='legend'
        labelStyle={labelStyle}
        isSkeleton={isSkeleton}
      >
        {labelTooltip}
      </Label>
      {children}
    </fieldset>
  )
}
