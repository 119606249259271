import React from 'react'
import { IconType } from '../../../types/icon-types'
import { Icon } from '../icon'

export interface IconWithBadgeProps {
  icon: IconType
  iconAriaLabel: string
  badgeCount?: number
}

export function IconWithBadge({ icon, iconAriaLabel, badgeCount = 0 }: IconWithBadgeProps) {
  return (
    <span className='c-icon-with-badge'>
      <Icon className='c-icon-with-badge__icon' icon={icon} ariaLabel={iconAriaLabel} />
      {badgeCount > 0 && (
        <span className='c-icon-with-badge__badge' aria-label={`Number of ${iconAriaLabel}`}>
          {badgeCount > 99 ? '99+' : badgeCount}
        </span>
      )}
    </span>
  )
}
