/**
 * Trims leading and trailing 0s, and returns a hyphen if no number passed
 * @param value Number value to trim (as string)
 * @returns Trimmed number value or `-` if no number passed
 */
export function trimZerosFromNumber(value: string): string {
  if (value == null || value.trim() === '') return '-'
  // Trim 0s from the LHS and RHS of the number. Interesting hack of converting to a number and then back to string
  return Number(value).toString() || '-'
}
