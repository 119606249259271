import { Outlet } from 'react-router-dom'
import { useCallback, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from '../auth'
import { LoadingPage } from './loading-page'
import { Button } from '@msaf/core-react'
import { ErrorPage } from './error-page'
import { ErrorCode } from './errors/constants'

export const ProtectedRoute = () => {
  const { isAuthenticated, isLoading, error, login, logout } = useAuth()
  const { pathname } = useLocation()

  // If Keycloak is not initialised throw Error to be handled by error boundary
  if (error === ErrorCode.KEYCLOAK_ERROR) {
    throw new Error(error)
  }

  const loginWithRedirect = useCallback(() => login({ redirectPath: pathname }), [login, pathname])

  useEffect(() => {
    if (!isLoading && !isAuthenticated && !error) loginWithRedirect()
  }, [isLoading, isAuthenticated, error])

  if (!isLoading && !!error) {
    console.warn('Login failed:', error)
    return (
      <ErrorPage header='Authentication error'>
        <Button label='Retry' onClick={async () => await logout()}></Button>
        <p>
          Please <a href='/contact-us'>contact us</a> if the problem persists.
        </p>
      </ErrorPage>
    )
  }

  if (isLoading || !isAuthenticated) return <LoadingPage />

  return <Outlet />
}
