/**
 * Formats a given number of bytes as a human readable bytes amount
 * e.g. 1024 bytes => 1.0 KiB
 * @param value Number (of bytes) to format
 * @returns Number formatted as human readable byte amount
 */
export function numberToHumanSize(value: number): string {
  // based on https://stackoverflow.com/a/41402498
  let u = 0
  const s = 1024
  while (value >= s || -value >= s) {
    value /= s
    u++
  }
  return (u ? value.toFixed(1) + ' ' : value) + ' KMGTPEZY'[u] + 'iB'
}
