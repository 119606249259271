import React from 'react'
import classNames from 'classnames'
import { BaseButtonProps } from '../index'

export interface SkeletonButtonProps extends BaseButtonProps {
  iconOnly: boolean
}

export function SkeletonButton({ size, iconOnly }: SkeletonButtonProps) {
  const skeletonClasses = classNames('c-skeleton', 'c-skeleton--btn', {
    'c-skeleton--large-btn': size === 'large',
    'c-skeleton--icon-only': iconOnly,
  })

  return (
    <div className={skeletonClasses}>
      <div className='c-skeleton c-skeleton--btn-label'></div>
    </div>
  )
}
