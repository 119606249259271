import { format } from 'date-fns'
import { DISPLAY_DATETIME_FORMAT } from '../constants'

/**
 * Formats date using given format.
 * Defaults to using a human-readable timestamp format.
 * @param timestamp Date to format
 * @param dateFormat Format to use
 * @returns Formatted date
 */
export function formatTimestamp(timestamp: string | Date, dateFormat: string = DISPLAY_DATETIME_FORMAT): string {
  if (!timestamp) {
    return '-'
  }

  return format(new Date(timestamp), dateFormat)
}
