import { DEFAULT_MIME_TYPES, DEFAULT_FILE_EXTENSIONS } from '../../../../constants'
import { splitFileName } from '../utils'

export interface FileValidationResult {
  isValid: boolean
  error?: string
}
export type FileValidator = (file: File) => FileValidationResult

export const DEFAULT_ALLOWED_FILE_TYPES: string[] = DEFAULT_MIME_TYPES
export const DEFAULT_ALLOWED_FILE_EXTENSIONS: string[] = DEFAULT_FILE_EXTENSIONS
export const DEFAULT_FILE_NAME_MAX_LENGTH: number = 255
export const DEFAULT_MAX_FILE_SIZE: number = 10
export const DEFAULT_INVALID_FILE_NAME_REGEX = [
  // eslint-disable-next-line no-control-regex
  /[<>:"/\\|?*\u0000-\u001F]/g,
  /^(con|prn|aux|nul|com\d|lpt\d)$/i,
]

export const nameValidator = (invalidRegex: RegExp[] = DEFAULT_INVALID_FILE_NAME_REGEX): FileValidator => {
  return (file: File) => {
    const name = splitFileName(file.name.toLowerCase())[0]
    if (name === '.' || name === '') {
      return {
        isValid: false,
        error: 'File name is invalid.',
      }
    }

    for (let i = 0; i < invalidRegex.length; i++) {
      if (name.match(invalidRegex[i])) {
        return {
          isValid: false,
          error: 'File name is invalid.',
        }
      }
    }

    return { isValid: true }
  }
}

export const extensionValidator = (allowedExtensions: string[] = DEFAULT_ALLOWED_FILE_EXTENSIONS): FileValidator => {
  return (file: File) => {
    const extension = splitFileName(file.name.toLowerCase()).pop()
    if (!(extension && allowedExtensions.includes(extension))) {
      return {
        isValid: false,
        error: `File extension is not valid. Valid extensions include: ${allowedExtensions.join(', ')}.`,
      }
    }

    return { isValid: true }
  }
}

export const mimeTypeValidator = (fileTypes: String[] = DEFAULT_ALLOWED_FILE_TYPES): FileValidator => {
  return (file: File) => {
    if (!fileTypes.includes(file.type)) {
      return {
        isValid: false,
        error: `Invalid file type. Valid file types include: ${fileTypes.join(', ')}.`,
      }
    }

    return { isValid: true }
  }
}

export const nameLengthValidator = (max_length = DEFAULT_FILE_NAME_MAX_LENGTH): FileValidator => {
  return (file: File) => {
    if (file.name.length > max_length) {
      return {
        isValid: false,
        error: 'File name is too long.',
      }
    }

    return { isValid: true }
  }
}

export const fileSizeValidator = (max_size = DEFAULT_MAX_FILE_SIZE): FileValidator => {
  return (file: File) => {
    if (Math.round(file.size / 1024 / 1024) > max_size) {
      return {
        isValid: false,
        error: `File is too large. Max file size is ${max_size}MB`,
      }
    }

    return { isValid: true }
  }
}

interface ValidatorProps {
  name?: RegExp[]
  extension?: string[]
  mimeType?: string[]
  nameLength?: number
  size?: number
}

export const buildDefaultValidators = ({
  name = DEFAULT_INVALID_FILE_NAME_REGEX,
  extension = DEFAULT_ALLOWED_FILE_EXTENSIONS,
  mimeType = DEFAULT_ALLOWED_FILE_TYPES,
  nameLength = DEFAULT_FILE_NAME_MAX_LENGTH,
  size = DEFAULT_MAX_FILE_SIZE,
}: ValidatorProps) => {
  const validators = []
  if (name) {
    validators.push(nameValidator(name))
  }
  if (extension) {
    validators.push(extensionValidator(extension))
  }
  if (mimeType) {
    validators.push(mimeTypeValidator(mimeType))
  }
  if (nameLength) {
    validators.push(nameLengthValidator(nameLength))
  }
  if (size) {
    validators.push(fileSizeValidator(size))
  }

  return validators
}

export const DEFAULT_VALIDATORS: FileValidator[] = buildDefaultValidators({})
