import { ErrorCode } from './components/errors/constants'
import { ContentImageProps, MainMenuItemProps } from '@msaf/core-react'
import ncapLogo from '../assets/TeUruKahika_Logo_Land-icon.png'
import TeUruKahikaLogo from '../assets/TeUruKahika_Logo_Land.png'

// ****** MISC

export const DEBOUNCE_DELAY = 300

// ****** DATE
export const DISPLAY_DATE_FORMAT = 'dd/MM/yyyy'

export const DATEPICKER_POPPER_OPTS = [
  {
    name: 'flip',
    options: {
      behavior: ['bottom'],
    },
  },
  {
    name: 'preventOverflow',
    options: {
      enabled: false,
    },
  },
  {
    name: 'hide',
    options: {
      enabled: false,
    },
  },
]

// ****** BRANDING
export const APP_NAME = 'Synthetic nitrogen use recording tool'

export const APP_LOGO = {
  iconLogoImagePath: ncapLogo,
  fullLogoImagePath: ncapLogo,
  logoTextLine1: 'Nitrogen',
  logoTextLine2: 'Use Recording',
  altText: 'Ncap',
  icon: undefined,
}

// TODO this is not exported form core-react?
export interface BrandingLogoProps {
  isSkeleton?: boolean
  link?: string
  altText?: string
  imagePath?: string
  logoLayout?: 'rectangle' | 'wide-rectangle'
}

export const BASE_BRANDING_LOGO: BrandingLogoProps = {
  imagePath: TeUruKahikaLogo,
  altText: 'Te Uru Kahika - Regional and Unitary Councils Aotearoa',
  logoLayout: 'wide-rectangle',
}

// Logos displayed in footer of landing page
export const FOOTER_LOGOS = [
  {
    imgPath: TeUruKahikaLogo,
    altText: 'Te Uru Kahika - Regional and Unitary Councils Aotearoa',
  },
]

// ****** LINKS
export const NAV_PANEL_LINKS: MainMenuItemProps[] = [
  {
    label: 'Dashboard',
    path: '/dashboard',
    icon: 'dashboard',
  },
  {
    label: 'Help centre',
    path: `/help-centre`,
    icon: 'question-mark-circle',
    isNewSection: true,
  },
  {
    label: 'Contact us',
    path: '/contact-us',
    icon: 'phone',
    isNewSection: true,
  },
]

export const FOOTER_MENU_LINKS = [
  {
    label: 'Contact us',
    path: '/contact-us',
  },
  {
    label: 'Help centre',
    path: '/help-centre',
  },
  {
    label: 'Register',
    path: '/?register=true',
  },
  {
    label: 'Sign in',
    path: '/?login=true',
  },
]

export const UNAUTHENTICATED_MAIN_MENU_LINKS: MainMenuItemProps[] = [
  {
    label: 'Home',
    icon: 'dashboard',
    path: '/',
  },
  {
    label: 'Contact us',
    icon: 'phone',
    path: '/contact-us',
    isNewSection: true,
  },
  {
    label: 'Help centre',
    icon: 'question-mark-circle',
    path: `/help-centre`,
  },
]

export const SCROLL_TARGET = '.l-record-container__content'

export const REGIONAL_COUNCIL_OPTIONS = [
  { value: '1', label: 'Northland Regional Council' },
  { value: '2', label: 'Auckland Council' },
  { value: '3', label: 'Waikato Regional Council' },
  { value: '4', label: 'Bay of Plenty Regional Council' },
  { value: '5', label: 'Gisborne District Council' },
  { value: '6', label: "Hawke's Bay Regional Council" },
  { value: '7', label: 'Taranaki Regional Council' },
  { value: '8', label: 'Horizons Regional Council' },
  { value: '9', label: 'Greater Wellington Regional Council' },
  { value: '12', label: 'West Coast Regional Council' },
  { value: '13', label: 'Environment Canterbury' },
  { value: '14', label: 'Otago Regional Council' },
  { value: '15', label: 'Environment Southland' },
  { value: '16', label: 'Tasman District Council' },
  { value: '17', label: 'Nelson City Council' },
  { value: '18', label: 'Marlborough District Council' },
  { value: '99', label: 'Other - Area Outside Region' },
]

const DISPLAY_ADDITIONAL_REPORTING_YEARS = 3

const getLandHoldingYearOptions = (displayAdditionalYears = DISPLAY_ADDITIONAL_REPORTING_YEARS) => {
  const lastYear = new Date().getFullYear() - 1
  const offsetYears = Array.from(Array(displayAdditionalYears).keys())
  return offsetYears.map((offset) => {
    return { value: `${lastYear + offset}`, label: `${lastYear + offset}` }
  })
}
export const LANDHOLDING_YEAR_OPTIONS = getLandHoldingYearOptions()

export const TOOLTIP_MESSAGE = {
  areaRegionalCouncil:
    'Please select multiple reporting regional councils if this land holding spans multiple regional council boundaries',
  areaGrazedLand: 'Total area of land used for Grazing in the Contiguous Land (in hectares)',
  areaGrazedForageCrops: 'Total area of Grazed Land used for Forage Crop in the Contiguous Land (in hectares)',
  areaGrazedNonForageCrops: 'Total area of Grazed Land NOT used for Forage Crop in the Contiguous Land (in hectares)',
  areaUnGrazedLand: 'Total area of Ungrazed Land in the Contiguous Land (in hectares)',
  appliedGrazedLand: 'Total Nitrogen KG / Ha / year applied on land used for Grazing in the Contiguous Land',
  appliedGrazedForageCrops:
    'Total Nitrogen KG / Ha / year applied on land used for Grazing Forage in the Contiguous Land',
  appliedGrazedNonForageCrops:
    'Total Nitrogen KG / Ha / year applied on Grazed Land NOT used for Forage Crop in the Contiguous Land',
  appliedUnGrazedLand: 'Total Nitrogen KG / Ha / year applied on land not used for Grazing in the Contiguous Land',
  farmAreaTotal:
    'Total area of land associated to Farm Operation (in hectares) including the races, milking shed, homestead area, cropping areas and non-dairy paddocks',
  farmAreaEffective:
    'Total effective area of land associated with the dairy Farm Operation (in hectares) i.e. excluding races, homestead, land not used for grazing or feeding the milking herd',
  nitrogenPercentRate: 'Nitrogen Percent Rate applied',
  fertiliserType: "Manufacturer's Product Code/Name of Fertiliser applied to Contiguous Land.",
  hasMetFertilizerPerGrazedPasture:
    'The grazed pasture limit of 190kg/N/ha has been followed for the relevant landholdings in the applicable reporting year',
  areaEffective: 'Total effective / farmed area of land associated to Farm Operation (in hectares)',
  dairySupplyNumber: 'If you have more than one dairy supply number, please enter your primary number',
  hasDataConsent:
    'Consent to data provided being shared with the relevant regional council and aggregated information to MfE',
  purchaseQuantity: 'The quantity purchased of the product',
  measure: 'The units of measure of the purchased product - metric tonne or litres',
  resourceConsent: 'If entering multiple resource consent numbers, please separate with a comma.',
}

export const DEFAULT_TABLE_RESULTS_PER_PAGE = 20

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const errorBoundaryIncludes = (error: any) =>
  [
    ErrorCode.NOT_FOUND,
    ErrorCode.PERMISSION_DENIED,
    ErrorCode.SESSION_TIMEOUT,
    ErrorCode.SERVER_ERROR,
    ErrorCode.EXTERNAL_API_TIMEOUT,
  ].includes(error.message)
export interface Contact {
  name: string
  link: string
  additionalResources: { link?: string; label: string }[]
  image?: ContentImageProps
}

export const CONTACT_DATA = [
  {
    councilName: 'Auckland Council',
    phone: '09 301 0101',
    email: 'monitoring@aucklandcouncil.govt.nz',
  },
  {
    councilName: 'Bay of Plenty Regional Council',
    phone: '0800 884 880',
    email: 'ncap@boprc.govt.nz',
  },
  {
    councilName: 'Environment Canterbury',
    phone: '0800 324 636',
    email: 'ncap@ecan.govt.nz',
  },
  {
    councilName: 'Environment Southland',
    phone: '03 211 5115',
    email: 'escompliance@es.govt.nz',
  },
  {
    councilName: 'Gisborne District Council',
    phone: '',
    email: 'fep@gdc.gvot.nz',
  },
  {
    councilName: 'Greater Wellington Regional Council',
    phone: '0800 496 734',
    email: 'notifications@gw.govt.nz',
  },
  {
    councilName: 'Hawke’s Bay Regional Council',
    phone: '0800 108 838',
    email: 'ComplianceReturns@hbrc.govt.nz',
  },
  {
    councilName: 'Horizons Regional Councill',
    phone: '0508 800 800',
    email: 'NitrogenReporting@horizons.govt.nz',
  },
  {
    councilName: 'Marlborough District Council',
    phone: '03 520 7400',
    email: 'mdc@marlborough.govt.nz',
  },
  {
    councilName: 'Nelson City Council',
    phone: '03 546 0200',
    email: 'enquiry@ncc.govt.nz',
  },
  {
    councilName: 'Northland Regional Council',
    phone: '0800 002 004',
    email: 'info@nrc.govt.nz',
  },
  {
    councilName: 'Otago Regional Council',
    phone: '0800 474 082',
    email: 'compliance@orc.govt.nz',
  },
  {
    councilName: 'Taranaki Regional Council',
    phone: '0800 736 222',
    email: 'info@trc.govt.nz',
  },
  {
    councilName: 'Tasman District Council',
    phone: '03 543-8400',
    email: 'info@tasman.govt.nz',
  },
  {
    councilName: 'Waikato Regional Council',
    phone: '',
    email: 'rudbusinesssupport@waikatoregion.govt.nz',
  },
  {
    councilName: 'West Coast Regional Council',
    phone: '0508 800 118',
    email: 'wcrccompliance@wcrc.govt.nz',
  },
]

// ****** MAPPING
const MAPBOX_ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN
export const MAPBOX_BASEMAP_URL = `https://api.mapbox.com/styles/v1/n-cap-webform/clhtpxzl600o701pz0k9rcixt/tiles/{z}/{x}/{y}@2x?access_token=${MAPBOX_ACCESS_TOKEN}`
