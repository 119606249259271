import { useEffect } from 'react'
import { createToastMessage, ToastMessage } from '@msaf/core-react'

interface UseToastMessageArgs {
  error?: unknown
  errorMessage?: string
  isSuccess?: boolean
  isError?: boolean
  successMessage?: string
}

export default function useToastMessage({
  error,
  errorMessage = 'Could not perform the requested action',
  successMessage = 'Success',
  isError,
  isSuccess,
}: UseToastMessageArgs) {
  useEffect(() => {
    if (isSuccess && successMessage) {
      createToastMessage(<ToastMessage messageType='success' title='Success' message={successMessage} />)
    }

    if (isError && errorMessage) {
      createToastMessage(<ToastMessage messageType='error' title='Error' message={errorMessage} />)
    }
  }, [isError, error, isSuccess, successMessage])
}
