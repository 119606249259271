import React from 'react'
import { HeadingRow, Icon } from '@msaf/core-react'

export interface MapPanelContentProps {
  heading: string | JSX.Element
  closePanel: () => void
}

export function MapPanelContent({ heading, closePanel, children }: React.PropsWithChildren<MapPanelContentProps>) {
  return (
    <>
      <HeadingRow
        headingLevel={1}
        headingText={heading}
        headingClassNames='c-map-menu-panel__heading'
        verticalSpacing='none'
      >
        <button className='c-map-menu-panel__close-btn' onClick={() => closePanel()}>
          <Icon icon='close' ariaLabel='Close menu panel' />
        </button>
      </HeadingRow>
      {children}
    </>
  )
}
