import React, { forwardRef } from 'react'
import * as ReactRouter from 'react-router-dom'
import * as history from 'history'

export type To = history.To

export interface NavLinkProps extends Omit<React.ComponentPropsWithRef<'a'>, 'style' | 'className'> {
  to: To
  exact?: boolean
  style?: React.CSSProperties | ((props: { isActive: boolean }) => React.CSSProperties)
  className?: string | ((props: { isActive: boolean }) => string)
}

/*
Wraps the react-router-dom library NavLink to limit the scope of properties that can be changed.
*/
export const NavLink = forwardRef<HTMLAnchorElement, NavLinkProps>(({ children, exact, ...props }, ref) => {
  return (
    <ReactRouter.NavLink ref={ref} end={exact} {...props}>
      {children}
    </ReactRouter.NavLink>
  )
})
