import React from 'react'
import classNames from 'classnames'
import { MainMenuLink, MainMenuLinkProps } from './main-menu-link'

export interface MainMenuItemProps extends MainMenuLinkProps {
  isNewSection?: boolean
}
export interface MainMenuLinksProps {
  links?: MainMenuItemProps[]
  closeMenu: VoidFunction
  isCollapsed?: boolean
  isSkeleton?: boolean
}

export function MainMenu({ links, closeMenu, isCollapsed, isSkeleton }: MainMenuLinksProps) {
  return isSkeleton ? (
    <div className='c-main-menu'>
      <div className='c-main-menu__items'>
        <div className='c-skeleton c-skeleton--menu-link'></div>
        <div className='c-skeleton c-skeleton--menu-link'></div>
        <div className='c-skeleton c-skeleton--menu-link'></div>
        <div className='c-skeleton c-skeleton--menu-link'></div>
      </div>
    </div>
  ) : (
    <nav className='c-main-menu' aria-label='Main'>
      <ul className='c-main-menu__items'>
        {links &&
          links.map(({ label, icon, path, isNewSection, exact }) => {
            const classes = classNames('c-main-menu__item', {
              'c-main-menu__item--new-section': isNewSection,
            })
            return (
              <li key={label} className={classes}>
                <MainMenuLink
                  label={label}
                  icon={icon}
                  path={path}
                  exact={exact}
                  closeMenu={closeMenu}
                  isCollapsed={isCollapsed}
                />
              </li>
            )
          })}
      </ul>
    </nav>
  )
}
